import { ConsentManagerBuilder } from '@segment/consent-manager'
import inEU from '@segment/in-eu'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

export const ConsentCTA = {
  All: 'Accept all',
  Close: 'Close modal',
  RequiredOnly: 'Required cookies only',
} as const

export type BannerContentComponent = React.FC<{
  saveConsent: (newPreferences: boolean) => void
  setCTA: (cta: string) => void
}>

// Allow to set the cookie domain and make the consent cookie available to all subdomains
const COOKIE_CONSENT_DOMAIN = process.env.COOKIE_CONSENT_DOMAIN

const CookieBannerWrapper: React.FC<{
  saveConsent: (newPreferences: boolean) => void
  preferences: object
  BannerContent: BannerContentComponent
}> = ({ saveConsent, preferences, BannerContent }) => {
  const { asPath, push } = useRouter()

  // Store the open/close state of the banner
  const [open, setOpen] = useState(false)

  // Store the CTA button that is pushed
  const [cta, setCTA] = useState<string | undefined>()

  // If the anchor #update-cookies is used, show the cookie consent notice banner
  useEffect(() => {
    const [url, hash] = asPath.split('#')
    if (hash === 'update-cookies') {
      setOpen(true)
      push(url, undefined, { shallow: true })
    }
  }, [asPath, push])

  const [prevConsent, setPrevConsent] = useState(preferences)

  // Send preference update to consent log API
  useEffect(() => {
    if (JSON.stringify(prevConsent) !== JSON.stringify(preferences)) {
      const postConsent = async () => {
        await fetch('/api/consent/log', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ cta, preferences }),
        })
      }
      postConsent().catch(console.error)

      setPrevConsent(preferences)
    }
    // Show consent banner only preferences are not set yet
    setOpen(Object.keys(preferences).length === 0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preferences])

  return (
    <>
      {open && (
        <BannerContent
          saveConsent={saveConsent}
          setCTA={setCTA}
        />
      )}
    </>
  )
}

export const CookieConsent: React.FC<{
  segmentWriteKey: string | undefined
  BannerContent: BannerContentComponent
}> = ({ segmentWriteKey, BannerContent }) => {

  // Render nothing if segment write key is not provided
  if (!segmentWriteKey) return null

  return (
    <ConsentManagerBuilder
      writeKey={segmentWriteKey}
      cookieDomain={COOKIE_CONSENT_DOMAIN}
      shouldRequireConsent={inEU}
    >
      {({ saveConsent, preferences }) => (
        <CookieBannerWrapper saveConsent={saveConsent} preferences={preferences} BannerContent={BannerContent} />
      )}
    </ConsentManagerBuilder>
  )
}

export default CookieConsent
