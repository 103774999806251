import { makeStyles } from '@material-ui/core/styles'
import React, { FC } from 'react'

/* 
 * Helper to let nodes appear with animations.
   Just wrap anything with <Appear> and use the sequence, duration and delayMultiplier for timings.

  Different then Transitions from MUI: this will just automatically animate upon DOM entry.

  Currently supports the following animations:
 */

export enum Animation {
  SCALE = 'scale',
  FADE = 'fade',
}

const useStyles = makeStyles((theme) => ({
  root: {
    animationFillMode: 'forwards', // ensure animation freezes at last keyframe
    animationTimingFunction: `${theme.transitions.easing.easeInOut}`,
  },

  [Animation.SCALE]: {
    transform: 'scale(0.0)', // same as initial keyframe
    animationName: '$scale',
  },
  '@keyframes scale': {
    '0%': {
      transform: 'scale(0.0)',
    },
    '50%': {
      transform: 'scale(1.08)',
    },
    '100%': {
      transform: 'scale(1.0)',
    },
  },

  [Animation.FADE]: {
    opacity: 0, // same as initial keyframe
    animationName: '$fade',
  },
  '@keyframes fade': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}))

export interface Props {
  children: React.ReactNode
  animation?: Animation
  sequence?: number
  delayMultiplier?: number
  duration?: number
}

export const Appear: FC<Props> = ({
  children,
  animation = Animation.FADE,
  sequence = 0,
  delayMultiplier = 500,
  duration = 800,
}) => {
  const classes = useStyles()

  return (
    <div
      className={`${classes.root} ${classes[animation!]}`}
      style={{
        animationDelay: `${sequence! * delayMultiplier!}ms`,
        animationDuration: `${duration}ms`,
      }}
    >
      {children}
    </div>
  )
}
