import { NormalizedCacheObject } from '@apollo/client'
import { ThemeProvider, Theme, CssBaseline } from '@material-ui/core'
import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { IntlProvider } from 'react-intl'

import ApolloProvider from '@aletheia/common/components/Apollo'
import { ReferralsProvider } from '@aletheia/common/components/Referrals'

export type SharedProvidersProps = {
  theme: Theme
  apolloState: NormalizedCacheObject
  requiredRoles?: string[]
}

const SharedProviders: React.FC<SharedProvidersProps> = ({
  children,
  theme,
  apolloState,
}) => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CookiesProvider>
          <ReferralsProvider>
            <ApolloProvider state={apolloState}>
              <IntlProvider locale="en-US">{children}</IntlProvider>
            </ApolloProvider>
          </ReferralsProvider>
        </CookiesProvider>
      </ThemeProvider>
    </>
  )
}

export default SharedProviders
