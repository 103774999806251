import {
  TNavRoutes,
  TRoute,
  TRouteDivider,
} from '@aletheia/common/components/Nav'

export const GWWC_DONATE_URL =
  process.env.GWWC_DONATE_URL || 'https://www.givingwhatwecan.org'
export const GWWC_DONATE_FULL_URL = `${GWWC_DONATE_URL}/funds/effective-altruism-funds?utm_source=eafunds`

const fundsRoutes: (TRoute | TRouteDivider)[] = [
  {
    href: '/funds/[slug]',
    as: '/funds/global-development',
    label: 'Global Health and Development',
  },
  {
    href: '/funds/[slug]',
    as: '/funds/animal-welfare',
    label: 'Animal Welfare',
  },
  {
    href: '/funds/[slug]',
    as: '/funds/far-future',
    label: 'Long-Term Future',
  },
  {
    href: '/funds/[slug]',
    as: '/funds/ea-community',
    label: 'EA Infrastructure',
  },
]

const aboutChildren: TRoute[] = [
  {
    href: '/about-us',
    label: 'What is EA Funds?',
  },
  {
    href: '/grantmaking-approach',
    label: 'Grantmaking Approach',
  },
  {
    href: '/team',
    label: 'Our Team',
  },
  {
    href: 'https://toothsome-truffle-ec7.notion.site/Careers-at-EA-Funds-4d8add64d93d4bf98ad6999dff029dc1',
    label: 'Careers',
  },
  {
    href: '/stats',
    label: 'Our Stats',
  },
  {
    href: '/faq',
    label: 'FAQ',
  },
]

/** Routes for the main navigation menu */
export const routes: TNavRoutes = [
  {
    href: '/',
    label: 'Home',
    hideOnDesktop: true,
    hideOnMobile: true,
  },
  {
    basePath: '/funds',
    children: fundsRoutes,
    label: 'Funds',
  },
  {
    href: '/grants',
    label: 'Grants Database',
  },
  {
    basePath: '/about-us',
    label: 'About',
    children: aboutChildren,
  },
  {
    href: GWWC_DONATE_FULL_URL,
    label: 'Donate',
  },
  {
    href: '/[...path]',
    as: '/apply-for-funding',
    label: 'Get Funded',
    color: 'primary',
    variant: 'contained',
  },
]

export default routes

/** Routes to show when there's no logged-in user */
export const navUserLoggedOutRoutes: TRoute[] = []
