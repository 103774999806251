import { Theme, createTheme, responsiveFontSizes } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { grey } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core/styles'
import { CSSProperties } from '@material-ui/styles'
import merge from 'lodash/merge'

import { globalStyles, Overrides } from '@aletheia/common/styles'

/**
 * Color palette for the site.
 *
 * MUI Palette docs: https://material-ui.com/customization/palette/
 *
 * Material Color Picker:
 * https://material.io/resources/color/#!/?view.left=0&view.right=0
 */
const palette: Pick<Theme['palette'], 'primary' | 'secondary'> = {
  primary: {
    main: '#0C869B',
    light: '#f0f5f7',
    dark: '#1B5266',
    contrastText: '#FFF',
  },
  secondary: {
    main: '#FC820E',
    light: '#FFB348',
    dark: '#9E4D00',
    contrastText: '#000',
  },
}

/** Font stacks */
const sansSerifStack = 'Raleway, Helvetica, "Helvetica Neue", Arial, sans-serif'
const serifStack = 'Merriweather, Georgia, Times, "Times New Roman", serif'
const monospaceStack =
  'Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;'

/** Base styles for all heading tags */
const headingStyle = {
  fontFamily: sansSerifStack,
  fontWeight: 700,
  lineHeight: 1.2,
  color: '#0C869B',
}

/** Global styles to apply everywhere */
const themeGlobals: Record<string, CSSProperties> = {
  html: {
    scrollBehavior: 'smooth',
    // Height of fixed top menu, allows to scroll to element with padding
    scrollPaddingTop: '90px',
    '@media (max-width: 600px)': {
      scrollPaddingTop: '48px',
    },
  },
  // Make icons that are inlined with text look reasonable
  '.MuiTypography-root, .MuiTableCell-root': {
    '& .MuiSvgIcon-root': {
      position: 'relative',
      top: '0.15em',
      fontSize: '1em',
    },
  },

  '.page-content .MuiTypography-body1': {
    lineHeight: '1.6rem',
    '&.MuiTypography-gutterBottom': {
      marginBottom: '1em',
    },
  },

  '*:target': {
    paddingTop: 'inherit',
    marginTop: 'inherit',
  },
}

/**
 * Global CSS declarations
 *
 * Built by recursively merging theme globals into global styles
 */
const globals = merge({}, globalStyles, themeGlobals)

/** Override MUI components */
const overrides: Overrides = {
  // Use the CSS baseline to create global styles
  MuiCssBaseline: {
    '@global': {
      ...globals,
    },
  },
  MuiTypography: {
    root: {
      '& code': {
        fontFamily: monospaceStack,
        whiteSpace: 'nowrap',
        padding: `4px 5px 6px`,
        background: grey[100],
        color: grey[700],
        fontWeight: 700,
        borderRadius: 5,
      },
      '& .MuiLink-button': {
        marginTop: -1,
      },
    },
  },
  MuiButton: {
    root: {
      fontSize: '1rem',
      fontFamily: sansSerifStack,
      textTransform: 'none',
      fontWeight: 700,
      lineHeight: '1.75',
    },
  },
  MuiMenu: {
    paper: {
      borderRadius: 0,
    },
  },
  MuiLink: {
    button: {
      marginTop: -2,
      font: 'inherit',
      lineHeight: 'inherit',
      textAlign: 'inherit',
    },
  },
  MuiDrawer: {
    root: {
      '& .MuiButton-label': {
        textTransform: 'none',
      },
      '& .MuiListItemText-root .MuiTypography-root': {
        fontFamily: sansSerifStack,
      },
    },
  },
  MuiToggleButtonGroup: {
    root: {
      width: '100%',
    },
  },
  MuiToggleButton: {
    root: {
      borderColor: palette.primary.main,
      fontFamily: sansSerifStack,
      color: palette.primary.main,
      textTransform: 'initial',
      '&.Mui-selected': {
        borderLeftColor: `${palette.primary.main} !important`,
        color: palette.primary.dark,
        backgroundColor: palette.primary.light,
        boxShadow: `inset 0 0 3px ${palette.primary.main}`,
        '&:hover': {
          backgroundColor: `${palette.primary.light} !important`,
        },
      },
    },
  },
  MuiStepIcon: {
    completed: {
      color: `${palette.primary.dark} !important`,
    },
  },
  MuiFormControlLabel: {
    label: {
      fontSize: '0.875rem',
      lineHeight: 1.43,
    },
  },
  MuiChip: {
    label: {
      fontFamily: sansSerifStack,
    },
  },
  MuiTooltip: {
    tooltip: {
      fontFamily: sansSerifStack,
    },
  },
  MuiTab: {
    root: {
      fontFamily: sansSerifStack,
      textTransform: 'initial',
    },
  },
}

const theme = responsiveFontSizes(
  createTheme({
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    typography: {
      htmlFontSize: 16,
      fontSize: 16,
      fontFamily: serifStack,
      body1: {
        lineHeight: 2.2,
      },
      h1: {
        ...headingStyle,
        fontSize: '2.8rem',
      },
      h2: {
        ...headingStyle,
        fontSize: '2.5rem',
      },
      h3: {
        ...headingStyle,
        fontSize: '2.2rem',
      },
      h4: {
        ...headingStyle,
        fontSize: '1.88rem',
      },
      h5: {
        ...headingStyle,
        fontSize: '1.66rem',
      },
      h6: {
        ...headingStyle,
        fontWeight: 400,
        fontSize: '1.46rem',
      },
    },
    palette,
    overrides,
  }),
  {
    factor: 5,
  },
)

export const SansSerifTypography = withStyles({
  root: { fontFamily: sansSerifStack },
})(Typography)

export default theme
