import { makeStyles } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState } from 'react'

import { Appear } from '@aletheia/common/components/Appear'

import Link from '../Link'
import NavDesktop from './NavDesktop'
import NavMobile from './NavMobile'
import { TNavRoutes, TNavRoutesFlat, TRoute, TRouteMember } from './types'

export * from './types'
export * from './utils'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
  },
  toolbar: {
    justifyContent: 'space-between',
    width: '100%',
  },
  desktopNavigation: {
    flexGrow: 1,
    ...theme.mixins.toolbar,
  },
  noLoginDesktopNavigation: {
    ...theme.mixins.toolbar,
  },
  logoWrapper: {
    flexGrow: 1,
  },
  logo: {
    height: 40,
    marginRight: theme.spacing(4),
  },
  [theme.breakpoints.up('md')]: {
    logoWrapper: {
      flexGrow: 0,
    },
  },
  spacer: {
    [theme.breakpoints.up('md')]: {
      marginBottom: 8,
    },
    ...theme.mixins.toolbar,
  },
}))

export interface NavProps {
  Logo: React.ElementType
  Content?: React.ElementType
  routes: TNavRoutes
  navUserLoggedInRoutes?: TNavRoutesFlat
  navUserLoggedOutRoutes?: TNavRoutesFlat
}

function isHomeRoute(route: TRouteMember): route is TRoute {
  return route && route !== 'DIVIDER' && route.label === 'Home'
}

const Nav: React.FC<NavProps> = (props) => {
  const classes = useStyles()
  const [showNavDrawer, setShowNavDrawer] = useState(false)
  const { Logo, navUserLoggedInRoutes, navUserLoggedOutRoutes, routes } = props
  const noLogin = !navUserLoggedInRoutes && !navUserLoggedOutRoutes
  const homeRoute = routes.filter(isHomeRoute)[0]

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="inherit" elevation={2}>
        <Container fixed>
          <Appear>
            <Toolbar disableGutters className={classes.toolbar}>
              <div className={classes.logoWrapper}>
                <Link
                  href={homeRoute?.href || '/'}
                  title={homeRoute?.label || 'Home'}
                >
                  <Logo className={classes.logo} />
                </Link>
              </div>
              <Hidden smDown>
                <div
                  className={
                    noLogin
                      ? classes.noLoginDesktopNavigation
                      : classes.desktopNavigation
                  }
                >
                  <NavDesktop {...props} />
                </div>
              </Hidden>
              <Hidden mdUp>
                <IconButton
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setShowNavDrawer(!showNavDrawer)}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Toolbar>
          </Appear>
        </Container>
      </AppBar>
      <div className={classes.spacer} />
      <NavMobile
        {...props}
        open={showNavDrawer}
        onClose={() => setShowNavDrawer(false)}
        onOpen={() => setShowNavDrawer(true)}
      />
    </div>
  )
}

export default Nav
