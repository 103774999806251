/**** GIVING WHAT WE CAN - UK ****/

// GWWC UK launch date: 26th August 2024 at 10 AM UTC
const GWWC_UK_LAUNCH_DATETIME = new Date('2024-08-26T10:00:00Z')
const now = new Date()
const isPastLaunchDatetime = now > GWWC_UK_LAUNCH_DATETIME

// Launch GWWC UK if feature flag is enabled or if the launch date has passed
export const enableGWWCUK =
  process.env.NEXT_PUBLIC_FEATURE_FLAG_GWWC_UK_ENABLED === 'true' ||
  isPastLaunchDatetime
