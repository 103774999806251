// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    Intercom: Intercom_.IntercomStatic
  }
}

/** Helper to get Intercom from the window object */
export const useIntercom = (): Intercom_.IntercomStatic | undefined => {
  return typeof window !== 'undefined' ? window.Intercom : undefined
}
