import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: string;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: string;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: string;
  /** The day, does not include a time. */
  Date: string;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: unknown;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: string;
};

export enum AccountingSystem {
  Netsuite = 'NETSUITE',
  Xero = 'XERO',
  XeroCash = 'XERO_CASH'
}

/** A connection to a list of `ActiveExtendedLottery` values. */
export type ActiveExtendedLotteriesConnection = {
  __typename?: 'ActiveExtendedLotteriesConnection';
  /** A list of edges which contains the `ActiveExtendedLottery` and cursor to aid in pagination. */
  edges: Array<ActiveExtendedLotteriesEdge>;
  /** A list of `ActiveExtendedLottery` objects. */
  nodes: Array<ActiveExtendedLottery>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ActiveExtendedLottery` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ActiveExtendedLottery` edge in the connection. */
export type ActiveExtendedLotteriesEdge = {
  __typename?: 'ActiveExtendedLotteriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ActiveExtendedLottery` at the end of the edge. */
  node: ActiveExtendedLottery;
};

/** Methods to use when ordering `ActiveExtendedLottery`. */
export enum ActiveExtendedLotteriesOrderBy {
  BlockSizeAsc = 'BLOCK_SIZE_ASC',
  BlockSizeDesc = 'BLOCK_SIZE_DESC',
  CloseTimestampAsc = 'CLOSE_TIMESTAMP_ASC',
  CloseTimestampDesc = 'CLOSE_TIMESTAMP_DESC',
  DrawEpochAsc = 'DRAW_EPOCH_ASC',
  DrawEpochDesc = 'DRAW_EPOCH_DESC',
  DrawTimestampAsc = 'DRAW_TIMESTAMP_ASC',
  DrawTimestampDesc = 'DRAW_TIMESTAMP_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LockTimestampAsc = 'LOCK_TIMESTAMP_ASC',
  LockTimestampDesc = 'LOCK_TIMESTAMP_DESC',
  Natural = 'NATURAL',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  WinningNumberAsc = 'WINNING_NUMBER_ASC',
  WinningNumberDesc = 'WINNING_NUMBER_DESC'
}

export type ActiveExtendedLottery = {
  __typename?: 'ActiveExtendedLottery';
  blockSize?: Maybe<Scalars['BigFloat']>;
  closeTimestamp?: Maybe<Scalars['Datetime']>;
  drawEpoch?: Maybe<Scalars['Int']>;
  drawTimestamp?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['BigInt']>;
  lockTimestamp?: Maybe<Scalars['Datetime']>;
  status?: Maybe<Scalars['String']>;
  winningNumber?: Maybe<Scalars['BigInt']>;
};

/**
 * A condition to be used against `ActiveExtendedLottery` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ActiveExtendedLotteryCondition = {
  /** Checks for equality with the object’s `blockSize` field. */
  blockSize?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `closeTimestamp` field. */
  closeTimestamp?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `drawEpoch` field. */
  drawEpoch?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `drawTimestamp` field. */
  drawTimestamp?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `lockTimestamp` field. */
  lockTimestamp?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `winningNumber` field. */
  winningNumber?: InputMaybe<Scalars['BigInt']>;
};

export type ActiveRecipientProgram = {
  __typename?: 'ActiveRecipientProgram';
  organizationSlug: Scalars['String'];
  recipientSlug: Scalars['String'];
};

/**
 * A condition to be used against `ActiveRecipientProgram` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ActiveRecipientProgramCondition = {
  /** Checks for equality with the object’s `organizationSlug` field. */
  organizationSlug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `recipientSlug` field. */
  recipientSlug?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `ActiveRecipientProgram` values. */
export type ActiveRecipientProgramsConnection = {
  __typename?: 'ActiveRecipientProgramsConnection';
  /** A list of edges which contains the `ActiveRecipientProgram` and cursor to aid in pagination. */
  edges: Array<ActiveRecipientProgramsEdge>;
  /** A list of `ActiveRecipientProgram` objects. */
  nodes: Array<ActiveRecipientProgram>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ActiveRecipientProgram` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ActiveRecipientProgram` edge in the connection. */
export type ActiveRecipientProgramsEdge = {
  __typename?: 'ActiveRecipientProgramsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ActiveRecipientProgram` at the end of the edge. */
  node: ActiveRecipientProgram;
};

/** Methods to use when ordering `ActiveRecipientProgram`. */
export enum ActiveRecipientProgramsOrderBy {
  Natural = 'NATURAL',
  OrganizationSlugAsc = 'ORGANIZATION_SLUG_ASC',
  OrganizationSlugDesc = 'ORGANIZATION_SLUG_DESC',
  RecipientSlugAsc = 'RECIPIENT_SLUG_ASC',
  RecipientSlugDesc = 'RECIPIENT_SLUG_DESC'
}

/** All input for the `addReminder` mutation. */
export type AddReminderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  newsletterSignup?: InputMaybe<Scalars['Boolean']>;
  reminderDate?: InputMaybe<Scalars['Date']>;
  reminderSourcePath?: InputMaybe<Scalars['String']>;
};

/** The output of our `addReminder` mutation. */
export type AddReminderPayload = {
  __typename?: 'AddReminderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  reminder?: Maybe<Reminder>;
};

export type Address = Node & {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressType?: Maybe<AddressType>;
  city?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `ContactPreference`. */
  contactPreferencesByCurrentAddressId: ContactPreferencesConnection;
  /** Reads and enables pagination through a set of `ContactPreference`. */
  contactPreferencesByCurrentAddressIdList: Array<ContactPreference>;
  /** Reads and enables pagination through a set of `ContactPreference`. */
  contactPreferencesByPersonIdAndCurrentAddressId: ContactPreferencesConnection;
  /** Reads and enables pagination through a set of `ContactPreference`. */
  contactPreferencesByPersonIdAndCurrentAddressIdList: Array<ContactPreference>;
  coordinates?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `Address`. */
  countryByCountryCode?: Maybe<Country>;
  countryCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  firstName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `GiftAidClaim`. */
  giftAidClaimsByAddressIdAndPersonId: GiftAidClaimsConnection;
  /** Reads and enables pagination through a set of `GiftAidClaim`. */
  giftAidClaimsByAddressIdAndPersonIdList: Array<GiftAidClaim>;
  googlePlaceId?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  lastName?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  organizationName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByBillingAddressId: PaymentsConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByBillingAddressIdList: Array<Payment>;
  /** Reads a single `Person` that is related to this `Address`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  postalCode?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByBillingAddressId: RecurringPaymentsConnection;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByBillingAddressIdList: Array<RecurringPayment>;
  region?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
};


export type AddressContactPreferencesByCurrentAddressIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ContactPreferenceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContactPreferencesOrderBy>>;
};


export type AddressContactPreferencesByCurrentAddressIdListArgs = {
  condition?: InputMaybe<ContactPreferenceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContactPreferencesOrderBy>>;
};


export type AddressContactPreferencesByPersonIdAndCurrentAddressIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ContactPreferenceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContactPreferencesOrderBy>>;
};


export type AddressContactPreferencesByPersonIdAndCurrentAddressIdListArgs = {
  condition?: InputMaybe<ContactPreferenceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContactPreferencesOrderBy>>;
};


export type AddressGiftAidClaimsByAddressIdAndPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GiftAidClaimCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GiftAidClaimsOrderBy>>;
};


export type AddressGiftAidClaimsByAddressIdAndPersonIdListArgs = {
  condition?: InputMaybe<GiftAidClaimCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GiftAidClaimsOrderBy>>;
};


export type AddressPaymentsByBillingAddressIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};


export type AddressPaymentsByBillingAddressIdListArgs = {
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};


export type AddressRecurringPaymentsByBillingAddressIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecurringPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};


export type AddressRecurringPaymentsByBillingAddressIdListArgs = {
  condition?: InputMaybe<RecurringPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};

/** A condition to be used against `Address` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AddressCondition = {
  /** Checks for equality with the object’s `addressLine1` field. */
  addressLine1?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `addressLine2` field. */
  addressLine2?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `addressType` field. */
  addressType?: InputMaybe<AddressType>;
  /** Checks for equality with the object’s `city` field. */
  city?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `coordinates` field. */
  coordinates?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `countryCode` field. */
  countryCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `googlePlaceId` field. */
  googlePlaceId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `houseNumber` field. */
  houseNumber?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `organizationName` field. */
  organizationName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `postalCode` field. */
  postalCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `region` field. */
  region?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export enum AddressType {
  Billing = 'BILLING'
}

/** A connection to a list of `Address` values. */
export type AddressesConnection = {
  __typename?: 'AddressesConnection';
  /** A list of edges which contains the `Address` and cursor to aid in pagination. */
  edges: Array<AddressesEdge>;
  /** A list of `Address` objects. */
  nodes: Array<Address>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Address` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Address` edge in the connection. */
export type AddressesEdge = {
  __typename?: 'AddressesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Address` at the end of the edge. */
  node: Address;
};

/** Methods to use when ordering `Address`. */
export enum AddressesOrderBy {
  AddressLine_1Asc = 'ADDRESS_LINE_1_ASC',
  AddressLine_1Desc = 'ADDRESS_LINE_1_DESC',
  AddressLine_2Asc = 'ADDRESS_LINE_2_ASC',
  AddressLine_2Desc = 'ADDRESS_LINE_2_DESC',
  AddressTypeAsc = 'ADDRESS_TYPE_ASC',
  AddressTypeDesc = 'ADDRESS_TYPE_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  CoordinatesAsc = 'COORDINATES_ASC',
  CoordinatesDesc = 'COORDINATES_DESC',
  CountryCodeAsc = 'COUNTRY_CODE_ASC',
  CountryCodeDesc = 'COUNTRY_CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  GooglePlaceIdAsc = 'GOOGLE_PLACE_ID_ASC',
  GooglePlaceIdDesc = 'GOOGLE_PLACE_ID_DESC',
  HouseNumberAsc = 'HOUSE_NUMBER_ASC',
  HouseNumberDesc = 'HOUSE_NUMBER_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  Natural = 'NATURAL',
  OrganizationNameAsc = 'ORGANIZATION_NAME_ASC',
  OrganizationNameDesc = 'ORGANIZATION_NAME_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PostalCodeAsc = 'POSTAL_CODE_ASC',
  PostalCodeDesc = 'POSTAL_CODE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RegionAsc = 'REGION_ASC',
  RegionDesc = 'REGION_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type AllPledgesPublic = {
  __typename?: 'AllPledgesPublic';
  anonymous?: Maybe<Scalars['Boolean']>;
  fullName?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Boolean']>;
  period?: Maybe<DateRange>;
  pledgeEnd?: Maybe<Scalars['Date']>;
  pledgePercentage?: Maybe<Scalars['BigFloat']>;
  pledgeStart?: Maybe<Scalars['Date']>;
  pledgeType?: Maybe<PledgeType>;
  rankByPledgeType?: Maybe<Scalars['BigInt']>;
  withdrawn?: Maybe<Scalars['Boolean']>;
};

/**
 * A condition to be used against `AllPledgesPublic` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AllPledgesPublicCondition = {
  /** Checks for equality with the object’s `anonymous` field. */
  anonymous?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `inactive` field. */
  inactive?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `period` field. */
  period?: InputMaybe<DateRangeInput>;
  /** Checks for equality with the object’s `pledgeEnd` field. */
  pledgeEnd?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `pledgePercentage` field. */
  pledgePercentage?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `pledgeStart` field. */
  pledgeStart?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `pledgeType` field. */
  pledgeType?: InputMaybe<PledgeType>;
  /** Checks for equality with the object’s `rankByPledgeType` field. */
  rankByPledgeType?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `withdrawn` field. */
  withdrawn?: InputMaybe<Scalars['Boolean']>;
};

/** A connection to a list of `AllPledgesPublic` values. */
export type AllPledgesPublicsConnection = {
  __typename?: 'AllPledgesPublicsConnection';
  /** A list of edges which contains the `AllPledgesPublic` and cursor to aid in pagination. */
  edges: Array<AllPledgesPublicsEdge>;
  /** A list of `AllPledgesPublic` objects. */
  nodes: Array<AllPledgesPublic>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AllPledgesPublic` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AllPledgesPublic` edge in the connection. */
export type AllPledgesPublicsEdge = {
  __typename?: 'AllPledgesPublicsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AllPledgesPublic` at the end of the edge. */
  node: AllPledgesPublic;
};

/** Methods to use when ordering `AllPledgesPublic`. */
export enum AllPledgesPublicsOrderBy {
  AnonymousAsc = 'ANONYMOUS_ASC',
  AnonymousDesc = 'ANONYMOUS_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  InactiveAsc = 'INACTIVE_ASC',
  InactiveDesc = 'INACTIVE_DESC',
  Natural = 'NATURAL',
  PeriodAsc = 'PERIOD_ASC',
  PeriodDesc = 'PERIOD_DESC',
  PledgeEndAsc = 'PLEDGE_END_ASC',
  PledgeEndDesc = 'PLEDGE_END_DESC',
  PledgePercentageAsc = 'PLEDGE_PERCENTAGE_ASC',
  PledgePercentageDesc = 'PLEDGE_PERCENTAGE_DESC',
  PledgeStartAsc = 'PLEDGE_START_ASC',
  PledgeStartDesc = 'PLEDGE_START_DESC',
  PledgeTypeAsc = 'PLEDGE_TYPE_ASC',
  PledgeTypeDesc = 'PLEDGE_TYPE_DESC',
  RankByPledgeTypeAsc = 'RANK_BY_PLEDGE_TYPE_ASC',
  RankByPledgeTypeDesc = 'RANK_BY_PLEDGE_TYPE_DESC',
  WithdrawnAsc = 'WITHDRAWN_ASC',
  WithdrawnDesc = 'WITHDRAWN_DESC'
}

export type BalanceSheetReportMonthlyTotal = {
  __typename?: 'BalanceSheetReportMonthlyTotal';
  amount?: Maybe<Scalars['BigFloat']>;
  reference?: Maybe<Scalars['String']>;
  reportDate?: Maybe<Scalars['Date']>;
};

/**
 * A condition to be used against `BalanceSheetReportMonthlyTotal` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type BalanceSheetReportMonthlyTotalCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `reference` field. */
  reference?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `reportDate` field. */
  reportDate?: InputMaybe<Scalars['Date']>;
};

/** A connection to a list of `BalanceSheetReportMonthlyTotal` values. */
export type BalanceSheetReportMonthlyTotalsConnection = {
  __typename?: 'BalanceSheetReportMonthlyTotalsConnection';
  /** A list of edges which contains the `BalanceSheetReportMonthlyTotal` and cursor to aid in pagination. */
  edges: Array<BalanceSheetReportMonthlyTotalsEdge>;
  /** A list of `BalanceSheetReportMonthlyTotal` objects. */
  nodes: Array<BalanceSheetReportMonthlyTotal>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BalanceSheetReportMonthlyTotal` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BalanceSheetReportMonthlyTotal` edge in the connection. */
export type BalanceSheetReportMonthlyTotalsEdge = {
  __typename?: 'BalanceSheetReportMonthlyTotalsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BalanceSheetReportMonthlyTotal` at the end of the edge. */
  node: BalanceSheetReportMonthlyTotal;
};

/** Methods to use when ordering `BalanceSheetReportMonthlyTotal`. */
export enum BalanceSheetReportMonthlyTotalsOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  Natural = 'NATURAL',
  ReferenceAsc = 'REFERENCE_ASC',
  ReferenceDesc = 'REFERENCE_DESC',
  ReportDateAsc = 'REPORT_DATE_ASC',
  ReportDateDesc = 'REPORT_DATE_DESC'
}

export type BalanceSheetReportPublic = {
  __typename?: 'BalanceSheetReportPublic';
  amount?: Maybe<Scalars['BigFloat']>;
  amountNormalized?: Maybe<Scalars['BigFloat']>;
  currencyCode?: Maybe<Scalars['String']>;
  recipient?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  reportDate?: Maybe<Scalars['Date']>;
};

/**
 * A condition to be used against `BalanceSheetReportPublic` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BalanceSheetReportPublicCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `amountNormalized` field. */
  amountNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `recipient` field. */
  recipient?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `reference` field. */
  reference?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `reportDate` field. */
  reportDate?: InputMaybe<Scalars['Date']>;
};

/** A connection to a list of `BalanceSheetReportPublic` values. */
export type BalanceSheetReportPublicsConnection = {
  __typename?: 'BalanceSheetReportPublicsConnection';
  /** A list of edges which contains the `BalanceSheetReportPublic` and cursor to aid in pagination. */
  edges: Array<BalanceSheetReportPublicsEdge>;
  /** A list of `BalanceSheetReportPublic` objects. */
  nodes: Array<BalanceSheetReportPublic>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BalanceSheetReportPublic` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BalanceSheetReportPublic` edge in the connection. */
export type BalanceSheetReportPublicsEdge = {
  __typename?: 'BalanceSheetReportPublicsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BalanceSheetReportPublic` at the end of the edge. */
  node: BalanceSheetReportPublic;
};

/** Methods to use when ordering `BalanceSheetReportPublic`. */
export enum BalanceSheetReportPublicsOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  AmountNormalizedAsc = 'AMOUNT_NORMALIZED_ASC',
  AmountNormalizedDesc = 'AMOUNT_NORMALIZED_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  Natural = 'NATURAL',
  RecipientAsc = 'RECIPIENT_ASC',
  RecipientDesc = 'RECIPIENT_DESC',
  ReferenceAsc = 'REFERENCE_ASC',
  ReferenceDesc = 'REFERENCE_DESC',
  ReportDateAsc = 'REPORT_DATE_ASC',
  ReportDateDesc = 'REPORT_DATE_DESC'
}

/** A range of `BigInt`. */
export type BigIntRange = {
  __typename?: 'BigIntRange';
  /** The ending bound of our range. */
  end?: Maybe<BigIntRangeBound>;
  /** The starting bound of our range. */
  start?: Maybe<BigIntRangeBound>;
};

/** The value at one end of a range. A range can either include this value, or not. */
export type BigIntRangeBound = {
  __typename?: 'BigIntRangeBound';
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean'];
  /** The value at one end of our range. */
  value: Scalars['BigInt'];
};

/** The value at one end of a range. A range can either include this value, or not. */
export type BigIntRangeBoundInput = {
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean'];
  /** The value at one end of our range. */
  value: Scalars['BigInt'];
};

/** A range of `BigInt`. */
export type BigIntRangeInput = {
  /** The ending bound of our range. */
  end?: InputMaybe<BigIntRangeBoundInput>;
  /** The starting bound of our range. */
  start?: InputMaybe<BigIntRangeBoundInput>;
};

/** All input for the `cancelRecurringPayment` mutation. */
export type CancelRecurringPaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  recurringPaymentId: Scalars['BigInt'];
};

/** The output of our `cancelRecurringPayment` mutation. */
export type CancelRecurringPaymentPayload = {
  __typename?: 'CancelRecurringPaymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `cancelRecurringReportedDonation` mutation. */
export type CancelRecurringReportedDonationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  recurringReportedDonationId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `cancelRecurringReportedDonation` mutation. */
export type CancelRecurringReportedDonationPayload = {
  __typename?: 'CancelRecurringReportedDonationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `RecurringReportedDonation`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `ExternalOrganization` that is related to this `RecurringReportedDonation`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  /** Reads a single `Person` that is related to this `RecurringReportedDonation`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  recurringReportedDonation?: Maybe<RecurringReportedDonation>;
  /** An edge for our `RecurringReportedDonation`. May be used by Relay 1. */
  recurringReportedDonationEdge?: Maybe<RecurringReportedDonationsEdge>;
};


/** The output of our `cancelRecurringReportedDonation` mutation. */
export type CancelRecurringReportedDonationPayloadRecurringReportedDonationEdgeArgs = {
  orderBy?: InputMaybe<Array<RecurringReportedDonationsOrderBy>>;
};

export type CompletionPayment = {
  __typename?: 'CompletionPayment';
  allocation?: Maybe<Scalars['JSON']>;
  amount: Scalars['BigFloat'];
  amountNormalized?: Maybe<Scalars['BigFloat']>;
  currencyCode: Scalars['String'];
  donationDate: Scalars['Date'];
  /** Reads a single `ExternalOrganization` that is related to this `CompletionPayment`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  /** Reads a single `FinancialPeriod` that is related to this `CompletionPayment`. */
  financialPeriodByFinancialPeriodId?: Maybe<FinancialPeriod>;
  financialPeriodId?: Maybe<Scalars['BigInt']>;
  /** Reads a single `Liability` that is related to this `CompletionPayment`. */
  liabilityByPledgeIdAndFinancialPeriodId?: Maybe<Liability>;
  objectId: Scalars['BigInt'];
  objectType: CompletionPaymentObjectType;
  organizationId?: Maybe<Scalars['BigInt']>;
  /** Reads a single `Payment` that is related to this `CompletionPayment`. */
  paymentByPaymentId?: Maybe<Payment>;
  paymentId?: Maybe<Scalars['BigInt']>;
  personId?: Maybe<Scalars['BigInt']>;
  pledgeId?: Maybe<Scalars['BigInt']>;
  /** Reads a single `ReportedDonation` that is related to this `CompletionPayment`. */
  reportedDonationByReportedDonationId?: Maybe<ReportedDonation>;
  reportedDonationId?: Maybe<Scalars['BigInt']>;
};

/**
 * A condition to be used against `CompletionPayment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CompletionPaymentCondition = {
  /** Checks for equality with the object’s `allocation` field. */
  allocation?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `amountNormalized` field. */
  amountNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `donationDate` field. */
  donationDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `financialPeriodId` field. */
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `objectId` field. */
  objectId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `objectType` field. */
  objectType?: InputMaybe<CompletionPaymentObjectType>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paymentId` field. */
  paymentId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `pledgeId` field. */
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `reportedDonationId` field. */
  reportedDonationId?: InputMaybe<Scalars['BigInt']>;
};

export enum CompletionPaymentObjectType {
  Payment = 'PAYMENT',
  ReportedDonation = 'REPORTED_DONATION'
}

/** A connection to a list of `CompletionPayment` values. */
export type CompletionPaymentsConnection = {
  __typename?: 'CompletionPaymentsConnection';
  /** A list of edges which contains the `CompletionPayment` and cursor to aid in pagination. */
  edges: Array<CompletionPaymentsEdge>;
  /** A list of `CompletionPayment` objects. */
  nodes: Array<CompletionPayment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompletionPayment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CompletionPayment` edge in the connection. */
export type CompletionPaymentsEdge = {
  __typename?: 'CompletionPaymentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CompletionPayment` at the end of the edge. */
  node: CompletionPayment;
};

/** Methods to use when ordering `CompletionPayment`. */
export enum CompletionPaymentsOrderBy {
  AllocationAsc = 'ALLOCATION_ASC',
  AllocationDesc = 'ALLOCATION_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  AmountNormalizedAsc = 'AMOUNT_NORMALIZED_ASC',
  AmountNormalizedDesc = 'AMOUNT_NORMALIZED_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  DonationDateAsc = 'DONATION_DATE_ASC',
  DonationDateDesc = 'DONATION_DATE_DESC',
  FinancialPeriodIdAsc = 'FINANCIAL_PERIOD_ID_ASC',
  FinancialPeriodIdDesc = 'FINANCIAL_PERIOD_ID_DESC',
  Natural = 'NATURAL',
  ObjectIdAsc = 'OBJECT_ID_ASC',
  ObjectIdDesc = 'OBJECT_ID_DESC',
  ObjectTypeAsc = 'OBJECT_TYPE_ASC',
  ObjectTypeDesc = 'OBJECT_TYPE_DESC',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PaymentIdAsc = 'PAYMENT_ID_ASC',
  PaymentIdDesc = 'PAYMENT_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PledgeIdAsc = 'PLEDGE_ID_ASC',
  PledgeIdDesc = 'PLEDGE_ID_DESC',
  ReportedDonationIdAsc = 'REPORTED_DONATION_ID_ASC',
  ReportedDonationIdDesc = 'REPORTED_DONATION_ID_DESC'
}

export type ContactPreference = Node & {
  __typename?: 'ContactPreference';
  /** Reads a single `Address` that is related to this `ContactPreference`. */
  addressByCurrentAddressId?: Maybe<Address>;
  /** Reads a single `Address` that is related to this `ContactPreference`. */
  addressByPersonIdAndCurrentAddressId?: Maybe<Address>;
  createdAt: Scalars['Datetime'];
  currentAddressId?: Maybe<Scalars['BigInt']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Person` that is related to this `ContactPreference`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `ContactPreference` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ContactPreferenceCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currentAddressId` field. */
  currentAddressId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `ContactPreference` values. */
export type ContactPreferencesConnection = {
  __typename?: 'ContactPreferencesConnection';
  /** A list of edges which contains the `ContactPreference` and cursor to aid in pagination. */
  edges: Array<ContactPreferencesEdge>;
  /** A list of `ContactPreference` objects. */
  nodes: Array<ContactPreference>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ContactPreference` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ContactPreference` edge in the connection. */
export type ContactPreferencesEdge = {
  __typename?: 'ContactPreferencesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ContactPreference` at the end of the edge. */
  node: ContactPreference;
};

/** Methods to use when ordering `ContactPreference`. */
export enum ContactPreferencesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrentAddressIdAsc = 'CURRENT_ADDRESS_ID_ASC',
  CurrentAddressIdDesc = 'CURRENT_ADDRESS_ID_DESC',
  Natural = 'NATURAL',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum ContactType {
  Individual = 'INDIVIDUAL',
  Organization = 'ORGANIZATION'
}

/** A connection to a list of `Country` values. */
export type CountriesConnection = {
  __typename?: 'CountriesConnection';
  /** A list of edges which contains the `Country` and cursor to aid in pagination. */
  edges: Array<CountriesEdge>;
  /** A list of `Country` objects. */
  nodes: Array<Country>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Country` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Country` edge in the connection. */
export type CountriesEdge = {
  __typename?: 'CountriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Country` at the end of the edge. */
  node: Country;
};

/** Methods to use when ordering `Country`. */
export enum CountriesOrderBy {
  IsoAlpha_2Asc = 'ISO_ALPHA_2_ASC',
  IsoAlpha_2Desc = 'ISO_ALPHA_2_DESC',
  IsoAlpha_3Asc = 'ISO_ALPHA_3_ASC',
  IsoAlpha_3Desc = 'ISO_ALPHA_3_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Country = Node & {
  __typename?: 'Country';
  /** Reads and enables pagination through a set of `Address`. */
  addressesByCountryCode: AddressesConnection;
  /** Reads and enables pagination through a set of `Address`. */
  addressesByCountryCodeList: Array<Address>;
  /** Reads and enables pagination through a set of `CurrencyByCountry`. */
  currencyByCountriesByCountryCode: CurrencyByCountriesConnection;
  /** Reads and enables pagination through a set of `CurrencyByCountry`. */
  currencyByCountriesByCountryCodeList: Array<CurrencyByCountry>;
  isoAlpha2: Scalars['String'];
  isoAlpha3: Scalars['String'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PaymentRecipient`. */
  paymentRecipientsByCountryCode: PaymentRecipientsConnection;
  /** Reads and enables pagination through a set of `PaymentRecipient`. */
  paymentRecipientsByCountryCodeList: Array<PaymentRecipient>;
};


export type CountryAddressesByCountryCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AddressCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressesOrderBy>>;
};


export type CountryAddressesByCountryCodeListArgs = {
  condition?: InputMaybe<AddressCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressesOrderBy>>;
};


export type CountryCurrencyByCountriesByCountryCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CurrencyByCountryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyByCountriesOrderBy>>;
};


export type CountryCurrencyByCountriesByCountryCodeListArgs = {
  condition?: InputMaybe<CurrencyByCountryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyByCountriesOrderBy>>;
};


export type CountryPaymentRecipientsByCountryCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentRecipientCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentRecipientsOrderBy>>;
};


export type CountryPaymentRecipientsByCountryCodeListArgs = {
  condition?: InputMaybe<PaymentRecipientCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentRecipientsOrderBy>>;
};

/** A condition to be used against `Country` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CountryCondition = {
  /** Checks for equality with the object’s `isoAlpha2` field. */
  isoAlpha2?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `isoAlpha3` field. */
  isoAlpha3?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
};

/** All input for the `createAddressByPersonOrTemporaryPerson` mutation. */
export type CreateAddressByPersonOrTemporaryPersonInput = {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  addressType?: InputMaybe<AddressType>;
  city?: InputMaybe<Scalars['String']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `createAddressByPersonOrTemporaryPerson` mutation. */
export type CreateAddressByPersonOrTemporaryPersonPayload = {
  __typename?: 'CreateAddressByPersonOrTemporaryPersonPayload';
  address?: Maybe<Address>;
  /** An edge for our `Address`. May be used by Relay 1. */
  addressEdge?: Maybe<AddressesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Country` that is related to this `Address`. */
  countryByCountryCode?: Maybe<Country>;
  /** Reads a single `Person` that is related to this `Address`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `createAddressByPersonOrTemporaryPerson` mutation. */
export type CreateAddressByPersonOrTemporaryPersonPayloadAddressEdgeArgs = {
  orderBy?: InputMaybe<Array<AddressesOrderBy>>;
};

/** All input for the `createExternalOrganization` mutation. */
export type CreateExternalOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  organizationSlug?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** The output of our `createExternalOrganization` mutation. */
export type CreateExternalOrganizationPayload = {
  __typename?: 'CreateExternalOrganizationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  externalOrganization?: Maybe<ExternalOrganization>;
  /** An edge for our `ExternalOrganization`. May be used by Relay 1. */
  externalOrganizationEdge?: Maybe<ExternalOrganizationsEdge>;
  /** Reads a single `Program` that is related to this `ExternalOrganization`. */
  programByOrganizationSlug?: Maybe<Program>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `createExternalOrganization` mutation. */
export type CreateExternalOrganizationPayloadExternalOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<ExternalOrganizationsOrderBy>>;
};

/** All input for the `createFinancialPeriod` mutation. */
export type CreateFinancialPeriodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  donationsTaxDeductible?: InputMaybe<Scalars['Boolean']>;
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  incomes?: InputMaybe<Array<InputMaybe<IncomeInput>>>;
  isSigningPledge?: InputMaybe<Scalars['Boolean']>;
  period?: InputMaybe<DateRangeInput>;
  personId?: InputMaybe<Scalars['BigInt']>;
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
};

/** The output of our `createFinancialPeriod` mutation. */
export type CreateFinancialPeriodPayload = {
  __typename?: 'CreateFinancialPeriodPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  financialPeriod?: Maybe<FinancialPeriod>;
  /** An edge for our `FinancialPeriod`. May be used by Relay 1. */
  financialPeriodEdge?: Maybe<FinancialPeriodsEdge>;
  /** Reads a single `Person` that is related to this `FinancialPeriod`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `createFinancialPeriod` mutation. */
export type CreateFinancialPeriodPayloadFinancialPeriodEdgeArgs = {
  orderBy?: InputMaybe<Array<FinancialPeriodsOrderBy>>;
};

/** All input for the `createGiftAidClaimFromPayment` mutation. */
export type CreateGiftAidClaimFromPaymentInput = {
  addressId?: InputMaybe<Scalars['BigInt']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `createGiftAidClaimFromPayment` mutation. */
export type CreateGiftAidClaimFromPaymentPayload = {
  __typename?: 'CreateGiftAidClaimFromPaymentPayload';
  /** Reads a single `Address` that is related to this `GiftAidClaim`. */
  addressByAddressIdAndPersonId?: Maybe<Address>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  giftAidClaim?: Maybe<GiftAidClaim>;
  /** An edge for our `GiftAidClaim`. May be used by Relay 1. */
  giftAidClaimEdge?: Maybe<GiftAidClaimsEdge>;
  /** Reads a single `Payment` that is related to this `GiftAidClaim`. */
  paymentByGiftAidPaymentId?: Maybe<Payment>;
  /** Reads a single `Payment` that is related to this `GiftAidClaim`. */
  paymentByPaymentIdAndPersonId?: Maybe<Payment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `createGiftAidClaimFromPayment` mutation. */
export type CreateGiftAidClaimFromPaymentPayloadGiftAidClaimEdgeArgs = {
  orderBy?: InputMaybe<Array<GiftAidClaimsOrderBy>>;
};

/** All input for the `createGocardlessRedirectFlowByPersonOrTemporaryPerson` mutation. */
export type CreateGocardlessRedirectFlowByPersonOrTemporaryPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `createGocardlessRedirectFlowByPersonOrTemporaryPerson` mutation. */
export type CreateGocardlessRedirectFlowByPersonOrTemporaryPersonPayload = {
  __typename?: 'CreateGocardlessRedirectFlowByPersonOrTemporaryPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  gocardlessRedirectFlow?: Maybe<GocardlessRedirectFlow>;
  /** An edge for our `GocardlessRedirectFlow`. May be used by Relay 1. */
  gocardlessRedirectFlowEdge?: Maybe<GocardlessRedirectFlowsEdge>;
  /** Reads a single `Person` that is related to this `GocardlessRedirectFlow`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `createGocardlessRedirectFlowByPersonOrTemporaryPerson` mutation. */
export type CreateGocardlessRedirectFlowByPersonOrTemporaryPersonPayloadGocardlessRedirectFlowEdgeArgs = {
  orderBy?: InputMaybe<Array<GocardlessRedirectFlowsOrderBy>>;
};

/** All input for the `createPaymentByPersonId` mutation. */
export type CreatePaymentByPersonIdInput = {
  allocation?: InputMaybe<Scalars['JSON']>;
  amount?: InputMaybe<Scalars['BigFloat']>;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  gateway?: InputMaybe<PaymentGateway>;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  personId?: InputMaybe<Scalars['BigInt']>;
  recipient?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentStatus>;
};

/** The output of our `createPaymentByPersonId` mutation. */
export type CreatePaymentByPersonIdPayload = {
  __typename?: 'CreatePaymentByPersonIdPayload';
  /** Reads a single `Address` that is related to this `Payment`. */
  addressByBillingAddressId?: Maybe<Address>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByChargeCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  payment?: Maybe<Payment>;
  /** An edge for our `Payment`. May be used by Relay 1. */
  paymentEdge?: Maybe<PaymentsEdge>;
  /** Reads a single `PaymentRecipient` that is related to this `Payment`. */
  paymentRecipientByRecipient?: Maybe<PaymentRecipient>;
  /** Reads a single `Person` that is related to this `Payment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `createPaymentByPersonId` mutation. */
export type CreatePaymentByPersonIdPayloadPaymentEdgeArgs = {
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};

/** All input for the `createPaymentByTemporaryPerson` mutation. */
export type CreatePaymentByTemporaryPersonInput = {
  allocation?: InputMaybe<Scalars['JSON']>;
  amount?: InputMaybe<Scalars['BigFloat']>;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  gateway?: InputMaybe<PaymentGateway>;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  recipient?: InputMaybe<Scalars['String']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `createPaymentByTemporaryPerson` mutation. */
export type CreatePaymentByTemporaryPersonPayload = {
  __typename?: 'CreatePaymentByTemporaryPersonPayload';
  /** Reads a single `Address` that is related to this `Payment`. */
  addressByBillingAddressId?: Maybe<Address>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByChargeCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  payment?: Maybe<Payment>;
  /** An edge for our `Payment`. May be used by Relay 1. */
  paymentEdge?: Maybe<PaymentsEdge>;
  /** Reads a single `PaymentRecipient` that is related to this `Payment`. */
  paymentRecipientByRecipient?: Maybe<PaymentRecipient>;
  /** Reads a single `Person` that is related to this `Payment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `createPaymentByTemporaryPerson` mutation. */
export type CreatePaymentByTemporaryPersonPayloadPaymentEdgeArgs = {
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};

/** All input for the `createPollOptionVoteByPersonOrTemporaryPerson` mutation. */
export type CreatePollOptionVoteByPersonOrTemporaryPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  context?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  pollOptionId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `createPollOptionVoteByPersonOrTemporaryPerson` mutation. */
export type CreatePollOptionVoteByPersonOrTemporaryPersonPayload = {
  __typename?: 'CreatePollOptionVoteByPersonOrTemporaryPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Pledge` that is related to this `PollOptionVote`. */
  pledgeByPledgeId?: Maybe<Pledge>;
  /** Reads a single `PollOptionPublic` that is related to this `PollOptionVote`. */
  pollOptionPublicByPollOptionId?: Maybe<PollOptionPublic>;
  pollOptionVote?: Maybe<PollOptionVote>;
  /** An edge for our `PollOptionVote`. May be used by Relay 1. */
  pollOptionVoteEdge?: Maybe<PollOptionVotesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `createPollOptionVoteByPersonOrTemporaryPerson` mutation. */
export type CreatePollOptionVoteByPersonOrTemporaryPersonPayloadPollOptionVoteEdgeArgs = {
  orderBy?: InputMaybe<Array<PollOptionVotesOrderBy>>;
};

/** All input for the `createPollOptionVote` mutation. */
export type CreatePollOptionVoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  context: Scalars['String'];
  pledgeId: Scalars['BigInt'];
  pollOptionId: Scalars['BigInt'];
};

/** The output of our `createPollOptionVote` mutation. */
export type CreatePollOptionVotePayload = {
  __typename?: 'CreatePollOptionVotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Pledge` that is related to this `PollOptionVote`. */
  pledgeByPledgeId?: Maybe<Pledge>;
  /** Reads a single `PollOptionPublic` that is related to this `PollOptionVote`. */
  pollOptionPublicByPollOptionId?: Maybe<PollOptionPublic>;
  pollOptionVote?: Maybe<PollOptionVote>;
  /** An edge for our `PollOptionVote`. May be used by Relay 1. */
  pollOptionVoteEdge?: Maybe<PollOptionVotesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `createPollOptionVote` mutation. */
export type CreatePollOptionVotePayloadPollOptionVoteEdgeArgs = {
  orderBy?: InputMaybe<Array<PollOptionVotesOrderBy>>;
};

/** All input for the `createRecurringPaymentByTemporaryPerson` mutation. */
export type CreateRecurringPaymentByTemporaryPersonInput = {
  allocation?: InputMaybe<Scalars['JSON']>;
  amount?: InputMaybe<Scalars['BigFloat']>;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  gateway?: InputMaybe<PaymentGateway>;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  period?: InputMaybe<RecurrencePeriod>;
  periodIndex?: InputMaybe<Scalars['Int']>;
  recipient?: InputMaybe<Scalars['String']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `createRecurringPaymentByTemporaryPerson` mutation. */
export type CreateRecurringPaymentByTemporaryPersonPayload = {
  __typename?: 'CreateRecurringPaymentByTemporaryPersonPayload';
  /** Reads a single `Address` that is related to this `RecurringPayment`. */
  addressByBillingAddressId?: Maybe<Address>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `RecurringPayment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `PaymentRecipient` that is related to this `RecurringPayment`. */
  paymentRecipientByRecipient?: Maybe<PaymentRecipient>;
  /** Reads a single `Person` that is related to this `RecurringPayment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  recurringPayment?: Maybe<RecurringPayment>;
  /** An edge for our `RecurringPayment`. May be used by Relay 1. */
  recurringPaymentEdge?: Maybe<RecurringPaymentsEdge>;
};


/** The output of our `createRecurringPaymentByTemporaryPerson` mutation. */
export type CreateRecurringPaymentByTemporaryPersonPayloadRecurringPaymentEdgeArgs = {
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};

/** All input for the `createRecurringPayment` mutation. */
export type CreateRecurringPaymentInput = {
  allocation?: InputMaybe<Scalars['JSON']>;
  amount?: InputMaybe<Scalars['BigFloat']>;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  gateway?: InputMaybe<PaymentGateway>;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  paymentMethod?: InputMaybe<PaymentMethod>;
  period?: InputMaybe<RecurrencePeriod>;
  periodIndex?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  recipient?: InputMaybe<Scalars['String']>;
};

/** The output of our `createRecurringPayment` mutation. */
export type CreateRecurringPaymentPayload = {
  __typename?: 'CreateRecurringPaymentPayload';
  /** Reads a single `Address` that is related to this `RecurringPayment`. */
  addressByBillingAddressId?: Maybe<Address>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `RecurringPayment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `PaymentRecipient` that is related to this `RecurringPayment`. */
  paymentRecipientByRecipient?: Maybe<PaymentRecipient>;
  /** Reads a single `Person` that is related to this `RecurringPayment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  recurringPayment?: Maybe<RecurringPayment>;
  /** An edge for our `RecurringPayment`. May be used by Relay 1. */
  recurringPaymentEdge?: Maybe<RecurringPaymentsEdge>;
};


/** The output of our `createRecurringPayment` mutation. */
export type CreateRecurringPaymentPayloadRecurringPaymentEdgeArgs = {
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};

/** All input for the `createRecurringReportedDonation` mutation. */
export type CreateRecurringReportedDonationInput = {
  amount?: InputMaybe<Scalars['BigFloat']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['BigInt']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  recurrenceInterval?: InputMaybe<IntervalInput>;
  startDate?: InputMaybe<Scalars['Date']>;
};

/** The output of our `createRecurringReportedDonation` mutation. */
export type CreateRecurringReportedDonationPayload = {
  __typename?: 'CreateRecurringReportedDonationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `RecurringReportedDonation`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `ExternalOrganization` that is related to this `RecurringReportedDonation`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  /** Reads a single `Person` that is related to this `RecurringReportedDonation`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  recurringReportedDonation?: Maybe<RecurringReportedDonation>;
  /** An edge for our `RecurringReportedDonation`. May be used by Relay 1. */
  recurringReportedDonationEdge?: Maybe<RecurringReportedDonationsEdge>;
};


/** The output of our `createRecurringReportedDonation` mutation. */
export type CreateRecurringReportedDonationPayloadRecurringReportedDonationEdgeArgs = {
  orderBy?: InputMaybe<Array<RecurringReportedDonationsOrderBy>>;
};

/** All input for the `createReportedDonationByPersonId` mutation. */
export type CreateReportedDonationByPersonIdInput = {
  amount?: InputMaybe<Scalars['BigFloat']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  donationDate?: InputMaybe<Scalars['Date']>;
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
  organizationId?: InputMaybe<Scalars['BigInt']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `createReportedDonationByPersonId` mutation. */
export type CreateReportedDonationByPersonIdPayload = {
  __typename?: 'CreateReportedDonationByPersonIdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `ReportedDonation`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `ExternalOrganization` that is related to this `ReportedDonation`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  /** Reads a single `Person` that is related to this `ReportedDonation`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  reportedDonation?: Maybe<ReportedDonation>;
  /** An edge for our `ReportedDonation`. May be used by Relay 1. */
  reportedDonationEdge?: Maybe<ReportedDonationsEdge>;
};


/** The output of our `createReportedDonationByPersonId` mutation. */
export type CreateReportedDonationByPersonIdPayloadReportedDonationEdgeArgs = {
  orderBy?: InputMaybe<Array<ReportedDonationsOrderBy>>;
};

/** All input for the `createTemporaryPersonByEmail` mutation. */
export type CreateTemporaryPersonByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  isLongLasting?: InputMaybe<Scalars['Boolean']>;
  recaptchaToken: Scalars['String'];
};

/** The output of our `createTemporaryPersonByEmail` mutation. */
export type CreateTemporaryPersonByEmailPayload = {
  __typename?: 'CreateTemporaryPersonByEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EmailAddress` that is related to this `TemporaryPerson`. */
  emailAddressByEmail?: Maybe<EmailAddress>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  temporaryPerson?: Maybe<TemporaryPerson>;
};

/** A connection to a list of `Currency` values. */
export type CurrenciesConnection = {
  __typename?: 'CurrenciesConnection';
  /** A list of edges which contains the `Currency` and cursor to aid in pagination. */
  edges: Array<CurrenciesEdge>;
  /** A list of `Currency` objects. */
  nodes: Array<Currency>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Currency` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Currency` edge in the connection. */
export type CurrenciesEdge = {
  __typename?: 'CurrenciesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Currency` at the end of the edge. */
  node: Currency;
};

/** Methods to use when ordering `Currency`. */
export enum CurrenciesOrderBy {
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CurrencyTypeAsc = 'CURRENCY_TYPE_ASC',
  CurrencyTypeDesc = 'CURRENCY_TYPE_DESC',
  DecimalsAsc = 'DECIMALS_ASC',
  DecimalsDesc = 'DECIMALS_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SymbolAsc = 'SYMBOL_ASC',
  SymbolDesc = 'SYMBOL_DESC'
}

export type Currency = Node & {
  __typename?: 'Currency';
  code: Scalars['String'];
  /** Reads and enables pagination through a set of `CurrencyByCountry`. */
  currencyByCountriesByCurrencyCode: CurrencyByCountriesConnection;
  /** Reads and enables pagination through a set of `CurrencyByCountry`. */
  currencyByCountriesByCurrencyCodeList: Array<CurrencyByCountry>;
  currencyType: CurrencyType;
  decimals: Scalars['Int'];
  /** Reads and enables pagination through a set of `ExchangeRate`. */
  exchangeRatesByCurrencyCode: ExchangeRatesConnection;
  /** Reads and enables pagination through a set of `ExchangeRate`. */
  exchangeRatesByCurrencyCodeList: Array<ExchangeRate>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PaymentRecipient`. */
  paymentRecipientsByCurrencyCode: PaymentRecipientsConnection;
  /** Reads and enables pagination through a set of `PaymentRecipient`. */
  paymentRecipientsByCurrencyCodeList: Array<PaymentRecipient>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByChargeCurrencyCode: PaymentsConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByChargeCurrencyCodeList: Array<Payment>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByCurrencyCode: PaymentsConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByCurrencyCodeList: Array<Payment>;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByCurrencyCode: RecurringPaymentsConnection;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByCurrencyCodeList: Array<RecurringPayment>;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  recurringReportedDonationsByCurrencyCode: RecurringReportedDonationsConnection;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  recurringReportedDonationsByCurrencyCodeList: Array<RecurringReportedDonation>;
  /** Reads and enables pagination through a set of `ReportedDonation`. */
  reportedDonationsByCurrencyCode: ReportedDonationsConnection;
  /** Reads and enables pagination through a set of `ReportedDonation`. */
  reportedDonationsByCurrencyCodeList: Array<ReportedDonation>;
  symbol?: Maybe<Scalars['String']>;
};


export type CurrencyCurrencyByCountriesByCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CurrencyByCountryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyByCountriesOrderBy>>;
};


export type CurrencyCurrencyByCountriesByCurrencyCodeListArgs = {
  condition?: InputMaybe<CurrencyByCountryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyByCountriesOrderBy>>;
};


export type CurrencyExchangeRatesByCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExchangeRateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeRatesOrderBy>>;
};


export type CurrencyExchangeRatesByCurrencyCodeListArgs = {
  condition?: InputMaybe<ExchangeRateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeRatesOrderBy>>;
};


export type CurrencyPaymentRecipientsByCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentRecipientCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentRecipientsOrderBy>>;
};


export type CurrencyPaymentRecipientsByCurrencyCodeListArgs = {
  condition?: InputMaybe<PaymentRecipientCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentRecipientsOrderBy>>;
};


export type CurrencyPaymentsByChargeCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};


export type CurrencyPaymentsByChargeCurrencyCodeListArgs = {
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};


export type CurrencyPaymentsByCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};


export type CurrencyPaymentsByCurrencyCodeListArgs = {
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};


export type CurrencyRecurringPaymentsByCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecurringPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};


export type CurrencyRecurringPaymentsByCurrencyCodeListArgs = {
  condition?: InputMaybe<RecurringPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};


export type CurrencyRecurringReportedDonationsByCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecurringReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringReportedDonationsOrderBy>>;
};


export type CurrencyRecurringReportedDonationsByCurrencyCodeListArgs = {
  condition?: InputMaybe<RecurringReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringReportedDonationsOrderBy>>;
};


export type CurrencyReportedDonationsByCurrencyCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportedDonationsOrderBy>>;
};


export type CurrencyReportedDonationsByCurrencyCodeListArgs = {
  condition?: InputMaybe<ReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportedDonationsOrderBy>>;
};

/** A connection to a list of `CurrencyByCountry` values. */
export type CurrencyByCountriesConnection = {
  __typename?: 'CurrencyByCountriesConnection';
  /** A list of edges which contains the `CurrencyByCountry` and cursor to aid in pagination. */
  edges: Array<CurrencyByCountriesEdge>;
  /** A list of `CurrencyByCountry` objects. */
  nodes: Array<CurrencyByCountry>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CurrencyByCountry` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CurrencyByCountry` edge in the connection. */
export type CurrencyByCountriesEdge = {
  __typename?: 'CurrencyByCountriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CurrencyByCountry` at the end of the edge. */
  node: CurrencyByCountry;
};

/** Methods to use when ordering `CurrencyByCountry`. */
export enum CurrencyByCountriesOrderBy {
  CountryCodeAsc = 'COUNTRY_CODE_ASC',
  CountryCodeDesc = 'COUNTRY_CODE_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  Natural = 'NATURAL',
  PrecedenceAsc = 'PRECEDENCE_ASC',
  PrecedenceDesc = 'PRECEDENCE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type CurrencyByCountry = Node & {
  __typename?: 'CurrencyByCountry';
  /** Reads a single `Country` that is related to this `CurrencyByCountry`. */
  countryByCountryCode?: Maybe<Country>;
  countryCode: Scalars['String'];
  /** Reads a single `Currency` that is related to this `CurrencyByCountry`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  precedence: Scalars['Int'];
};

/**
 * A condition to be used against `CurrencyByCountry` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CurrencyByCountryCondition = {
  /** Checks for equality with the object’s `countryCode` field. */
  countryCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `precedence` field. */
  precedence?: InputMaybe<Scalars['Int']>;
};

/**
 * A condition to be used against `Currency` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CurrencyCondition = {
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `currencyType` field. */
  currencyType?: InputMaybe<CurrencyType>;
  /** Checks for equality with the object’s `decimals` field. */
  decimals?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `symbol` field. */
  symbol?: InputMaybe<Scalars['String']>;
};

export enum CurrencyType {
  Crypto = 'CRYPTO',
  Fiat = 'FIAT'
}

export type CurrentExchangeRate = {
  __typename?: 'CurrentExchangeRate';
  currencyCode?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  rate?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `CurrentExchangeRate` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CurrentExchangeRateCondition = {
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `rate` field. */
  rate?: InputMaybe<Scalars['BigFloat']>;
};

/** A connection to a list of `CurrentExchangeRate` values. */
export type CurrentExchangeRatesConnection = {
  __typename?: 'CurrentExchangeRatesConnection';
  /** A list of edges which contains the `CurrentExchangeRate` and cursor to aid in pagination. */
  edges: Array<CurrentExchangeRatesEdge>;
  /** A list of `CurrentExchangeRate` objects. */
  nodes: Array<CurrentExchangeRate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CurrentExchangeRate` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CurrentExchangeRate` edge in the connection. */
export type CurrentExchangeRatesEdge = {
  __typename?: 'CurrentExchangeRatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CurrentExchangeRate` at the end of the edge. */
  node: CurrentExchangeRate;
};

/** Methods to use when ordering `CurrentExchangeRate`. */
export enum CurrentExchangeRatesOrderBy {
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  Natural = 'NATURAL',
  RateAsc = 'RATE_ASC',
  RateDesc = 'RATE_DESC'
}

/** A range of `Date`. */
export type DateRange = {
  __typename?: 'DateRange';
  /** The ending bound of our range. */
  end?: Maybe<DateRangeBound>;
  /** The starting bound of our range. */
  start?: Maybe<DateRangeBound>;
};

/** The value at one end of a range. A range can either include this value, or not. */
export type DateRangeBound = {
  __typename?: 'DateRangeBound';
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean'];
  /** The value at one end of our range. */
  value: Scalars['Date'];
};

/** The value at one end of a range. A range can either include this value, or not. */
export type DateRangeBoundInput = {
  /** Whether or not the value of this bound is included in the range. */
  inclusive: Scalars['Boolean'];
  /** The value at one end of our range. */
  value: Scalars['Date'];
};

/** A range of `Date`. */
export type DateRangeInput = {
  /** The ending bound of our range. */
  end?: InputMaybe<DateRangeBoundInput>;
  /** The starting bound of our range. */
  start?: InputMaybe<DateRangeBoundInput>;
};

/** All input for the `deleteFinancialPeriod` mutation. */
export type DeleteFinancialPeriodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `deleteFinancialPeriod` mutation. */
export type DeleteFinancialPeriodPayload = {
  __typename?: 'DeleteFinancialPeriodPayload';
  bigInt?: Maybe<Scalars['BigInt']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteGiftAidClaimFromPayment` mutation. */
export type DeleteGiftAidClaimFromPaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `deleteGiftAidClaimFromPayment` mutation. */
export type DeleteGiftAidClaimFromPaymentPayload = {
  __typename?: 'DeleteGiftAidClaimFromPaymentPayload';
  /** Reads a single `Address` that is related to this `GiftAidClaim`. */
  addressByAddressIdAndPersonId?: Maybe<Address>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  giftAidClaim?: Maybe<GiftAidClaim>;
  /** An edge for our `GiftAidClaim`. May be used by Relay 1. */
  giftAidClaimEdge?: Maybe<GiftAidClaimsEdge>;
  /** Reads a single `Payment` that is related to this `GiftAidClaim`. */
  paymentByGiftAidPaymentId?: Maybe<Payment>;
  /** Reads a single `Payment` that is related to this `GiftAidClaim`. */
  paymentByPaymentIdAndPersonId?: Maybe<Payment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `deleteGiftAidClaimFromPayment` mutation. */
export type DeleteGiftAidClaimFromPaymentPayloadGiftAidClaimEdgeArgs = {
  orderBy?: InputMaybe<Array<GiftAidClaimsOrderBy>>;
};

/** All input for the `deleteReportedDonation` mutation. */
export type DeleteReportedDonationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `deleteReportedDonation` mutation. */
export type DeleteReportedDonationPayload = {
  __typename?: 'DeleteReportedDonationPayload';
  bigInt?: Maybe<Scalars['BigInt']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type EmailAddress = Node & {
  __typename?: 'EmailAddress';
  createdAt: Scalars['Datetime'];
  email: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Person`. */
  peopleByIdAndEmail: PeopleConnection;
  /** Reads and enables pagination through a set of `Person`. */
  peopleByIdAndEmailList: Array<Person>;
  /** Reads a single `Person` that is related to this `EmailAddress`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  updatedAt: Scalars['Datetime'];
};


export type EmailAddressPeopleByIdAndEmailArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PersonCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PeopleOrderBy>>;
};


export type EmailAddressPeopleByIdAndEmailListArgs = {
  condition?: InputMaybe<PersonCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PeopleOrderBy>>;
};

/**
 * A condition to be used against `EmailAddress` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EmailAddressCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `EmailAddress` values. */
export type EmailAddressesConnection = {
  __typename?: 'EmailAddressesConnection';
  /** A list of edges which contains the `EmailAddress` and cursor to aid in pagination. */
  edges: Array<EmailAddressesEdge>;
  /** A list of `EmailAddress` objects. */
  nodes: Array<EmailAddress>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EmailAddress` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EmailAddress` edge in the connection. */
export type EmailAddressesEdge = {
  __typename?: 'EmailAddressesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EmailAddress` at the end of the edge. */
  node: EmailAddress;
};

/** Methods to use when ordering `EmailAddress`. */
export enum EmailAddressesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  Natural = 'NATURAL',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ExchangeRate = Node & {
  __typename?: 'ExchangeRate';
  /** Reads a single `Currency` that is related to this `ExchangeRate`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode: Scalars['String'];
  date: Scalars['Date'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  rate?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `ExchangeRate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ExchangeRateCondition = {
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `rate` field. */
  rate?: InputMaybe<Scalars['BigFloat']>;
};

/** A connection to a list of `ExchangeRate` values. */
export type ExchangeRatesConnection = {
  __typename?: 'ExchangeRatesConnection';
  /** A list of edges which contains the `ExchangeRate` and cursor to aid in pagination. */
  edges: Array<ExchangeRatesEdge>;
  /** A list of `ExchangeRate` objects. */
  nodes: Array<ExchangeRate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExchangeRate` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ExchangeRate` edge in the connection. */
export type ExchangeRatesEdge = {
  __typename?: 'ExchangeRatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ExchangeRate` at the end of the edge. */
  node: ExchangeRate;
};

/** Methods to use when ordering `ExchangeRate`. */
export enum ExchangeRatesOrderBy {
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RateAsc = 'RATE_ASC',
  RateDesc = 'RATE_DESC'
}

/** A connection to a list of `ExtendedLottery` values. */
export type ExtendedLotteriesConnection = {
  __typename?: 'ExtendedLotteriesConnection';
  /** A list of edges which contains the `ExtendedLottery` and cursor to aid in pagination. */
  edges: Array<ExtendedLotteriesEdge>;
  /** A list of `ExtendedLottery` objects. */
  nodes: Array<ExtendedLottery>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExtendedLottery` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ExtendedLottery` edge in the connection. */
export type ExtendedLotteriesEdge = {
  __typename?: 'ExtendedLotteriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ExtendedLottery` at the end of the edge. */
  node: ExtendedLottery;
};

/** Methods to use when ordering `ExtendedLottery`. */
export enum ExtendedLotteriesOrderBy {
  BlockSizeAsc = 'BLOCK_SIZE_ASC',
  BlockSizeDesc = 'BLOCK_SIZE_DESC',
  CloseTimestampAsc = 'CLOSE_TIMESTAMP_ASC',
  CloseTimestampDesc = 'CLOSE_TIMESTAMP_DESC',
  DrawEpochAsc = 'DRAW_EPOCH_ASC',
  DrawEpochDesc = 'DRAW_EPOCH_DESC',
  DrawTimestampAsc = 'DRAW_TIMESTAMP_ASC',
  DrawTimestampDesc = 'DRAW_TIMESTAMP_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LockTimestampAsc = 'LOCK_TIMESTAMP_ASC',
  LockTimestampDesc = 'LOCK_TIMESTAMP_DESC',
  Natural = 'NATURAL',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  WinningNumberAsc = 'WINNING_NUMBER_ASC',
  WinningNumberDesc = 'WINNING_NUMBER_DESC'
}

export type ExtendedLottery = {
  __typename?: 'ExtendedLottery';
  blockSize?: Maybe<Scalars['BigFloat']>;
  closeTimestamp?: Maybe<Scalars['Datetime']>;
  drawEpoch?: Maybe<Scalars['Int']>;
  drawTimestamp?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['BigInt']>;
  lockTimestamp?: Maybe<Scalars['Datetime']>;
  status?: Maybe<Scalars['String']>;
  winningNumber?: Maybe<Scalars['BigInt']>;
};

/**
 * A condition to be used against `ExtendedLottery` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ExtendedLotteryCondition = {
  /** Checks for equality with the object’s `blockSize` field. */
  blockSize?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `closeTimestamp` field. */
  closeTimestamp?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `drawEpoch` field. */
  drawEpoch?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `drawTimestamp` field. */
  drawTimestamp?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `lockTimestamp` field. */
  lockTimestamp?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `winningNumber` field. */
  winningNumber?: InputMaybe<Scalars['BigInt']>;
};

export type ExternalOrganization = Node & {
  __typename?: 'ExternalOrganization';
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByOrganizationId: CompletionPaymentsConnection;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByOrganizationIdList: Array<CompletionPayment>;
  createdAt: Scalars['Datetime'];
  id: Scalars['BigInt'];
  keywords?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  organizationSlug?: Maybe<Scalars['String']>;
  /** Reads a single `Program` that is related to this `ExternalOrganization`. */
  programByOrganizationSlug?: Maybe<Program>;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  recurringReportedDonationsByOrganizationId: RecurringReportedDonationsConnection;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  recurringReportedDonationsByOrganizationIdList: Array<RecurringReportedDonation>;
  /** Reads and enables pagination through a set of `ReportedDonation`. */
  reportedDonationsByOrganizationId: ReportedDonationsConnection;
  /** Reads and enables pagination through a set of `ReportedDonation`. */
  reportedDonationsByOrganizationIdList: Array<ReportedDonation>;
  updatedAt: Scalars['Datetime'];
  url?: Maybe<Scalars['String']>;
};


export type ExternalOrganizationCompletionPaymentsByOrganizationIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type ExternalOrganizationCompletionPaymentsByOrganizationIdListArgs = {
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type ExternalOrganizationRecurringReportedDonationsByOrganizationIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecurringReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringReportedDonationsOrderBy>>;
};


export type ExternalOrganizationRecurringReportedDonationsByOrganizationIdListArgs = {
  condition?: InputMaybe<RecurringReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringReportedDonationsOrderBy>>;
};


export type ExternalOrganizationReportedDonationsByOrganizationIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportedDonationsOrderBy>>;
};


export type ExternalOrganizationReportedDonationsByOrganizationIdListArgs = {
  condition?: InputMaybe<ReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportedDonationsOrderBy>>;
};

/**
 * A condition to be used against `ExternalOrganization` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ExternalOrganizationCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `keywords` field. */
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `organizationSlug` field. */
  organizationSlug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `ExternalOrganization` values. */
export type ExternalOrganizationsConnection = {
  __typename?: 'ExternalOrganizationsConnection';
  /** A list of edges which contains the `ExternalOrganization` and cursor to aid in pagination. */
  edges: Array<ExternalOrganizationsEdge>;
  /** A list of `ExternalOrganization` objects. */
  nodes: Array<ExternalOrganization>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ExternalOrganization` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ExternalOrganization` edge in the connection. */
export type ExternalOrganizationsEdge = {
  __typename?: 'ExternalOrganizationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ExternalOrganization` at the end of the edge. */
  node: ExternalOrganization;
};

/** Methods to use when ordering `ExternalOrganization`. */
export enum ExternalOrganizationsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KeywordsAsc = 'KEYWORDS_ASC',
  KeywordsDesc = 'KEYWORDS_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrganizationSlugAsc = 'ORGANIZATION_SLUG_ASC',
  OrganizationSlugDesc = 'ORGANIZATION_SLUG_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC'
}

export type FinancialPeriod = Node & {
  __typename?: 'FinancialPeriod';
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByFinancialPeriodId: CompletionPaymentsConnection;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByFinancialPeriodIdList: Array<CompletionPayment>;
  createdAt: Scalars['Datetime'];
  currencyCode: Scalars['String'];
  donationsTaxDeductible?: Maybe<Scalars['Boolean']>;
  employmentStatus?: Maybe<IncomeEmploymentStatus>;
  id: Scalars['BigInt'];
  /** Reads and enables pagination through a set of `Income`. */
  incomesByFinancialPeriodId: IncomesConnection;
  /** Reads and enables pagination through a set of `Income`. */
  incomesByFinancialPeriodIdList: Array<Income>;
  /** Reads and enables pagination through a set of `Liability`. */
  liabilitiesByFinancialPeriodId: LiabilitiesConnection;
  /** Reads and enables pagination through a set of `Liability`. */
  liabilitiesByFinancialPeriodIdList: Array<Liability>;
  /** Reads and enables pagination through a set of `LiabilityPayment`. */
  liabilityPaymentsByFinancialPeriodId: LiabilityPaymentsConnection;
  /** Reads and enables pagination through a set of `LiabilityPayment`. */
  liabilityPaymentsByFinancialPeriodIdList: Array<LiabilityPayment>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  period: DateRange;
  /** Reads a single `Person` that is related to this `FinancialPeriod`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  updatedAt: Scalars['Datetime'];
  wealthAmount?: Maybe<Scalars['BigFloat']>;
};


export type FinancialPeriodCompletionPaymentsByFinancialPeriodIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type FinancialPeriodCompletionPaymentsByFinancialPeriodIdListArgs = {
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type FinancialPeriodIncomesByFinancialPeriodIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IncomeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IncomesOrderBy>>;
};


export type FinancialPeriodIncomesByFinancialPeriodIdListArgs = {
  condition?: InputMaybe<IncomeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IncomesOrderBy>>;
};


export type FinancialPeriodLiabilitiesByFinancialPeriodIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilitiesOrderBy>>;
};


export type FinancialPeriodLiabilitiesByFinancialPeriodIdListArgs = {
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilitiesOrderBy>>;
};


export type FinancialPeriodLiabilityPaymentsByFinancialPeriodIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilityPaymentsOrderBy>>;
};


export type FinancialPeriodLiabilityPaymentsByFinancialPeriodIdListArgs = {
  condition?: InputMaybe<LiabilityPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilityPaymentsOrderBy>>;
};

/**
 * A condition to be used against `FinancialPeriod` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FinancialPeriodCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `donationsTaxDeductible` field. */
  donationsTaxDeductible?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `employmentStatus` field. */
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `period` field. */
  period?: InputMaybe<DateRangeInput>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `wealthAmount` field. */
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
};

/** A connection to a list of `FinancialPeriod` values. */
export type FinancialPeriodsConnection = {
  __typename?: 'FinancialPeriodsConnection';
  /** A list of edges which contains the `FinancialPeriod` and cursor to aid in pagination. */
  edges: Array<FinancialPeriodsEdge>;
  /** A list of `FinancialPeriod` objects. */
  nodes: Array<FinancialPeriod>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FinancialPeriod` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FinancialPeriod` edge in the connection. */
export type FinancialPeriodsEdge = {
  __typename?: 'FinancialPeriodsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FinancialPeriod` at the end of the edge. */
  node: FinancialPeriod;
};

/** Methods to use when ordering `FinancialPeriod`. */
export enum FinancialPeriodsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  DonationsTaxDeductibleAsc = 'DONATIONS_TAX_DEDUCTIBLE_ASC',
  DonationsTaxDeductibleDesc = 'DONATIONS_TAX_DEDUCTIBLE_DESC',
  EmploymentStatusAsc = 'EMPLOYMENT_STATUS_ASC',
  EmploymentStatusDesc = 'EMPLOYMENT_STATUS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PeriodAsc = 'PERIOD_ASC',
  PeriodDesc = 'PERIOD_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  WealthAmountAsc = 'WEALTH_AMOUNT_ASC',
  WealthAmountDesc = 'WEALTH_AMOUNT_DESC'
}

export type FundraiserStat = {
  __typename?: 'FundraiserStat';
  amountRaisedNormalized?: Maybe<Scalars['BigFloat']>;
  fundraiserId?: Maybe<Scalars['String']>;
  numDonors?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `FundraiserStat` values. */
export type FundraiserStatsConnection = {
  __typename?: 'FundraiserStatsConnection';
  /** A list of edges which contains the `FundraiserStat` and cursor to aid in pagination. */
  edges: Array<FundraiserStatsEdge>;
  /** A list of `FundraiserStat` objects. */
  nodes: Array<FundraiserStat>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FundraiserStat` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FundraiserStat` edge in the connection. */
export type FundraiserStatsEdge = {
  __typename?: 'FundraiserStatsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FundraiserStat` at the end of the edge. */
  node: FundraiserStat;
};

export type GetPledgeByPersonOrTemporaryPersonT = {
  __typename?: 'GetPledgeByPersonOrTemporaryPersonT';
  anonymous: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  email?: Maybe<Scalars['String']>;
  emailVerifiedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['BigInt'];
  incomePercentage: Scalars['BigFloat'];
  locale: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  motivation?: Maybe<Scalars['String']>;
  period: DateRange;
  personId: Scalars['BigInt'];
  pledgeType: PledgeType;
  updatedAt: Scalars['Datetime'];
  wealthPercentage?: Maybe<Scalars['BigFloat']>;
};

export type GiftAidClaim = Node & {
  __typename?: 'GiftAidClaim';
  /** Reads a single `Address` that is related to this `GiftAidClaim`. */
  addressByAddressIdAndPersonId?: Maybe<Address>;
  addressId: Scalars['BigInt'];
  createdAt: Scalars['Datetime'];
  giftAidPaymentId?: Maybe<Scalars['BigInt']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Payment` that is related to this `GiftAidClaim`. */
  paymentByGiftAidPaymentId?: Maybe<Payment>;
  /** Reads a single `Payment` that is related to this `GiftAidClaim`. */
  paymentByPaymentIdAndPersonId?: Maybe<Payment>;
  paymentId: Scalars['BigInt'];
  personId: Scalars['BigInt'];
  reportId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `GiftAidClaim` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GiftAidClaimCondition = {
  /** Checks for equality with the object’s `addressId` field. */
  addressId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `giftAidPaymentId` field. */
  giftAidPaymentId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paymentId` field. */
  paymentId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `reportId` field. */
  reportId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `GiftAidClaim` values. */
export type GiftAidClaimsConnection = {
  __typename?: 'GiftAidClaimsConnection';
  /** A list of edges which contains the `GiftAidClaim` and cursor to aid in pagination. */
  edges: Array<GiftAidClaimsEdge>;
  /** A list of `GiftAidClaim` objects. */
  nodes: Array<GiftAidClaim>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GiftAidClaim` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GiftAidClaim` edge in the connection. */
export type GiftAidClaimsEdge = {
  __typename?: 'GiftAidClaimsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GiftAidClaim` at the end of the edge. */
  node: GiftAidClaim;
};

/** Methods to use when ordering `GiftAidClaim`. */
export enum GiftAidClaimsOrderBy {
  AddressIdAsc = 'ADDRESS_ID_ASC',
  AddressIdDesc = 'ADDRESS_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GiftAidPaymentIdAsc = 'GIFT_AID_PAYMENT_ID_ASC',
  GiftAidPaymentIdDesc = 'GIFT_AID_PAYMENT_ID_DESC',
  Natural = 'NATURAL',
  PaymentIdAsc = 'PAYMENT_ID_ASC',
  PaymentIdDesc = 'PAYMENT_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReportIdAsc = 'REPORT_ID_ASC',
  ReportIdDesc = 'REPORT_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type GivingWhatWeCanSurvey = Node & {
  __typename?: 'GivingWhatWeCanSurvey';
  counterfactualDonationPercentage?: Maybe<Scalars['Float']>;
  createdAt: Scalars['Datetime'];
  gwwcDonationInfluence?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  otherComments?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `GivingWhatWeCanSurvey`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  referrer?: Maybe<Array<Maybe<Scalars['String']>>>;
  referrerNotes?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  wherePlanningToGive?: Maybe<Array<Maybe<Scalars['String']>>>;
  wherePlanningToGiveNotes?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `GivingWhatWeCanSurvey` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type GivingWhatWeCanSurveyCondition = {
  /** Checks for equality with the object’s `counterfactualDonationPercentage` field. */
  counterfactualDonationPercentage?: InputMaybe<Scalars['Float']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `gwwcDonationInfluence` field. */
  gwwcDonationInfluence?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `otherComments` field. */
  otherComments?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `referrer` field. */
  referrer?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `referrerNotes` field. */
  referrerNotes?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `wherePlanningToGive` field. */
  wherePlanningToGive?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `wherePlanningToGiveNotes` field. */
  wherePlanningToGiveNotes?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `GivingWhatWeCanSurvey` values. */
export type GivingWhatWeCanSurveysConnection = {
  __typename?: 'GivingWhatWeCanSurveysConnection';
  /** A list of edges which contains the `GivingWhatWeCanSurvey` and cursor to aid in pagination. */
  edges: Array<GivingWhatWeCanSurveysEdge>;
  /** A list of `GivingWhatWeCanSurvey` objects. */
  nodes: Array<GivingWhatWeCanSurvey>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GivingWhatWeCanSurvey` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GivingWhatWeCanSurvey` edge in the connection. */
export type GivingWhatWeCanSurveysEdge = {
  __typename?: 'GivingWhatWeCanSurveysEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GivingWhatWeCanSurvey` at the end of the edge. */
  node: GivingWhatWeCanSurvey;
};

/** Methods to use when ordering `GivingWhatWeCanSurvey`. */
export enum GivingWhatWeCanSurveysOrderBy {
  CounterfactualDonationPercentageAsc = 'COUNTERFACTUAL_DONATION_PERCENTAGE_ASC',
  CounterfactualDonationPercentageDesc = 'COUNTERFACTUAL_DONATION_PERCENTAGE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GwwcDonationInfluenceAsc = 'GWWC_DONATION_INFLUENCE_ASC',
  GwwcDonationInfluenceDesc = 'GWWC_DONATION_INFLUENCE_DESC',
  Natural = 'NATURAL',
  OtherCommentsAsc = 'OTHER_COMMENTS_ASC',
  OtherCommentsDesc = 'OTHER_COMMENTS_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReferrerAsc = 'REFERRER_ASC',
  ReferrerDesc = 'REFERRER_DESC',
  ReferrerNotesAsc = 'REFERRER_NOTES_ASC',
  ReferrerNotesDesc = 'REFERRER_NOTES_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  WherePlanningToGiveAsc = 'WHERE_PLANNING_TO_GIVE_ASC',
  WherePlanningToGiveDesc = 'WHERE_PLANNING_TO_GIVE_DESC',
  WherePlanningToGiveNotesAsc = 'WHERE_PLANNING_TO_GIVE_NOTES_ASC',
  WherePlanningToGiveNotesDesc = 'WHERE_PLANNING_TO_GIVE_NOTES_DESC'
}

export type GlobalStat = {
  __typename?: 'GlobalStat';
  activeTryGivingPledgesTotal?: Maybe<Scalars['Int']>;
  gwwcDonationsTotal?: Maybe<Scalars['BigFloat']>;
  gwwcPledgesTotal?: Maybe<Scalars['Int']>;
  pledgersCountriesTotal?: Maybe<Scalars['Int']>;
  pledgesDonationsTotal?: Maybe<Scalars['BigFloat']>;
  tryGivingCompletedTotal?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `GlobalStat` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type GlobalStatCondition = {
  /** Checks for equality with the object’s `activeTryGivingPledgesTotal` field. */
  activeTryGivingPledgesTotal?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `gwwcDonationsTotal` field. */
  gwwcDonationsTotal?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `gwwcPledgesTotal` field. */
  gwwcPledgesTotal?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `pledgersCountriesTotal` field. */
  pledgersCountriesTotal?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `pledgesDonationsTotal` field. */
  pledgesDonationsTotal?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `tryGivingCompletedTotal` field. */
  tryGivingCompletedTotal?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `GlobalStat` values. */
export type GlobalStatsConnection = {
  __typename?: 'GlobalStatsConnection';
  /** A list of edges which contains the `GlobalStat` and cursor to aid in pagination. */
  edges: Array<GlobalStatsEdge>;
  /** A list of `GlobalStat` objects. */
  nodes: Array<GlobalStat>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GlobalStat` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GlobalStat` edge in the connection. */
export type GlobalStatsEdge = {
  __typename?: 'GlobalStatsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GlobalStat` at the end of the edge. */
  node: GlobalStat;
};

/** Methods to use when ordering `GlobalStat`. */
export enum GlobalStatsOrderBy {
  ActiveTryGivingPledgesTotalAsc = 'ACTIVE_TRY_GIVING_PLEDGES_TOTAL_ASC',
  ActiveTryGivingPledgesTotalDesc = 'ACTIVE_TRY_GIVING_PLEDGES_TOTAL_DESC',
  GwwcDonationsTotalAsc = 'GWWC_DONATIONS_TOTAL_ASC',
  GwwcDonationsTotalDesc = 'GWWC_DONATIONS_TOTAL_DESC',
  GwwcPledgesTotalAsc = 'GWWC_PLEDGES_TOTAL_ASC',
  GwwcPledgesTotalDesc = 'GWWC_PLEDGES_TOTAL_DESC',
  Natural = 'NATURAL',
  PledgersCountriesTotalAsc = 'PLEDGERS_COUNTRIES_TOTAL_ASC',
  PledgersCountriesTotalDesc = 'PLEDGERS_COUNTRIES_TOTAL_DESC',
  PledgesDonationsTotalAsc = 'PLEDGES_DONATIONS_TOTAL_ASC',
  PledgesDonationsTotalDesc = 'PLEDGES_DONATIONS_TOTAL_DESC',
  TryGivingCompletedTotalAsc = 'TRY_GIVING_COMPLETED_TOTAL_ASC',
  TryGivingCompletedTotalDesc = 'TRY_GIVING_COMPLETED_TOTAL_DESC'
}

export type GocardlessMandate = {
  __typename?: 'GocardlessMandate';
  createdAt: Scalars['Datetime'];
  gocardlessBankAccountBankName?: Maybe<Scalars['String']>;
  gocardlessBankAccountHolderName?: Maybe<Scalars['String']>;
  gocardlessBankAccountId?: Maybe<Scalars['String']>;
  gocardlessBankAccountNumberEnding?: Maybe<Scalars['Int']>;
  gocardlessCustomerId: Scalars['String'];
  gocardlessMandateId: Scalars['String'];
  /** Reads a single `Person` that is related to this `GocardlessMandate`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  status: GocardlessMandateStatus;
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `GocardlessMandate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GocardlessMandateCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `gocardlessBankAccountBankName` field. */
  gocardlessBankAccountBankName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `gocardlessBankAccountHolderName` field. */
  gocardlessBankAccountHolderName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `gocardlessBankAccountId` field. */
  gocardlessBankAccountId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `gocardlessBankAccountNumberEnding` field. */
  gocardlessBankAccountNumberEnding?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `gocardlessCustomerId` field. */
  gocardlessCustomerId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `gocardlessMandateId` field. */
  gocardlessMandateId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<GocardlessMandateStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export enum GocardlessMandateStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  PendingCustomerApproval = 'PENDING_CUSTOMER_APPROVAL',
  PendingSubmission = 'PENDING_SUBMISSION',
  Submitted = 'SUBMITTED'
}

/** A connection to a list of `GocardlessMandate` values. */
export type GocardlessMandatesConnection = {
  __typename?: 'GocardlessMandatesConnection';
  /** A list of edges which contains the `GocardlessMandate` and cursor to aid in pagination. */
  edges: Array<GocardlessMandatesEdge>;
  /** A list of `GocardlessMandate` objects. */
  nodes: Array<GocardlessMandate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GocardlessMandate` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GocardlessMandate` edge in the connection. */
export type GocardlessMandatesEdge = {
  __typename?: 'GocardlessMandatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GocardlessMandate` at the end of the edge. */
  node: GocardlessMandate;
};

/** Methods to use when ordering `GocardlessMandate`. */
export enum GocardlessMandatesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GocardlessBankAccountBankNameAsc = 'GOCARDLESS_BANK_ACCOUNT_BANK_NAME_ASC',
  GocardlessBankAccountBankNameDesc = 'GOCARDLESS_BANK_ACCOUNT_BANK_NAME_DESC',
  GocardlessBankAccountHolderNameAsc = 'GOCARDLESS_BANK_ACCOUNT_HOLDER_NAME_ASC',
  GocardlessBankAccountHolderNameDesc = 'GOCARDLESS_BANK_ACCOUNT_HOLDER_NAME_DESC',
  GocardlessBankAccountIdAsc = 'GOCARDLESS_BANK_ACCOUNT_ID_ASC',
  GocardlessBankAccountIdDesc = 'GOCARDLESS_BANK_ACCOUNT_ID_DESC',
  GocardlessBankAccountNumberEndingAsc = 'GOCARDLESS_BANK_ACCOUNT_NUMBER_ENDING_ASC',
  GocardlessBankAccountNumberEndingDesc = 'GOCARDLESS_BANK_ACCOUNT_NUMBER_ENDING_DESC',
  GocardlessCustomerIdAsc = 'GOCARDLESS_CUSTOMER_ID_ASC',
  GocardlessCustomerIdDesc = 'GOCARDLESS_CUSTOMER_ID_DESC',
  GocardlessMandateIdAsc = 'GOCARDLESS_MANDATE_ID_ASC',
  GocardlessMandateIdDesc = 'GOCARDLESS_MANDATE_ID_DESC',
  Natural = 'NATURAL',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type GocardlessRedirectFlow = Node & {
  __typename?: 'GocardlessRedirectFlow';
  createdAt: Scalars['Datetime'];
  expires: Scalars['Datetime'];
  gocardlessRedirectFlowId?: Maybe<Scalars['String']>;
  gocardlessRedirectFlowUrl?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Person` that is related to this `GocardlessRedirectFlow`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  sessionToken: Scalars['UUID'];
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `GocardlessRedirectFlow` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type GocardlessRedirectFlowCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `expires` field. */
  expires?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `gocardlessRedirectFlowId` field. */
  gocardlessRedirectFlowId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `gocardlessRedirectFlowUrl` field. */
  gocardlessRedirectFlowUrl?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `sessionToken` field. */
  sessionToken?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `GocardlessRedirectFlow` values. */
export type GocardlessRedirectFlowsConnection = {
  __typename?: 'GocardlessRedirectFlowsConnection';
  /** A list of edges which contains the `GocardlessRedirectFlow` and cursor to aid in pagination. */
  edges: Array<GocardlessRedirectFlowsEdge>;
  /** A list of `GocardlessRedirectFlow` objects. */
  nodes: Array<GocardlessRedirectFlow>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GocardlessRedirectFlow` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GocardlessRedirectFlow` edge in the connection. */
export type GocardlessRedirectFlowsEdge = {
  __typename?: 'GocardlessRedirectFlowsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GocardlessRedirectFlow` at the end of the edge. */
  node: GocardlessRedirectFlow;
};

/** Methods to use when ordering `GocardlessRedirectFlow`. */
export enum GocardlessRedirectFlowsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  ExpiresAsc = 'EXPIRES_ASC',
  ExpiresDesc = 'EXPIRES_DESC',
  GocardlessRedirectFlowIdAsc = 'GOCARDLESS_REDIRECT_FLOW_ID_ASC',
  GocardlessRedirectFlowIdDesc = 'GOCARDLESS_REDIRECT_FLOW_ID_DESC',
  GocardlessRedirectFlowUrlAsc = 'GOCARDLESS_REDIRECT_FLOW_URL_ASC',
  GocardlessRedirectFlowUrlDesc = 'GOCARDLESS_REDIRECT_FLOW_URL_DESC',
  Natural = 'NATURAL',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SessionTokenAsc = 'SESSION_TOKEN_ASC',
  SessionTokenDesc = 'SESSION_TOKEN_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Income = Node & {
  __typename?: 'Income';
  amount: Scalars['BigFloat'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `FinancialPeriod` that is related to this `Income`. */
  financialPeriodByFinancialPeriodId?: Maybe<FinancialPeriod>;
  financialPeriodId: Scalars['BigInt'];
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Person` that is related to this `Income`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  updatedAt: Scalars['Datetime'];
};

/** A condition to be used against `Income` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type IncomeCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `financialPeriodId` field. */
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export enum IncomeEmploymentStatus {
  Employed = 'EMPLOYED',
  Retired = 'RETIRED',
  Student = 'STUDENT',
  Unemployed = 'UNEMPLOYED'
}

/** An input for mutations affecting `Income` */
export type IncomeInput = {
  amount: Scalars['BigFloat'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  financialPeriodId: Scalars['BigInt'];
  id?: InputMaybe<Scalars['BigInt']>;
  personId: Scalars['BigInt'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `Income` values. */
export type IncomesConnection = {
  __typename?: 'IncomesConnection';
  /** A list of edges which contains the `Income` and cursor to aid in pagination. */
  edges: Array<IncomesEdge>;
  /** A list of `Income` objects. */
  nodes: Array<Income>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Income` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Income` edge in the connection. */
export type IncomesEdge = {
  __typename?: 'IncomesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Income` at the end of the edge. */
  node: Income;
};

/** Methods to use when ordering `Income`. */
export enum IncomesOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FinancialPeriodIdAsc = 'FINANCIAL_PERIOD_ID_ASC',
  FinancialPeriodIdDesc = 'FINANCIAL_PERIOD_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type Interval = {
  __typename?: 'Interval';
  /** A quantity of days. */
  days?: Maybe<Scalars['Int']>;
  /** A quantity of hours. */
  hours?: Maybe<Scalars['Int']>;
  /** A quantity of minutes. */
  minutes?: Maybe<Scalars['Int']>;
  /** A quantity of months. */
  months?: Maybe<Scalars['Int']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: Maybe<Scalars['Float']>;
  /** A quantity of years. */
  years?: Maybe<Scalars['Int']>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type IntervalInput = {
  /** A quantity of days. */
  days?: InputMaybe<Scalars['Int']>;
  /** A quantity of hours. */
  hours?: InputMaybe<Scalars['Int']>;
  /** A quantity of minutes. */
  minutes?: InputMaybe<Scalars['Int']>;
  /** A quantity of months. */
  months?: InputMaybe<Scalars['Int']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: InputMaybe<Scalars['Float']>;
  /** A quantity of years. */
  years?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Liability` values. */
export type LiabilitiesConnection = {
  __typename?: 'LiabilitiesConnection';
  /** A list of edges which contains the `Liability` and cursor to aid in pagination. */
  edges: Array<LiabilitiesEdge>;
  /** A list of `Liability` objects. */
  nodes: Array<Liability>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Liability` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Liability` edge in the connection. */
export type LiabilitiesEdge = {
  __typename?: 'LiabilitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Liability` at the end of the edge. */
  node: Liability;
};

/** Methods to use when ordering `Liability`. */
export enum LiabilitiesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  FinancialPeriodIdAsc = 'FINANCIAL_PERIOD_ID_ASC',
  FinancialPeriodIdDesc = 'FINANCIAL_PERIOD_ID_DESC',
  IncomeLiabilityAmountAsc = 'INCOME_LIABILITY_AMOUNT_ASC',
  IncomeLiabilityAmountDesc = 'INCOME_LIABILITY_AMOUNT_DESC',
  LiabilityAmountAsc = 'LIABILITY_AMOUNT_ASC',
  LiabilityAmountDesc = 'LIABILITY_AMOUNT_DESC',
  LiabilityNormalizedAsc = 'LIABILITY_NORMALIZED_ASC',
  LiabilityNormalizedDesc = 'LIABILITY_NORMALIZED_DESC',
  LiabilityPeriodAsc = 'LIABILITY_PERIOD_ASC',
  LiabilityPeriodDesc = 'LIABILITY_PERIOD_DESC',
  Natural = 'NATURAL',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PledgeIdAsc = 'PLEDGE_ID_ASC',
  PledgeIdDesc = 'PLEDGE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  WealthLiabilityAmountAsc = 'WEALTH_LIABILITY_AMOUNT_ASC',
  WealthLiabilityAmountDesc = 'WEALTH_LIABILITY_AMOUNT_DESC'
}

export type Liability = Node & {
  __typename?: 'Liability';
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByPledgeIdAndFinancialPeriodId: CompletionPaymentsConnection;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByPledgeIdAndFinancialPeriodIdList: Array<CompletionPayment>;
  createdAt: Scalars['Datetime'];
  currencyCode: Scalars['String'];
  /** Reads a single `FinancialPeriod` that is related to this `Liability`. */
  financialPeriodByFinancialPeriodId?: Maybe<FinancialPeriod>;
  financialPeriodId: Scalars['BigInt'];
  incomeLiabilityAmount?: Maybe<Scalars['BigFloat']>;
  liabilityAmount: Scalars['BigFloat'];
  liabilityNormalized: Scalars['BigFloat'];
  liabilityPeriod: DateRange;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Person` that is related to this `Liability`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  /** Reads a single `Pledge` that is related to this `Liability`. */
  pledgeByPledgeId?: Maybe<Pledge>;
  pledgeId: Scalars['BigInt'];
  updatedAt: Scalars['Datetime'];
  wealthLiabilityAmount?: Maybe<Scalars['BigFloat']>;
};


export type LiabilityCompletionPaymentsByPledgeIdAndFinancialPeriodIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type LiabilityCompletionPaymentsByPledgeIdAndFinancialPeriodIdListArgs = {
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};

export type LiabilityCompletionT = {
  __typename?: 'LiabilityCompletionT';
  completionAmount?: Maybe<Scalars['BigFloat']>;
  completionAmountNormalized?: Maybe<Scalars['BigFloat']>;
  completionPercentage?: Maybe<Scalars['BigFloat']>;
  currencyCode?: Maybe<Scalars['String']>;
  financialPeriodId?: Maybe<Scalars['BigInt']>;
  financialPeriodWealth?: Maybe<Scalars['BigFloat']>;
  incomeLiabilityAmount?: Maybe<Scalars['BigFloat']>;
  liabilityAmount?: Maybe<Scalars['BigFloat']>;
  period?: Maybe<DateRange>;
  personId?: Maybe<Scalars['BigInt']>;
  pledgeId?: Maybe<Scalars['BigInt']>;
  wealthLiabilityAmount?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LiabilityCompletionT` values. */
export type LiabilityCompletionTsConnection = {
  __typename?: 'LiabilityCompletionTsConnection';
  /** A list of edges which contains the `LiabilityCompletionT` and cursor to aid in pagination. */
  edges: Array<LiabilityCompletionTsEdge>;
  /** A list of `LiabilityCompletionT` objects. */
  nodes: Array<LiabilityCompletionT>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LiabilityCompletionT` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LiabilityCompletionT` edge in the connection. */
export type LiabilityCompletionTsEdge = {
  __typename?: 'LiabilityCompletionTsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LiabilityCompletionT` at the end of the edge. */
  node: LiabilityCompletionT;
};

/**
 * A condition to be used against `Liability` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LiabilityCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `financialPeriodId` field. */
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `incomeLiabilityAmount` field. */
  incomeLiabilityAmount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `liabilityAmount` field. */
  liabilityAmount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `liabilityNormalized` field. */
  liabilityNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `liabilityPeriod` field. */
  liabilityPeriod?: InputMaybe<DateRangeInput>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `pledgeId` field. */
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `wealthLiabilityAmount` field. */
  wealthLiabilityAmount?: InputMaybe<Scalars['BigFloat']>;
};

export type LiabilityPayment = Node & {
  __typename?: 'LiabilityPayment';
  createdAt: Scalars['Datetime'];
  /** Reads a single `FinancialPeriod` that is related to this `LiabilityPayment`. */
  financialPeriodByFinancialPeriodId?: Maybe<FinancialPeriod>;
  financialPeriodId: Scalars['BigInt'];
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Payment` that is related to this `LiabilityPayment`. */
  paymentByPaymentId?: Maybe<Payment>;
  paymentId?: Maybe<Scalars['BigInt']>;
  /** Reads a single `Person` that is related to this `LiabilityPayment`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  /** Reads a single `Pledge` that is related to this `LiabilityPayment`. */
  pledgeByPledgeId?: Maybe<Pledge>;
  pledgeId: Scalars['BigInt'];
  /** Reads a single `ReportedDonation` that is related to this `LiabilityPayment`. */
  reportedDonationByReportedDonationId?: Maybe<ReportedDonation>;
  reportedDonationId?: Maybe<Scalars['BigInt']>;
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `LiabilityPayment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LiabilityPaymentCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `financialPeriodId` field. */
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paymentId` field. */
  paymentId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `pledgeId` field. */
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `reportedDonationId` field. */
  reportedDonationId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `LiabilityPayment` values. */
export type LiabilityPaymentsConnection = {
  __typename?: 'LiabilityPaymentsConnection';
  /** A list of edges which contains the `LiabilityPayment` and cursor to aid in pagination. */
  edges: Array<LiabilityPaymentsEdge>;
  /** A list of `LiabilityPayment` objects. */
  nodes: Array<LiabilityPayment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LiabilityPayment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LiabilityPayment` edge in the connection. */
export type LiabilityPaymentsEdge = {
  __typename?: 'LiabilityPaymentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LiabilityPayment` at the end of the edge. */
  node: LiabilityPayment;
};

/** Methods to use when ordering `LiabilityPayment`. */
export enum LiabilityPaymentsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FinancialPeriodIdAsc = 'FINANCIAL_PERIOD_ID_ASC',
  FinancialPeriodIdDesc = 'FINANCIAL_PERIOD_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PaymentIdAsc = 'PAYMENT_ID_ASC',
  PaymentIdDesc = 'PAYMENT_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PledgeIdAsc = 'PLEDGE_ID_ASC',
  PledgeIdDesc = 'PLEDGE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReportedDonationIdAsc = 'REPORTED_DONATION_ID_ASC',
  ReportedDonationIdDesc = 'REPORTED_DONATION_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type LiabilityTotalCompletionT = {
  __typename?: 'LiabilityTotalCompletionT';
  completionAmount?: Maybe<Scalars['BigFloat']>;
  completionAmountNormalized?: Maybe<Scalars['BigFloat']>;
  completionPercentage?: Maybe<Scalars['BigFloat']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencySortOrder?: Maybe<Scalars['BigInt']>;
  liabilityAmount?: Maybe<Scalars['BigFloat']>;
  liabilityAmountNormalized?: Maybe<Scalars['BigFloat']>;
  pledgeId?: Maybe<Scalars['BigInt']>;
};

/** A connection to a list of `LiabilityTotalCompletionT` values. */
export type LiabilityTotalCompletionTsConnection = {
  __typename?: 'LiabilityTotalCompletionTsConnection';
  /** A list of edges which contains the `LiabilityTotalCompletionT` and cursor to aid in pagination. */
  edges: Array<LiabilityTotalCompletionTsEdge>;
  /** A list of `LiabilityTotalCompletionT` objects. */
  nodes: Array<LiabilityTotalCompletionT>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LiabilityTotalCompletionT` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LiabilityTotalCompletionT` edge in the connection. */
export type LiabilityTotalCompletionTsEdge = {
  __typename?: 'LiabilityTotalCompletionTsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LiabilityTotalCompletionT` at the end of the edge. */
  node: LiabilityTotalCompletionT;
};

export type LotteryEntryComplete = {
  __typename?: 'LotteryEntryComplete';
  amountNormalized?: Maybe<Scalars['BigFloat']>;
  anonymous?: Maybe<Scalars['Boolean']>;
  benefactor?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  displayName?: Maybe<Scalars['String']>;
  lotteryId?: Maybe<Scalars['BigInt']>;
  paymentReference?: Maybe<Scalars['String']>;
  tickets?: Maybe<Scalars['JSON']>;
};

/**
 * A condition to be used against `LotteryEntryComplete` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LotteryEntryCompleteCondition = {
  /** Checks for equality with the object’s `amountNormalized` field. */
  amountNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `anonymous` field. */
  anonymous?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `benefactor` field. */
  benefactor?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `lotteryId` field. */
  lotteryId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paymentReference` field. */
  paymentReference?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tickets` field. */
  tickets?: InputMaybe<Scalars['JSON']>;
};

/** A connection to a list of `LotteryEntryComplete` values. */
export type LotteryEntryCompletesConnection = {
  __typename?: 'LotteryEntryCompletesConnection';
  /** A list of edges which contains the `LotteryEntryComplete` and cursor to aid in pagination. */
  edges: Array<LotteryEntryCompletesEdge>;
  /** A list of `LotteryEntryComplete` objects. */
  nodes: Array<LotteryEntryComplete>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LotteryEntryComplete` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LotteryEntryComplete` edge in the connection. */
export type LotteryEntryCompletesEdge = {
  __typename?: 'LotteryEntryCompletesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LotteryEntryComplete` at the end of the edge. */
  node: LotteryEntryComplete;
};

/** Methods to use when ordering `LotteryEntryComplete`. */
export enum LotteryEntryCompletesOrderBy {
  AmountNormalizedAsc = 'AMOUNT_NORMALIZED_ASC',
  AmountNormalizedDesc = 'AMOUNT_NORMALIZED_DESC',
  AnonymousAsc = 'ANONYMOUS_ASC',
  AnonymousDesc = 'ANONYMOUS_DESC',
  BenefactorAsc = 'BENEFACTOR_ASC',
  BenefactorDesc = 'BENEFACTOR_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  LotteryIdAsc = 'LOTTERY_ID_ASC',
  LotteryIdDesc = 'LOTTERY_ID_DESC',
  Natural = 'NATURAL',
  PaymentReferenceAsc = 'PAYMENT_REFERENCE_ASC',
  PaymentReferenceDesc = 'PAYMENT_REFERENCE_DESC',
  TicketsAsc = 'TICKETS_ASC',
  TicketsDesc = 'TICKETS_DESC'
}

export type LotteryEntryPublic = {
  __typename?: 'LotteryEntryPublic';
  amount?: Maybe<Scalars['BigFloat']>;
  amountNormalized?: Maybe<Scalars['BigFloat']>;
  amountNormalizedNetFee?: Maybe<Scalars['BigFloat']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  currencyCode?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  feeNormalized?: Maybe<Scalars['BigFloat']>;
  giftAid?: Maybe<Scalars['BigFloat']>;
  lotteryId?: Maybe<Scalars['BigInt']>;
  paymentReference?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LotteryEntryPublic` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LotteryEntryPublicCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `amountNormalized` field. */
  amountNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `amountNormalizedNetFee` field. */
  amountNormalizedNetFee?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `feeNormalized` field. */
  feeNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `giftAid` field. */
  giftAid?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `lotteryId` field. */
  lotteryId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paymentReference` field. */
  paymentReference?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `total` field. */
  total?: InputMaybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LotteryEntryPublic` values. */
export type LotteryEntryPublicsConnection = {
  __typename?: 'LotteryEntryPublicsConnection';
  /** A list of edges which contains the `LotteryEntryPublic` and cursor to aid in pagination. */
  edges: Array<LotteryEntryPublicsEdge>;
  /** A list of `LotteryEntryPublic` objects. */
  nodes: Array<LotteryEntryPublic>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LotteryEntryPublic` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LotteryEntryPublic` edge in the connection. */
export type LotteryEntryPublicsEdge = {
  __typename?: 'LotteryEntryPublicsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LotteryEntryPublic` at the end of the edge. */
  node: LotteryEntryPublic;
};

/** Methods to use when ordering `LotteryEntryPublic`. */
export enum LotteryEntryPublicsOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  AmountNormalizedAsc = 'AMOUNT_NORMALIZED_ASC',
  AmountNormalizedDesc = 'AMOUNT_NORMALIZED_DESC',
  AmountNormalizedNetFeeAsc = 'AMOUNT_NORMALIZED_NET_FEE_ASC',
  AmountNormalizedNetFeeDesc = 'AMOUNT_NORMALIZED_NET_FEE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  DisplayNameAsc = 'DISPLAY_NAME_ASC',
  DisplayNameDesc = 'DISPLAY_NAME_DESC',
  FeeNormalizedAsc = 'FEE_NORMALIZED_ASC',
  FeeNormalizedDesc = 'FEE_NORMALIZED_DESC',
  GiftAidAsc = 'GIFT_AID_ASC',
  GiftAidDesc = 'GIFT_AID_DESC',
  LotteryIdAsc = 'LOTTERY_ID_ASC',
  LotteryIdDesc = 'LOTTERY_ID_DESC',
  Natural = 'NATURAL',
  PaymentReferenceAsc = 'PAYMENT_REFERENCE_ASC',
  PaymentReferenceDesc = 'PAYMENT_REFERENCE_DESC',
  TotalAsc = 'TOTAL_ASC',
  TotalDesc = 'TOTAL_DESC'
}

/** A connection to a list of `LotterySummary` values. */
export type LotterySummariesConnection = {
  __typename?: 'LotterySummariesConnection';
  /** A list of edges which contains the `LotterySummary` and cursor to aid in pagination. */
  edges: Array<LotterySummariesEdge>;
  /** A list of `LotterySummary` objects. */
  nodes: Array<LotterySummary>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LotterySummary` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LotterySummary` edge in the connection. */
export type LotterySummariesEdge = {
  __typename?: 'LotterySummariesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LotterySummary` at the end of the edge. */
  node: LotterySummary;
};

/** Methods to use when ordering `LotterySummary`. */
export enum LotterySummariesOrderBy {
  BenefactorLiabilityAsc = 'BENEFACTOR_LIABILITY_ASC',
  BenefactorLiabilityDesc = 'BENEFACTOR_LIABILITY_DESC',
  BenefactorStartingTicketNumberAsc = 'BENEFACTOR_STARTING_TICKET_NUMBER_ASC',
  BenefactorStartingTicketNumberDesc = 'BENEFACTOR_STARTING_TICKET_NUMBER_DESC',
  BenefactorWinsLastBlockAsc = 'BENEFACTOR_WINS_LAST_BLOCK_ASC',
  BenefactorWinsLastBlockDesc = 'BENEFACTOR_WINS_LAST_BLOCK_DESC',
  BlockSizeAsc = 'BLOCK_SIZE_ASC',
  BlockSizeDesc = 'BLOCK_SIZE_DESC',
  CloseTimestampAsc = 'CLOSE_TIMESTAMP_ASC',
  CloseTimestampDesc = 'CLOSE_TIMESTAMP_DESC',
  DrawEpochAsc = 'DRAW_EPOCH_ASC',
  DrawEpochDesc = 'DRAW_EPOCH_DESC',
  DrawTimestampAsc = 'DRAW_TIMESTAMP_ASC',
  DrawTimestampDesc = 'DRAW_TIMESTAMP_DESC',
  EntriesTotalNormalizedAsc = 'ENTRIES_TOTAL_NORMALIZED_ASC',
  EntriesTotalNormalizedDesc = 'ENTRIES_TOTAL_NORMALIZED_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LockTimestampAsc = 'LOCK_TIMESTAMP_ASC',
  LockTimestampDesc = 'LOCK_TIMESTAMP_DESC',
  MaxBlockIdAsc = 'MAX_BLOCK_ID_ASC',
  MaxBlockIdDesc = 'MAX_BLOCK_ID_DESC',
  Natural = 'NATURAL',
  NumEntriesAsc = 'NUM_ENTRIES_ASC',
  NumEntriesDesc = 'NUM_ENTRIES_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TicketsAssignedAsc = 'TICKETS_ASSIGNED_ASC',
  TicketsAssignedDesc = 'TICKETS_ASSIGNED_DESC',
  WinningNumberAsc = 'WINNING_NUMBER_ASC',
  WinningNumberDesc = 'WINNING_NUMBER_DESC',
  WinningNumberHexAsc = 'WINNING_NUMBER_HEX_ASC',
  WinningNumberHexDesc = 'WINNING_NUMBER_HEX_DESC',
  WinningTicketsAsc = 'WINNING_TICKETS_ASC',
  WinningTicketsDesc = 'WINNING_TICKETS_DESC'
}

export type LotterySummary = {
  __typename?: 'LotterySummary';
  benefactorLiability?: Maybe<Scalars['BigFloat']>;
  benefactorStartingTicketNumber?: Maybe<Scalars['BigInt']>;
  benefactorWinsLastBlock?: Maybe<Scalars['Boolean']>;
  blockSize?: Maybe<Scalars['BigFloat']>;
  closeTimestamp?: Maybe<Scalars['Datetime']>;
  drawEpoch?: Maybe<Scalars['BigInt']>;
  drawTimestamp?: Maybe<Scalars['Datetime']>;
  entriesTotalNormalized?: Maybe<Scalars['BigFloat']>;
  id?: Maybe<Scalars['BigInt']>;
  lockTimestamp?: Maybe<Scalars['Datetime']>;
  maxBlockId?: Maybe<Scalars['Int']>;
  numEntries?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  ticketsAssigned?: Maybe<Scalars['Boolean']>;
  winningNumber?: Maybe<Scalars['BigInt']>;
  winningNumberHex?: Maybe<Scalars['String']>;
  winningTickets?: Maybe<Scalars['JSON']>;
};

/**
 * A condition to be used against `LotterySummary` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LotterySummaryCondition = {
  /** Checks for equality with the object’s `benefactorLiability` field. */
  benefactorLiability?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `benefactorStartingTicketNumber` field. */
  benefactorStartingTicketNumber?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `benefactorWinsLastBlock` field. */
  benefactorWinsLastBlock?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `blockSize` field. */
  blockSize?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `closeTimestamp` field. */
  closeTimestamp?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `drawEpoch` field. */
  drawEpoch?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `drawTimestamp` field. */
  drawTimestamp?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `entriesTotalNormalized` field. */
  entriesTotalNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `lockTimestamp` field. */
  lockTimestamp?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `maxBlockId` field. */
  maxBlockId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `numEntries` field. */
  numEntries?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `ticketsAssigned` field. */
  ticketsAssigned?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `winningNumber` field. */
  winningNumber?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `winningNumberHex` field. */
  winningNumberHex?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `winningTickets` field. */
  winningTickets?: InputMaybe<Scalars['JSON']>;
};

export type LotteryTicketPublic = {
  __typename?: 'LotteryTicketPublic';
  benefactor?: Maybe<Scalars['Boolean']>;
  blockId?: Maybe<Scalars['Int']>;
  lotteryId?: Maybe<Scalars['BigInt']>;
  paymentReference?: Maybe<Scalars['String']>;
  winRange?: Maybe<BigIntRange>;
};

/**
 * A condition to be used against `LotteryTicketPublic` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type LotteryTicketPublicCondition = {
  /** Checks for equality with the object’s `benefactor` field. */
  benefactor?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `blockId` field. */
  blockId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lotteryId` field. */
  lotteryId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paymentReference` field. */
  paymentReference?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `winRange` field. */
  winRange?: InputMaybe<BigIntRangeInput>;
};

/** A connection to a list of `LotteryTicketPublic` values. */
export type LotteryTicketPublicsConnection = {
  __typename?: 'LotteryTicketPublicsConnection';
  /** A list of edges which contains the `LotteryTicketPublic` and cursor to aid in pagination. */
  edges: Array<LotteryTicketPublicsEdge>;
  /** A list of `LotteryTicketPublic` objects. */
  nodes: Array<LotteryTicketPublic>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LotteryTicketPublic` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LotteryTicketPublic` edge in the connection. */
export type LotteryTicketPublicsEdge = {
  __typename?: 'LotteryTicketPublicsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LotteryTicketPublic` at the end of the edge. */
  node: LotteryTicketPublic;
};

/** Methods to use when ordering `LotteryTicketPublic`. */
export enum LotteryTicketPublicsOrderBy {
  BenefactorAsc = 'BENEFACTOR_ASC',
  BenefactorDesc = 'BENEFACTOR_DESC',
  BlockIdAsc = 'BLOCK_ID_ASC',
  BlockIdDesc = 'BLOCK_ID_DESC',
  LotteryIdAsc = 'LOTTERY_ID_ASC',
  LotteryIdDesc = 'LOTTERY_ID_DESC',
  Natural = 'NATURAL',
  PaymentReferenceAsc = 'PAYMENT_REFERENCE_ASC',
  PaymentReferenceDesc = 'PAYMENT_REFERENCE_DESC',
  WinRangeAsc = 'WIN_RANGE_ASC',
  WinRangeDesc = 'WIN_RANGE_DESC'
}

export type LotteryWinner = {
  __typename?: 'LotteryWinner';
  benefactor?: Maybe<Scalars['Boolean']>;
  blockId?: Maybe<Scalars['Int']>;
  lotteryId?: Maybe<Scalars['BigInt']>;
  paymentReference?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `LotteryWinner` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LotteryWinnerCondition = {
  /** Checks for equality with the object’s `benefactor` field. */
  benefactor?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `blockId` field. */
  blockId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lotteryId` field. */
  lotteryId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `paymentReference` field. */
  paymentReference?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `LotteryWinner` values. */
export type LotteryWinnersConnection = {
  __typename?: 'LotteryWinnersConnection';
  /** A list of edges which contains the `LotteryWinner` and cursor to aid in pagination. */
  edges: Array<LotteryWinnersEdge>;
  /** A list of `LotteryWinner` objects. */
  nodes: Array<LotteryWinner>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LotteryWinner` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LotteryWinner` edge in the connection. */
export type LotteryWinnersEdge = {
  __typename?: 'LotteryWinnersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LotteryWinner` at the end of the edge. */
  node: LotteryWinner;
};

/** Methods to use when ordering `LotteryWinner`. */
export enum LotteryWinnersOrderBy {
  BenefactorAsc = 'BENEFACTOR_ASC',
  BenefactorDesc = 'BENEFACTOR_DESC',
  BlockIdAsc = 'BLOCK_ID_ASC',
  BlockIdDesc = 'BLOCK_ID_DESC',
  LotteryIdAsc = 'LOTTERY_ID_ASC',
  LotteryIdDesc = 'LOTTERY_ID_DESC',
  Natural = 'NATURAL',
  PaymentReferenceAsc = 'PAYMENT_REFERENCE_ASC',
  PaymentReferenceDesc = 'PAYMENT_REFERENCE_DESC'
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  addReminder?: Maybe<AddReminderPayload>;
  cancelRecurringPayment?: Maybe<CancelRecurringPaymentPayload>;
  cancelRecurringReportedDonation?: Maybe<CancelRecurringReportedDonationPayload>;
  createAddressByPersonOrTemporaryPerson?: Maybe<CreateAddressByPersonOrTemporaryPersonPayload>;
  createExternalOrganization?: Maybe<CreateExternalOrganizationPayload>;
  createFinancialPeriod?: Maybe<CreateFinancialPeriodPayload>;
  createGiftAidClaimFromPayment?: Maybe<CreateGiftAidClaimFromPaymentPayload>;
  createGocardlessRedirectFlowByPersonOrTemporaryPerson?: Maybe<CreateGocardlessRedirectFlowByPersonOrTemporaryPersonPayload>;
  createPaymentByPersonId?: Maybe<CreatePaymentByPersonIdPayload>;
  createPaymentByTemporaryPerson?: Maybe<CreatePaymentByTemporaryPersonPayload>;
  createPollOptionVote?: Maybe<CreatePollOptionVotePayload>;
  createPollOptionVoteByPersonOrTemporaryPerson?: Maybe<CreatePollOptionVoteByPersonOrTemporaryPersonPayload>;
  createRecurringPayment?: Maybe<CreateRecurringPaymentPayload>;
  createRecurringPaymentByTemporaryPerson?: Maybe<CreateRecurringPaymentByTemporaryPersonPayload>;
  createRecurringReportedDonation?: Maybe<CreateRecurringReportedDonationPayload>;
  createReportedDonationByPersonId?: Maybe<CreateReportedDonationByPersonIdPayload>;
  createTemporaryPersonByEmail?: Maybe<CreateTemporaryPersonByEmailPayload>;
  deleteFinancialPeriod?: Maybe<DeleteFinancialPeriodPayload>;
  deleteGiftAidClaimFromPayment?: Maybe<DeleteGiftAidClaimFromPaymentPayload>;
  deleteReportedDonation?: Maybe<DeleteReportedDonationPayload>;
  signPledge?: Maybe<SignPledgePayload>;
  signPledgeByTemporaryPerson?: Maybe<SignPledgeByTemporaryPersonPayload>;
  syncAuth0User?: Maybe<SyncAuth0UserPayload>;
  updateContactPreferenceByPersonId?: Maybe<UpdateContactPreferenceByPersonIdPayload>;
  updateFinancialPeriod?: Maybe<UpdateFinancialPeriodPayload>;
  updatePersonByPersonId?: Maybe<UpdatePersonByPersonIdPayload>;
  updatePersonEmailAuth0?: Maybe<UpdatePersonEmailAuth0Payload>;
  updatePledgeByPersonOrTemporaryPerson?: Maybe<UpdatePledgeByPersonOrTemporaryPersonPayload>;
  updateRecurringPaymentAmount?: Maybe<UpdateRecurringPaymentAmountPayload>;
  updateReportedDonation?: Maybe<UpdateReportedDonationPayload>;
  updateReportedPayment?: Maybe<UpdateReportedPaymentPayload>;
  verifyEmail?: Maybe<VerifyEmailPayload>;
  voidPayment?: Maybe<VoidPaymentPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddReminderArgs = {
  input: AddReminderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCancelRecurringPaymentArgs = {
  input: CancelRecurringPaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCancelRecurringReportedDonationArgs = {
  input: CancelRecurringReportedDonationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAddressByPersonOrTemporaryPersonArgs = {
  input: CreateAddressByPersonOrTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExternalOrganizationArgs = {
  input: CreateExternalOrganizationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFinancialPeriodArgs = {
  input: CreateFinancialPeriodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGiftAidClaimFromPaymentArgs = {
  input: CreateGiftAidClaimFromPaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGocardlessRedirectFlowByPersonOrTemporaryPersonArgs = {
  input: CreateGocardlessRedirectFlowByPersonOrTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePaymentByPersonIdArgs = {
  input: CreatePaymentByPersonIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePaymentByTemporaryPersonArgs = {
  input: CreatePaymentByTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePollOptionVoteArgs = {
  input: CreatePollOptionVoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePollOptionVoteByPersonOrTemporaryPersonArgs = {
  input: CreatePollOptionVoteByPersonOrTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRecurringPaymentArgs = {
  input: CreateRecurringPaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRecurringPaymentByTemporaryPersonArgs = {
  input: CreateRecurringPaymentByTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRecurringReportedDonationArgs = {
  input: CreateRecurringReportedDonationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReportedDonationByPersonIdArgs = {
  input: CreateReportedDonationByPersonIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTemporaryPersonByEmailArgs = {
  input: CreateTemporaryPersonByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFinancialPeriodArgs = {
  input: DeleteFinancialPeriodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGiftAidClaimFromPaymentArgs = {
  input: DeleteGiftAidClaimFromPaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteReportedDonationArgs = {
  input: DeleteReportedDonationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSignPledgeArgs = {
  input: SignPledgeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSignPledgeByTemporaryPersonArgs = {
  input: SignPledgeByTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSyncAuth0UserArgs = {
  input: SyncAuth0UserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateContactPreferenceByPersonIdArgs = {
  input: UpdateContactPreferenceByPersonIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFinancialPeriodArgs = {
  input: UpdateFinancialPeriodInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonByPersonIdArgs = {
  input: UpdatePersonByPersonIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePersonEmailAuth0Args = {
  input: UpdatePersonEmailAuth0Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePledgeByPersonOrTemporaryPersonArgs = {
  input: UpdatePledgeByPersonOrTemporaryPersonInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRecurringPaymentAmountArgs = {
  input: UpdateRecurringPaymentAmountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReportedDonationArgs = {
  input: UpdateReportedDonationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReportedPaymentArgs = {
  input: UpdateReportedPaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationVoidPaymentArgs = {
  input: VoidPaymentInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** A connection to a list of `NormalizedCurrency` values. */
export type NormalizedCurrenciesConnection = {
  __typename?: 'NormalizedCurrenciesConnection';
  /** A list of edges which contains the `NormalizedCurrency` and cursor to aid in pagination. */
  edges: Array<NormalizedCurrenciesEdge>;
  /** A list of `NormalizedCurrency` objects. */
  nodes: Array<NormalizedCurrency>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NormalizedCurrency` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `NormalizedCurrency` edge in the connection. */
export type NormalizedCurrenciesEdge = {
  __typename?: 'NormalizedCurrenciesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `NormalizedCurrency` at the end of the edge. */
  node: NormalizedCurrency;
};

/** Methods to use when ordering `NormalizedCurrency`. */
export enum NormalizedCurrenciesOrderBy {
  CodeAsc = 'CODE_ASC',
  CodeDesc = 'CODE_DESC',
  CurrencyTypeAsc = 'CURRENCY_TYPE_ASC',
  CurrencyTypeDesc = 'CURRENCY_TYPE_DESC',
  DecimalsAsc = 'DECIMALS_ASC',
  DecimalsDesc = 'DECIMALS_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  SymbolAsc = 'SYMBOL_ASC',
  SymbolDesc = 'SYMBOL_DESC'
}

export type NormalizedCurrency = {
  __typename?: 'NormalizedCurrency';
  code?: Maybe<Scalars['String']>;
  currencyType?: Maybe<CurrencyType>;
  decimals?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `NormalizedCurrency` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type NormalizedCurrencyCondition = {
  /** Checks for equality with the object’s `code` field. */
  code?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `currencyType` field. */
  currencyType?: InputMaybe<CurrencyType>;
  /** Checks for equality with the object’s `decimals` field. */
  decimals?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `symbol` field. */
  symbol?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `OrganizationCategory` values. */
export type OrganizationCategoriesConnection = {
  __typename?: 'OrganizationCategoriesConnection';
  /** A list of edges which contains the `OrganizationCategory` and cursor to aid in pagination. */
  edges: Array<OrganizationCategoriesEdge>;
  /** A list of `OrganizationCategory` objects. */
  nodes: Array<OrganizationCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrganizationCategory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `OrganizationCategory` edge in the connection. */
export type OrganizationCategoriesEdge = {
  __typename?: 'OrganizationCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `OrganizationCategory` at the end of the edge. */
  node: OrganizationCategory;
};

/** Methods to use when ordering `OrganizationCategory`. */
export enum OrganizationCategoriesOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC'
}

export type OrganizationCategory = Node & {
  __typename?: 'OrganizationCategory';
  description: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  slug: Scalars['String'];
  sortOrder: Scalars['Int'];
};

/**
 * A condition to be used against `OrganizationCategory` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type OrganizationCategoryCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: InputMaybe<Scalars['Int']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type Payment = Node & {
  __typename?: 'Payment';
  accountingSystem: AccountingSystem;
  /** Reads a single `Address` that is related to this `Payment`. */
  addressByBillingAddressId?: Maybe<Address>;
  allocation: Scalars['JSON'];
  /** The amount that was charged by the gateway after currency conversion. denominated in `currency_code` */
  amount?: Maybe<Scalars['BigFloat']>;
  amountNormalized?: Maybe<Scalars['BigFloat']>;
  billingAddressId?: Maybe<Scalars['BigInt']>;
  /** The amount that the user requested to be charged in their preferred currency, denominated in `charge_currency_code` */
  chargeAmount: Scalars['BigFloat'];
  chargeCurrencyCode: Scalars['String'];
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByPaymentId: CompletionPaymentsConnection;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByPaymentIdList: Array<CompletionPayment>;
  createdAt: Scalars['Datetime'];
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByChargeCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode?: Maybe<Scalars['String']>;
  donationDate?: Maybe<Scalars['Date']>;
  fee?: Maybe<Scalars['BigFloat']>;
  feeNormalized?: Maybe<Scalars['BigFloat']>;
  gateway: PaymentGateway;
  gatewayData?: Maybe<Scalars['JSON']>;
  gatewayTransactionId?: Maybe<Scalars['String']>;
  /** Reads a single `GiftAidClaim` that is related to this `Payment`. */
  giftAidClaimByGiftAidPaymentId?: Maybe<GiftAidClaim>;
  /** Reads and enables pagination through a set of `GiftAidClaim`. */
  giftAidClaimsByPaymentIdAndPersonId: GiftAidClaimsConnection;
  /** Reads and enables pagination through a set of `GiftAidClaim`. */
  giftAidClaimsByPaymentIdAndPersonIdList: Array<GiftAidClaim>;
  id: Scalars['BigInt'];
  lastReceiptSentAt?: Maybe<Scalars['Datetime']>;
  /** Reads and enables pagination through a set of `LiabilityPayment`. */
  liabilityPaymentsByPaymentId: LiabilityPaymentsConnection;
  /** Reads and enables pagination through a set of `LiabilityPayment`. */
  liabilityPaymentsByPaymentIdList: Array<LiabilityPayment>;
  metadata?: Maybe<Scalars['JSON']>;
  net?: Maybe<Scalars['BigFloat']>;
  netNormalized?: Maybe<Scalars['BigFloat']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PaymentAccessToken`. */
  paymentAccessTokensByPaymentIdAndPersonId: PaymentAccessTokensConnection;
  /** Reads and enables pagination through a set of `PaymentAccessToken`. */
  paymentAccessTokensByPaymentIdAndPersonIdList: Array<PaymentAccessToken>;
  paymentMethod?: Maybe<PaymentMethod>;
  /** Reads a single `PaymentRecipient` that is related to this `Payment`. */
  paymentRecipientByRecipient?: Maybe<PaymentRecipient>;
  paymentType?: Maybe<PaymentType>;
  /** Reads a single `Person` that is related to this `Payment`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  receivedAt?: Maybe<Scalars['Datetime']>;
  recipient: Scalars['String'];
  recurrence: PaymentRecurrence;
  reference: Scalars['String'];
  status: PaymentStatus;
  statusData?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['Datetime'];
};


export type PaymentCompletionPaymentsByPaymentIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type PaymentCompletionPaymentsByPaymentIdListArgs = {
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type PaymentGiftAidClaimsByPaymentIdAndPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GiftAidClaimCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GiftAidClaimsOrderBy>>;
};


export type PaymentGiftAidClaimsByPaymentIdAndPersonIdListArgs = {
  condition?: InputMaybe<GiftAidClaimCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GiftAidClaimsOrderBy>>;
};


export type PaymentLiabilityPaymentsByPaymentIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilityPaymentsOrderBy>>;
};


export type PaymentLiabilityPaymentsByPaymentIdListArgs = {
  condition?: InputMaybe<LiabilityPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilityPaymentsOrderBy>>;
};


export type PaymentPaymentAccessTokensByPaymentIdAndPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentAccessTokenCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentAccessTokensOrderBy>>;
};


export type PaymentPaymentAccessTokensByPaymentIdAndPersonIdListArgs = {
  condition?: InputMaybe<PaymentAccessTokenCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentAccessTokensOrderBy>>;
};

export type PaymentAccessToken = Node & {
  __typename?: 'PaymentAccessToken';
  createdAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Payment` that is related to this `PaymentAccessToken`. */
  paymentByPaymentIdAndPersonId?: Maybe<Payment>;
  paymentId: Scalars['BigInt'];
  personId: Scalars['BigInt'];
  token: Scalars['UUID'];
  tokenExpiresAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `PaymentAccessToken` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PaymentAccessTokenCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `paymentId` field. */
  paymentId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `token` field. */
  token?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `tokenExpiresAt` field. */
  tokenExpiresAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `PaymentAccessToken` values. */
export type PaymentAccessTokensConnection = {
  __typename?: 'PaymentAccessTokensConnection';
  /** A list of edges which contains the `PaymentAccessToken` and cursor to aid in pagination. */
  edges: Array<PaymentAccessTokensEdge>;
  /** A list of `PaymentAccessToken` objects. */
  nodes: Array<PaymentAccessToken>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PaymentAccessToken` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PaymentAccessToken` edge in the connection. */
export type PaymentAccessTokensEdge = {
  __typename?: 'PaymentAccessTokensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PaymentAccessToken` at the end of the edge. */
  node: PaymentAccessToken;
};

/** Methods to use when ordering `PaymentAccessToken`. */
export enum PaymentAccessTokensOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  Natural = 'NATURAL',
  PaymentIdAsc = 'PAYMENT_ID_ASC',
  PaymentIdDesc = 'PAYMENT_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TokenAsc = 'TOKEN_ASC',
  TokenDesc = 'TOKEN_DESC',
  TokenExpiresAtAsc = 'TOKEN_EXPIRES_AT_ASC',
  TokenExpiresAtDesc = 'TOKEN_EXPIRES_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A condition to be used against `Payment` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PaymentCondition = {
  /** Checks for equality with the object’s `accountingSystem` field. */
  accountingSystem?: InputMaybe<AccountingSystem>;
  /** Checks for equality with the object’s `allocation` field. */
  allocation?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `amountNormalized` field. */
  amountNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `billingAddressId` field. */
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `chargeAmount` field. */
  chargeAmount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `chargeCurrencyCode` field. */
  chargeCurrencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `donationDate` field. */
  donationDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `fee` field. */
  fee?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `feeNormalized` field. */
  feeNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `gateway` field. */
  gateway?: InputMaybe<PaymentGateway>;
  /** Checks for equality with the object’s `gatewayData` field. */
  gatewayData?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `gatewayTransactionId` field. */
  gatewayTransactionId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `lastReceiptSentAt` field. */
  lastReceiptSentAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `net` field. */
  net?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `netNormalized` field. */
  netNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `paymentMethod` field. */
  paymentMethod?: InputMaybe<PaymentMethod>;
  /** Checks for equality with the object’s `paymentType` field. */
  paymentType?: InputMaybe<PaymentType>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `receivedAt` field. */
  receivedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `recipient` field. */
  recipient?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `recurrence` field. */
  recurrence?: InputMaybe<PaymentRecurrence>;
  /** Checks for equality with the object’s `reference` field. */
  reference?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<PaymentStatus>;
  /** Checks for equality with the object’s `statusData` field. */
  statusData?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export enum PaymentGateway {
  Chariot = 'CHARIOT',
  Crypto = 'CRYPTO',
  GiftAid = 'GIFT_AID',
  Gocardless = 'GOCARDLESS',
  Offline = 'OFFLINE',
  Paypal = 'PAYPAL',
  PayrollGiving = 'PAYROLL_GIVING',
  Stripe = 'STRIPE'
}

export enum PaymentMethod {
  Acss = 'ACSS',
  BacsDirectDebit = 'BACS_DIRECT_DEBIT',
  BankTransfer = 'BANK_TRANSFER',
  Cheque = 'CHEQUE',
  CreditCard = 'CREDIT_CARD',
  Crypto = 'CRYPTO',
  DonorAdvisedFund = 'DONOR_ADVISED_FUND',
  PayrollGiving = 'PAYROLL_GIVING',
  Stock = 'STOCK'
}

export type PaymentRecipient = Node & {
  __typename?: 'PaymentRecipient';
  accountingSystem: AccountingSystem;
  /** Reads a single `Country` that is related to this `PaymentRecipient`. */
  countryByCountryCode?: Maybe<Country>;
  countryCode: Scalars['String'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `Currency` that is related to this `PaymentRecipient`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode: Scalars['String'];
  /** Default timezone on tax receipts to this recipient */
  defaultTimezone: Scalars['String'];
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByRecipient: PaymentsConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByRecipientList: Array<Payment>;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByRecipient: RecurringPaymentsConnection;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByRecipientList: Array<RecurringPayment>;
  slug: Scalars['String'];
  updatedAt: Scalars['Datetime'];
};


export type PaymentRecipientPaymentsByRecipientArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};


export type PaymentRecipientPaymentsByRecipientListArgs = {
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};


export type PaymentRecipientRecurringPaymentsByRecipientArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecurringPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};


export type PaymentRecipientRecurringPaymentsByRecipientListArgs = {
  condition?: InputMaybe<RecurringPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};

/**
 * A condition to be used against `PaymentRecipient` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PaymentRecipientCondition = {
  /** Checks for equality with the object’s `accountingSystem` field. */
  accountingSystem?: InputMaybe<AccountingSystem>;
  /** Checks for equality with the object’s `countryCode` field. */
  countryCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `defaultTimezone` field. */
  defaultTimezone?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `PaymentRecipient` values. */
export type PaymentRecipientsConnection = {
  __typename?: 'PaymentRecipientsConnection';
  /** A list of edges which contains the `PaymentRecipient` and cursor to aid in pagination. */
  edges: Array<PaymentRecipientsEdge>;
  /** A list of `PaymentRecipient` objects. */
  nodes: Array<PaymentRecipient>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PaymentRecipient` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PaymentRecipient` edge in the connection. */
export type PaymentRecipientsEdge = {
  __typename?: 'PaymentRecipientsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PaymentRecipient` at the end of the edge. */
  node: PaymentRecipient;
};

/** Methods to use when ordering `PaymentRecipient`. */
export enum PaymentRecipientsOrderBy {
  AccountingSystemAsc = 'ACCOUNTING_SYSTEM_ASC',
  AccountingSystemDesc = 'ACCOUNTING_SYSTEM_DESC',
  CountryCodeAsc = 'COUNTRY_CODE_ASC',
  CountryCodeDesc = 'COUNTRY_CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  DefaultTimezoneAsc = 'DEFAULT_TIMEZONE_ASC',
  DefaultTimezoneDesc = 'DEFAULT_TIMEZONE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum PaymentRecurrence {
  OneOff = 'ONE_OFF',
  Recurring = 'RECURRING'
}

export type PaymentStatsAggregate = {
  __typename?: 'PaymentStatsAggregate';
  numDonations?: Maybe<Scalars['BigInt']>;
  numDonationsLastYear?: Maybe<Scalars['BigInt']>;
  numDonors?: Maybe<Scalars['BigInt']>;
  numDonorsLastYear?: Maybe<Scalars['BigInt']>;
  totalDonations?: Maybe<Scalars['BigFloat']>;
  totalDonationsLastYear?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `PaymentStatsAggregate` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type PaymentStatsAggregateCondition = {
  /** Checks for equality with the object’s `numDonations` field. */
  numDonations?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `numDonationsLastYear` field. */
  numDonationsLastYear?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `numDonors` field. */
  numDonors?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `numDonorsLastYear` field. */
  numDonorsLastYear?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `totalDonations` field. */
  totalDonations?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalDonationsLastYear` field. */
  totalDonationsLastYear?: InputMaybe<Scalars['BigFloat']>;
};

/** A connection to a list of `PaymentStatsAggregate` values. */
export type PaymentStatsAggregatesConnection = {
  __typename?: 'PaymentStatsAggregatesConnection';
  /** A list of edges which contains the `PaymentStatsAggregate` and cursor to aid in pagination. */
  edges: Array<PaymentStatsAggregatesEdge>;
  /** A list of `PaymentStatsAggregate` objects. */
  nodes: Array<PaymentStatsAggregate>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PaymentStatsAggregate` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PaymentStatsAggregate` edge in the connection. */
export type PaymentStatsAggregatesEdge = {
  __typename?: 'PaymentStatsAggregatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PaymentStatsAggregate` at the end of the edge. */
  node: PaymentStatsAggregate;
};

/** Methods to use when ordering `PaymentStatsAggregate`. */
export enum PaymentStatsAggregatesOrderBy {
  Natural = 'NATURAL',
  NumDonationsAsc = 'NUM_DONATIONS_ASC',
  NumDonationsDesc = 'NUM_DONATIONS_DESC',
  NumDonationsLastYearAsc = 'NUM_DONATIONS_LAST_YEAR_ASC',
  NumDonationsLastYearDesc = 'NUM_DONATIONS_LAST_YEAR_DESC',
  NumDonorsAsc = 'NUM_DONORS_ASC',
  NumDonorsDesc = 'NUM_DONORS_DESC',
  NumDonorsLastYearAsc = 'NUM_DONORS_LAST_YEAR_ASC',
  NumDonorsLastYearDesc = 'NUM_DONORS_LAST_YEAR_DESC',
  TotalDonationsAsc = 'TOTAL_DONATIONS_ASC',
  TotalDonationsDesc = 'TOTAL_DONATIONS_DESC',
  TotalDonationsLastYearAsc = 'TOTAL_DONATIONS_LAST_YEAR_ASC',
  TotalDonationsLastYearDesc = 'TOTAL_DONATIONS_LAST_YEAR_DESC'
}

export enum PaymentStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Succeeded = 'SUCCEEDED',
  Unpaid = 'UNPAID',
  Voided = 'VOIDED'
}

export enum PaymentType {
  Donation = 'DONATION',
  EmployerMatch = 'EMPLOYER_MATCH',
  Grant = 'GRANT'
}

/** A connection to a list of `Payment` values. */
export type PaymentsConnection = {
  __typename?: 'PaymentsConnection';
  /** A list of edges which contains the `Payment` and cursor to aid in pagination. */
  edges: Array<PaymentsEdge>;
  /** A list of `Payment` objects. */
  nodes: Array<Payment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Payment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Payment` edge in the connection. */
export type PaymentsEdge = {
  __typename?: 'PaymentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Payment` at the end of the edge. */
  node: Payment;
};

/** Methods to use when ordering `Payment`. */
export enum PaymentsOrderBy {
  AccountingSystemAsc = 'ACCOUNTING_SYSTEM_ASC',
  AccountingSystemDesc = 'ACCOUNTING_SYSTEM_DESC',
  AllocationAsc = 'ALLOCATION_ASC',
  AllocationDesc = 'ALLOCATION_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  AmountNormalizedAsc = 'AMOUNT_NORMALIZED_ASC',
  AmountNormalizedDesc = 'AMOUNT_NORMALIZED_DESC',
  BillingAddressIdAsc = 'BILLING_ADDRESS_ID_ASC',
  BillingAddressIdDesc = 'BILLING_ADDRESS_ID_DESC',
  ChargeAmountAsc = 'CHARGE_AMOUNT_ASC',
  ChargeAmountDesc = 'CHARGE_AMOUNT_DESC',
  ChargeCurrencyCodeAsc = 'CHARGE_CURRENCY_CODE_ASC',
  ChargeCurrencyCodeDesc = 'CHARGE_CURRENCY_CODE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  DonationDateAsc = 'DONATION_DATE_ASC',
  DonationDateDesc = 'DONATION_DATE_DESC',
  FeeAsc = 'FEE_ASC',
  FeeDesc = 'FEE_DESC',
  FeeNormalizedAsc = 'FEE_NORMALIZED_ASC',
  FeeNormalizedDesc = 'FEE_NORMALIZED_DESC',
  GatewayAsc = 'GATEWAY_ASC',
  GatewayDataAsc = 'GATEWAY_DATA_ASC',
  GatewayDataDesc = 'GATEWAY_DATA_DESC',
  GatewayDesc = 'GATEWAY_DESC',
  GatewayTransactionIdAsc = 'GATEWAY_TRANSACTION_ID_ASC',
  GatewayTransactionIdDesc = 'GATEWAY_TRANSACTION_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastReceiptSentAtAsc = 'LAST_RECEIPT_SENT_AT_ASC',
  LastReceiptSentAtDesc = 'LAST_RECEIPT_SENT_AT_DESC',
  MetadataAsc = 'METADATA_ASC',
  MetadataDesc = 'METADATA_DESC',
  Natural = 'NATURAL',
  NetAsc = 'NET_ASC',
  NetDesc = 'NET_DESC',
  NetNormalizedAsc = 'NET_NORMALIZED_ASC',
  NetNormalizedDesc = 'NET_NORMALIZED_DESC',
  PaymentMethodAsc = 'PAYMENT_METHOD_ASC',
  PaymentMethodDesc = 'PAYMENT_METHOD_DESC',
  PaymentTypeAsc = 'PAYMENT_TYPE_ASC',
  PaymentTypeDesc = 'PAYMENT_TYPE_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReceivedAtAsc = 'RECEIVED_AT_ASC',
  ReceivedAtDesc = 'RECEIVED_AT_DESC',
  RecipientAsc = 'RECIPIENT_ASC',
  RecipientDesc = 'RECIPIENT_DESC',
  RecurrenceAsc = 'RECURRENCE_ASC',
  RecurrenceDesc = 'RECURRENCE_DESC',
  ReferenceAsc = 'REFERENCE_ASC',
  ReferenceDesc = 'REFERENCE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDataAsc = 'STATUS_DATA_ASC',
  StatusDataDesc = 'STATUS_DATA_DESC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A connection to a list of `Person` values. */
export type PeopleConnection = {
  __typename?: 'PeopleConnection';
  /** A list of edges which contains the `Person` and cursor to aid in pagination. */
  edges: Array<PeopleEdge>;
  /** A list of `Person` objects. */
  nodes: Array<Person>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Person` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Person` edge in the connection. */
export type PeopleEdge = {
  __typename?: 'PeopleEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Person` at the end of the edge. */
  node: Person;
};

/** Methods to use when ordering `Person`. */
export enum PeopleOrderBy {
  Auth0UserIdAsc = 'AUTH0_USER_ID_ASC',
  Auth0UserIdDesc = 'AUTH0_USER_ID_DESC',
  BirthDateAsc = 'BIRTH_DATE_ASC',
  BirthDateDesc = 'BIRTH_DATE_DESC',
  ContactTypeAsc = 'CONTACT_TYPE_ASC',
  ContactTypeDesc = 'CONTACT_TYPE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsAnonymizedAsc = 'IS_ANONYMIZED_ASC',
  IsAnonymizedDesc = 'IS_ANONYMIZED_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  Natural = 'NATURAL',
  OrganizationNameAsc = 'ORGANIZATION_NAME_ASC',
  OrganizationNameDesc = 'ORGANIZATION_NAME_DESC',
  PreferredLocaleAsc = 'PREFERRED_LOCALE_ASC',
  PreferredLocaleDesc = 'PREFERRED_LOCALE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TimezoneAsc = 'TIMEZONE_ASC',
  TimezoneDesc = 'TIMEZONE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Person = Node & {
  __typename?: 'Person';
  /** Reads and enables pagination through a set of `Address`. */
  addressesByPersonId: AddressesConnection;
  /** Reads and enables pagination through a set of `Address`. */
  addressesByPersonIdList: Array<Address>;
  auth0UserId?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['Date']>;
  /** Reads a single `ContactPreference` that is related to this `Person`. */
  contactPreferenceByPersonId?: Maybe<ContactPreference>;
  contactType?: Maybe<ContactType>;
  createdAt: Scalars['Datetime'];
  email?: Maybe<Scalars['String']>;
  /** Reads a single `EmailAddress` that is related to this `Person`. */
  emailAddressByIdAndEmail?: Maybe<EmailAddress>;
  /** Reads and enables pagination through a set of `EmailAddress`. */
  emailAddressesByPersonId: EmailAddressesConnection;
  /** Reads and enables pagination through a set of `EmailAddress`. */
  emailAddressesByPersonIdList: Array<EmailAddress>;
  /** Reads and enables pagination through a set of `FinancialPeriod`. */
  financialPeriodsByPersonId: FinancialPeriodsConnection;
  /** Reads and enables pagination through a set of `FinancialPeriod`. */
  financialPeriodsByPersonIdList: Array<FinancialPeriod>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  /** Reads a single `GivingWhatWeCanSurvey` that is related to this `Person`. */
  givingWhatWeCanSurveyByPersonId?: Maybe<GivingWhatWeCanSurvey>;
  /** Reads and enables pagination through a set of `GocardlessMandate`. */
  gocardlessMandatesByPersonId: GocardlessMandatesConnection;
  /** Reads and enables pagination through a set of `GocardlessMandate`. */
  gocardlessMandatesByPersonIdList: Array<GocardlessMandate>;
  /** Reads a single `GocardlessRedirectFlow` that is related to this `Person`. */
  gocardlessRedirectFlowByPersonId?: Maybe<GocardlessRedirectFlow>;
  id: Scalars['BigInt'];
  /** Reads and enables pagination through a set of `Income`. */
  incomesByPersonId: IncomesConnection;
  /** Reads and enables pagination through a set of `Income`. */
  incomesByPersonIdList: Array<Income>;
  isAnonymized: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Liability`. */
  liabilitiesByPersonId: LiabilitiesConnection;
  /** Reads and enables pagination through a set of `Liability`. */
  liabilitiesByPersonIdList: Array<Liability>;
  /** Reads and enables pagination through a set of `LiabilityPayment`. */
  liabilityPaymentsByPersonId: LiabilityPaymentsConnection;
  /** Reads and enables pagination through a set of `LiabilityPayment`. */
  liabilityPaymentsByPersonIdList: Array<LiabilityPayment>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  organizationName?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByPersonId: PaymentsConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByPersonIdList: Array<Payment>;
  /** Reads and enables pagination through a set of `PledgeRank`. */
  pledgeRanksByPersonId: PledgeRanksConnection;
  /** Reads and enables pagination through a set of `PledgeRank`. */
  pledgeRanksByPersonIdList: Array<PledgeRank>;
  /** Reads and enables pagination through a set of `Pledge`. */
  pledgesByPersonId: PledgesConnection;
  /** Reads and enables pagination through a set of `Pledge`. */
  pledgesByPersonIdList: Array<Pledge>;
  preferredLocale?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByPersonId: RecurringPaymentsConnection;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByPersonIdList: Array<RecurringPayment>;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  recurringReportedDonationsByPersonId: RecurringReportedDonationsConnection;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  recurringReportedDonationsByPersonIdList: Array<RecurringReportedDonation>;
  /** Reads and enables pagination through a set of `ReportedDonation`. */
  reportedDonationsByPersonId: ReportedDonationsConnection;
  /** Reads and enables pagination through a set of `ReportedDonation`. */
  reportedDonationsByPersonIdList: Array<ReportedDonation>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
};


export type PersonAddressesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AddressCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressesOrderBy>>;
};


export type PersonAddressesByPersonIdListArgs = {
  condition?: InputMaybe<AddressCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressesOrderBy>>;
};


export type PersonEmailAddressesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmailAddressCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmailAddressesOrderBy>>;
};


export type PersonEmailAddressesByPersonIdListArgs = {
  condition?: InputMaybe<EmailAddressCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmailAddressesOrderBy>>;
};


export type PersonFinancialPeriodsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FinancialPeriodCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FinancialPeriodsOrderBy>>;
};


export type PersonFinancialPeriodsByPersonIdListArgs = {
  condition?: InputMaybe<FinancialPeriodCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FinancialPeriodsOrderBy>>;
};


export type PersonGocardlessMandatesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GocardlessMandateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GocardlessMandatesOrderBy>>;
};


export type PersonGocardlessMandatesByPersonIdListArgs = {
  condition?: InputMaybe<GocardlessMandateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GocardlessMandatesOrderBy>>;
};


export type PersonIncomesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IncomeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IncomesOrderBy>>;
};


export type PersonIncomesByPersonIdListArgs = {
  condition?: InputMaybe<IncomeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IncomesOrderBy>>;
};


export type PersonLiabilitiesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilitiesOrderBy>>;
};


export type PersonLiabilitiesByPersonIdListArgs = {
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilitiesOrderBy>>;
};


export type PersonLiabilityPaymentsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilityPaymentsOrderBy>>;
};


export type PersonLiabilityPaymentsByPersonIdListArgs = {
  condition?: InputMaybe<LiabilityPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilityPaymentsOrderBy>>;
};


export type PersonPaymentsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};


export type PersonPaymentsByPersonIdListArgs = {
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};


export type PersonPledgeRanksByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PledgeRankCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeRanksOrderBy>>;
};


export type PersonPledgeRanksByPersonIdListArgs = {
  condition?: InputMaybe<PledgeRankCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeRanksOrderBy>>;
};


export type PersonPledgesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PledgeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgesOrderBy>>;
};


export type PersonPledgesByPersonIdListArgs = {
  condition?: InputMaybe<PledgeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgesOrderBy>>;
};


export type PersonRecurringPaymentsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecurringPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};


export type PersonRecurringPaymentsByPersonIdListArgs = {
  condition?: InputMaybe<RecurringPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};


export type PersonRecurringReportedDonationsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecurringReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringReportedDonationsOrderBy>>;
};


export type PersonRecurringReportedDonationsByPersonIdListArgs = {
  condition?: InputMaybe<RecurringReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringReportedDonationsOrderBy>>;
};


export type PersonReportedDonationsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportedDonationsOrderBy>>;
};


export type PersonReportedDonationsByPersonIdListArgs = {
  condition?: InputMaybe<ReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportedDonationsOrderBy>>;
};

/** A condition to be used against `Person` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PersonCondition = {
  /** Checks for equality with the object’s `auth0UserId` field. */
  auth0UserId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `birthDate` field. */
  birthDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `contactType` field. */
  contactType?: InputMaybe<ContactType>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `isAnonymized` field. */
  isAnonymized?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `organizationName` field. */
  organizationName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `preferredLocale` field. */
  preferredLocale?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type Pledge = Node & {
  __typename?: 'Pledge';
  anonymous: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  emailVerifiedAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['BigInt'];
  incomePercentage: Scalars['BigFloat'];
  /** Reads and enables pagination through a set of `Liability`. */
  liabilitiesByPledgeId: LiabilitiesConnection;
  /** Reads and enables pagination through a set of `Liability`. */
  liabilitiesByPledgeIdList: Array<Liability>;
  /** Reads and enables pagination through a set of `LiabilityPayment`. */
  liabilityPaymentsByPledgeId: LiabilityPaymentsConnection;
  /** Reads and enables pagination through a set of `LiabilityPayment`. */
  liabilityPaymentsByPledgeIdList: Array<LiabilityPayment>;
  /** Locale in which this pledge was taken */
  locale: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
  motivation?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  period: DateRange;
  /** Reads a single `Person` that is related to this `Pledge`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  /** Reads and enables pagination through a set of `PledgeRank`. */
  pledgeRanksByLatestPledgeId: PledgeRanksConnection;
  /** Reads and enables pagination through a set of `PledgeRank`. */
  pledgeRanksByLatestPledgeIdList: Array<PledgeRank>;
  pledgeType: PledgeType;
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  pollOptionVotesByPledgeId: PollOptionVotesConnection;
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  pollOptionVotesByPledgeIdList: Array<PollOptionVote>;
  updatedAt: Scalars['Datetime'];
  wealthPercentage?: Maybe<Scalars['BigFloat']>;
};


export type PledgeLiabilitiesByPledgeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilitiesOrderBy>>;
};


export type PledgeLiabilitiesByPledgeIdListArgs = {
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilitiesOrderBy>>;
};


export type PledgeLiabilityPaymentsByPledgeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilityPaymentsOrderBy>>;
};


export type PledgeLiabilityPaymentsByPledgeIdListArgs = {
  condition?: InputMaybe<LiabilityPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilityPaymentsOrderBy>>;
};


export type PledgePledgeRanksByLatestPledgeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PledgeRankCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeRanksOrderBy>>;
};


export type PledgePledgeRanksByLatestPledgeIdListArgs = {
  condition?: InputMaybe<PledgeRankCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeRanksOrderBy>>;
};


export type PledgePollOptionVotesByPledgeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PollOptionVoteCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PollOptionVotesOrderBy>>;
};


export type PledgePollOptionVotesByPledgeIdListArgs = {
  condition?: InputMaybe<PollOptionVoteCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PollOptionVotesOrderBy>>;
};

/** A condition to be used against `Pledge` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PledgeCondition = {
  /** Checks for equality with the object’s `anonymous` field. */
  anonymous?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `emailVerifiedAt` field. */
  emailVerifiedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `incomePercentage` field. */
  incomePercentage?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `locale` field. */
  locale?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `motivation` field. */
  motivation?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `period` field. */
  period?: InputMaybe<DateRangeInput>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `pledgeType` field. */
  pledgeType?: InputMaybe<PledgeType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `wealthPercentage` field. */
  wealthPercentage?: InputMaybe<Scalars['BigFloat']>;
};

export type PledgeRank = Node & {
  __typename?: 'PledgeRank';
  commencementDate: Scalars['Date'];
  createdAt: Scalars['Datetime'];
  latestPledgeId?: Maybe<Scalars['BigInt']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Person` that is related to this `PledgeRank`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  /** Reads a single `Pledge` that is related to this `PledgeRank`. */
  pledgeByLatestPledgeId?: Maybe<Pledge>;
  pledgeType: PledgeType;
  rankByPledgeType: Scalars['Int'];
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `PledgeRank` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PledgeRankCondition = {
  /** Checks for equality with the object’s `commencementDate` field. */
  commencementDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `latestPledgeId` field. */
  latestPledgeId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `pledgeType` field. */
  pledgeType?: InputMaybe<PledgeType>;
  /** Checks for equality with the object’s `rankByPledgeType` field. */
  rankByPledgeType?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `PledgeRank` values. */
export type PledgeRanksConnection = {
  __typename?: 'PledgeRanksConnection';
  /** A list of edges which contains the `PledgeRank` and cursor to aid in pagination. */
  edges: Array<PledgeRanksEdge>;
  /** A list of `PledgeRank` objects. */
  nodes: Array<PledgeRank>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PledgeRank` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PledgeRank` edge in the connection. */
export type PledgeRanksEdge = {
  __typename?: 'PledgeRanksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PledgeRank` at the end of the edge. */
  node: PledgeRank;
};

/** Methods to use when ordering `PledgeRank`. */
export enum PledgeRanksOrderBy {
  CommencementDateAsc = 'COMMENCEMENT_DATE_ASC',
  CommencementDateDesc = 'COMMENCEMENT_DATE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  LatestPledgeIdAsc = 'LATEST_PLEDGE_ID_ASC',
  LatestPledgeIdDesc = 'LATEST_PLEDGE_ID_DESC',
  Natural = 'NATURAL',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PledgeTypeAsc = 'PLEDGE_TYPE_ASC',
  PledgeTypeDesc = 'PLEDGE_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RankByPledgeTypeAsc = 'RANK_BY_PLEDGE_TYPE_ASC',
  RankByPledgeTypeDesc = 'RANK_BY_PLEDGE_TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export enum PledgeType {
  GivingWhatWeCan = 'GIVING_WHAT_WE_CAN',
  TryGiving = 'TRY_GIVING'
}

export type PledgedMembersPublic = {
  __typename?: 'PledgedMembersPublic';
  active?: Maybe<Scalars['Boolean']>;
  anonymous?: Maybe<Scalars['Boolean']>;
  commencementDate?: Maybe<Scalars['Date']>;
  fullName?: Maybe<Scalars['String']>;
  latestPledgeEndDate?: Maybe<Scalars['Date']>;
  latestPledgePercentage?: Maybe<Scalars['BigFloat']>;
  pledgeType?: Maybe<PledgeType>;
  rankByPledgeType?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `PledgedMembersPublic` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type PledgedMembersPublicCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `anonymous` field. */
  anonymous?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `commencementDate` field. */
  commencementDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `latestPledgeEndDate` field. */
  latestPledgeEndDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `latestPledgePercentage` field. */
  latestPledgePercentage?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `pledgeType` field. */
  pledgeType?: InputMaybe<PledgeType>;
  /** Checks for equality with the object’s `rankByPledgeType` field. */
  rankByPledgeType?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `PledgedMembersPublic` values. */
export type PledgedMembersPublicsConnection = {
  __typename?: 'PledgedMembersPublicsConnection';
  /** A list of edges which contains the `PledgedMembersPublic` and cursor to aid in pagination. */
  edges: Array<PledgedMembersPublicsEdge>;
  /** A list of `PledgedMembersPublic` objects. */
  nodes: Array<PledgedMembersPublic>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PledgedMembersPublic` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PledgedMembersPublic` edge in the connection. */
export type PledgedMembersPublicsEdge = {
  __typename?: 'PledgedMembersPublicsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PledgedMembersPublic` at the end of the edge. */
  node: PledgedMembersPublic;
};

/** Methods to use when ordering `PledgedMembersPublic`. */
export enum PledgedMembersPublicsOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  AnonymousAsc = 'ANONYMOUS_ASC',
  AnonymousDesc = 'ANONYMOUS_DESC',
  CommencementDateAsc = 'COMMENCEMENT_DATE_ASC',
  CommencementDateDesc = 'COMMENCEMENT_DATE_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  LatestPledgeEndDateAsc = 'LATEST_PLEDGE_END_DATE_ASC',
  LatestPledgeEndDateDesc = 'LATEST_PLEDGE_END_DATE_DESC',
  LatestPledgePercentageAsc = 'LATEST_PLEDGE_PERCENTAGE_ASC',
  LatestPledgePercentageDesc = 'LATEST_PLEDGE_PERCENTAGE_DESC',
  Natural = 'NATURAL',
  PledgeTypeAsc = 'PLEDGE_TYPE_ASC',
  PledgeTypeDesc = 'PLEDGE_TYPE_DESC',
  RankByPledgeTypeAsc = 'RANK_BY_PLEDGE_TYPE_ASC',
  RankByPledgeTypeDesc = 'RANK_BY_PLEDGE_TYPE_DESC'
}

/** A connection to a list of `Pledge` values. */
export type PledgesConnection = {
  __typename?: 'PledgesConnection';
  /** A list of edges which contains the `Pledge` and cursor to aid in pagination. */
  edges: Array<PledgesEdge>;
  /** A list of `Pledge` objects. */
  nodes: Array<Pledge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Pledge` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Pledge` edge in the connection. */
export type PledgesEdge = {
  __typename?: 'PledgesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Pledge` at the end of the edge. */
  node: Pledge;
};

/** Methods to use when ordering `Pledge`. */
export enum PledgesOrderBy {
  AnonymousAsc = 'ANONYMOUS_ASC',
  AnonymousDesc = 'ANONYMOUS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailVerifiedAtAsc = 'EMAIL_VERIFIED_AT_ASC',
  EmailVerifiedAtDesc = 'EMAIL_VERIFIED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IncomePercentageAsc = 'INCOME_PERCENTAGE_ASC',
  IncomePercentageDesc = 'INCOME_PERCENTAGE_DESC',
  LocaleAsc = 'LOCALE_ASC',
  LocaleDesc = 'LOCALE_DESC',
  MetadataAsc = 'METADATA_ASC',
  MetadataDesc = 'METADATA_DESC',
  MotivationAsc = 'MOTIVATION_ASC',
  MotivationDesc = 'MOTIVATION_DESC',
  Natural = 'NATURAL',
  PeriodAsc = 'PERIOD_ASC',
  PeriodDesc = 'PERIOD_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PledgeTypeAsc = 'PLEDGE_TYPE_ASC',
  PledgeTypeDesc = 'PLEDGE_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  WealthPercentageAsc = 'WEALTH_PERCENTAGE_ASC',
  WealthPercentageDesc = 'WEALTH_PERCENTAGE_DESC'
}

export type PollOptionPublic = Node & {
  __typename?: 'PollOptionPublic';
  createdAt: Scalars['Datetime'];
  id: Scalars['BigInt'];
  label: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  pollId: Scalars['BigInt'];
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  pollOptionVotesByPollOptionId: PollOptionVotesConnection;
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  pollOptionVotesByPollOptionIdList: Array<PollOptionVote>;
  /** Reads a single `PollPublic` that is related to this `PollOptionPublic`. */
  pollPublicByPollId?: Maybe<PollPublic>;
  updatedAt: Scalars['Datetime'];
  voteCount: Scalars['Int'];
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  votesByPersonOrTemporaryPerson: PollOptionVotesConnection;
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  votesByPersonOrTemporaryPersonList?: Maybe<Array<PollOptionVote>>;
};


export type PollOptionPublicPollOptionVotesByPollOptionIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PollOptionVoteCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PollOptionVotesOrderBy>>;
};


export type PollOptionPublicPollOptionVotesByPollOptionIdListArgs = {
  condition?: InputMaybe<PollOptionVoteCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PollOptionVotesOrderBy>>;
};


export type PollOptionPublicVotesByPersonOrTemporaryPersonArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


export type PollOptionPublicVotesByPersonOrTemporaryPersonListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `PollOptionPublic` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PollOptionPublicCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `label` field. */
  label?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `pollId` field. */
  pollId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `voteCount` field. */
  voteCount?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `PollOptionPublic` values. */
export type PollOptionPublicsConnection = {
  __typename?: 'PollOptionPublicsConnection';
  /** A list of edges which contains the `PollOptionPublic` and cursor to aid in pagination. */
  edges: Array<PollOptionPublicsEdge>;
  /** A list of `PollOptionPublic` objects. */
  nodes: Array<PollOptionPublic>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PollOptionPublic` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PollOptionPublic` edge in the connection. */
export type PollOptionPublicsEdge = {
  __typename?: 'PollOptionPublicsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PollOptionPublic` at the end of the edge. */
  node: PollOptionPublic;
};

/** Methods to use when ordering `PollOptionPublic`. */
export enum PollOptionPublicsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  Natural = 'NATURAL',
  PollIdAsc = 'POLL_ID_ASC',
  PollIdDesc = 'POLL_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VoteCountAsc = 'VOTE_COUNT_ASC',
  VoteCountDesc = 'VOTE_COUNT_DESC'
}

export type PollOptionVote = Node & {
  __typename?: 'PollOptionVote';
  context: Scalars['String'];
  createdAt: Scalars['Datetime'];
  metadata?: Maybe<Scalars['JSON']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Pledge` that is related to this `PollOptionVote`. */
  pledgeByPledgeId?: Maybe<Pledge>;
  pledgeId: Scalars['BigInt'];
  pollOptionId: Scalars['BigInt'];
  /** Reads a single `PollOptionPublic` that is related to this `PollOptionVote`. */
  pollOptionPublicByPollOptionId?: Maybe<PollOptionPublic>;
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `PollOptionVote` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PollOptionVoteCondition = {
  /** Checks for equality with the object’s `context` field. */
  context?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `pledgeId` field. */
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `pollOptionId` field. */
  pollOptionId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `PollOptionVote` values. */
export type PollOptionVotesConnection = {
  __typename?: 'PollOptionVotesConnection';
  /** A list of edges which contains the `PollOptionVote` and cursor to aid in pagination. */
  edges: Array<PollOptionVotesEdge>;
  /** A list of `PollOptionVote` objects. */
  nodes: Array<PollOptionVote>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PollOptionVote` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PollOptionVote` edge in the connection. */
export type PollOptionVotesEdge = {
  __typename?: 'PollOptionVotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PollOptionVote` at the end of the edge. */
  node: PollOptionVote;
};

/** Methods to use when ordering `PollOptionVote`. */
export enum PollOptionVotesOrderBy {
  ContextAsc = 'CONTEXT_ASC',
  ContextDesc = 'CONTEXT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  MetadataAsc = 'METADATA_ASC',
  MetadataDesc = 'METADATA_DESC',
  Natural = 'NATURAL',
  PledgeIdAsc = 'PLEDGE_ID_ASC',
  PledgeIdDesc = 'PLEDGE_ID_DESC',
  PollOptionIdAsc = 'POLL_OPTION_ID_ASC',
  PollOptionIdDesc = 'POLL_OPTION_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type PollPublic = Node & {
  __typename?: 'PollPublic';
  createdAt: Scalars['Datetime'];
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PollOptionPublic`. */
  pollOptionPublicsByPollId: PollOptionPublicsConnection;
  /** Reads and enables pagination through a set of `PollOptionPublic`. */
  pollOptionPublicsByPollIdList: Array<PollOptionPublic>;
  question: Scalars['String'];
  questionOrdering: QuestionOrdering;
  updatedAt: Scalars['Datetime'];
  voteMechanism: VoteMechanism;
};


export type PollPublicPollOptionPublicsByPollIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PollOptionPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PollOptionPublicsOrderBy>>;
};


export type PollPublicPollOptionPublicsByPollIdListArgs = {
  condition?: InputMaybe<PollOptionPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PollOptionPublicsOrderBy>>;
};

/**
 * A condition to be used against `PollPublic` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PollPublicCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `question` field. */
  question?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `questionOrdering` field. */
  questionOrdering?: InputMaybe<QuestionOrdering>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `voteMechanism` field. */
  voteMechanism?: InputMaybe<VoteMechanism>;
};

/** A connection to a list of `PollPublic` values. */
export type PollPublicsConnection = {
  __typename?: 'PollPublicsConnection';
  /** A list of edges which contains the `PollPublic` and cursor to aid in pagination. */
  edges: Array<PollPublicsEdge>;
  /** A list of `PollPublic` objects. */
  nodes: Array<PollPublic>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PollPublic` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PollPublic` edge in the connection. */
export type PollPublicsEdge = {
  __typename?: 'PollPublicsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PollPublic` at the end of the edge. */
  node: PollPublic;
};

/** Methods to use when ordering `PollPublic`. */
export enum PollPublicsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  QuestionAsc = 'QUESTION_ASC',
  QuestionDesc = 'QUESTION_DESC',
  QuestionOrderingAsc = 'QUESTION_ORDERING_ASC',
  QuestionOrderingDesc = 'QUESTION_ORDERING_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  VoteMechanismAsc = 'VOTE_MECHANISM_ASC',
  VoteMechanismDesc = 'VOTE_MECHANISM_DESC'
}

export type Program = Node & {
  __typename?: 'Program';
  active: Scalars['Boolean'];
  approvedFromResearch?: Maybe<Scalars['Boolean']>;
  childrenOrVulnerablePopulationsPolicy?: Maybe<Scalars['Boolean']>;
  childrenOrVulnerablePopulationsPolicyDetails?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  description?: Maybe<Scalars['String']>;
  evaluatorEndorsement?: Maybe<Array<Maybe<Scalars['String']>>>;
  evaluatorEndorsementOther?: Maybe<Scalars['String']>;
  /** Reads a single `ExternalOrganization` that is related to this `Program`. */
  externalOrganizationByOrganizationSlug?: Maybe<ExternalOrganization>;
  grantEvaluationProcess?: Maybe<Scalars['String']>;
  hidden?: Maybe<Scalars['Boolean']>;
  href?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  isDataSharingPartner: Scalars['Boolean'];
  metadata?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  organizationSlug: Scalars['String'];
  politicalActivity?: Maybe<Scalars['Boolean']>;
  politicalActivityDetails?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  type?: Maybe<ProgramType>;
  updatedAt: Scalars['Datetime'];
};

/** A condition to be used against `Program` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProgramCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `approvedFromResearch` field. */
  approvedFromResearch?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `childrenOrVulnerablePopulationsPolicy` field. */
  childrenOrVulnerablePopulationsPolicy?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `childrenOrVulnerablePopulationsPolicyDetails` field. */
  childrenOrVulnerablePopulationsPolicyDetails?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `evaluatorEndorsement` field. */
  evaluatorEndorsement?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `evaluatorEndorsementOther` field. */
  evaluatorEndorsementOther?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `grantEvaluationProcess` field. */
  grantEvaluationProcess?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `hidden` field. */
  hidden?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `href` field. */
  href?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `isDataSharingPartner` field. */
  isDataSharingPartner?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `organizationSlug` field. */
  organizationSlug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `politicalActivity` field. */
  politicalActivity?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `politicalActivityDetails` field. */
  politicalActivityDetails?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<ProgramType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `ProgramDetailsLegacy` values. */
export type ProgramDetailsLegaciesConnection = {
  __typename?: 'ProgramDetailsLegaciesConnection';
  /** A list of edges which contains the `ProgramDetailsLegacy` and cursor to aid in pagination. */
  edges: Array<ProgramDetailsLegaciesEdge>;
  /** A list of `ProgramDetailsLegacy` objects. */
  nodes: Array<ProgramDetailsLegacy>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProgramDetailsLegacy` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProgramDetailsLegacy` edge in the connection. */
export type ProgramDetailsLegaciesEdge = {
  __typename?: 'ProgramDetailsLegaciesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ProgramDetailsLegacy` at the end of the edge. */
  node: ProgramDetailsLegacy;
};

/** Methods to use when ordering `ProgramDetailsLegacy`. */
export enum ProgramDetailsLegaciesOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  HiddenAsc = 'HIDDEN_ASC',
  HiddenDesc = 'HIDDEN_DESC',
  HrefAsc = 'HREF_ASC',
  HrefDesc = 'HREF_DESC',
  IsDataSharingPartnerAsc = 'IS_DATA_SHARING_PARTNER_ASC',
  IsDataSharingPartnerDesc = 'IS_DATA_SHARING_PARTNER_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  ProgramAsc = 'PROGRAM_ASC',
  ProgramDesc = 'PROGRAM_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ProgramDetailsLegacy = {
  __typename?: 'ProgramDetailsLegacy';
  active?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  hidden?: Maybe<Scalars['Boolean']>;
  href?: Maybe<Scalars['String']>;
  isDataSharingPartner?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  program: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<ProgramType>;
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/**
 * A condition to be used against `ProgramDetailsLegacy` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ProgramDetailsLegacyCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `hidden` field. */
  hidden?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `href` field. */
  href?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `isDataSharingPartner` field. */
  isDataSharingPartner?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `program` field. */
  program?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<ProgramType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export enum ProgramType {
  Fund = 'FUND',
  Meta = 'META',
  NonProfit = 'NON_PROFIT'
}

/** A connection to a list of `Program` values. */
export type ProgramsConnection = {
  __typename?: 'ProgramsConnection';
  /** A list of edges which contains the `Program` and cursor to aid in pagination. */
  edges: Array<ProgramsEdge>;
  /** A list of `Program` objects. */
  nodes: Array<Program>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Program` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Program` edge in the connection. */
export type ProgramsEdge = {
  __typename?: 'ProgramsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Program` at the end of the edge. */
  node: Program;
};

/** Methods to use when ordering `Program`. */
export enum ProgramsOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  ApprovedFromResearchAsc = 'APPROVED_FROM_RESEARCH_ASC',
  ApprovedFromResearchDesc = 'APPROVED_FROM_RESEARCH_DESC',
  ChildrenOrVulnerablePopulationsPolicyAsc = 'CHILDREN_OR_VULNERABLE_POPULATIONS_POLICY_ASC',
  ChildrenOrVulnerablePopulationsPolicyDesc = 'CHILDREN_OR_VULNERABLE_POPULATIONS_POLICY_DESC',
  ChildrenOrVulnerablePopulationsPolicyDetailsAsc = 'CHILDREN_OR_VULNERABLE_POPULATIONS_POLICY_DETAILS_ASC',
  ChildrenOrVulnerablePopulationsPolicyDetailsDesc = 'CHILDREN_OR_VULNERABLE_POPULATIONS_POLICY_DETAILS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  EvaluatorEndorsementAsc = 'EVALUATOR_ENDORSEMENT_ASC',
  EvaluatorEndorsementDesc = 'EVALUATOR_ENDORSEMENT_DESC',
  EvaluatorEndorsementOtherAsc = 'EVALUATOR_ENDORSEMENT_OTHER_ASC',
  EvaluatorEndorsementOtherDesc = 'EVALUATOR_ENDORSEMENT_OTHER_DESC',
  GrantEvaluationProcessAsc = 'GRANT_EVALUATION_PROCESS_ASC',
  GrantEvaluationProcessDesc = 'GRANT_EVALUATION_PROCESS_DESC',
  HiddenAsc = 'HIDDEN_ASC',
  HiddenDesc = 'HIDDEN_DESC',
  HrefAsc = 'HREF_ASC',
  HrefDesc = 'HREF_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDataSharingPartnerAsc = 'IS_DATA_SHARING_PARTNER_ASC',
  IsDataSharingPartnerDesc = 'IS_DATA_SHARING_PARTNER_DESC',
  MetadataAsc = 'METADATA_ASC',
  MetadataDesc = 'METADATA_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrganizationSlugAsc = 'ORGANIZATION_SLUG_ASC',
  OrganizationSlugDesc = 'ORGANIZATION_SLUG_DESC',
  PoliticalActivityAsc = 'POLITICAL_ACTIVITY_ASC',
  PoliticalActivityDesc = 'POLITICAL_ACTIVITY_DESC',
  PoliticalActivityDetailsAsc = 'POLITICAL_ACTIVITY_DETAILS_ASC',
  PoliticalActivityDetailsDesc = 'POLITICAL_ACTIVITY_DETAILS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /** Reads a single `Address` using its globally unique `ID`. */
  address?: Maybe<Address>;
  addressById?: Maybe<Address>;
  addressByIdAndPersonId?: Maybe<Address>;
  /** Reads and enables pagination through a set of `ActiveExtendedLottery`. */
  allActiveExtendedLotteries?: Maybe<ActiveExtendedLotteriesConnection>;
  /** Reads a set of `ActiveExtendedLottery`. */
  allActiveExtendedLotteriesList?: Maybe<Array<ActiveExtendedLottery>>;
  /** Reads and enables pagination through a set of `ActiveRecipientProgram`. */
  allActiveRecipientPrograms?: Maybe<ActiveRecipientProgramsConnection>;
  /** Reads a set of `ActiveRecipientProgram`. */
  allActiveRecipientProgramsList?: Maybe<Array<ActiveRecipientProgram>>;
  /** Reads and enables pagination through a set of `Address`. */
  allAddresses?: Maybe<AddressesConnection>;
  /** Reads a set of `Address`. */
  allAddressesList?: Maybe<Array<Address>>;
  /** Reads and enables pagination through a set of `AllPledgesPublic`. */
  allAllPledgesPublics?: Maybe<AllPledgesPublicsConnection>;
  /** Reads a set of `AllPledgesPublic`. */
  allAllPledgesPublicsList?: Maybe<Array<AllPledgesPublic>>;
  /** Reads and enables pagination through a set of `BalanceSheetReportMonthlyTotal`. */
  allBalanceSheetReportMonthlyTotals?: Maybe<BalanceSheetReportMonthlyTotalsConnection>;
  /** Reads a set of `BalanceSheetReportMonthlyTotal`. */
  allBalanceSheetReportMonthlyTotalsList?: Maybe<Array<BalanceSheetReportMonthlyTotal>>;
  /** Reads and enables pagination through a set of `BalanceSheetReportPublic`. */
  allBalanceSheetReportPublics?: Maybe<BalanceSheetReportPublicsConnection>;
  /** Reads a set of `BalanceSheetReportPublic`. */
  allBalanceSheetReportPublicsList?: Maybe<Array<BalanceSheetReportPublic>>;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  allCompletionPayments?: Maybe<CompletionPaymentsConnection>;
  /** Reads a set of `CompletionPayment`. */
  allCompletionPaymentsList?: Maybe<Array<CompletionPayment>>;
  /** Reads and enables pagination through a set of `ContactPreference`. */
  allContactPreferences?: Maybe<ContactPreferencesConnection>;
  /** Reads a set of `ContactPreference`. */
  allContactPreferencesList?: Maybe<Array<ContactPreference>>;
  /** Reads and enables pagination through a set of `Country`. */
  allCountries?: Maybe<CountriesConnection>;
  /** Reads a set of `Country`. */
  allCountriesList?: Maybe<Array<Country>>;
  /** Reads and enables pagination through a set of `Currency`. */
  allCurrencies?: Maybe<CurrenciesConnection>;
  /** Reads a set of `Currency`. */
  allCurrenciesList?: Maybe<Array<Currency>>;
  /** Reads and enables pagination through a set of `CurrencyByCountry`. */
  allCurrencyByCountries?: Maybe<CurrencyByCountriesConnection>;
  /** Reads a set of `CurrencyByCountry`. */
  allCurrencyByCountriesList?: Maybe<Array<CurrencyByCountry>>;
  /** Reads and enables pagination through a set of `CurrentExchangeRate`. */
  allCurrentExchangeRates?: Maybe<CurrentExchangeRatesConnection>;
  /** Reads a set of `CurrentExchangeRate`. */
  allCurrentExchangeRatesList?: Maybe<Array<CurrentExchangeRate>>;
  /** Reads and enables pagination through a set of `EmailAddress`. */
  allEmailAddresses?: Maybe<EmailAddressesConnection>;
  /** Reads a set of `EmailAddress`. */
  allEmailAddressesList?: Maybe<Array<EmailAddress>>;
  /** Reads and enables pagination through a set of `ExchangeRate`. */
  allExchangeRates?: Maybe<ExchangeRatesConnection>;
  /** Reads a set of `ExchangeRate`. */
  allExchangeRatesList?: Maybe<Array<ExchangeRate>>;
  /** Reads and enables pagination through a set of `ExtendedLottery`. */
  allExtendedLotteries?: Maybe<ExtendedLotteriesConnection>;
  /** Reads a set of `ExtendedLottery`. */
  allExtendedLotteriesList?: Maybe<Array<ExtendedLottery>>;
  /** Reads and enables pagination through a set of `ExternalOrganization`. */
  allExternalOrganizations?: Maybe<ExternalOrganizationsConnection>;
  /** Reads a set of `ExternalOrganization`. */
  allExternalOrganizationsList?: Maybe<Array<ExternalOrganization>>;
  /** Reads and enables pagination through a set of `FinancialPeriod`. */
  allFinancialPeriods?: Maybe<FinancialPeriodsConnection>;
  /** Reads a set of `FinancialPeriod`. */
  allFinancialPeriodsList?: Maybe<Array<FinancialPeriod>>;
  /** Reads and enables pagination through a set of `GiftAidClaim`. */
  allGiftAidClaims?: Maybe<GiftAidClaimsConnection>;
  /** Reads a set of `GiftAidClaim`. */
  allGiftAidClaimsList?: Maybe<Array<GiftAidClaim>>;
  /** Reads and enables pagination through a set of `GivingWhatWeCanSurvey`. */
  allGivingWhatWeCanSurveys?: Maybe<GivingWhatWeCanSurveysConnection>;
  /** Reads a set of `GivingWhatWeCanSurvey`. */
  allGivingWhatWeCanSurveysList?: Maybe<Array<GivingWhatWeCanSurvey>>;
  /** Reads and enables pagination through a set of `GlobalStat`. */
  allGlobalStats?: Maybe<GlobalStatsConnection>;
  /** Reads a set of `GlobalStat`. */
  allGlobalStatsList?: Maybe<Array<GlobalStat>>;
  /** Reads and enables pagination through a set of `GocardlessMandate`. */
  allGocardlessMandates?: Maybe<GocardlessMandatesConnection>;
  /** Reads a set of `GocardlessMandate`. */
  allGocardlessMandatesList?: Maybe<Array<GocardlessMandate>>;
  /** Reads and enables pagination through a set of `GocardlessRedirectFlow`. */
  allGocardlessRedirectFlows?: Maybe<GocardlessRedirectFlowsConnection>;
  /** Reads a set of `GocardlessRedirectFlow`. */
  allGocardlessRedirectFlowsList?: Maybe<Array<GocardlessRedirectFlow>>;
  /** Reads and enables pagination through a set of `Income`. */
  allIncomes?: Maybe<IncomesConnection>;
  /** Reads a set of `Income`. */
  allIncomesList?: Maybe<Array<Income>>;
  /** Reads and enables pagination through a set of `Liability`. */
  allLiabilities?: Maybe<LiabilitiesConnection>;
  /** Reads a set of `Liability`. */
  allLiabilitiesList?: Maybe<Array<Liability>>;
  /** Reads and enables pagination through a set of `LiabilityPayment`. */
  allLiabilityPayments?: Maybe<LiabilityPaymentsConnection>;
  /** Reads a set of `LiabilityPayment`. */
  allLiabilityPaymentsList?: Maybe<Array<LiabilityPayment>>;
  /** Reads and enables pagination through a set of `LotteryEntryComplete`. */
  allLotteryEntryCompletes?: Maybe<LotteryEntryCompletesConnection>;
  /** Reads a set of `LotteryEntryComplete`. */
  allLotteryEntryCompletesList?: Maybe<Array<LotteryEntryComplete>>;
  /** Reads and enables pagination through a set of `LotteryEntryPublic`. */
  allLotteryEntryPublics?: Maybe<LotteryEntryPublicsConnection>;
  /** Reads a set of `LotteryEntryPublic`. */
  allLotteryEntryPublicsList?: Maybe<Array<LotteryEntryPublic>>;
  /** Reads and enables pagination through a set of `LotterySummary`. */
  allLotterySummaries?: Maybe<LotterySummariesConnection>;
  /** Reads a set of `LotterySummary`. */
  allLotterySummariesList?: Maybe<Array<LotterySummary>>;
  /** Reads and enables pagination through a set of `LotteryTicketPublic`. */
  allLotteryTicketPublics?: Maybe<LotteryTicketPublicsConnection>;
  /** Reads a set of `LotteryTicketPublic`. */
  allLotteryTicketPublicsList?: Maybe<Array<LotteryTicketPublic>>;
  /** Reads and enables pagination through a set of `LotteryWinner`. */
  allLotteryWinners?: Maybe<LotteryWinnersConnection>;
  /** Reads a set of `LotteryWinner`. */
  allLotteryWinnersList?: Maybe<Array<LotteryWinner>>;
  /** Reads and enables pagination through a set of `NormalizedCurrency`. */
  allNormalizedCurrencies?: Maybe<NormalizedCurrenciesConnection>;
  /** Reads a set of `NormalizedCurrency`. */
  allNormalizedCurrenciesList?: Maybe<Array<NormalizedCurrency>>;
  /** Reads and enables pagination through a set of `OrganizationCategory`. */
  allOrganizationCategories?: Maybe<OrganizationCategoriesConnection>;
  /** Reads a set of `OrganizationCategory`. */
  allOrganizationCategoriesList?: Maybe<Array<OrganizationCategory>>;
  /** Reads and enables pagination through a set of `PaymentAccessToken`. */
  allPaymentAccessTokens?: Maybe<PaymentAccessTokensConnection>;
  /** Reads a set of `PaymentAccessToken`. */
  allPaymentAccessTokensList?: Maybe<Array<PaymentAccessToken>>;
  /** Reads and enables pagination through a set of `PaymentRecipient`. */
  allPaymentRecipients?: Maybe<PaymentRecipientsConnection>;
  /** Reads a set of `PaymentRecipient`. */
  allPaymentRecipientsList?: Maybe<Array<PaymentRecipient>>;
  /** Reads and enables pagination through a set of `PaymentStatsAggregate`. */
  allPaymentStatsAggregates?: Maybe<PaymentStatsAggregatesConnection>;
  /** Reads a set of `PaymentStatsAggregate`. */
  allPaymentStatsAggregatesList?: Maybe<Array<PaymentStatsAggregate>>;
  /** Reads and enables pagination through a set of `Payment`. */
  allPayments?: Maybe<PaymentsConnection>;
  /** Reads a set of `Payment`. */
  allPaymentsList?: Maybe<Array<Payment>>;
  /** Reads and enables pagination through a set of `Person`. */
  allPeople?: Maybe<PeopleConnection>;
  /** Reads a set of `Person`. */
  allPeopleList?: Maybe<Array<Person>>;
  /** Reads and enables pagination through a set of `PledgeRank`. */
  allPledgeRanks?: Maybe<PledgeRanksConnection>;
  /** Reads a set of `PledgeRank`. */
  allPledgeRanksList?: Maybe<Array<PledgeRank>>;
  /** Reads and enables pagination through a set of `PledgedMembersPublic`. */
  allPledgedMembersPublics?: Maybe<PledgedMembersPublicsConnection>;
  /** Reads a set of `PledgedMembersPublic`. */
  allPledgedMembersPublicsList?: Maybe<Array<PledgedMembersPublic>>;
  /** Reads and enables pagination through a set of `Pledge`. */
  allPledges?: Maybe<PledgesConnection>;
  /** Reads a set of `Pledge`. */
  allPledgesList?: Maybe<Array<Pledge>>;
  /** Reads and enables pagination through a set of `PollOptionPublic`. */
  allPollOptionPublics?: Maybe<PollOptionPublicsConnection>;
  /** Reads a set of `PollOptionPublic`. */
  allPollOptionPublicsList?: Maybe<Array<PollOptionPublic>>;
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  allPollOptionVotes?: Maybe<PollOptionVotesConnection>;
  /** Reads a set of `PollOptionVote`. */
  allPollOptionVotesList?: Maybe<Array<PollOptionVote>>;
  /** Reads and enables pagination through a set of `PollPublic`. */
  allPollPublics?: Maybe<PollPublicsConnection>;
  /** Reads a set of `PollPublic`. */
  allPollPublicsList?: Maybe<Array<PollPublic>>;
  /** Reads and enables pagination through a set of `ProgramDetailsLegacy`. */
  allProgramDetailsLegacies?: Maybe<ProgramDetailsLegaciesConnection>;
  /** Reads a set of `ProgramDetailsLegacy`. */
  allProgramDetailsLegaciesList?: Maybe<Array<ProgramDetailsLegacy>>;
  /** Reads and enables pagination through a set of `Program`. */
  allPrograms?: Maybe<ProgramsConnection>;
  /** Reads a set of `Program`. */
  allProgramsList?: Maybe<Array<Program>>;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  allRecurringPayments?: Maybe<RecurringPaymentsConnection>;
  /** Reads a set of `RecurringPayment`. */
  allRecurringPaymentsList?: Maybe<Array<RecurringPayment>>;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  allRecurringReportedDonations?: Maybe<RecurringReportedDonationsConnection>;
  /** Reads a set of `RecurringReportedDonation`. */
  allRecurringReportedDonationsList?: Maybe<Array<RecurringReportedDonation>>;
  /** Reads and enables pagination through a set of `ReferralSource`. */
  allReferralSources?: Maybe<ReferralSourcesConnection>;
  /** Reads a set of `ReferralSource`. */
  allReferralSourcesList?: Maybe<Array<ReferralSource>>;
  /** Reads and enables pagination through a set of `ReportedDonation`. */
  allReportedDonations?: Maybe<ReportedDonationsConnection>;
  /** Reads a set of `ReportedDonation`. */
  allReportedDonationsList?: Maybe<Array<ReportedDonation>>;
  arraySuffix?: Maybe<Array<Maybe<Scalars['String']>>>;
  closestExchangeRate?: Maybe<ExchangeRate>;
  /** Reads a single `ContactPreference` using its globally unique `ID`. */
  contactPreference?: Maybe<ContactPreference>;
  contactPreferenceByPersonId?: Maybe<ContactPreference>;
  convertCurrency?: Maybe<Scalars['BigFloat']>;
  /** Reads a single `Country` using its globally unique `ID`. */
  country?: Maybe<Country>;
  countryByIsoAlpha2?: Maybe<Country>;
  /** Reads a single `Currency` using its globally unique `ID`. */
  currency?: Maybe<Currency>;
  currencyByCode?: Maybe<Currency>;
  /** Reads a single `CurrencyByCountry` using its globally unique `ID`. */
  currencyByCountry?: Maybe<CurrencyByCountry>;
  currencyByCountryByCountryCodeAndCurrencyCode?: Maybe<CurrencyByCountry>;
  currentPerson?: Maybe<Person>;
  decodeHashid?: Maybe<Scalars['BigInt']>;
  /** Reads a single `EmailAddress` using its globally unique `ID`. */
  emailAddress?: Maybe<EmailAddress>;
  emailAddressByEmail?: Maybe<EmailAddress>;
  emailAddressByPersonIdAndEmail?: Maybe<EmailAddress>;
  encodeHashid?: Maybe<Scalars['String']>;
  /** Reads a single `ExchangeRate` using its globally unique `ID`. */
  exchangeRate?: Maybe<ExchangeRate>;
  exchangeRateByCurrencyCodeAndDate?: Maybe<ExchangeRate>;
  extendedLotteryById?: Maybe<ExtendedLottery>;
  /** Reads a single `ExternalOrganization` using its globally unique `ID`. */
  externalOrganization?: Maybe<ExternalOrganization>;
  externalOrganizationById?: Maybe<ExternalOrganization>;
  externalOrganizationByName?: Maybe<ExternalOrganization>;
  externalOrganizationByOrganizationSlug?: Maybe<ExternalOrganization>;
  /** Reads and enables pagination through a set of `ExternalOrganization`. */
  externalOrganizationTypeahead?: Maybe<ExternalOrganizationsConnection>;
  /** Reads and enables pagination through a set of `ExternalOrganization`. */
  externalOrganizationTypeaheadList?: Maybe<Array<ExternalOrganization>>;
  /** Reads a single `FinancialPeriod` using its globally unique `ID`. */
  financialPeriod?: Maybe<FinancialPeriod>;
  financialPeriodById?: Maybe<FinancialPeriod>;
  financialPeriodByPersonIdAndPeriodAndCurrencyCode?: Maybe<FinancialPeriod>;
  /** Reads and enables pagination through a set of `Person`. */
  findPersonByNameOrEmail?: Maybe<PeopleConnection>;
  /** Reads and enables pagination through a set of `Person`. */
  findPersonByNameOrEmailList?: Maybe<Array<Person>>;
  /** Reads and enables pagination through a set of `Address`. */
  getAddressesByPersonOrTemporaryPerson?: Maybe<AddressesConnection>;
  /** Reads and enables pagination through a set of `Address`. */
  getAddressesByPersonOrTemporaryPersonList?: Maybe<Array<Address>>;
  /** Reads and enables pagination through a set of `FinancialPeriod`. */
  getFinancialPeriodsByPersonId?: Maybe<FinancialPeriodsConnection>;
  /** Reads and enables pagination through a set of `FinancialPeriod`. */
  getFinancialPeriodsByPersonIdList?: Maybe<Array<FinancialPeriod>>;
  /** Reads and enables pagination through a set of `FundraiserStat`. */
  getFundraiserStats?: Maybe<FundraiserStatsConnection>;
  /** Reads and enables pagination through a set of `FundraiserStat`. */
  getFundraiserStatsList?: Maybe<Array<FundraiserStat>>;
  /** Reads and enables pagination through a set of `GocardlessMandate`. */
  getGocardlessMandatesByPersonOrTemporaryPerson?: Maybe<GocardlessMandatesConnection>;
  /** Reads and enables pagination through a set of `GocardlessMandate`. */
  getGocardlessMandatesByPersonOrTemporaryPersonList?: Maybe<Array<GocardlessMandate>>;
  getGocardlessRedirectFlowByPersonOrTemporaryPerson?: Maybe<GocardlessRedirectFlow>;
  getImpliedExchangeRate?: Maybe<Scalars['BigFloat']>;
  /** Reads and enables pagination through a set of `Income`. */
  getIncomesByPersonId?: Maybe<IncomesConnection>;
  /** Reads and enables pagination through a set of `Income`. */
  getIncomesByPersonIdList?: Maybe<Array<Income>>;
  /** Reads and enables pagination through a set of `LiabilityCompletionT`. */
  getLiabilityCompletionByPledge?: Maybe<LiabilityCompletionTsConnection>;
  /** Reads and enables pagination through a set of `LiabilityCompletionT`. */
  getLiabilityCompletionByPledgeList?: Maybe<Array<LiabilityCompletionT>>;
  /** Reads and enables pagination through a set of `LiabilityTotalCompletionT`. */
  getLiabilityTotalCompletion?: Maybe<LiabilityTotalCompletionTsConnection>;
  /** Reads and enables pagination through a set of `LiabilityTotalCompletionT`. */
  getLiabilityTotalCompletionList?: Maybe<Array<LiabilityTotalCompletionT>>;
  /** Reads and enables pagination through a set of `LotteryEntryPublic`. */
  getLotteryEntriesByLotteryId?: Maybe<LotteryEntryPublicsConnection>;
  /** Reads and enables pagination through a set of `LotteryEntryPublic`. */
  getLotteryEntriesByLotteryIdList?: Maybe<Array<LotteryEntryPublic>>;
  /** Reads and enables pagination through a set of `LotteryEntryComplete`. */
  getLotteryEntriesCompleteByLotteryId?: Maybe<LotteryEntryCompletesConnection>;
  /** Reads and enables pagination through a set of `LotteryEntryComplete`. */
  getLotteryEntriesCompleteByLotteryIdList?: Maybe<Array<LotteryEntryComplete>>;
  getLotterySummaryByLotteryId?: Maybe<LotterySummary>;
  getPaymentByPersonOrTemporaryPerson?: Maybe<Payment>;
  getPaymentStatsAggregate?: Maybe<PaymentStatsAggregate>;
  getPledgeByPersonOrTemporaryPerson?: Maybe<GetPledgeByPersonOrTemporaryPersonT>;
  getPledgeByTemporaryPerson?: Maybe<Pledge>;
  /** Reads and enables pagination through a set of `Pledge`. */
  getPledgesByPersonId?: Maybe<PledgesConnection>;
  /** Reads and enables pagination through a set of `Pledge`. */
  getPledgesByPersonIdList?: Maybe<Array<Pledge>>;
  getRecurringPaymentByPersonOrTemporaryPerson?: Maybe<RecurringPayment>;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  getRecurringReportedDonationsByPersonId?: Maybe<RecurringReportedDonationsConnection>;
  /** Reads and enables pagination through a set of `RecurringReportedDonation`. */
  getRecurringReportedDonationsByPersonIdList?: Maybe<Array<RecurringReportedDonation>>;
  getTotalPledgeSignups?: Maybe<Scalars['Int']>;
  /** Reads a single `GiftAidClaim` using its globally unique `ID`. */
  giftAidClaim?: Maybe<GiftAidClaim>;
  giftAidClaimByGiftAidPaymentId?: Maybe<GiftAidClaim>;
  giftAidClaimByPaymentId?: Maybe<GiftAidClaim>;
  /** Reads a single `GivingWhatWeCanSurvey` using its globally unique `ID`. */
  givingWhatWeCanSurvey?: Maybe<GivingWhatWeCanSurvey>;
  givingWhatWeCanSurveyByPersonId?: Maybe<GivingWhatWeCanSurvey>;
  gocardlessMandateByGocardlessMandateId?: Maybe<GocardlessMandate>;
  /** Reads a single `GocardlessRedirectFlow` using its globally unique `ID`. */
  gocardlessRedirectFlow?: Maybe<GocardlessRedirectFlow>;
  gocardlessRedirectFlowByGocardlessRedirectFlowId?: Maybe<GocardlessRedirectFlow>;
  gocardlessRedirectFlowByGocardlessRedirectFlowUrl?: Maybe<GocardlessRedirectFlow>;
  gocardlessRedirectFlowByPersonId?: Maybe<GocardlessRedirectFlow>;
  gocardlessRedirectFlowBySessionToken?: Maybe<GocardlessRedirectFlow>;
  hashidsdefaultalphabet?: Maybe<Scalars['String']>;
  hex2Bigint?: Maybe<Scalars['BigInt']>;
  /** Reads a single `Income` using its globally unique `ID`. */
  income?: Maybe<Income>;
  incomeById?: Maybe<Income>;
  isValidJson?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Liability` using its globally unique `ID`. */
  liability?: Maybe<Liability>;
  liabilityByPledgeIdAndFinancialPeriodId?: Maybe<Liability>;
  /** Reads a single `LiabilityPayment` using its globally unique `ID`. */
  liabilityPayment?: Maybe<LiabilityPayment>;
  liabilityPaymentById?: Maybe<LiabilityPayment>;
  liabilityPaymentByPersonIdAndPaymentId?: Maybe<LiabilityPayment>;
  liabilityPaymentByPersonIdAndReportedDonationId?: Maybe<LiabilityPayment>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  normalizeAmountToUsd?: Maybe<Scalars['BigFloat']>;
  /** Reads a single `OrganizationCategory` using its globally unique `ID`. */
  organizationCategory?: Maybe<OrganizationCategory>;
  organizationCategoryBySlug?: Maybe<OrganizationCategory>;
  /** Reads a single `Payment` using its globally unique `ID`. */
  payment?: Maybe<Payment>;
  /** Reads a single `PaymentAccessToken` using its globally unique `ID`. */
  paymentAccessToken?: Maybe<PaymentAccessToken>;
  paymentAccessTokenByPaymentId?: Maybe<PaymentAccessToken>;
  paymentAccessTokenByToken?: Maybe<PaymentAccessToken>;
  paymentByGatewayAndGatewayTransactionId?: Maybe<Payment>;
  paymentById?: Maybe<Payment>;
  paymentByIdAndPersonId?: Maybe<Payment>;
  paymentByReference?: Maybe<Payment>;
  /** Reads a single `PaymentRecipient` using its globally unique `ID`. */
  paymentRecipient?: Maybe<PaymentRecipient>;
  paymentRecipientBySlug?: Maybe<PaymentRecipient>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByPersonId?: Maybe<PaymentsConnection>;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByPersonIdList?: Maybe<Array<Payment>>;
  /** Reads a single `Person` using its globally unique `ID`. */
  person?: Maybe<Person>;
  personByAuth0UserId?: Maybe<Person>;
  personByEmail?: Maybe<Person>;
  personById?: Maybe<Person>;
  /** Reads a single `Pledge` using its globally unique `ID`. */
  pledge?: Maybe<Pledge>;
  pledgeById?: Maybe<Pledge>;
  /** Reads a single `PledgeRank` using its globally unique `ID`. */
  pledgeRank?: Maybe<PledgeRank>;
  pledgeRankByPersonIdAndPledgeType?: Maybe<PledgeRank>;
  /** Reads a single `PollOptionPublic` using its globally unique `ID`. */
  pollOptionPublic?: Maybe<PollOptionPublic>;
  pollOptionPublicById?: Maybe<PollOptionPublic>;
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  pollOptionPublicVotesByTemporaryPerson?: Maybe<PollOptionVotesConnection>;
  /** Reads and enables pagination through a set of `PollOptionVote`. */
  pollOptionPublicVotesByTemporaryPersonList?: Maybe<Array<PollOptionVote>>;
  /** Reads a single `PollOptionVote` using its globally unique `ID`. */
  pollOptionVote?: Maybe<PollOptionVote>;
  pollOptionVoteByPollOptionIdAndPledgeId?: Maybe<PollOptionVote>;
  /** Reads a single `PollPublic` using its globally unique `ID`. */
  pollPublic?: Maybe<PollPublic>;
  pollPublicById?: Maybe<PollPublic>;
  /** Reads a single `Program` using its globally unique `ID`. */
  program?: Maybe<Program>;
  programById?: Maybe<Program>;
  programBySlug?: Maybe<Program>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads a single `RecurringPayment` using its globally unique `ID`. */
  recurringPayment?: Maybe<RecurringPayment>;
  recurringPaymentById?: Maybe<RecurringPayment>;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByPersonId?: Maybe<RecurringPaymentsConnection>;
  /** Reads and enables pagination through a set of `RecurringPayment`. */
  recurringPaymentsByPersonIdList?: Maybe<Array<RecurringPayment>>;
  /** Reads a single `RecurringReportedDonation` using its globally unique `ID`. */
  recurringReportedDonation?: Maybe<RecurringReportedDonation>;
  recurringReportedDonationById?: Maybe<RecurringReportedDonation>;
  /** Reads a single `ReferralSource` using its globally unique `ID`. */
  referralSource?: Maybe<ReferralSource>;
  referralSourceBySlug?: Maybe<ReferralSource>;
  /** Reads a single `ReportedDonation` using its globally unique `ID`. */
  reportedDonation?: Maybe<ReportedDonation>;
  reportedDonationById?: Maybe<ReportedDonation>;
  /** Reads and enables pagination through a set of `ReportedDonation`. */
  reportedDonationsByPersonId?: Maybe<ReportedDonationsConnection>;
  /** Reads and enables pagination through a set of `ReportedDonation`. */
  reportedDonationsByPersonIdList?: Maybe<Array<ReportedDonation>>;
  sendReceipt?: Maybe<Scalars['String']>;
  slugify?: Maybe<Scalars['String']>;
  validHref?: Maybe<Scalars['Boolean']>;
  validRelativeUrl?: Maybe<Scalars['Boolean']>;
  validUrl?: Maybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAddressArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAddressByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAddressByIdAndPersonIdArgs = {
  id: Scalars['BigInt'];
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAllActiveExtendedLotteriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActiveExtendedLotteryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActiveExtendedLotteriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllActiveExtendedLotteriesListArgs = {
  condition?: InputMaybe<ActiveExtendedLotteryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActiveExtendedLotteriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllActiveRecipientProgramsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActiveRecipientProgramCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActiveRecipientProgramsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllActiveRecipientProgramsListArgs = {
  condition?: InputMaybe<ActiveRecipientProgramCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActiveRecipientProgramsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllAddressesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AddressCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllAddressesListArgs = {
  condition?: InputMaybe<AddressCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AddressesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllAllPledgesPublicsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AllPledgesPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AllPledgesPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllAllPledgesPublicsListArgs = {
  condition?: InputMaybe<AllPledgesPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AllPledgesPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllBalanceSheetReportMonthlyTotalsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BalanceSheetReportMonthlyTotalCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BalanceSheetReportMonthlyTotalsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllBalanceSheetReportMonthlyTotalsListArgs = {
  condition?: InputMaybe<BalanceSheetReportMonthlyTotalCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BalanceSheetReportMonthlyTotalsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllBalanceSheetReportPublicsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BalanceSheetReportPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BalanceSheetReportPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllBalanceSheetReportPublicsListArgs = {
  condition?: InputMaybe<BalanceSheetReportPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BalanceSheetReportPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCompletionPaymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCompletionPaymentsListArgs = {
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllContactPreferencesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ContactPreferenceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContactPreferencesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllContactPreferencesListArgs = {
  condition?: InputMaybe<ContactPreferenceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContactPreferencesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCountriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CountryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCountriesListArgs = {
  condition?: InputMaybe<CountryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCurrenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CurrencyCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCurrenciesListArgs = {
  condition?: InputMaybe<CurrencyCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrenciesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCurrencyByCountriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CurrencyByCountryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyByCountriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCurrencyByCountriesListArgs = {
  condition?: InputMaybe<CurrencyByCountryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrencyByCountriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCurrentExchangeRatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CurrentExchangeRateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrentExchangeRatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCurrentExchangeRatesListArgs = {
  condition?: InputMaybe<CurrentExchangeRateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CurrentExchangeRatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllEmailAddressesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmailAddressCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmailAddressesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllEmailAddressesListArgs = {
  condition?: InputMaybe<EmailAddressCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmailAddressesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllExchangeRatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExchangeRateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeRatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllExchangeRatesListArgs = {
  condition?: InputMaybe<ExchangeRateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExchangeRatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllExtendedLotteriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExtendedLotteryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExtendedLotteriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllExtendedLotteriesListArgs = {
  condition?: InputMaybe<ExtendedLotteryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExtendedLotteriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllExternalOrganizationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ExternalOrganizationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalOrganizationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllExternalOrganizationsListArgs = {
  condition?: InputMaybe<ExternalOrganizationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ExternalOrganizationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllFinancialPeriodsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FinancialPeriodCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FinancialPeriodsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllFinancialPeriodsListArgs = {
  condition?: InputMaybe<FinancialPeriodCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FinancialPeriodsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllGiftAidClaimsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GiftAidClaimCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GiftAidClaimsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllGiftAidClaimsListArgs = {
  condition?: InputMaybe<GiftAidClaimCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GiftAidClaimsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllGivingWhatWeCanSurveysArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GivingWhatWeCanSurveyCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GivingWhatWeCanSurveysOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllGivingWhatWeCanSurveysListArgs = {
  condition?: InputMaybe<GivingWhatWeCanSurveyCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GivingWhatWeCanSurveysOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllGlobalStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GlobalStatCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GlobalStatsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllGlobalStatsListArgs = {
  condition?: InputMaybe<GlobalStatCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GlobalStatsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllGocardlessMandatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GocardlessMandateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GocardlessMandatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllGocardlessMandatesListArgs = {
  condition?: InputMaybe<GocardlessMandateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GocardlessMandatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllGocardlessRedirectFlowsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GocardlessRedirectFlowCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GocardlessRedirectFlowsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllGocardlessRedirectFlowsListArgs = {
  condition?: InputMaybe<GocardlessRedirectFlowCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GocardlessRedirectFlowsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllIncomesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IncomeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IncomesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllIncomesListArgs = {
  condition?: InputMaybe<IncomeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IncomesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLiabilitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilitiesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLiabilitiesListArgs = {
  condition?: InputMaybe<LiabilityCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilitiesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLiabilityPaymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilityPaymentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLiabilityPaymentsListArgs = {
  condition?: InputMaybe<LiabilityPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilityPaymentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLotteryEntryCompletesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LotteryEntryCompleteCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LotteryEntryCompletesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLotteryEntryCompletesListArgs = {
  condition?: InputMaybe<LotteryEntryCompleteCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LotteryEntryCompletesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLotteryEntryPublicsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LotteryEntryPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LotteryEntryPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLotteryEntryPublicsListArgs = {
  condition?: InputMaybe<LotteryEntryPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LotteryEntryPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLotterySummariesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LotterySummaryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LotterySummariesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLotterySummariesListArgs = {
  condition?: InputMaybe<LotterySummaryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LotterySummariesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLotteryTicketPublicsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LotteryTicketPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LotteryTicketPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLotteryTicketPublicsListArgs = {
  condition?: InputMaybe<LotteryTicketPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LotteryTicketPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLotteryWinnersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LotteryWinnerCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LotteryWinnersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLotteryWinnersListArgs = {
  condition?: InputMaybe<LotteryWinnerCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LotteryWinnersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllNormalizedCurrenciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<NormalizedCurrencyCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NormalizedCurrenciesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllNormalizedCurrenciesListArgs = {
  condition?: InputMaybe<NormalizedCurrencyCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<NormalizedCurrenciesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllOrganizationCategoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCategoryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCategoriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllOrganizationCategoriesListArgs = {
  condition?: InputMaybe<OrganizationCategoryCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCategoriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPaymentAccessTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentAccessTokenCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentAccessTokensOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPaymentAccessTokensListArgs = {
  condition?: InputMaybe<PaymentAccessTokenCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentAccessTokensOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPaymentRecipientsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentRecipientCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentRecipientsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPaymentRecipientsListArgs = {
  condition?: InputMaybe<PaymentRecipientCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentRecipientsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPaymentStatsAggregatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentStatsAggregateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentStatsAggregatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPaymentStatsAggregatesListArgs = {
  condition?: InputMaybe<PaymentStatsAggregateCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentStatsAggregatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPaymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPaymentsListArgs = {
  condition?: InputMaybe<PaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPeopleArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PersonCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PeopleOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPeopleListArgs = {
  condition?: InputMaybe<PersonCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PeopleOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgeRanksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PledgeRankCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeRanksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgeRanksListArgs = {
  condition?: InputMaybe<PledgeRankCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgeRanksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgedMembersPublicsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PledgedMembersPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgedMembersPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgedMembersPublicsListArgs = {
  condition?: InputMaybe<PledgedMembersPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgedMembersPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PledgeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPledgesListArgs = {
  condition?: InputMaybe<PledgeCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PledgesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPollOptionPublicsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PollOptionPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PollOptionPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPollOptionPublicsListArgs = {
  condition?: InputMaybe<PollOptionPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PollOptionPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPollOptionVotesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PollOptionVoteCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PollOptionVotesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPollOptionVotesListArgs = {
  condition?: InputMaybe<PollOptionVoteCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PollOptionVotesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPollPublicsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PollPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PollPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPollPublicsListArgs = {
  condition?: InputMaybe<PollPublicCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PollPublicsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllProgramDetailsLegaciesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramDetailsLegacyCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramDetailsLegaciesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllProgramDetailsLegaciesListArgs = {
  condition?: InputMaybe<ProgramDetailsLegacyCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramDetailsLegaciesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllProgramsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProgramCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllProgramsListArgs = {
  condition?: InputMaybe<ProgramCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProgramsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllRecurringPaymentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecurringPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllRecurringPaymentsListArgs = {
  condition?: InputMaybe<RecurringPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllRecurringReportedDonationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RecurringReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringReportedDonationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllRecurringReportedDonationsListArgs = {
  condition?: InputMaybe<RecurringReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RecurringReportedDonationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllReferralSourcesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ReferralSourceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralSourcesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllReferralSourcesListArgs = {
  condition?: InputMaybe<ReferralSourceCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReferralSourcesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllReportedDonationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportedDonationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllReportedDonationsListArgs = {
  condition?: InputMaybe<ReportedDonationCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ReportedDonationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryArraySuffixArgs = {
  arr?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  suffix?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClosestExchangeRateArgs = {
  currencyCode?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryContactPreferenceArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryContactPreferenceByPersonIdArgs = {
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryConvertCurrencyArgs = {
  amount?: InputMaybe<Scalars['BigFloat']>;
  exchangeRateDate?: InputMaybe<Scalars['Date']>;
  fromCurrencyCode?: InputMaybe<Scalars['String']>;
  toCurrencyCode?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCountryArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCountryByIsoAlpha2Args = {
  isoAlpha2: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrencyArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrencyByCodeArgs = {
  code: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrencyByCountryArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrencyByCountryByCountryCodeAndCurrencyCodeArgs = {
  countryCode: Scalars['String'];
  currencyCode: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDecodeHashidArgs = {
  hashid: Scalars['String'];
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailAddressArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailAddressByEmailArgs = {
  email: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailAddressByPersonIdAndEmailArgs = {
  email: Scalars['String'];
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEncodeHashidArgs = {
  key: Scalars['BigInt'];
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExchangeRateArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExchangeRateByCurrencyCodeAndDateArgs = {
  currencyCode: Scalars['String'];
  date: Scalars['Date'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExtendedLotteryByIdArgs = {
  id?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryExternalOrganizationArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExternalOrganizationByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExternalOrganizationByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExternalOrganizationByOrganizationSlugArgs = {
  organizationSlug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryExternalOrganizationTypeaheadArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryExternalOrganizationTypeaheadListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFinancialPeriodArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFinancialPeriodByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFinancialPeriodByPersonIdAndPeriodAndCurrencyCodeArgs = {
  currencyCode: Scalars['String'];
  period: DateRangeInput;
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFindPersonByNameOrEmailArgs = {
  _limit?: InputMaybe<Scalars['Int']>;
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFindPersonByNameOrEmailListArgs = {
  _limit?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAddressesByPersonOrTemporaryPersonArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAddressesByPersonOrTemporaryPersonListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetFinancialPeriodsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetFinancialPeriodsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetFundraiserStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fundraiserId?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetFundraiserStatsListArgs = {
  currencyCode?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fundraiserId?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGocardlessMandatesByPersonOrTemporaryPersonArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGocardlessMandatesByPersonOrTemporaryPersonListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetGocardlessRedirectFlowByPersonOrTemporaryPersonArgs = {
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetImpliedExchangeRateArgs = {
  exchangeRateDate?: InputMaybe<Scalars['Date']>;
  fromCurrencyCode?: InputMaybe<Scalars['String']>;
  toCurrencyCode?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetIncomesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetIncomesByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLiabilityCompletionByPledgeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLiabilityCompletionByPledgeListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLiabilityTotalCompletionArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLiabilityTotalCompletionListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLotteryEntriesByLotteryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lotteryId?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLotteryEntriesByLotteryIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  lotteryId?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLotteryEntriesCompleteByLotteryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  lotteryId?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLotteryEntriesCompleteByLotteryIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  lotteryId?: InputMaybe<Scalars['BigInt']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLotterySummaryByLotteryIdArgs = {
  lotteryId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPaymentByPersonOrTemporaryPersonArgs = {
  paymentId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPledgeByPersonOrTemporaryPersonArgs = {
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPledgeByTemporaryPersonArgs = {
  sessionToken: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPledgesByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPledgesByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetRecurringPaymentByPersonOrTemporaryPersonArgs = {
  recurringPaymentId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetRecurringReportedDonationsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetRecurringReportedDonationsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetTotalPledgeSignupsArgs = {
  excludeTrialPledges?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGiftAidClaimArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGiftAidClaimByGiftAidPaymentIdArgs = {
  giftAidPaymentId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGiftAidClaimByPaymentIdArgs = {
  paymentId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGivingWhatWeCanSurveyArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGivingWhatWeCanSurveyByPersonIdArgs = {
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGocardlessMandateByGocardlessMandateIdArgs = {
  gocardlessMandateId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGocardlessRedirectFlowArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGocardlessRedirectFlowByGocardlessRedirectFlowIdArgs = {
  gocardlessRedirectFlowId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGocardlessRedirectFlowByGocardlessRedirectFlowUrlArgs = {
  gocardlessRedirectFlowUrl: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGocardlessRedirectFlowByPersonIdArgs = {
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGocardlessRedirectFlowBySessionTokenArgs = {
  sessionToken: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryHex2BigintArgs = {
  hex?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIncomeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIncomeByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIsValidJsonArgs = {
  pJson: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLiabilityArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLiabilityByPledgeIdAndFinancialPeriodIdArgs = {
  financialPeriodId: Scalars['BigInt'];
  pledgeId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLiabilityPaymentArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLiabilityPaymentByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLiabilityPaymentByPersonIdAndPaymentIdArgs = {
  paymentId: Scalars['BigInt'];
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLiabilityPaymentByPersonIdAndReportedDonationIdArgs = {
  personId: Scalars['BigInt'];
  reportedDonationId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNormalizeAmountToUsdArgs = {
  amount?: InputMaybe<Scalars['BigFloat']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  exchangeRateDate?: InputMaybe<Scalars['Date']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationCategoryArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationCategoryBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentAccessTokenArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentAccessTokenByPaymentIdArgs = {
  paymentId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentAccessTokenByTokenArgs = {
  token: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentByGatewayAndGatewayTransactionIdArgs = {
  gateway: PaymentGateway;
  gatewayTransactionId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentByIdAndPersonIdArgs = {
  id: Scalars['BigInt'];
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentByReferenceArgs = {
  reference: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentRecipientArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentRecipientBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonByAuth0UserIdArgs = {
  auth0UserId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonByEmailArgs = {
  email: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeRankArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPledgeRankByPersonIdAndPledgeTypeArgs = {
  personId: Scalars['BigInt'];
  pledgeType: PledgeType;
};


/** The root query type which gives access points into the data universe. */
export type QueryPollOptionPublicArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPollOptionPublicByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPollOptionPublicVotesByTemporaryPersonArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sessionToken: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPollOptionPublicVotesByTemporaryPersonListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sessionToken: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPollOptionVoteArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPollOptionVoteByPollOptionIdAndPledgeIdArgs = {
  pledgeId: Scalars['BigInt'];
  pollOptionId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPollPublicArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPollPublicByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProgramBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecurringPaymentArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecurringPaymentByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecurringPaymentsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecurringPaymentsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecurringReportedDonationArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRecurringReportedDonationByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReferralSourceArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReferralSourceBySlugArgs = {
  slug: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReportedDonationArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReportedDonationByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReportedDonationsByPersonIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReportedDonationsByPersonIdListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySendReceiptArgs = {
  paymentId?: InputMaybe<Scalars['BigInt']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySlugifyArgs = {
  value: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryValidHrefArgs = {
  href: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryValidRelativeUrlArgs = {
  url: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryValidUrlArgs = {
  url: Scalars['String'];
};

export enum QuestionOrdering {
  Random = 'RANDOM'
}

export enum RecurrencePeriod {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type RecurringPayment = Node & {
  __typename?: 'RecurringPayment';
  /** Reads a single `Address` that is related to this `RecurringPayment`. */
  addressByBillingAddressId?: Maybe<Address>;
  allocation: Scalars['JSON'];
  amount: Scalars['BigFloat'];
  billingAddressId?: Maybe<Scalars['BigInt']>;
  createdAt: Scalars['Datetime'];
  /** Reads a single `Currency` that is related to this `RecurringPayment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode: Scalars['String'];
  gateway: PaymentGateway;
  gatewayData?: Maybe<Scalars['JSON']>;
  id: Scalars['BigInt'];
  metadata?: Maybe<Scalars['JSON']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  paymentMethod?: Maybe<PaymentMethod>;
  /** Reads a single `PaymentRecipient` that is related to this `RecurringPayment`. */
  paymentRecipientByRecipient?: Maybe<PaymentRecipient>;
  period: RecurrencePeriod;
  periodIndex: Scalars['Int'];
  periodInterval: Scalars['Int'];
  /** Reads a single `Person` that is related to this `RecurringPayment`. */
  personByPersonId?: Maybe<Person>;
  personId?: Maybe<Scalars['BigInt']>;
  recipient: Scalars['String'];
  status: RecurringPaymentStatus;
  statusData?: Maybe<Scalars['JSON']>;
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `RecurringPayment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type RecurringPaymentCondition = {
  /** Checks for equality with the object’s `allocation` field. */
  allocation?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `billingAddressId` field. */
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `gateway` field. */
  gateway?: InputMaybe<PaymentGateway>;
  /** Checks for equality with the object’s `gatewayData` field. */
  gatewayData?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `metadata` field. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `paymentMethod` field. */
  paymentMethod?: InputMaybe<PaymentMethod>;
  /** Checks for equality with the object’s `period` field. */
  period?: InputMaybe<RecurrencePeriod>;
  /** Checks for equality with the object’s `periodIndex` field. */
  periodIndex?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `periodInterval` field. */
  periodInterval?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `recipient` field. */
  recipient?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<RecurringPaymentStatus>;
  /** Checks for equality with the object’s `statusData` field. */
  statusData?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export enum RecurringPaymentStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Incomplete = 'INCOMPLETE',
  Pending = 'PENDING',
  Updating = 'UPDATING'
}

/** A connection to a list of `RecurringPayment` values. */
export type RecurringPaymentsConnection = {
  __typename?: 'RecurringPaymentsConnection';
  /** A list of edges which contains the `RecurringPayment` and cursor to aid in pagination. */
  edges: Array<RecurringPaymentsEdge>;
  /** A list of `RecurringPayment` objects. */
  nodes: Array<RecurringPayment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecurringPayment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RecurringPayment` edge in the connection. */
export type RecurringPaymentsEdge = {
  __typename?: 'RecurringPaymentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RecurringPayment` at the end of the edge. */
  node: RecurringPayment;
};

/** Methods to use when ordering `RecurringPayment`. */
export enum RecurringPaymentsOrderBy {
  AllocationAsc = 'ALLOCATION_ASC',
  AllocationDesc = 'ALLOCATION_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  BillingAddressIdAsc = 'BILLING_ADDRESS_ID_ASC',
  BillingAddressIdDesc = 'BILLING_ADDRESS_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  GatewayAsc = 'GATEWAY_ASC',
  GatewayDataAsc = 'GATEWAY_DATA_ASC',
  GatewayDataDesc = 'GATEWAY_DATA_DESC',
  GatewayDesc = 'GATEWAY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MetadataAsc = 'METADATA_ASC',
  MetadataDesc = 'METADATA_DESC',
  Natural = 'NATURAL',
  PaymentMethodAsc = 'PAYMENT_METHOD_ASC',
  PaymentMethodDesc = 'PAYMENT_METHOD_DESC',
  PeriodAsc = 'PERIOD_ASC',
  PeriodDesc = 'PERIOD_DESC',
  PeriodIndexAsc = 'PERIOD_INDEX_ASC',
  PeriodIndexDesc = 'PERIOD_INDEX_DESC',
  PeriodIntervalAsc = 'PERIOD_INTERVAL_ASC',
  PeriodIntervalDesc = 'PERIOD_INTERVAL_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RecipientAsc = 'RECIPIENT_ASC',
  RecipientDesc = 'RECIPIENT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDataAsc = 'STATUS_DATA_ASC',
  StatusDataDesc = 'STATUS_DATA_DESC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type RecurringReportedDonation = Node & {
  __typename?: 'RecurringReportedDonation';
  amount: Scalars['BigFloat'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `Currency` that is related to this `RecurringReportedDonation`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode: Scalars['String'];
  /** Reads a single `ExternalOrganization` that is related to this `RecurringReportedDonation`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  id: Scalars['BigInt'];
  nextDate: Scalars['Date'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  organizationId: Scalars['BigInt'];
  /** Reads a single `Person` that is related to this `RecurringReportedDonation`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  recurrenceInterval: Interval;
  startDate: Scalars['Date'];
  status: RecurringPaymentStatus;
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `RecurringReportedDonation` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type RecurringReportedDonationCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `nextDate` field. */
  nextDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `recurrenceInterval` field. */
  recurrenceInterval?: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<RecurringPaymentStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `RecurringReportedDonation` values. */
export type RecurringReportedDonationsConnection = {
  __typename?: 'RecurringReportedDonationsConnection';
  /** A list of edges which contains the `RecurringReportedDonation` and cursor to aid in pagination. */
  edges: Array<RecurringReportedDonationsEdge>;
  /** A list of `RecurringReportedDonation` objects. */
  nodes: Array<RecurringReportedDonation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RecurringReportedDonation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RecurringReportedDonation` edge in the connection. */
export type RecurringReportedDonationsEdge = {
  __typename?: 'RecurringReportedDonationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RecurringReportedDonation` at the end of the edge. */
  node: RecurringReportedDonation;
};

/** Methods to use when ordering `RecurringReportedDonation`. */
export enum RecurringReportedDonationsOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  NextDateAsc = 'NEXT_DATE_ASC',
  NextDateDesc = 'NEXT_DATE_DESC',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RecurrenceIntervalAsc = 'RECURRENCE_INTERVAL_ASC',
  RecurrenceIntervalDesc = 'RECURRENCE_INTERVAL_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type ReferralSource = Node & {
  __typename?: 'ReferralSource';
  description: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  slug: Scalars['String'];
  sortOrder: Scalars['Int'];
};

/**
 * A condition to be used against `ReferralSource` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ReferralSourceCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `ReferralSource` values. */
export type ReferralSourcesConnection = {
  __typename?: 'ReferralSourcesConnection';
  /** A list of edges which contains the `ReferralSource` and cursor to aid in pagination. */
  edges: Array<ReferralSourcesEdge>;
  /** A list of `ReferralSource` objects. */
  nodes: Array<ReferralSource>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReferralSource` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReferralSource` edge in the connection. */
export type ReferralSourcesEdge = {
  __typename?: 'ReferralSourcesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReferralSource` at the end of the edge. */
  node: ReferralSource;
};

/** Methods to use when ordering `ReferralSource`. */
export enum ReferralSourcesOrderBy {
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC'
}

export type Reminder = Node & {
  __typename?: 'Reminder';
  createdAt: Scalars['Datetime'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['BigInt'];
  newsletterSignup: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  remindedAt?: Maybe<Scalars['Datetime']>;
  reminderDate: Scalars['Date'];
  reminderSourcePath?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
};

export type ReportedDonation = Node & {
  __typename?: 'ReportedDonation';
  amount?: Maybe<Scalars['BigFloat']>;
  amountNormalized?: Maybe<Scalars['BigFloat']>;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByReportedDonationId: CompletionPaymentsConnection;
  /** Reads and enables pagination through a set of `CompletionPayment`. */
  completionPaymentsByReportedDonationIdList: Array<CompletionPayment>;
  createdAt: Scalars['Datetime'];
  /** Reads a single `Currency` that is related to this `ReportedDonation`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  currencyCode?: Maybe<Scalars['String']>;
  donationDate?: Maybe<Scalars['Date']>;
  /** Reads a single `ExternalOrganization` that is related to this `ReportedDonation`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  id: Scalars['BigInt'];
  /** Reads and enables pagination through a set of `LiabilityPayment`. */
  liabilityPaymentsByReportedDonationId: LiabilityPaymentsConnection;
  /** Reads and enables pagination through a set of `LiabilityPayment`. */
  liabilityPaymentsByReportedDonationIdList: Array<LiabilityPayment>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  organizationId: Scalars['BigInt'];
  /** Reads a single `Person` that is related to this `ReportedDonation`. */
  personByPersonId?: Maybe<Person>;
  personId: Scalars['BigInt'];
  recurrence: PaymentRecurrence;
  updatedAt: Scalars['Datetime'];
};


export type ReportedDonationCompletionPaymentsByReportedDonationIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type ReportedDonationCompletionPaymentsByReportedDonationIdListArgs = {
  condition?: InputMaybe<CompletionPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CompletionPaymentsOrderBy>>;
};


export type ReportedDonationLiabilityPaymentsByReportedDonationIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LiabilityPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilityPaymentsOrderBy>>;
};


export type ReportedDonationLiabilityPaymentsByReportedDonationIdListArgs = {
  condition?: InputMaybe<LiabilityPaymentCondition>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LiabilityPaymentsOrderBy>>;
};

/**
 * A condition to be used against `ReportedDonation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ReportedDonationCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `amountNormalized` field. */
  amountNormalized?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `donationDate` field. */
  donationDate?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `personId` field. */
  personId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `recurrence` field. */
  recurrence?: InputMaybe<PaymentRecurrence>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `ReportedDonation` values. */
export type ReportedDonationsConnection = {
  __typename?: 'ReportedDonationsConnection';
  /** A list of edges which contains the `ReportedDonation` and cursor to aid in pagination. */
  edges: Array<ReportedDonationsEdge>;
  /** A list of `ReportedDonation` objects. */
  nodes: Array<ReportedDonation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReportedDonation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReportedDonation` edge in the connection. */
export type ReportedDonationsEdge = {
  __typename?: 'ReportedDonationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReportedDonation` at the end of the edge. */
  node: ReportedDonation;
};

/** Methods to use when ordering `ReportedDonation`. */
export enum ReportedDonationsOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  AmountNormalizedAsc = 'AMOUNT_NORMALIZED_ASC',
  AmountNormalizedDesc = 'AMOUNT_NORMALIZED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  DonationDateAsc = 'DONATION_DATE_ASC',
  DonationDateDesc = 'DONATION_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PersonIdAsc = 'PERSON_ID_ASC',
  PersonIdDesc = 'PERSON_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RecurrenceAsc = 'RECURRENCE_ASC',
  RecurrenceDesc = 'RECURRENCE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** All input for the `signPledgeByTemporaryPerson` mutation. */
export type SignPledgeByTemporaryPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  incomeAmount?: InputMaybe<Scalars['BigFloat']>;
  incomePercentage?: InputMaybe<Scalars['BigFloat']>;
  locale?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  motivation?: InputMaybe<Scalars['String']>;
  personBirthDate?: InputMaybe<Scalars['Date']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personLastName?: InputMaybe<Scalars['String']>;
  pledgePeriod?: InputMaybe<DateRangeInput>;
  pledgeType?: InputMaybe<PledgeType>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
  wealthPercentage?: InputMaybe<Scalars['BigFloat']>;
};

/** The output of our `signPledgeByTemporaryPerson` mutation. */
export type SignPledgeByTemporaryPersonPayload = {
  __typename?: 'SignPledgeByTemporaryPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Pledge`. */
  personByPersonId?: Maybe<Person>;
  pledge?: Maybe<Pledge>;
  /** An edge for our `Pledge`. May be used by Relay 1. */
  pledgeEdge?: Maybe<PledgesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `signPledgeByTemporaryPerson` mutation. */
export type SignPledgeByTemporaryPersonPayloadPledgeEdgeArgs = {
  orderBy?: InputMaybe<Array<PledgesOrderBy>>;
};

/** All input for the `signPledge` mutation. */
export type SignPledgeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  incomeAmount?: InputMaybe<Scalars['BigFloat']>;
  incomePercentage?: InputMaybe<Scalars['BigFloat']>;
  locale?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  motivation?: InputMaybe<Scalars['String']>;
  personBirthDate?: InputMaybe<Scalars['Date']>;
  personFirstName?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  personLastName?: InputMaybe<Scalars['String']>;
  pledgePeriod?: InputMaybe<DateRangeInput>;
  pledgeType?: InputMaybe<PledgeType>;
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
  wealthPercentage?: InputMaybe<Scalars['BigFloat']>;
};

/** The output of our `signPledge` mutation. */
export type SignPledgePayload = {
  __typename?: 'SignPledgePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Pledge`. */
  personByPersonId?: Maybe<Person>;
  pledge?: Maybe<Pledge>;
  /** An edge for our `Pledge`. May be used by Relay 1. */
  pledgeEdge?: Maybe<PledgesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `signPledge` mutation. */
export type SignPledgePayloadPledgeEdgeArgs = {
  orderBy?: InputMaybe<Array<PledgesOrderBy>>;
};

/** All input for the `syncAuth0User` mutation. */
export type SyncAuth0UserInput = {
  auth0User?: InputMaybe<Scalars['JSON']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `syncAuth0User` mutation. */
export type SyncAuth0UserPayload = {
  __typename?: 'SyncAuth0UserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EmailAddress` that is related to this `Person`. */
  emailAddressByIdAndEmail?: Maybe<EmailAddress>;
  person?: Maybe<Person>;
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `syncAuth0User` mutation. */
export type SyncAuth0UserPayloadPersonEdgeArgs = {
  orderBy?: InputMaybe<Array<PeopleOrderBy>>;
};

export type TemporaryPerson = Node & {
  __typename?: 'TemporaryPerson';
  email: Scalars['String'];
  /** Reads a single `EmailAddress` that is related to this `TemporaryPerson`. */
  emailAddressByEmail?: Maybe<EmailAddress>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  sessionToken: Scalars['UUID'];
  tokenExpiresAt: Scalars['Datetime'];
  tokenIssuedAt: Scalars['Datetime'];
};

/** All input for the `updateContactPreferenceByPersonId` mutation. */
export type UpdateContactPreferenceByPersonIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currentAddressId?: InputMaybe<Scalars['BigInt']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `updateContactPreferenceByPersonId` mutation. */
export type UpdateContactPreferenceByPersonIdPayload = {
  __typename?: 'UpdateContactPreferenceByPersonIdPayload';
  /** Reads a single `Address` that is related to this `ContactPreference`. */
  addressByCurrentAddressId?: Maybe<Address>;
  /** Reads a single `Address` that is related to this `ContactPreference`. */
  addressByPersonIdAndCurrentAddressId?: Maybe<Address>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  contactPreference?: Maybe<ContactPreference>;
  /** An edge for our `ContactPreference`. May be used by Relay 1. */
  contactPreferenceEdge?: Maybe<ContactPreferencesEdge>;
  /** Reads a single `Person` that is related to this `ContactPreference`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `updateContactPreferenceByPersonId` mutation. */
export type UpdateContactPreferenceByPersonIdPayloadContactPreferenceEdgeArgs = {
  orderBy?: InputMaybe<Array<ContactPreferencesOrderBy>>;
};

/** All input for the `updateFinancialPeriod` mutation. */
export type UpdateFinancialPeriodInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  donationsTaxDeductible?: InputMaybe<Scalars['Boolean']>;
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  id?: InputMaybe<Scalars['BigInt']>;
  incomes?: InputMaybe<Array<InputMaybe<IncomeInput>>>;
  period?: InputMaybe<DateRangeInput>;
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
};

/** The output of our `updateFinancialPeriod` mutation. */
export type UpdateFinancialPeriodPayload = {
  __typename?: 'UpdateFinancialPeriodPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  financialPeriod?: Maybe<FinancialPeriod>;
  /** An edge for our `FinancialPeriod`. May be used by Relay 1. */
  financialPeriodEdge?: Maybe<FinancialPeriodsEdge>;
  /** Reads a single `Person` that is related to this `FinancialPeriod`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `updateFinancialPeriod` mutation. */
export type UpdateFinancialPeriodPayloadFinancialPeriodEdgeArgs = {
  orderBy?: InputMaybe<Array<FinancialPeriodsOrderBy>>;
};

/** All input for the `updatePersonByPersonId` mutation. */
export type UpdatePersonByPersonIdInput = {
  birthDate?: InputMaybe<Scalars['Date']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `updatePersonByPersonId` mutation. */
export type UpdatePersonByPersonIdPayload = {
  __typename?: 'UpdatePersonByPersonIdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `EmailAddress` that is related to this `Person`. */
  emailAddressByIdAndEmail?: Maybe<EmailAddress>;
  person?: Maybe<Person>;
  /** An edge for our `Person`. May be used by Relay 1. */
  personEdge?: Maybe<PeopleEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `updatePersonByPersonId` mutation. */
export type UpdatePersonByPersonIdPayloadPersonEdgeArgs = {
  orderBy?: InputMaybe<Array<PeopleOrderBy>>;
};

/** All input for the `updatePersonEmailAuth0` mutation. */
export type UpdatePersonEmailAuth0Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  personId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `updatePersonEmailAuth0` mutation. */
export type UpdatePersonEmailAuth0Payload = {
  __typename?: 'UpdatePersonEmailAuth0Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']>;
};

/** All input for the `updatePledgeByPersonOrTemporaryPerson` mutation. */
export type UpdatePledgeByPersonOrTemporaryPersonInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `updatePledgeByPersonOrTemporaryPerson` mutation. */
export type UpdatePledgeByPersonOrTemporaryPersonPayload = {
  __typename?: 'UpdatePledgeByPersonOrTemporaryPersonPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Pledge`. */
  personByPersonId?: Maybe<Person>;
  pledge?: Maybe<Pledge>;
  /** An edge for our `Pledge`. May be used by Relay 1. */
  pledgeEdge?: Maybe<PledgesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `updatePledgeByPersonOrTemporaryPerson` mutation. */
export type UpdatePledgeByPersonOrTemporaryPersonPayloadPledgeEdgeArgs = {
  orderBy?: InputMaybe<Array<PledgesOrderBy>>;
};

/** All input for the `updateRecurringPaymentAmount` mutation. */
export type UpdateRecurringPaymentAmountInput = {
  amount: Scalars['BigFloat'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  recurringPaymentId: Scalars['BigInt'];
};

/** The output of our `updateRecurringPaymentAmount` mutation. */
export type UpdateRecurringPaymentAmountPayload = {
  __typename?: 'UpdateRecurringPaymentAmountPayload';
  /** Reads a single `Address` that is related to this `RecurringPayment`. */
  addressByBillingAddressId?: Maybe<Address>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `RecurringPayment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `PaymentRecipient` that is related to this `RecurringPayment`. */
  paymentRecipientByRecipient?: Maybe<PaymentRecipient>;
  /** Reads a single `Person` that is related to this `RecurringPayment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  recurringPayment?: Maybe<RecurringPayment>;
  /** An edge for our `RecurringPayment`. May be used by Relay 1. */
  recurringPaymentEdge?: Maybe<RecurringPaymentsEdge>;
};


/** The output of our `updateRecurringPaymentAmount` mutation. */
export type UpdateRecurringPaymentAmountPayloadRecurringPaymentEdgeArgs = {
  orderBy?: InputMaybe<Array<RecurringPaymentsOrderBy>>;
};

/** All input for the `updateReportedDonation` mutation. */
export type UpdateReportedDonationInput = {
  amount?: InputMaybe<Scalars['BigFloat']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  donationDate?: InputMaybe<Scalars['Date']>;
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  organizationId?: InputMaybe<Scalars['BigInt']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `updateReportedDonation` mutation. */
export type UpdateReportedDonationPayload = {
  __typename?: 'UpdateReportedDonationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `ReportedDonation`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  /** Reads a single `ExternalOrganization` that is related to this `ReportedDonation`. */
  externalOrganizationByOrganizationId?: Maybe<ExternalOrganization>;
  /** Reads a single `Person` that is related to this `ReportedDonation`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  reportedDonation?: Maybe<ReportedDonation>;
  /** An edge for our `ReportedDonation`. May be used by Relay 1. */
  reportedDonationEdge?: Maybe<ReportedDonationsEdge>;
};


/** The output of our `updateReportedDonation` mutation. */
export type UpdateReportedDonationPayloadReportedDonationEdgeArgs = {
  orderBy?: InputMaybe<Array<ReportedDonationsOrderBy>>;
};

/** All input for the `updateReportedPayment` mutation. */
export type UpdateReportedPaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['BigInt']>;
  personId?: InputMaybe<Scalars['BigInt']>;
  pledgeId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `updateReportedPayment` mutation. */
export type UpdateReportedPaymentPayload = {
  __typename?: 'UpdateReportedPaymentPayload';
  /** Reads a single `Address` that is related to this `Payment`. */
  addressByBillingAddressId?: Maybe<Address>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByChargeCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  payment?: Maybe<Payment>;
  /** An edge for our `Payment`. May be used by Relay 1. */
  paymentEdge?: Maybe<PaymentsEdge>;
  /** Reads a single `PaymentRecipient` that is related to this `Payment`. */
  paymentRecipientByRecipient?: Maybe<PaymentRecipient>;
  /** Reads a single `Person` that is related to this `Payment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `updateReportedPayment` mutation. */
export type UpdateReportedPaymentPayloadPaymentEdgeArgs = {
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};

/** All input for the `verifyEmail` mutation. */
export type VerifyEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  pledgeId: Scalars['BigInt'];
  token: Scalars['UUID'];
};

/** The output of our `verifyEmail` mutation. */
export type VerifyEmailPayload = {
  __typename?: 'VerifyEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Person` that is related to this `Pledge`. */
  personByPersonId?: Maybe<Person>;
  pledge?: Maybe<Pledge>;
  /** An edge for our `Pledge`. May be used by Relay 1. */
  pledgeEdge?: Maybe<PledgesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `verifyEmail` mutation. */
export type VerifyEmailPayloadPledgeEdgeArgs = {
  orderBy?: InputMaybe<Array<PledgesOrderBy>>;
};

/** All input for the `voidPayment` mutation. */
export type VoidPaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentId?: InputMaybe<Scalars['BigInt']>;
};

/** The output of our `voidPayment` mutation. */
export type VoidPaymentPayload = {
  __typename?: 'VoidPaymentPayload';
  /** Reads a single `Address` that is related to this `Payment`. */
  addressByBillingAddressId?: Maybe<Address>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByChargeCurrencyCode?: Maybe<Currency>;
  /** Reads a single `Currency` that is related to this `Payment`. */
  currencyByCurrencyCode?: Maybe<Currency>;
  payment?: Maybe<Payment>;
  /** An edge for our `Payment`. May be used by Relay 1. */
  paymentEdge?: Maybe<PaymentsEdge>;
  /** Reads a single `PaymentRecipient` that is related to this `Payment`. */
  paymentRecipientByRecipient?: Maybe<PaymentRecipient>;
  /** Reads a single `Person` that is related to this `Payment`. */
  personByPersonId?: Maybe<Person>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our `voidPayment` mutation. */
export type VoidPaymentPayloadPaymentEdgeArgs = {
  orderBy?: InputMaybe<Array<PaymentsOrderBy>>;
};

export enum VoteMechanism {
  Multiple = 'MULTIPLE'
}

export type CreateExternalOrganizationMutationVariables = Exact<{
  name: Scalars['String'];
  organizationSlug?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
  keywords?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type CreateExternalOrganizationMutation = { __typename?: 'Mutation', createExternalOrganization?: { __typename?: 'CreateExternalOrganizationPayload', externalOrganization?: { __typename?: 'ExternalOrganization', id: string, name: string, keywords?: Array<string | null | undefined> | null | undefined, organizationSlug?: string | null | undefined, url?: string | null | undefined } | null | undefined } | null | undefined };

export type GetExternalOrganizationTypeaheadQueryVariables = Exact<{
  searchText: Scalars['String'];
}>;


export type GetExternalOrganizationTypeaheadQuery = { __typename?: 'Query', ExternalOrganizations?: { __typename?: 'ExternalOrganizationsConnection', edges: Array<{ __typename?: 'ExternalOrganizationsEdge', node: { __typename?: 'ExternalOrganization', id: string, name: string, organizationSlug?: string | null | undefined, url?: string | null | undefined } }> } | null | undefined };

export type GetExternalOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExternalOrganizationsQuery = { __typename?: 'Query', ExternalOrganizations?: { __typename?: 'ExternalOrganizationsConnection', edges: Array<{ __typename?: 'ExternalOrganizationsEdge', node: { __typename?: 'ExternalOrganization', id: string, name: string, organizationSlug?: string | null | undefined, url?: string | null | undefined } }> } | null | undefined };

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationsQuery = { __typename?: 'Query', Organizations?: { __typename?: 'ProgramDetailsLegaciesConnection', edges: Array<{ __typename?: 'ProgramDetailsLegaciesEdge', node: { __typename?: 'ProgramDetailsLegacy', slug: string, name: string, program: string, type?: ProgramType | null | undefined, active?: boolean | null | undefined, hidden?: boolean | null | undefined, href?: string | null | undefined, isDataSharingPartner?: boolean | null | undefined } }> } | null | undefined };

export type CancelRecurringPaymentMutationVariables = Exact<{
  recurringPaymentId: Scalars['BigInt'];
}>;


export type CancelRecurringPaymentMutation = { __typename?: 'Mutation', cancelRecurringPayment?: { __typename?: 'CancelRecurringPaymentPayload', clientMutationId?: string | null | undefined } | null | undefined };

export type CreateGiftAidClaimMutationVariables = Exact<{
  paymentId: Scalars['BigInt'];
  addressId: Scalars['BigInt'];
}>;


export type CreateGiftAidClaimMutation = { __typename?: 'Mutation', createGiftAidClaimFromPayment?: { __typename?: 'CreateGiftAidClaimFromPaymentPayload', giftAidClaim?: { __typename?: 'GiftAidClaim', paymentId: string, personId: string, addressId: string, reportId?: number | null | undefined } | null | undefined } | null | undefined };

export type CreateGocardlessRedirectFlowMutationVariables = Exact<{
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type CreateGocardlessRedirectFlowMutation = { __typename?: 'Mutation', mutationResult?: { __typename?: 'CreateGocardlessRedirectFlowByPersonOrTemporaryPersonPayload', RedirectFlow?: { __typename?: 'GocardlessRedirectFlow', personId: string, sessionToken: string, gocardlessRedirectFlowId?: string | null | undefined, gocardlessRedirectFlowUrl?: string | null | undefined, expires: string, createdAt: string, updatedAt: string } | null | undefined } | null | undefined };

export type CreatePaymentMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  currencyCode: Scalars['String'];
  amount: Scalars['BigFloat'];
  recipient: Scalars['String'];
  allocation: Scalars['JSON'];
  gateway: PaymentGateway;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  paymentMethod: PaymentMethod;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  status?: InputMaybe<PaymentStatus>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
}>;


export type CreatePaymentMutation = { __typename?: 'Mutation', mutationResult?: { __typename?: 'CreatePaymentByPersonIdPayload', payment?: { __typename?: 'Payment', id: string, personId: string, chargeCurrencyCode: string, chargeAmount: string, currencyCode?: string | null | undefined, amount?: string | null | undefined, amountNormalized?: string | null | undefined, net?: string | null | undefined, fee?: string | null | undefined, recipient: string, allocation: unknown, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, gatewayTransactionId?: string | null | undefined, paymentMethod?: PaymentMethod | null | undefined, recurrence: PaymentRecurrence, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: PaymentStatus, statusData?: unknown | null | undefined, reference: string, createdAt: string, updatedAt: string } | null | undefined } | null | undefined };

export type CreatePaymentByTemporaryPersonMutationVariables = Exact<{
  sessionToken: Scalars['UUID'];
  currencyCode: Scalars['String'];
  amount: Scalars['BigFloat'];
  recipient: Scalars['String'];
  allocation: Scalars['JSON'];
  gateway: PaymentGateway;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  paymentMethod: PaymentMethod;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  metadata?: InputMaybe<Scalars['JSON']>;
}>;


export type CreatePaymentByTemporaryPersonMutation = { __typename?: 'Mutation', mutationResult?: { __typename?: 'CreatePaymentByTemporaryPersonPayload', payment?: { __typename?: 'Payment', id: string, personId: string, chargeCurrencyCode: string, chargeAmount: string, currencyCode?: string | null | undefined, amount?: string | null | undefined, amountNormalized?: string | null | undefined, net?: string | null | undefined, fee?: string | null | undefined, recipient: string, allocation: unknown, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, gatewayTransactionId?: string | null | undefined, paymentMethod?: PaymentMethod | null | undefined, recurrence: PaymentRecurrence, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: PaymentStatus, statusData?: unknown | null | undefined, reference: string, createdAt: string, updatedAt: string } | null | undefined } | null | undefined };

export type CreateRecurringPaymentMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  period: RecurrencePeriod;
  periodIndex: Scalars['Int'];
  allocation: Scalars['JSON'];
  amount: Scalars['BigFloat'];
  currencyCode: Scalars['String'];
  recipient: Scalars['String'];
  gateway: PaymentGateway;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  paymentMethod: PaymentMethod;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  metadata?: InputMaybe<Scalars['JSON']>;
}>;


export type CreateRecurringPaymentMutation = { __typename?: 'Mutation', mutationResult?: { __typename?: 'CreateRecurringPaymentPayload', recurringPayment?: { __typename?: 'RecurringPayment', id: string, personId?: string | null | undefined, period: RecurrencePeriod, periodIndex: number, periodInterval: number, allocation: unknown, amount: string, currencyCode: string, recipient: string, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, paymentMethod?: PaymentMethod | null | undefined, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: RecurringPaymentStatus, statusData?: unknown | null | undefined, createdAt: string, updatedAt: string } | null | undefined } | null | undefined };

export type CreateRecurringPaymentByTemporaryPersonMutationVariables = Exact<{
  sessionToken: Scalars['UUID'];
  period: RecurrencePeriod;
  periodIndex: Scalars['Int'];
  allocation: Scalars['JSON'];
  amount: Scalars['BigFloat'];
  currencyCode: Scalars['String'];
  recipient: Scalars['String'];
  gateway: PaymentGateway;
  gatewayData?: InputMaybe<Scalars['JSON']>;
  paymentMethod: PaymentMethod;
  billingAddressId?: InputMaybe<Scalars['BigInt']>;
  metadata?: InputMaybe<Scalars['JSON']>;
}>;


export type CreateRecurringPaymentByTemporaryPersonMutation = { __typename?: 'Mutation', mutationResult?: { __typename?: 'CreateRecurringPaymentByTemporaryPersonPayload', recurringPayment?: { __typename?: 'RecurringPayment', id: string, personId?: string | null | undefined, period: RecurrencePeriod, periodIndex: number, periodInterval: number, allocation: unknown, amount: string, currencyCode: string, recipient: string, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, paymentMethod?: PaymentMethod | null | undefined, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: RecurringPaymentStatus, statusData?: unknown | null | undefined, createdAt: string, updatedAt: string } | null | undefined } | null | undefined };

export type DeleteGiftAidClaimMutationVariables = Exact<{
  paymentId: Scalars['BigInt'];
}>;


export type DeleteGiftAidClaimMutation = { __typename?: 'Mutation', GiftAidClaim?: { __typename?: 'DeleteGiftAidClaimFromPaymentPayload', giftAidClaim?: { __typename?: 'GiftAidClaim', paymentId: string, personId: string, addressId: string, reportId?: number | null | undefined } | null | undefined } | null | undefined };

export type UpdateRecurringPaymentAmountMutationVariables = Exact<{
  recurringPaymentId: Scalars['BigInt'];
  amount: Scalars['BigFloat'];
}>;


export type UpdateRecurringPaymentAmountMutation = { __typename?: 'Mutation', updateRecurringPaymentAmount?: { __typename?: 'UpdateRecurringPaymentAmountPayload', clientMutationId?: string | null | undefined } | null | undefined };

export type VoidPaymentMutationVariables = Exact<{
  paymentId: Scalars['BigInt'];
}>;


export type VoidPaymentMutation = { __typename?: 'Mutation', voidPayment?: { __typename?: 'VoidPaymentPayload', payment?: { __typename?: 'Payment', id: string, status: PaymentStatus } | null | undefined } | null | undefined };

export type LotteryFieldsFragment = { __typename?: 'ExtendedLottery', id?: string | null | undefined, blockSize?: string | null | undefined, closeTimestamp?: string | null | undefined, lockTimestamp?: string | null | undefined, drawTimestamp?: string | null | undefined, drawEpoch?: number | null | undefined, status?: string | null | undefined, winningNumber?: string | null | undefined };

export type LotterySummaryFieldsFragment = { __typename?: 'LotterySummary', id?: string | null | undefined, blockSize?: string | null | undefined, closeTimestamp?: string | null | undefined, lockTimestamp?: string | null | undefined, drawTimestamp?: string | null | undefined, winningNumber?: string | null | undefined, winningNumberHex?: string | null | undefined, drawEpoch?: string | null | undefined, status?: string | null | undefined, ticketsAssigned?: boolean | null | undefined, maxBlockId?: number | null | undefined, numEntries?: number | null | undefined, entriesTotalNormalized?: string | null | undefined, benefactorLiability?: string | null | undefined, benefactorStartingTicketNumber?: string | null | undefined, benefactorWinsLastBlock?: boolean | null | undefined, winningTickets?: unknown | null | undefined };

export type PaymentFieldsFragment = { __typename?: 'Payment', id: string, personId: string, chargeCurrencyCode: string, chargeAmount: string, currencyCode?: string | null | undefined, amount?: string | null | undefined, amountNormalized?: string | null | undefined, net?: string | null | undefined, fee?: string | null | undefined, recipient: string, allocation: unknown, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, gatewayTransactionId?: string | null | undefined, paymentMethod?: PaymentMethod | null | undefined, recurrence: PaymentRecurrence, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: PaymentStatus, statusData?: unknown | null | undefined, reference: string, createdAt: string, updatedAt: string };

export type RecurringPaymentFieldsFragment = { __typename?: 'RecurringPayment', id: string, personId?: string | null | undefined, period: RecurrencePeriod, periodIndex: number, periodInterval: number, allocation: unknown, amount: string, currencyCode: string, recipient: string, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, paymentMethod?: PaymentMethod | null | undefined, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: RecurringPaymentStatus, statusData?: unknown | null | undefined, createdAt: string, updatedAt: string };

export type GetAllRecipientProgramsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRecipientProgramsQuery = { __typename?: 'Query', RecipientPrograms?: Array<{ __typename?: 'ActiveRecipientProgram', organizationSlug: string, recipientSlug: string }> | null | undefined };

export type GetFundraiserStatsQueryVariables = Exact<{
  fundraiserId: Scalars['String'];
  currencyCode: Scalars['String'];
}>;


export type GetFundraiserStatsQuery = { __typename?: 'Query', FundraiserStats?: { __typename?: 'FundraiserStatsConnection', edges: Array<{ __typename?: 'FundraiserStatsEdge', node: { __typename?: 'FundraiserStat', fundraiserId?: string | null | undefined, amountRaisedNormalized?: string | null | undefined, numDonors?: string | null | undefined } }> } | null | undefined };

export type GetGocardlessMandatesQueryVariables = Exact<{
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetGocardlessMandatesQuery = { __typename?: 'Query', gocardlessMandates?: { __typename?: 'GocardlessMandatesConnection', edges: Array<{ __typename?: 'GocardlessMandatesEdge', node: { __typename?: 'GocardlessMandate', personId: string, gocardlessCustomerId: string, gocardlessMandateId: string, status: GocardlessMandateStatus, gocardlessBankAccountId?: string | null | undefined, gocardlessBankAccountHolderName?: string | null | undefined, gocardlessBankAccountBankName?: string | null | undefined, gocardlessBankAccountNumberEnding?: number | null | undefined } }> } | null | undefined };

export type GetGocardlessRedirectFlowQueryVariables = Exact<{
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetGocardlessRedirectFlowQuery = { __typename?: 'Query', GocardlessRedirectFlow?: { __typename?: 'GocardlessRedirectFlow', personId: string, sessionToken: string, gocardlessRedirectFlowId?: string | null | undefined, gocardlessRedirectFlowUrl?: string | null | undefined, expires: string, createdAt: string, updatedAt: string } | null | undefined };

export type GetLotteryByIdQueryVariables = Exact<{
  lotteryId: Scalars['BigInt'];
}>;


export type GetLotteryByIdQuery = { __typename?: 'Query', Lottery?: { __typename?: 'ExtendedLottery', id?: string | null | undefined, blockSize?: string | null | undefined, closeTimestamp?: string | null | undefined, lockTimestamp?: string | null | undefined, drawTimestamp?: string | null | undefined, drawEpoch?: number | null | undefined, status?: string | null | undefined, winningNumber?: string | null | undefined } | null | undefined };

export type GetLotteryEntriesCompleteQueryVariables = Exact<{
  lotteryId: Scalars['BigInt'];
}>;


export type GetLotteryEntriesCompleteQuery = { __typename?: 'Query', LotteryEntriesComplete?: { __typename?: 'LotteryEntryCompletesConnection', edges: Array<{ __typename?: 'LotteryEntryCompletesEdge', node: { __typename?: 'LotteryEntryComplete', lotteryId?: string | null | undefined, paymentReference?: string | null | undefined, amountNormalized?: string | null | undefined, displayName?: string | null | undefined, tickets?: unknown | null | undefined, createdAt?: string | null | undefined, benefactor?: boolean | null | undefined } }> } | null | undefined };

export type GetLotteryEntriesPublicByLotteryIdQueryVariables = Exact<{
  lotteryId: Scalars['BigInt'];
}>;


export type GetLotteryEntriesPublicByLotteryIdQuery = { __typename?: 'Query', LotteryEntriesPublic?: { __typename?: 'LotteryEntryPublicsConnection', edges: Array<{ __typename?: 'LotteryEntryPublicsEdge', node: { __typename?: 'LotteryEntryPublic', lotteryId?: string | null | undefined, paymentReference?: string | null | undefined, amount?: string | null | undefined, currencyCode?: string | null | undefined, amountNormalized?: string | null | undefined, giftAid?: string | null | undefined, total?: string | null | undefined, displayName?: string | null | undefined, createdAt?: string | null | undefined } }> } | null | undefined };

export type GetLotterySummariesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLotterySummariesQuery = { __typename?: 'Query', Lotteries?: { __typename?: 'LotterySummariesConnection', edges: Array<{ __typename?: 'LotterySummariesEdge', node: { __typename?: 'LotterySummary', id?: string | null | undefined, blockSize?: string | null | undefined, closeTimestamp?: string | null | undefined, lockTimestamp?: string | null | undefined, drawTimestamp?: string | null | undefined, winningNumber?: string | null | undefined, winningNumberHex?: string | null | undefined, drawEpoch?: string | null | undefined, status?: string | null | undefined, ticketsAssigned?: boolean | null | undefined, maxBlockId?: number | null | undefined, numEntries?: number | null | undefined, entriesTotalNormalized?: string | null | undefined, benefactorLiability?: string | null | undefined, benefactorStartingTicketNumber?: string | null | undefined, benefactorWinsLastBlock?: boolean | null | undefined, winningTickets?: unknown | null | undefined } }> } | null | undefined };

export type GetLotterySummaryQueryVariables = Exact<{
  lotteryId: Scalars['BigInt'];
}>;


export type GetLotterySummaryQuery = { __typename?: 'Query', LotterySummary?: { __typename?: 'LotterySummary', id?: string | null | undefined, blockSize?: string | null | undefined, closeTimestamp?: string | null | undefined, lockTimestamp?: string | null | undefined, drawTimestamp?: string | null | undefined, winningNumber?: string | null | undefined, winningNumberHex?: string | null | undefined, drawEpoch?: string | null | undefined, status?: string | null | undefined, ticketsAssigned?: boolean | null | undefined, maxBlockId?: number | null | undefined, numEntries?: number | null | undefined, entriesTotalNormalized?: string | null | undefined, benefactorLiability?: string | null | undefined, benefactorStartingTicketNumber?: string | null | undefined, benefactorWinsLastBlock?: boolean | null | undefined, winningTickets?: unknown | null | undefined } | null | undefined };

export type GetLotteryTicketsPublicByLotteryIdQueryVariables = Exact<{
  lotteryId: Scalars['BigInt'];
}>;


export type GetLotteryTicketsPublicByLotteryIdQuery = { __typename?: 'Query', LotteryTicketsPublic?: { __typename?: 'LotteryTicketPublicsConnection', edges: Array<{ __typename?: 'LotteryTicketPublicsEdge', node: { __typename?: 'LotteryTicketPublic', lotteryId?: string | null | undefined, paymentReference?: string | null | undefined, blockId?: number | null | undefined, benefactor?: boolean | null | undefined, winRange?: { __typename?: 'BigIntRange', start?: { __typename?: 'BigIntRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'BigIntRangeBound', value: string, inclusive: boolean } | null | undefined } | null | undefined } }> } | null | undefined };

export type GetLotteryWinnersQueryVariables = Exact<{
  lotteryId: Scalars['BigInt'];
}>;


export type GetLotteryWinnersQuery = { __typename?: 'Query', LotteryWinners?: { __typename?: 'LotteryWinnersConnection', edges: Array<{ __typename?: 'LotteryWinnersEdge', node: { __typename?: 'LotteryWinner', lotteryId?: string | null | undefined, paymentReference?: string | null | undefined, blockId?: number | null | undefined, benefactor?: boolean | null | undefined } }> } | null | undefined };

export type GetPaymentQueryVariables = Exact<{
  paymentId: Scalars['BigInt'];
}>;


export type GetPaymentQuery = { __typename?: 'Query', Payment?: { __typename?: 'Payment', id: string, personId: string, chargeCurrencyCode: string, chargeAmount: string, currencyCode?: string | null | undefined, amount?: string | null | undefined, amountNormalized?: string | null | undefined, net?: string | null | undefined, fee?: string | null | undefined, recipient: string, allocation: unknown, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, gatewayTransactionId?: string | null | undefined, paymentMethod?: PaymentMethod | null | undefined, recurrence: PaymentRecurrence, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: PaymentStatus, statusData?: unknown | null | undefined, reference: string, createdAt: string, updatedAt: string } | null | undefined };

export type GetPaymentByPersonOrTemporaryPersonQueryVariables = Exact<{
  paymentId: Scalars['BigInt'];
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetPaymentByPersonOrTemporaryPersonQuery = { __typename?: 'Query', Payment?: { __typename?: 'Payment', id: string, personId: string, chargeCurrencyCode: string, chargeAmount: string, currencyCode?: string | null | undefined, amount?: string | null | undefined, amountNormalized?: string | null | undefined, net?: string | null | undefined, fee?: string | null | undefined, recipient: string, allocation: unknown, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, gatewayTransactionId?: string | null | undefined, paymentMethod?: PaymentMethod | null | undefined, recurrence: PaymentRecurrence, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: PaymentStatus, statusData?: unknown | null | undefined, reference: string, createdAt: string, updatedAt: string } | null | undefined };

export type GetPaymentsQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetPaymentsQuery = { __typename?: 'Query', Payments?: { __typename?: 'PaymentsConnection', edges: Array<{ __typename?: 'PaymentsEdge', node: { __typename?: 'Payment', id: string, personId: string, chargeCurrencyCode: string, chargeAmount: string, currencyCode?: string | null | undefined, amount?: string | null | undefined, amountNormalized?: string | null | undefined, net?: string | null | undefined, fee?: string | null | undefined, recipient: string, allocation: unknown, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, gatewayTransactionId?: string | null | undefined, paymentMethod?: PaymentMethod | null | undefined, recurrence: PaymentRecurrence, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: PaymentStatus, statusData?: unknown | null | undefined, reference: string, createdAt: string, updatedAt: string, giftAidClaimsByPaymentIdAndPersonId: { __typename?: 'GiftAidClaimsConnection', edges: Array<{ __typename?: 'GiftAidClaimsEdge', node: { __typename?: 'GiftAidClaim', nodeId: string, paymentId: string, personId: string, addressId: string, reportId?: number | null | undefined } }> } } }> } | null | undefined };

export type GetRecurringPaymentQueryVariables = Exact<{
  id: Scalars['BigInt'];
}>;


export type GetRecurringPaymentQuery = { __typename?: 'Query', recurringPayment?: { __typename?: 'RecurringPayment', id: string, personId?: string | null | undefined, period: RecurrencePeriod, periodIndex: number, periodInterval: number, allocation: unknown, amount: string, currencyCode: string, recipient: string, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, paymentMethod?: PaymentMethod | null | undefined, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: RecurringPaymentStatus, statusData?: unknown | null | undefined, createdAt: string, updatedAt: string } | null | undefined };

export type GetRecurringPaymentByPersonOrTemporaryPersonQueryVariables = Exact<{
  id?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetRecurringPaymentByPersonOrTemporaryPersonQuery = { __typename?: 'Query', recurringPayment?: { __typename?: 'RecurringPayment', id: string, personId?: string | null | undefined, period: RecurrencePeriod, periodIndex: number, periodInterval: number, allocation: unknown, amount: string, currencyCode: string, recipient: string, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, paymentMethod?: PaymentMethod | null | undefined, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: RecurringPaymentStatus, statusData?: unknown | null | undefined, createdAt: string, updatedAt: string } | null | undefined };

export type GetRecurringPaymentsQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetRecurringPaymentsQuery = { __typename?: 'Query', RecurringPayments?: { __typename?: 'RecurringPaymentsConnection', edges: Array<{ __typename?: 'RecurringPaymentsEdge', node: { __typename?: 'RecurringPayment', id: string, personId?: string | null | undefined, period: RecurrencePeriod, periodIndex: number, periodInterval: number, allocation: unknown, amount: string, currencyCode: string, recipient: string, gateway: PaymentGateway, gatewayData?: unknown | null | undefined, paymentMethod?: PaymentMethod | null | undefined, billingAddressId?: string | null | undefined, metadata?: unknown | null | undefined, status: RecurringPaymentStatus, statusData?: unknown | null | undefined, createdAt: string, updatedAt: string } }> } | null | undefined };

export type GetTotalPledgeSignupsQueryVariables = Exact<{
  startDate: Scalars['Date'];
  excludeTrialPledges?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetTotalPledgeSignupsQuery = { __typename?: 'Query', TotalPledgeSignups?: number | null | undefined };

export type SendReceiptQueryVariables = Exact<{
  paymentId: Scalars['BigInt'];
}>;


export type SendReceiptQuery = { __typename?: 'Query', sendReceipt?: string | null | undefined };

export type AddReminderMutationVariables = Exact<{
  reminderDate: Scalars['Date'];
  email: Scalars['String'];
  newsletterSignup: Scalars['Boolean'];
  reminderSourcePath: Scalars['String'];
}>;


export type AddReminderMutation = { __typename?: 'Mutation', addReminder?: { __typename?: 'AddReminderPayload', reminder?: { __typename?: 'Reminder', email: string, reminderDate: string, newsletterSignup: boolean, reminderSourcePath?: string | null | undefined } | null | undefined } | null | undefined };

export type CreateAddressMutationVariables = Exact<{
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
  addressType?: InputMaybe<AddressType>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  googlePlaceId?: InputMaybe<Scalars['String']>;
}>;


export type CreateAddressMutation = { __typename?: 'Mutation', mutationResult?: { __typename?: 'CreateAddressByPersonOrTemporaryPersonPayload', address?: { __typename?: 'Address', id: string, personId: string, addressType?: AddressType | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, houseNumber?: string | null | undefined, addressLine1?: string | null | undefined, addressLine2?: string | null | undefined, city?: string | null | undefined, region?: string | null | undefined, postalCode?: string | null | undefined, countryCode?: string | null | undefined } | null | undefined } | null | undefined };

export type CreateTemporaryPersonMutationVariables = Exact<{
  email: Scalars['String'];
  recaptchaToken: Scalars['String'];
  isLongLasting?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateTemporaryPersonMutation = { __typename?: 'Mutation', createTemporaryPersonByEmail?: { __typename?: 'CreateTemporaryPersonByEmailPayload', TemporaryPerson?: { __typename?: 'TemporaryPerson', email: string, sessionToken: string, tokenIssuedAt: string, tokenExpiresAt: string } | null | undefined } | null | undefined };

export type SyncAuth0UserMutationVariables = Exact<{
  auth0User: Scalars['JSON'];
}>;


export type SyncAuth0UserMutation = { __typename?: 'Mutation', syncAuth0User?: { __typename?: 'SyncAuth0UserPayload', Person?: { __typename?: 'Person', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, fullName?: string | null | undefined, birthDate?: string | null | undefined, createdAt: string, updatedAt: string, isAnonymized: boolean, auth0UserId?: string | null | undefined } | null | undefined } | null | undefined };

export type UpdatePersonByIdMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['Date']>;
}>;


export type UpdatePersonByIdMutation = { __typename?: 'Mutation', updatePersonByPersonId?: { __typename?: 'UpdatePersonByPersonIdPayload', person?: { __typename?: 'Person', id: string, fullName?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, email?: string | null | undefined, birthDate?: string | null | undefined } | null | undefined } | null | undefined };

export type UpdatePersonEmailMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  email: Scalars['String'];
}>;


export type UpdatePersonEmailMutation = { __typename?: 'Mutation', Person?: { __typename?: 'UpdatePersonEmailAuth0Payload', message?: string | null | undefined } | null | undefined };

export type FindPersonByNameOrEmailQueryVariables = Exact<{
  searchText: Scalars['String'];
}>;


export type FindPersonByNameOrEmailQuery = { __typename?: 'Query', People?: { __typename?: 'PeopleConnection', edges: Array<{ __typename?: 'PeopleEdge', node: { __typename?: 'Person', id: string, email?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, fullName?: string | null | undefined } }> } | null | undefined };

export type GetAddressesQueryVariables = Exact<{
  personId?: InputMaybe<Scalars['BigInt']>;
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetAddressesQuery = { __typename?: 'Query', Addresses?: { __typename?: 'AddressesConnection', edges: Array<{ __typename?: 'AddressesEdge', node: { __typename?: 'Address', id: string, personId: string, addressType?: AddressType | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, houseNumber?: string | null | undefined, addressLine1?: string | null | undefined, addressLine2?: string | null | undefined, city?: string | null | undefined, region?: string | null | undefined, postalCode?: string | null | undefined, countryCode?: string | null | undefined, coordinates?: string | null | undefined, createdAt: string, updatedAt: string } }> } | null | undefined };

export type GetPersonQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPersonQuery = { __typename?: 'Query', Person?: { __typename?: 'Person', id: string, email?: string | null | undefined, fullName?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined, birthDate?: string | null | undefined, auth0UserId?: string | null | undefined } | null | undefined };

export type GetPersonByIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetPersonByIdQuery = { __typename?: 'Query', Person?: { __typename?: 'Person', id: string, firstName?: string | null | undefined, lastName?: string | null | undefined, fullName?: string | null | undefined, email?: string | null | undefined, birthDate?: string | null | undefined, createdAt: string, updatedAt: string, auth0UserId?: string | null | undefined } | null | undefined };

export type CancelRecurringReportedDonationMutationVariables = Exact<{
  recurringReportedDonationId: Scalars['BigInt'];
}>;


export type CancelRecurringReportedDonationMutation = { __typename?: 'Mutation', cancelRecurringReportedDonation?: { __typename?: 'CancelRecurringReportedDonationPayload', RecurringReportedDonation?: { __typename?: 'RecurringReportedDonation', id: string } | null | undefined } | null | undefined };

export type CreateFinancialPeriodMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  donationsTaxDeductible?: InputMaybe<Scalars['Boolean']>;
  incomes?: InputMaybe<Array<InputMaybe<IncomeInput>> | InputMaybe<IncomeInput>>;
}>;


export type CreateFinancialPeriodMutation = { __typename?: 'Mutation', createFinancialPeriod?: { __typename?: 'CreateFinancialPeriodPayload', financialPeriod?: { __typename?: 'FinancialPeriod', id: string, personId: string, wealthAmount?: string | null | undefined, currencyCode: string, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined } | null | undefined };

export type CreateRecurringReportedDonationMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  organizationId: Scalars['BigInt'];
  amount: Scalars['BigFloat'];
  currencyCode: Scalars['String'];
  startDate: Scalars['Date'];
  recurrenceInterval: IntervalInput;
}>;


export type CreateRecurringReportedDonationMutation = { __typename?: 'Mutation', RecurringReportedDonation?: { __typename?: 'CreateRecurringReportedDonationPayload', recurringReportedDonation?: { __typename?: 'RecurringReportedDonation', id: string, personId: string, organizationId: string, amount: string, currencyCode: string, startDate: string, nextDate: string, status: RecurringPaymentStatus, createdAt: string, updatedAt: string, recurrenceInterval: { __typename?: 'Interval', years?: number | null | undefined, months?: number | null | undefined, days?: number | null | undefined, hours?: number | null | undefined, minutes?: number | null | undefined, seconds?: number | null | undefined } } | null | undefined } | null | undefined };

export type CreateReportedDonationMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  donationDate: Scalars['Date'];
  amount: Scalars['BigFloat'];
  currencyCode: Scalars['String'];
  organizationId: Scalars['BigInt'];
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
}>;


export type CreateReportedDonationMutation = { __typename?: 'Mutation', createReportedDonationByPersonId?: { __typename?: 'CreateReportedDonationByPersonIdPayload', reportedDonation?: { __typename?: 'ReportedDonation', id: string, personId: string, donationDate?: string | null | undefined, amount?: string | null | undefined, currencyCode?: string | null | undefined, organizationId: string } | null | undefined } | null | undefined };

export type DeleteFinancialPeriodMutationVariables = Exact<{
  id: Scalars['BigInt'];
}>;


export type DeleteFinancialPeriodMutation = { __typename?: 'Mutation', deleteFinancialPeriod?: { __typename?: 'DeleteFinancialPeriodPayload', clientMutationId?: string | null | undefined } | null | undefined };

export type DeleteReportedDonationMutationVariables = Exact<{
  reportedDonationId: Scalars['BigInt'];
}>;


export type DeleteReportedDonationMutation = { __typename?: 'Mutation', deleteReportedDonation?: { __typename?: 'DeleteReportedDonationPayload', clientMutationId?: string | null | undefined } | null | undefined };

export type SignPledgeMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  incomePercentage: Scalars['BigFloat'];
  wealthPercentage?: InputMaybe<Scalars['BigFloat']>;
  pledgeType: PledgeType;
  motivation?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  metadata: Scalars['JSON'];
  incomeAmount?: InputMaybe<Scalars['BigFloat']>;
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
  currencyCode: Scalars['String'];
  employmentStatus: IncomeEmploymentStatus;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['Date']>;
}>;


export type SignPledgeMutation = { __typename?: 'Mutation', signPledge?: { __typename?: 'SignPledgePayload', pledge?: { __typename?: 'Pledge', id: string, personId: string, incomePercentage: string, wealthPercentage?: string | null | undefined, motivation?: string | null | undefined, locale: string, metadata?: unknown | null | undefined, pledgeType: PledgeType, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined } | null | undefined };

export type SignPledgeByTemporaryPersonMutationVariables = Exact<{
  sessionToken: Scalars['UUID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  incomePercentage: Scalars['BigFloat'];
  wealthPercentage?: InputMaybe<Scalars['BigFloat']>;
  pledgeType: PledgeType;
  motivation?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  metadata: Scalars['JSON'];
  incomeAmount?: InputMaybe<Scalars['BigFloat']>;
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
  currencyCode: Scalars['String'];
  employmentStatus: IncomeEmploymentStatus;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['Date']>;
}>;


export type SignPledgeByTemporaryPersonMutation = { __typename?: 'Mutation', signPledgeByTemporaryPerson?: { __typename?: 'SignPledgeByTemporaryPersonPayload', pledge?: { __typename?: 'Pledge', id: string, personId: string, incomePercentage: string, wealthPercentage?: string | null | undefined, motivation?: string | null | undefined, locale: string, metadata?: unknown | null | undefined, pledgeType: PledgeType, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined } | null | undefined };

export type UpdateFinancialPeriodMutationVariables = Exact<{
  id: Scalars['BigInt'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  wealthAmount?: InputMaybe<Scalars['BigFloat']>;
  currencyCode?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<IncomeEmploymentStatus>;
  donationsTaxDeductible?: InputMaybe<Scalars['Boolean']>;
  incomes?: InputMaybe<Array<InputMaybe<IncomeInput>> | InputMaybe<IncomeInput>>;
}>;


export type UpdateFinancialPeriodMutation = { __typename?: 'Mutation', updateFinancialPeriod?: { __typename?: 'UpdateFinancialPeriodPayload', financialPeriod?: { __typename?: 'FinancialPeriod', id: string, personId: string, wealthAmount?: string | null | undefined, currencyCode: string, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined } | null | undefined };

export type UpdateReportedPaymentMutationVariables = Exact<{
  id: Scalars['BigInt'];
  personId: Scalars['BigInt'];
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
}>;


export type UpdateReportedPaymentMutation = { __typename?: 'Mutation', updateReportedPayment?: { __typename?: 'UpdateReportedPaymentPayload', payment?: { __typename?: 'Payment', id: string } | null | undefined } | null | undefined };

export type UpdatePledgeByPersonOrTemporaryPersonMutationVariables = Exact<{
  pledgeId: Scalars['BigInt'];
  metadata: Scalars['JSON'];
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type UpdatePledgeByPersonOrTemporaryPersonMutation = { __typename?: 'Mutation', updatePledgeByPersonOrTemporaryPerson?: { __typename?: 'UpdatePledgeByPersonOrTemporaryPersonPayload', pledge?: { __typename?: 'Pledge', id: string, metadata?: unknown | null | undefined } | null | undefined } | null | undefined };

export type UpdateReportedDonationMutationVariables = Exact<{
  id: Scalars['BigInt'];
  personId: Scalars['BigInt'];
  donationDate: Scalars['Date'];
  amount: Scalars['BigFloat'];
  currencyCode: Scalars['String'];
  organizationId: Scalars['BigInt'];
  pledgeId?: InputMaybe<Scalars['BigInt']>;
  financialPeriodId?: InputMaybe<Scalars['BigInt']>;
}>;


export type UpdateReportedDonationMutation = { __typename?: 'Mutation', updateReportedDonation?: { __typename?: 'UpdateReportedDonationPayload', reportedDonation?: { __typename?: 'ReportedDonation', id: string, personId: string, donationDate?: string | null | undefined, amount?: string | null | undefined, currencyCode?: string | null | undefined, organizationId: string } | null | undefined } | null | undefined };

export type VerifyEmailMutationVariables = Exact<{
  pledgeId: Scalars['BigInt'];
  emailVerificationToken: Scalars['UUID'];
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail?: { __typename?: 'VerifyEmailPayload', pledge?: { __typename?: 'Pledge', id: string } | null | undefined } | null | undefined };

export type RecurringReportedDonationFragment = { __typename?: 'RecurringReportedDonation', id: string, personId: string, organizationId: string, amount: string, currencyCode: string, startDate: string, nextDate: string, status: RecurringPaymentStatus, createdAt: string, updatedAt: string, externalOrganization?: { __typename?: 'ExternalOrganization', id: string, name: string, organizationSlug?: string | null | undefined, url?: string | null | undefined } | null | undefined, recurrenceInterval: { __typename?: 'Interval', years?: number | null | undefined, months?: number | null | undefined, days?: number | null | undefined, hours?: number | null | undefined, minutes?: number | null | undefined, seconds?: number | null | undefined } };

export type ReportedDonationFragment = { __typename?: 'ReportedDonation', id: string, personId: string, amount?: string | null | undefined, amountNormalized?: string | null | undefined, currencyCode?: string | null | undefined, donationDate?: string | null | undefined, organization?: { __typename?: 'ExternalOrganization', id: string, name: string, organizationSlug?: string | null | undefined } | null | undefined };

export type GetCompletionPaymentsByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetCompletionPaymentsByPersonIdQuery = { __typename?: 'Query', CompletionPayments?: { __typename?: 'CompletionPaymentsConnection', edges: Array<{ __typename?: 'CompletionPaymentsEdge', node: { __typename?: 'CompletionPayment', objectId: string, objectType: CompletionPaymentObjectType, reportedDonationId?: string | null | undefined, organizationId?: string | null | undefined, paymentId?: string | null | undefined, personId?: string | null | undefined, pledgeId?: string | null | undefined, financialPeriodId?: string | null | undefined, amount: string, currencyCode: string, amountNormalized?: string | null | undefined, allocation?: unknown | null | undefined, donationDate: string, externalOrganization?: { __typename?: 'ExternalOrganization', id: string, name: string } | null | undefined, liability?: { __typename?: 'Liability', liabilityPeriod: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined } }> } | null | undefined };

export type GetFinancialPeriodsByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetFinancialPeriodsByPersonIdQuery = { __typename?: 'Query', FinancialPeriods?: { __typename?: 'FinancialPeriodsConnection', edges: Array<{ __typename?: 'FinancialPeriodsEdge', node: { __typename?: 'FinancialPeriod', id: string, personId: string, employmentStatus?: IncomeEmploymentStatus | null | undefined, donationsTaxDeductible?: boolean | null | undefined, wealthAmount?: string | null | undefined, currencyCode: string, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined }, incomes: { __typename?: 'IncomesConnection', edges: Array<{ __typename?: 'IncomesEdge', node: { __typename?: 'Income', nodeId: string, id: string, personId: string, amount: string, financialPeriodId: string } }> }, liabilities: { __typename?: 'LiabilitiesConnection', edges: Array<{ __typename?: 'LiabilitiesEdge', node: { __typename?: 'Liability', nodeId: string, pledgeId: string, financialPeriodId: string, personId: string, currencyCode: string, liabilityAmount: string, liabilityNormalized: string, incomeLiabilityAmount?: string | null | undefined, wealthLiabilityAmount?: string | null | undefined, pledge?: { __typename?: 'Pledge', id: string, pledgeType: PledgeType, incomePercentage: string, wealthPercentage?: string | null | undefined } | null | undefined, liabilityPeriod: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } }> } } }> } | null | undefined };

export type GetIncomesByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetIncomesByPersonIdQuery = { __typename?: 'Query', Incomes?: { __typename?: 'IncomesConnection', edges: Array<{ __typename?: 'IncomesEdge', node: { __typename?: 'Income', id: string, personId: string, amount: string, financialPeriod?: { __typename?: 'FinancialPeriod', id: string, currencyCode: string, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined } }> } | null | undefined };

export type GetLiabilitiesByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetLiabilitiesByPersonIdQuery = { __typename?: 'Query', Liabilities?: { __typename?: 'LiabilitiesConnection', edges: Array<{ __typename?: 'LiabilitiesEdge', node: { __typename?: 'Liability', personId: string, pledgeId: string, financialPeriodId: string, currencyCode: string, liabilityAmount: string, liabilityNormalized: string, liabilityPeriod: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } }> } | null | undefined };

export type GetLiabilityCompletionsQueryVariables = Exact<{
  pledgeId: Scalars['BigInt'];
}>;


export type GetLiabilityCompletionsQuery = { __typename?: 'Query', LiabilityCompletions?: { __typename?: 'LiabilityCompletionTsConnection', edges: Array<{ __typename?: 'LiabilityCompletionTsEdge', node: { __typename?: 'LiabilityCompletionT', personId?: string | null | undefined, pledgeId?: string | null | undefined, financialPeriodId?: string | null | undefined, currencyCode?: string | null | undefined, liabilityAmount?: string | null | undefined, incomeLiabilityAmount?: string | null | undefined, wealthLiabilityAmount?: string | null | undefined, financialPeriodWealth?: string | null | undefined, completionAmount?: string | null | undefined, completionAmountNormalized?: string | null | undefined, completionPercentage?: string | null | undefined, period?: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } | null | undefined } }> } | null | undefined };

export type GetLiabilityTotalCompletionsQueryVariables = Exact<{
  pledgeId: Scalars['BigInt'];
}>;


export type GetLiabilityTotalCompletionsQuery = { __typename?: 'Query', LiabilityTotalCompletions?: { __typename?: 'LiabilityTotalCompletionTsConnection', edges: Array<{ __typename?: 'LiabilityTotalCompletionTsEdge', node: { __typename?: 'LiabilityTotalCompletionT', pledgeId?: string | null | undefined, currencyCode?: string | null | undefined, currencySortOrder?: string | null | undefined, liabilityAmount?: string | null | undefined, liabilityAmountNormalized?: string | null | undefined, completionAmount?: string | null | undefined, completionAmountNormalized?: string | null | undefined, completionPercentage?: string | null | undefined } }> } | null | undefined };

export type GetPledgeByPersonOrTemporaryPersonQueryVariables = Exact<{
  pledgeId: Scalars['BigInt'];
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetPledgeByPersonOrTemporaryPersonQuery = { __typename?: 'Query', Pledge?: { __typename?: 'GetPledgeByPersonOrTemporaryPersonT', id: string, personId: string, incomePercentage: string, wealthPercentage?: string | null | undefined, pledgeType: PledgeType, motivation?: string | null | undefined, locale: string, metadata?: unknown | null | undefined, emailVerifiedAt?: string | null | undefined, email?: string | null | undefined, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined } } | null | undefined };

export type GetPledgeRanksByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetPledgeRanksByPersonIdQuery = { __typename?: 'Query', PledgeRanks?: { __typename?: 'PledgeRanksConnection', edges: Array<{ __typename?: 'PledgeRanksEdge', node: { __typename?: 'PledgeRank', createdAt: string, pledgeType: PledgeType, rankByPledgeType: number, commencementDate: string, personByPersonId?: { __typename?: 'Person', id: string, fullName?: string | null | undefined } | null | undefined } }> } | null | undefined };

export type GetPledgedMemberPublicsLatestRankQueryVariables = Exact<{
  pledgeType?: InputMaybe<PledgeType>;
}>;


export type GetPledgedMemberPublicsLatestRankQuery = { __typename?: 'Query', PledgedMembers?: Array<{ __typename?: 'PledgedMembersPublic', rankByPledgeType?: number | null | undefined }> | null | undefined };

export type GetPledgesByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetPledgesByPersonIdQuery = { __typename?: 'Query', Pledges?: { __typename?: 'PledgesConnection', edges: Array<{ __typename?: 'PledgesEdge', node: { __typename?: 'Pledge', id: string, personId: string, incomePercentage: string, wealthPercentage?: string | null | undefined, pledgeType: PledgeType, motivation?: string | null | undefined, period: { __typename?: 'DateRange', start?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined, end?: { __typename?: 'DateRangeBound', value: string, inclusive: boolean } | null | undefined }, liabilities: { __typename?: 'LiabilitiesConnection', edges: Array<{ __typename?: 'LiabilitiesEdge', node: { __typename?: 'Liability', nodeId: string, pledgeId: string, financialPeriodId: string, personId: string, currencyCode: string, liabilityAmount: string, liabilityNormalized: string } }> } } }> } | null | undefined };

export type GetRecurringReportedDonationQueryVariables = Exact<{
  recurringReportedDonationId: Scalars['BigInt'];
}>;


export type GetRecurringReportedDonationQuery = { __typename?: 'Query', RecurringReportedDonation?: { __typename?: 'RecurringReportedDonation', id: string, personId: string, organizationId: string, amount: string, currencyCode: string, startDate: string, nextDate: string, status: RecurringPaymentStatus, createdAt: string, updatedAt: string, externalOrganization?: { __typename?: 'ExternalOrganization', id: string, name: string, organizationSlug?: string | null | undefined, url?: string | null | undefined } | null | undefined, recurrenceInterval: { __typename?: 'Interval', years?: number | null | undefined, months?: number | null | undefined, days?: number | null | undefined, hours?: number | null | undefined, minutes?: number | null | undefined, seconds?: number | null | undefined } } | null | undefined };

export type GetRecurringReportedDonationsByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetRecurringReportedDonationsByPersonIdQuery = { __typename?: 'Query', RecurringReportedDonations?: { __typename?: 'RecurringReportedDonationsConnection', edges: Array<{ __typename?: 'RecurringReportedDonationsEdge', node: { __typename?: 'RecurringReportedDonation', id: string, personId: string, organizationId: string, amount: string, currencyCode: string, startDate: string, nextDate: string, status: RecurringPaymentStatus, createdAt: string, updatedAt: string, externalOrganization?: { __typename?: 'ExternalOrganization', id: string, name: string, organizationSlug?: string | null | undefined, url?: string | null | undefined } | null | undefined, recurrenceInterval: { __typename?: 'Interval', years?: number | null | undefined, months?: number | null | undefined, days?: number | null | undefined, hours?: number | null | undefined, minutes?: number | null | undefined, seconds?: number | null | undefined } } }> } | null | undefined };

export type GetReportedDonationQueryVariables = Exact<{
  reportedDonationId: Scalars['BigInt'];
}>;


export type GetReportedDonationQuery = { __typename?: 'Query', ReportedDonation?: { __typename?: 'ReportedDonation', id: string, personId: string, amount?: string | null | undefined, amountNormalized?: string | null | undefined, currencyCode?: string | null | undefined, donationDate?: string | null | undefined, organization?: { __typename?: 'ExternalOrganization', id: string, name: string, organizationSlug?: string | null | undefined } | null | undefined } | null | undefined };

export type GetReportedDonationsByPersonIdQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetReportedDonationsByPersonIdQuery = { __typename?: 'Query', ReportedDonations?: { __typename?: 'ReportedDonationsConnection', edges: Array<{ __typename?: 'ReportedDonationsEdge', node: { __typename?: 'ReportedDonation', id: string, personId: string, amount?: string | null | undefined, amountNormalized?: string | null | undefined, currencyCode?: string | null | undefined, donationDate?: string | null | undefined, createdAt: string, updatedAt: string, externalOrganization?: { __typename?: 'ExternalOrganization', id: string, name: string, organizationSlug?: string | null | undefined } | null | undefined } }> } | null | undefined };

export type CreatePollOptionVoteByPersonOrTemporaryPersonMutationVariables = Exact<{
  pledgeId: Scalars['BigInt'];
  pollOptionId: Scalars['BigInt'];
  context: Scalars['String'];
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type CreatePollOptionVoteByPersonOrTemporaryPersonMutation = { __typename?: 'Mutation', createPollOptionVoteByPersonOrTemporaryPerson?: { __typename?: 'CreatePollOptionVoteByPersonOrTemporaryPersonPayload', pollOptionVote?: { __typename?: 'PollOptionVote', pledgeId: string, pollOptionId: string, context: string } | null | undefined } | null | undefined };

export type GetPollByPersonOrTemporaryPersonQueryVariables = Exact<{
  pollId: Scalars['BigInt'];
  sessionToken?: InputMaybe<Scalars['UUID']>;
}>;


export type GetPollByPersonOrTemporaryPersonQuery = { __typename?: 'Query', Poll?: { __typename?: 'PollPublic', id: string, voteMechanism: VoteMechanism, questionOrdering: QuestionOrdering, question: string, Options: Array<{ __typename?: 'PollOptionPublic', id: string, pollId: string, label: string, voteCount: number, Votes?: Array<{ __typename?: 'PollOptionVote', pledgeId: string, pollOptionId: string, context: string }> | null | undefined }> } | null | undefined };

export type AllCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCountriesQuery = { __typename?: 'Query', Countries?: { __typename?: 'CountriesConnection', edges: Array<{ __typename?: 'CountriesEdge', node: { __typename?: 'Country', name: string, isoAlpha2: string, isoAlpha3: string } }> } | null | undefined };

export type AllCountriesWithCurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllCountriesWithCurrenciesQuery = { __typename?: 'Query', Countries?: { __typename?: 'CountriesConnection', edges: Array<{ __typename?: 'CountriesEdge', node: { __typename?: 'Country', name: string, isoAlpha2: string, isoAlpha3: string, currencies: Array<{ __typename?: 'CurrencyByCountry', currencyCode: string }> } }> } | null | undefined };

export type AllCurrenciesQueryVariables = Exact<{
  currencyType?: InputMaybe<CurrencyType>;
}>;


export type AllCurrenciesQuery = { __typename?: 'Query', Currencies?: { __typename?: 'CurrenciesConnection', edges: Array<{ __typename?: 'CurrenciesEdge', node: { __typename?: 'Currency', name: string, code: string, decimals: number, symbol?: string | null | undefined, currencyType: CurrencyType } }> } | null | undefined };

export type AllFiatCurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllFiatCurrenciesQuery = { __typename?: 'Query', FiatCurrencies?: { __typename?: 'CurrenciesConnection', edges: Array<{ __typename?: 'CurrenciesEdge', node: { __typename?: 'Currency', name: string, code: string, symbol?: string | null | undefined } }> } | null | undefined };

export type AllMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllMembersQuery = { __typename?: 'Query', Pledges?: Array<{ __typename?: 'PledgedMembersPublic', pledgeType?: PledgeType | null | undefined, active?: boolean | null | undefined, rankByPledgeType?: number | null | undefined, fullName?: string | null | undefined, commencementDate?: string | null | undefined }> | null | undefined };

export type ClosestExchangeRateQueryVariables = Exact<{
  currencyCode: Scalars['String'];
  date: Scalars['Date'];
}>;


export type ClosestExchangeRateQuery = { __typename?: 'Query', ExchangeRate?: { __typename?: 'ExchangeRate', currencyCode: string, date: string, rate?: string | null | undefined } | null | undefined };

export type GetCurrencyQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type GetCurrencyQuery = { __typename?: 'Query', Currency?: { __typename?: 'Currency', name: string, code: string, decimals: number, symbol?: string | null | undefined, currencyType: CurrencyType } | null | undefined };

export type CurrencyFieldsFragment = { __typename?: 'Currency', name: string, code: string, decimals: number, symbol?: string | null | undefined };

export type PaymentCurrenciesQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentCurrenciesQuery = { __typename?: 'Query', USD?: { __typename?: 'Currency', name: string, code: string, decimals: number, symbol?: string | null | undefined } | null | undefined, GBP?: { __typename?: 'Currency', name: string, code: string, decimals: number, symbol?: string | null | undefined } | null | undefined };

export type ExchangeRateFieldsFragment = { __typename?: 'ExchangeRate', currencyCode: string, date: string, rate?: string | null | undefined };

export type PaymentExchangeRatesQueryVariables = Exact<{
  date: Scalars['Date'];
}>;


export type PaymentExchangeRatesQuery = { __typename?: 'Query', GBP?: { __typename?: 'ExchangeRate', currencyCode: string, date: string, rate?: string | null | undefined } | null | undefined, BTC?: { __typename?: 'ExchangeRate', currencyCode: string, date: string, rate?: string | null | undefined } | null | undefined, ETH?: { __typename?: 'ExchangeRate', currencyCode: string, date: string, rate?: string | null | undefined } | null | undefined, LTC?: { __typename?: 'ExchangeRate', currencyCode: string, date: string, rate?: string | null | undefined } | null | undefined };

export type GetStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStatsQuery = { __typename?: 'Query', allGlobalStatsList?: Array<{ __typename?: 'GlobalStat', gwwcPledgesTotal?: number | null | undefined, activeTryGivingPledgesTotal?: number | null | undefined, tryGivingCompletedTotal?: number | null | undefined, pledgersCountriesTotal?: number | null | undefined, pledgesDonationsTotal?: string | null | undefined, gwwcDonationsTotal?: string | null | undefined }> | null | undefined };

export type GetPaymentStatsAggregateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentStatsAggregateQuery = { __typename?: 'Query', PaymentStats?: { __typename?: 'PaymentStatsAggregate', totalDonations?: string | null | undefined, numDonations?: string | null | undefined, numDonors?: string | null | undefined, totalDonationsLastYear?: string | null | undefined, numDonationsLastYear?: string | null | undefined, numDonorsLastYear?: string | null | undefined } | null | undefined };

export type UpdateSettingsContactPreferenceMutationVariables = Exact<{
  personId: Scalars['BigInt'];
  currentAddressId?: InputMaybe<Scalars['BigInt']>;
}>;


export type UpdateSettingsContactPreferenceMutation = { __typename?: 'Mutation', updateContactPreferenceByPersonId?: { __typename?: 'UpdateContactPreferenceByPersonIdPayload', contactPreference?: { __typename?: 'ContactPreference', personId: string, currentAddressId?: string | null | undefined } | null | undefined } | null | undefined };

export type GetSettingsQueryVariables = Exact<{
  personId: Scalars['BigInt'];
}>;


export type GetSettingsQuery = { __typename?: 'Query', ContactPreference?: { __typename?: 'ContactPreference', currentAddressId?: string | null | undefined } | null | undefined };

export const LotteryFieldsFragmentDoc = gql`
    fragment LotteryFields on ExtendedLottery {
  id
  blockSize
  closeTimestamp
  lockTimestamp
  drawTimestamp
  drawEpoch
  status
  winningNumber
}
    `;
export const LotterySummaryFieldsFragmentDoc = gql`
    fragment LotterySummaryFields on LotterySummary {
  id
  blockSize
  closeTimestamp
  lockTimestamp
  drawTimestamp
  winningNumber
  winningNumberHex
  drawEpoch
  status
  ticketsAssigned
  maxBlockId
  numEntries
  entriesTotalNormalized
  benefactorLiability
  benefactorStartingTicketNumber
  benefactorWinsLastBlock
  winningTickets
}
    `;
export const PaymentFieldsFragmentDoc = gql`
    fragment PaymentFields on Payment {
  id
  personId
  chargeCurrencyCode
  chargeAmount
  currencyCode
  amount
  amountNormalized
  net
  fee
  recipient
  allocation
  gateway
  gatewayData
  gatewayTransactionId
  paymentMethod
  recurrence
  billingAddressId
  metadata
  status
  statusData
  reference
  createdAt
  updatedAt
}
    `;
export const RecurringPaymentFieldsFragmentDoc = gql`
    fragment RecurringPaymentFields on RecurringPayment {
  id
  personId
  period
  periodIndex
  periodInterval
  allocation
  amount
  currencyCode
  recipient
  gateway
  gatewayData
  paymentMethod
  billingAddressId
  metadata
  status
  statusData
  createdAt
  updatedAt
}
    `;
export const RecurringReportedDonationFragmentDoc = gql`
    fragment RecurringReportedDonation on RecurringReportedDonation {
  id
  personId
  organizationId
  externalOrganization: externalOrganizationByOrganizationId {
    id
    name
    organizationSlug
    url
  }
  amount
  currencyCode
  startDate
  recurrenceInterval {
    years
    months
    days
    hours
    minutes
    seconds
  }
  nextDate
  status
  createdAt
  updatedAt
}
    `;
export const ReportedDonationFragmentDoc = gql`
    fragment ReportedDonation on ReportedDonation {
  id
  personId
  amount
  amountNormalized
  currencyCode
  donationDate
  organization: externalOrganizationByOrganizationId {
    id
    name
    organizationSlug
  }
}
    `;
export const CurrencyFieldsFragmentDoc = gql`
    fragment currencyFields on Currency {
  name
  code
  decimals
  symbol
}
    `;
export const ExchangeRateFieldsFragmentDoc = gql`
    fragment exchangeRateFields on ExchangeRate {
  currencyCode
  date
  rate
}
    `;
export const CreateExternalOrganizationDocument = gql`
    mutation createExternalOrganization($name: String!, $organizationSlug: String, $url: String, $keywords: [String]) {
  createExternalOrganization(
    input: {name: $name, organizationSlug: $organizationSlug, url: $url, keywords: $keywords}
  ) {
    externalOrganization {
      id
      name
      keywords
      organizationSlug
      url
    }
  }
}
    `;
export type CreateExternalOrganizationMutationFn = Apollo.MutationFunction<CreateExternalOrganizationMutation, CreateExternalOrganizationMutationVariables>;

/**
 * __useCreateExternalOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateExternalOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalOrganizationMutation, { data, loading, error }] = useCreateExternalOrganizationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      organizationSlug: // value for 'organizationSlug'
 *      url: // value for 'url'
 *      keywords: // value for 'keywords'
 *   },
 * });
 */
export function useCreateExternalOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateExternalOrganizationMutation, CreateExternalOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExternalOrganizationMutation, CreateExternalOrganizationMutationVariables>(CreateExternalOrganizationDocument, options);
      }
export type CreateExternalOrganizationMutationHookResult = ReturnType<typeof useCreateExternalOrganizationMutation>;
export type CreateExternalOrganizationMutationResult = Apollo.MutationResult<CreateExternalOrganizationMutation>;
export type CreateExternalOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateExternalOrganizationMutation, CreateExternalOrganizationMutationVariables>;
export const GetExternalOrganizationTypeaheadDocument = gql`
    query getExternalOrganizationTypeahead($searchText: String!) {
  ExternalOrganizations: externalOrganizationTypeahead(
    searchText: $searchText
    first: 10
  ) {
    edges {
      node {
        id
        name
        organizationSlug
        url
      }
    }
  }
}
    `;

/**
 * __useGetExternalOrganizationTypeaheadQuery__
 *
 * To run a query within a React component, call `useGetExternalOrganizationTypeaheadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalOrganizationTypeaheadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalOrganizationTypeaheadQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useGetExternalOrganizationTypeaheadQuery(baseOptions: Apollo.QueryHookOptions<GetExternalOrganizationTypeaheadQuery, GetExternalOrganizationTypeaheadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalOrganizationTypeaheadQuery, GetExternalOrganizationTypeaheadQueryVariables>(GetExternalOrganizationTypeaheadDocument, options);
      }
export function useGetExternalOrganizationTypeaheadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalOrganizationTypeaheadQuery, GetExternalOrganizationTypeaheadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalOrganizationTypeaheadQuery, GetExternalOrganizationTypeaheadQueryVariables>(GetExternalOrganizationTypeaheadDocument, options);
        }
export type GetExternalOrganizationTypeaheadQueryHookResult = ReturnType<typeof useGetExternalOrganizationTypeaheadQuery>;
export type GetExternalOrganizationTypeaheadLazyQueryHookResult = ReturnType<typeof useGetExternalOrganizationTypeaheadLazyQuery>;
export type GetExternalOrganizationTypeaheadQueryResult = Apollo.QueryResult<GetExternalOrganizationTypeaheadQuery, GetExternalOrganizationTypeaheadQueryVariables>;
export const GetExternalOrganizationsDocument = gql`
    query getExternalOrganizations {
  ExternalOrganizations: allExternalOrganizations(orderBy: [NAME_ASC]) {
    edges {
      node {
        id
        name
        organizationSlug
        url
      }
    }
  }
}
    `;

/**
 * __useGetExternalOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetExternalOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExternalOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetExternalOrganizationsQuery, GetExternalOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalOrganizationsQuery, GetExternalOrganizationsQueryVariables>(GetExternalOrganizationsDocument, options);
      }
export function useGetExternalOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalOrganizationsQuery, GetExternalOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalOrganizationsQuery, GetExternalOrganizationsQueryVariables>(GetExternalOrganizationsDocument, options);
        }
export type GetExternalOrganizationsQueryHookResult = ReturnType<typeof useGetExternalOrganizationsQuery>;
export type GetExternalOrganizationsLazyQueryHookResult = ReturnType<typeof useGetExternalOrganizationsLazyQuery>;
export type GetExternalOrganizationsQueryResult = Apollo.QueryResult<GetExternalOrganizationsQuery, GetExternalOrganizationsQueryVariables>;
export const GetOrganizationsDocument = gql`
    query getOrganizations {
  Organizations: allProgramDetailsLegacies {
    edges {
      node {
        slug
        name
        program
        type
        active
        hidden
        href
        isDataSharingPartner
      }
    }
  }
}
    `;

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
      }
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
        }
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export const CancelRecurringPaymentDocument = gql`
    mutation cancelRecurringPayment($recurringPaymentId: BigInt!) {
  cancelRecurringPayment(input: {recurringPaymentId: $recurringPaymentId}) {
    clientMutationId
  }
}
    `;
export type CancelRecurringPaymentMutationFn = Apollo.MutationFunction<CancelRecurringPaymentMutation, CancelRecurringPaymentMutationVariables>;

/**
 * __useCancelRecurringPaymentMutation__
 *
 * To run a mutation, you first call `useCancelRecurringPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRecurringPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRecurringPaymentMutation, { data, loading, error }] = useCancelRecurringPaymentMutation({
 *   variables: {
 *      recurringPaymentId: // value for 'recurringPaymentId'
 *   },
 * });
 */
export function useCancelRecurringPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CancelRecurringPaymentMutation, CancelRecurringPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelRecurringPaymentMutation, CancelRecurringPaymentMutationVariables>(CancelRecurringPaymentDocument, options);
      }
export type CancelRecurringPaymentMutationHookResult = ReturnType<typeof useCancelRecurringPaymentMutation>;
export type CancelRecurringPaymentMutationResult = Apollo.MutationResult<CancelRecurringPaymentMutation>;
export type CancelRecurringPaymentMutationOptions = Apollo.BaseMutationOptions<CancelRecurringPaymentMutation, CancelRecurringPaymentMutationVariables>;
export const CreateGiftAidClaimDocument = gql`
    mutation createGiftAidClaim($paymentId: BigInt!, $addressId: BigInt!) {
  createGiftAidClaimFromPayment(
    input: {paymentId: $paymentId, addressId: $addressId}
  ) {
    giftAidClaim {
      paymentId
      personId
      addressId
      reportId
    }
  }
}
    `;
export type CreateGiftAidClaimMutationFn = Apollo.MutationFunction<CreateGiftAidClaimMutation, CreateGiftAidClaimMutationVariables>;

/**
 * __useCreateGiftAidClaimMutation__
 *
 * To run a mutation, you first call `useCreateGiftAidClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGiftAidClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGiftAidClaimMutation, { data, loading, error }] = useCreateGiftAidClaimMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useCreateGiftAidClaimMutation(baseOptions?: Apollo.MutationHookOptions<CreateGiftAidClaimMutation, CreateGiftAidClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGiftAidClaimMutation, CreateGiftAidClaimMutationVariables>(CreateGiftAidClaimDocument, options);
      }
export type CreateGiftAidClaimMutationHookResult = ReturnType<typeof useCreateGiftAidClaimMutation>;
export type CreateGiftAidClaimMutationResult = Apollo.MutationResult<CreateGiftAidClaimMutation>;
export type CreateGiftAidClaimMutationOptions = Apollo.BaseMutationOptions<CreateGiftAidClaimMutation, CreateGiftAidClaimMutationVariables>;
export const CreateGocardlessRedirectFlowDocument = gql`
    mutation createGocardlessRedirectFlow($personId: BigInt, $sessionToken: UUID) {
  mutationResult: createGocardlessRedirectFlowByPersonOrTemporaryPerson(
    input: {personId: $personId, sessionToken: $sessionToken}
  ) {
    RedirectFlow: gocardlessRedirectFlow {
      personId
      sessionToken
      gocardlessRedirectFlowId
      gocardlessRedirectFlowUrl
      expires
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateGocardlessRedirectFlowMutationFn = Apollo.MutationFunction<CreateGocardlessRedirectFlowMutation, CreateGocardlessRedirectFlowMutationVariables>;

/**
 * __useCreateGocardlessRedirectFlowMutation__
 *
 * To run a mutation, you first call `useCreateGocardlessRedirectFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGocardlessRedirectFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGocardlessRedirectFlowMutation, { data, loading, error }] = useCreateGocardlessRedirectFlowMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useCreateGocardlessRedirectFlowMutation(baseOptions?: Apollo.MutationHookOptions<CreateGocardlessRedirectFlowMutation, CreateGocardlessRedirectFlowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGocardlessRedirectFlowMutation, CreateGocardlessRedirectFlowMutationVariables>(CreateGocardlessRedirectFlowDocument, options);
      }
export type CreateGocardlessRedirectFlowMutationHookResult = ReturnType<typeof useCreateGocardlessRedirectFlowMutation>;
export type CreateGocardlessRedirectFlowMutationResult = Apollo.MutationResult<CreateGocardlessRedirectFlowMutation>;
export type CreateGocardlessRedirectFlowMutationOptions = Apollo.BaseMutationOptions<CreateGocardlessRedirectFlowMutation, CreateGocardlessRedirectFlowMutationVariables>;
export const CreatePaymentDocument = gql`
    mutation createPayment($personId: BigInt!, $currencyCode: String!, $amount: BigFloat!, $recipient: String!, $allocation: JSON!, $gateway: PaymentGateway!, $gatewayData: JSON, $paymentMethod: PaymentMethod!, $billingAddressId: BigInt, $metadata: JSON, $status: PaymentStatus, $createdAt: Datetime) {
  mutationResult: createPaymentByPersonId(
    input: {personId: $personId, currencyCode: $currencyCode, amount: $amount, recipient: $recipient, allocation: $allocation, gateway: $gateway, gatewayData: $gatewayData, paymentMethod: $paymentMethod, billingAddressId: $billingAddressId, metadata: $metadata, status: $status, createdAt: $createdAt}
  ) {
    payment {
      ...PaymentFields
    }
  }
}
    ${PaymentFieldsFragmentDoc}`;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      currencyCode: // value for 'currencyCode'
 *      amount: // value for 'amount'
 *      recipient: // value for 'recipient'
 *      allocation: // value for 'allocation'
 *      gateway: // value for 'gateway'
 *      gatewayData: // value for 'gatewayData'
 *      paymentMethod: // value for 'paymentMethod'
 *      billingAddressId: // value for 'billingAddressId'
 *      metadata: // value for 'metadata'
 *      status: // value for 'status'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const CreatePaymentByTemporaryPersonDocument = gql`
    mutation createPaymentByTemporaryPerson($sessionToken: UUID!, $currencyCode: String!, $amount: BigFloat!, $recipient: String!, $allocation: JSON!, $gateway: PaymentGateway!, $gatewayData: JSON, $paymentMethod: PaymentMethod!, $billingAddressId: BigInt, $metadata: JSON) {
  mutationResult: createPaymentByTemporaryPerson(
    input: {sessionToken: $sessionToken, currencyCode: $currencyCode, amount: $amount, recipient: $recipient, allocation: $allocation, gateway: $gateway, gatewayData: $gatewayData, paymentMethod: $paymentMethod, billingAddressId: $billingAddressId, metadata: $metadata}
  ) {
    payment {
      ...PaymentFields
    }
  }
}
    ${PaymentFieldsFragmentDoc}`;
export type CreatePaymentByTemporaryPersonMutationFn = Apollo.MutationFunction<CreatePaymentByTemporaryPersonMutation, CreatePaymentByTemporaryPersonMutationVariables>;

/**
 * __useCreatePaymentByTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useCreatePaymentByTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentByTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentByTemporaryPersonMutation, { data, loading, error }] = useCreatePaymentByTemporaryPersonMutation({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *      currencyCode: // value for 'currencyCode'
 *      amount: // value for 'amount'
 *      recipient: // value for 'recipient'
 *      allocation: // value for 'allocation'
 *      gateway: // value for 'gateway'
 *      gatewayData: // value for 'gatewayData'
 *      paymentMethod: // value for 'paymentMethod'
 *      billingAddressId: // value for 'billingAddressId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreatePaymentByTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentByTemporaryPersonMutation, CreatePaymentByTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentByTemporaryPersonMutation, CreatePaymentByTemporaryPersonMutationVariables>(CreatePaymentByTemporaryPersonDocument, options);
      }
export type CreatePaymentByTemporaryPersonMutationHookResult = ReturnType<typeof useCreatePaymentByTemporaryPersonMutation>;
export type CreatePaymentByTemporaryPersonMutationResult = Apollo.MutationResult<CreatePaymentByTemporaryPersonMutation>;
export type CreatePaymentByTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<CreatePaymentByTemporaryPersonMutation, CreatePaymentByTemporaryPersonMutationVariables>;
export const CreateRecurringPaymentDocument = gql`
    mutation createRecurringPayment($personId: BigInt!, $period: RecurrencePeriod!, $periodIndex: Int!, $allocation: JSON!, $amount: BigFloat!, $currencyCode: String!, $recipient: String!, $gateway: PaymentGateway!, $gatewayData: JSON, $paymentMethod: PaymentMethod!, $billingAddressId: BigInt, $metadata: JSON) {
  mutationResult: createRecurringPayment(
    input: {personId: $personId, period: $period, periodIndex: $periodIndex, allocation: $allocation, amount: $amount, currencyCode: $currencyCode, recipient: $recipient, gateway: $gateway, gatewayData: $gatewayData, paymentMethod: $paymentMethod, billingAddressId: $billingAddressId, metadata: $metadata}
  ) {
    recurringPayment {
      ...RecurringPaymentFields
    }
  }
}
    ${RecurringPaymentFieldsFragmentDoc}`;
export type CreateRecurringPaymentMutationFn = Apollo.MutationFunction<CreateRecurringPaymentMutation, CreateRecurringPaymentMutationVariables>;

/**
 * __useCreateRecurringPaymentMutation__
 *
 * To run a mutation, you first call `useCreateRecurringPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringPaymentMutation, { data, loading, error }] = useCreateRecurringPaymentMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      period: // value for 'period'
 *      periodIndex: // value for 'periodIndex'
 *      allocation: // value for 'allocation'
 *      amount: // value for 'amount'
 *      currencyCode: // value for 'currencyCode'
 *      recipient: // value for 'recipient'
 *      gateway: // value for 'gateway'
 *      gatewayData: // value for 'gatewayData'
 *      paymentMethod: // value for 'paymentMethod'
 *      billingAddressId: // value for 'billingAddressId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreateRecurringPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecurringPaymentMutation, CreateRecurringPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecurringPaymentMutation, CreateRecurringPaymentMutationVariables>(CreateRecurringPaymentDocument, options);
      }
export type CreateRecurringPaymentMutationHookResult = ReturnType<typeof useCreateRecurringPaymentMutation>;
export type CreateRecurringPaymentMutationResult = Apollo.MutationResult<CreateRecurringPaymentMutation>;
export type CreateRecurringPaymentMutationOptions = Apollo.BaseMutationOptions<CreateRecurringPaymentMutation, CreateRecurringPaymentMutationVariables>;
export const CreateRecurringPaymentByTemporaryPersonDocument = gql`
    mutation createRecurringPaymentByTemporaryPerson($sessionToken: UUID!, $period: RecurrencePeriod!, $periodIndex: Int!, $allocation: JSON!, $amount: BigFloat!, $currencyCode: String!, $recipient: String!, $gateway: PaymentGateway!, $gatewayData: JSON, $paymentMethod: PaymentMethod!, $billingAddressId: BigInt, $metadata: JSON) {
  mutationResult: createRecurringPaymentByTemporaryPerson(
    input: {sessionToken: $sessionToken, period: $period, periodIndex: $periodIndex, allocation: $allocation, amount: $amount, currencyCode: $currencyCode, recipient: $recipient, gateway: $gateway, gatewayData: $gatewayData, paymentMethod: $paymentMethod, billingAddressId: $billingAddressId, metadata: $metadata}
  ) {
    recurringPayment {
      ...RecurringPaymentFields
    }
  }
}
    ${RecurringPaymentFieldsFragmentDoc}`;
export type CreateRecurringPaymentByTemporaryPersonMutationFn = Apollo.MutationFunction<CreateRecurringPaymentByTemporaryPersonMutation, CreateRecurringPaymentByTemporaryPersonMutationVariables>;

/**
 * __useCreateRecurringPaymentByTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useCreateRecurringPaymentByTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringPaymentByTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringPaymentByTemporaryPersonMutation, { data, loading, error }] = useCreateRecurringPaymentByTemporaryPersonMutation({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *      period: // value for 'period'
 *      periodIndex: // value for 'periodIndex'
 *      allocation: // value for 'allocation'
 *      amount: // value for 'amount'
 *      currencyCode: // value for 'currencyCode'
 *      recipient: // value for 'recipient'
 *      gateway: // value for 'gateway'
 *      gatewayData: // value for 'gatewayData'
 *      paymentMethod: // value for 'paymentMethod'
 *      billingAddressId: // value for 'billingAddressId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useCreateRecurringPaymentByTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecurringPaymentByTemporaryPersonMutation, CreateRecurringPaymentByTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecurringPaymentByTemporaryPersonMutation, CreateRecurringPaymentByTemporaryPersonMutationVariables>(CreateRecurringPaymentByTemporaryPersonDocument, options);
      }
export type CreateRecurringPaymentByTemporaryPersonMutationHookResult = ReturnType<typeof useCreateRecurringPaymentByTemporaryPersonMutation>;
export type CreateRecurringPaymentByTemporaryPersonMutationResult = Apollo.MutationResult<CreateRecurringPaymentByTemporaryPersonMutation>;
export type CreateRecurringPaymentByTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<CreateRecurringPaymentByTemporaryPersonMutation, CreateRecurringPaymentByTemporaryPersonMutationVariables>;
export const DeleteGiftAidClaimDocument = gql`
    mutation deleteGiftAidClaim($paymentId: BigInt!) {
  GiftAidClaim: deleteGiftAidClaimFromPayment(input: {paymentId: $paymentId}) {
    giftAidClaim {
      paymentId
      personId
      addressId
      reportId
    }
  }
}
    `;
export type DeleteGiftAidClaimMutationFn = Apollo.MutationFunction<DeleteGiftAidClaimMutation, DeleteGiftAidClaimMutationVariables>;

/**
 * __useDeleteGiftAidClaimMutation__
 *
 * To run a mutation, you first call `useDeleteGiftAidClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGiftAidClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGiftAidClaimMutation, { data, loading, error }] = useDeleteGiftAidClaimMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useDeleteGiftAidClaimMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGiftAidClaimMutation, DeleteGiftAidClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGiftAidClaimMutation, DeleteGiftAidClaimMutationVariables>(DeleteGiftAidClaimDocument, options);
      }
export type DeleteGiftAidClaimMutationHookResult = ReturnType<typeof useDeleteGiftAidClaimMutation>;
export type DeleteGiftAidClaimMutationResult = Apollo.MutationResult<DeleteGiftAidClaimMutation>;
export type DeleteGiftAidClaimMutationOptions = Apollo.BaseMutationOptions<DeleteGiftAidClaimMutation, DeleteGiftAidClaimMutationVariables>;
export const UpdateRecurringPaymentAmountDocument = gql`
    mutation updateRecurringPaymentAmount($recurringPaymentId: BigInt!, $amount: BigFloat!) {
  updateRecurringPaymentAmount(
    input: {recurringPaymentId: $recurringPaymentId, amount: $amount}
  ) {
    clientMutationId
  }
}
    `;
export type UpdateRecurringPaymentAmountMutationFn = Apollo.MutationFunction<UpdateRecurringPaymentAmountMutation, UpdateRecurringPaymentAmountMutationVariables>;

/**
 * __useUpdateRecurringPaymentAmountMutation__
 *
 * To run a mutation, you first call `useUpdateRecurringPaymentAmountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecurringPaymentAmountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecurringPaymentAmountMutation, { data, loading, error }] = useUpdateRecurringPaymentAmountMutation({
 *   variables: {
 *      recurringPaymentId: // value for 'recurringPaymentId'
 *      amount: // value for 'amount'
 *   },
 * });
 */
export function useUpdateRecurringPaymentAmountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecurringPaymentAmountMutation, UpdateRecurringPaymentAmountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecurringPaymentAmountMutation, UpdateRecurringPaymentAmountMutationVariables>(UpdateRecurringPaymentAmountDocument, options);
      }
export type UpdateRecurringPaymentAmountMutationHookResult = ReturnType<typeof useUpdateRecurringPaymentAmountMutation>;
export type UpdateRecurringPaymentAmountMutationResult = Apollo.MutationResult<UpdateRecurringPaymentAmountMutation>;
export type UpdateRecurringPaymentAmountMutationOptions = Apollo.BaseMutationOptions<UpdateRecurringPaymentAmountMutation, UpdateRecurringPaymentAmountMutationVariables>;
export const VoidPaymentDocument = gql`
    mutation voidPayment($paymentId: BigInt!) {
  voidPayment(input: {paymentId: $paymentId}) {
    payment {
      id
      status
    }
  }
}
    `;
export type VoidPaymentMutationFn = Apollo.MutationFunction<VoidPaymentMutation, VoidPaymentMutationVariables>;

/**
 * __useVoidPaymentMutation__
 *
 * To run a mutation, you first call `useVoidPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidPaymentMutation, { data, loading, error }] = useVoidPaymentMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useVoidPaymentMutation(baseOptions?: Apollo.MutationHookOptions<VoidPaymentMutation, VoidPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoidPaymentMutation, VoidPaymentMutationVariables>(VoidPaymentDocument, options);
      }
export type VoidPaymentMutationHookResult = ReturnType<typeof useVoidPaymentMutation>;
export type VoidPaymentMutationResult = Apollo.MutationResult<VoidPaymentMutation>;
export type VoidPaymentMutationOptions = Apollo.BaseMutationOptions<VoidPaymentMutation, VoidPaymentMutationVariables>;
export const GetAllRecipientProgramsDocument = gql`
    query getAllRecipientPrograms {
  RecipientPrograms: allActiveRecipientProgramsList {
    organizationSlug
    recipientSlug
  }
}
    `;

/**
 * __useGetAllRecipientProgramsQuery__
 *
 * To run a query within a React component, call `useGetAllRecipientProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRecipientProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRecipientProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRecipientProgramsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRecipientProgramsQuery, GetAllRecipientProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRecipientProgramsQuery, GetAllRecipientProgramsQueryVariables>(GetAllRecipientProgramsDocument, options);
      }
export function useGetAllRecipientProgramsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRecipientProgramsQuery, GetAllRecipientProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRecipientProgramsQuery, GetAllRecipientProgramsQueryVariables>(GetAllRecipientProgramsDocument, options);
        }
export type GetAllRecipientProgramsQueryHookResult = ReturnType<typeof useGetAllRecipientProgramsQuery>;
export type GetAllRecipientProgramsLazyQueryHookResult = ReturnType<typeof useGetAllRecipientProgramsLazyQuery>;
export type GetAllRecipientProgramsQueryResult = Apollo.QueryResult<GetAllRecipientProgramsQuery, GetAllRecipientProgramsQueryVariables>;
export const GetFundraiserStatsDocument = gql`
    query getFundraiserStats($fundraiserId: String!, $currencyCode: String!) {
  FundraiserStats: getFundraiserStats(
    fundraiserId: $fundraiserId
    currencyCode: $currencyCode
  ) {
    edges {
      node {
        fundraiserId
        amountRaisedNormalized
        numDonors
      }
    }
  }
}
    `;

/**
 * __useGetFundraiserStatsQuery__
 *
 * To run a query within a React component, call `useGetFundraiserStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFundraiserStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFundraiserStatsQuery({
 *   variables: {
 *      fundraiserId: // value for 'fundraiserId'
 *      currencyCode: // value for 'currencyCode'
 *   },
 * });
 */
export function useGetFundraiserStatsQuery(baseOptions: Apollo.QueryHookOptions<GetFundraiserStatsQuery, GetFundraiserStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFundraiserStatsQuery, GetFundraiserStatsQueryVariables>(GetFundraiserStatsDocument, options);
      }
export function useGetFundraiserStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFundraiserStatsQuery, GetFundraiserStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFundraiserStatsQuery, GetFundraiserStatsQueryVariables>(GetFundraiserStatsDocument, options);
        }
export type GetFundraiserStatsQueryHookResult = ReturnType<typeof useGetFundraiserStatsQuery>;
export type GetFundraiserStatsLazyQueryHookResult = ReturnType<typeof useGetFundraiserStatsLazyQuery>;
export type GetFundraiserStatsQueryResult = Apollo.QueryResult<GetFundraiserStatsQuery, GetFundraiserStatsQueryVariables>;
export const GetGocardlessMandatesDocument = gql`
    query getGocardlessMandates($personId: BigInt, $sessionToken: UUID) {
  gocardlessMandates: getGocardlessMandatesByPersonOrTemporaryPerson(
    personId: $personId
    sessionToken: $sessionToken
  ) {
    edges {
      node {
        personId
        gocardlessCustomerId
        gocardlessMandateId
        status
        gocardlessBankAccountId
        gocardlessBankAccountHolderName
        gocardlessBankAccountBankName
        gocardlessBankAccountNumberEnding
      }
    }
  }
}
    `;

/**
 * __useGetGocardlessMandatesQuery__
 *
 * To run a query within a React component, call `useGetGocardlessMandatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGocardlessMandatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGocardlessMandatesQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetGocardlessMandatesQuery(baseOptions?: Apollo.QueryHookOptions<GetGocardlessMandatesQuery, GetGocardlessMandatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGocardlessMandatesQuery, GetGocardlessMandatesQueryVariables>(GetGocardlessMandatesDocument, options);
      }
export function useGetGocardlessMandatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGocardlessMandatesQuery, GetGocardlessMandatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGocardlessMandatesQuery, GetGocardlessMandatesQueryVariables>(GetGocardlessMandatesDocument, options);
        }
export type GetGocardlessMandatesQueryHookResult = ReturnType<typeof useGetGocardlessMandatesQuery>;
export type GetGocardlessMandatesLazyQueryHookResult = ReturnType<typeof useGetGocardlessMandatesLazyQuery>;
export type GetGocardlessMandatesQueryResult = Apollo.QueryResult<GetGocardlessMandatesQuery, GetGocardlessMandatesQueryVariables>;
export const GetGocardlessRedirectFlowDocument = gql`
    query getGocardlessRedirectFlow($personId: BigInt, $sessionToken: UUID) {
  GocardlessRedirectFlow: getGocardlessRedirectFlowByPersonOrTemporaryPerson(
    personId: $personId
    sessionToken: $sessionToken
  ) {
    personId
    sessionToken
    gocardlessRedirectFlowId
    gocardlessRedirectFlowUrl
    expires
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetGocardlessRedirectFlowQuery__
 *
 * To run a query within a React component, call `useGetGocardlessRedirectFlowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGocardlessRedirectFlowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGocardlessRedirectFlowQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetGocardlessRedirectFlowQuery(baseOptions?: Apollo.QueryHookOptions<GetGocardlessRedirectFlowQuery, GetGocardlessRedirectFlowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGocardlessRedirectFlowQuery, GetGocardlessRedirectFlowQueryVariables>(GetGocardlessRedirectFlowDocument, options);
      }
export function useGetGocardlessRedirectFlowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGocardlessRedirectFlowQuery, GetGocardlessRedirectFlowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGocardlessRedirectFlowQuery, GetGocardlessRedirectFlowQueryVariables>(GetGocardlessRedirectFlowDocument, options);
        }
export type GetGocardlessRedirectFlowQueryHookResult = ReturnType<typeof useGetGocardlessRedirectFlowQuery>;
export type GetGocardlessRedirectFlowLazyQueryHookResult = ReturnType<typeof useGetGocardlessRedirectFlowLazyQuery>;
export type GetGocardlessRedirectFlowQueryResult = Apollo.QueryResult<GetGocardlessRedirectFlowQuery, GetGocardlessRedirectFlowQueryVariables>;
export const GetLotteryByIdDocument = gql`
    query getLotteryById($lotteryId: BigInt!) {
  Lottery: extendedLotteryById(id: $lotteryId) {
    ...LotteryFields
  }
}
    ${LotteryFieldsFragmentDoc}`;

/**
 * __useGetLotteryByIdQuery__
 *
 * To run a query within a React component, call `useGetLotteryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotteryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotteryByIdQuery({
 *   variables: {
 *      lotteryId: // value for 'lotteryId'
 *   },
 * });
 */
export function useGetLotteryByIdQuery(baseOptions: Apollo.QueryHookOptions<GetLotteryByIdQuery, GetLotteryByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLotteryByIdQuery, GetLotteryByIdQueryVariables>(GetLotteryByIdDocument, options);
      }
export function useGetLotteryByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLotteryByIdQuery, GetLotteryByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLotteryByIdQuery, GetLotteryByIdQueryVariables>(GetLotteryByIdDocument, options);
        }
export type GetLotteryByIdQueryHookResult = ReturnType<typeof useGetLotteryByIdQuery>;
export type GetLotteryByIdLazyQueryHookResult = ReturnType<typeof useGetLotteryByIdLazyQuery>;
export type GetLotteryByIdQueryResult = Apollo.QueryResult<GetLotteryByIdQuery, GetLotteryByIdQueryVariables>;
export const GetLotteryEntriesCompleteDocument = gql`
    query getLotteryEntriesComplete($lotteryId: BigInt!) {
  LotteryEntriesComplete: getLotteryEntriesCompleteByLotteryId(
    lotteryId: $lotteryId
  ) {
    edges {
      node {
        lotteryId
        paymentReference
        amountNormalized
        displayName
        tickets
        createdAt
        benefactor
      }
    }
  }
}
    `;

/**
 * __useGetLotteryEntriesCompleteQuery__
 *
 * To run a query within a React component, call `useGetLotteryEntriesCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotteryEntriesCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotteryEntriesCompleteQuery({
 *   variables: {
 *      lotteryId: // value for 'lotteryId'
 *   },
 * });
 */
export function useGetLotteryEntriesCompleteQuery(baseOptions: Apollo.QueryHookOptions<GetLotteryEntriesCompleteQuery, GetLotteryEntriesCompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLotteryEntriesCompleteQuery, GetLotteryEntriesCompleteQueryVariables>(GetLotteryEntriesCompleteDocument, options);
      }
export function useGetLotteryEntriesCompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLotteryEntriesCompleteQuery, GetLotteryEntriesCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLotteryEntriesCompleteQuery, GetLotteryEntriesCompleteQueryVariables>(GetLotteryEntriesCompleteDocument, options);
        }
export type GetLotteryEntriesCompleteQueryHookResult = ReturnType<typeof useGetLotteryEntriesCompleteQuery>;
export type GetLotteryEntriesCompleteLazyQueryHookResult = ReturnType<typeof useGetLotteryEntriesCompleteLazyQuery>;
export type GetLotteryEntriesCompleteQueryResult = Apollo.QueryResult<GetLotteryEntriesCompleteQuery, GetLotteryEntriesCompleteQueryVariables>;
export const GetLotteryEntriesPublicByLotteryIdDocument = gql`
    query getLotteryEntriesPublicByLotteryId($lotteryId: BigInt!) {
  LotteryEntriesPublic: getLotteryEntriesByLotteryId(lotteryId: $lotteryId) {
    edges {
      node {
        lotteryId
        paymentReference
        amount
        currencyCode
        amountNormalized
        giftAid
        total
        displayName
        createdAt
      }
    }
  }
}
    `;

/**
 * __useGetLotteryEntriesPublicByLotteryIdQuery__
 *
 * To run a query within a React component, call `useGetLotteryEntriesPublicByLotteryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotteryEntriesPublicByLotteryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotteryEntriesPublicByLotteryIdQuery({
 *   variables: {
 *      lotteryId: // value for 'lotteryId'
 *   },
 * });
 */
export function useGetLotteryEntriesPublicByLotteryIdQuery(baseOptions: Apollo.QueryHookOptions<GetLotteryEntriesPublicByLotteryIdQuery, GetLotteryEntriesPublicByLotteryIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLotteryEntriesPublicByLotteryIdQuery, GetLotteryEntriesPublicByLotteryIdQueryVariables>(GetLotteryEntriesPublicByLotteryIdDocument, options);
      }
export function useGetLotteryEntriesPublicByLotteryIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLotteryEntriesPublicByLotteryIdQuery, GetLotteryEntriesPublicByLotteryIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLotteryEntriesPublicByLotteryIdQuery, GetLotteryEntriesPublicByLotteryIdQueryVariables>(GetLotteryEntriesPublicByLotteryIdDocument, options);
        }
export type GetLotteryEntriesPublicByLotteryIdQueryHookResult = ReturnType<typeof useGetLotteryEntriesPublicByLotteryIdQuery>;
export type GetLotteryEntriesPublicByLotteryIdLazyQueryHookResult = ReturnType<typeof useGetLotteryEntriesPublicByLotteryIdLazyQuery>;
export type GetLotteryEntriesPublicByLotteryIdQueryResult = Apollo.QueryResult<GetLotteryEntriesPublicByLotteryIdQuery, GetLotteryEntriesPublicByLotteryIdQueryVariables>;
export const GetLotterySummariesDocument = gql`
    query getLotterySummaries {
  Lotteries: allLotterySummaries(orderBy: [DRAW_TIMESTAMP_DESC, BLOCK_SIZE_ASC]) {
    edges {
      node {
        ...LotterySummaryFields
      }
    }
  }
}
    ${LotterySummaryFieldsFragmentDoc}`;

/**
 * __useGetLotterySummariesQuery__
 *
 * To run a query within a React component, call `useGetLotterySummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotterySummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotterySummariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLotterySummariesQuery(baseOptions?: Apollo.QueryHookOptions<GetLotterySummariesQuery, GetLotterySummariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLotterySummariesQuery, GetLotterySummariesQueryVariables>(GetLotterySummariesDocument, options);
      }
export function useGetLotterySummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLotterySummariesQuery, GetLotterySummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLotterySummariesQuery, GetLotterySummariesQueryVariables>(GetLotterySummariesDocument, options);
        }
export type GetLotterySummariesQueryHookResult = ReturnType<typeof useGetLotterySummariesQuery>;
export type GetLotterySummariesLazyQueryHookResult = ReturnType<typeof useGetLotterySummariesLazyQuery>;
export type GetLotterySummariesQueryResult = Apollo.QueryResult<GetLotterySummariesQuery, GetLotterySummariesQueryVariables>;
export const GetLotterySummaryDocument = gql`
    query getLotterySummary($lotteryId: BigInt!) {
  LotterySummary: getLotterySummaryByLotteryId(lotteryId: $lotteryId) {
    ...LotterySummaryFields
  }
}
    ${LotterySummaryFieldsFragmentDoc}`;

/**
 * __useGetLotterySummaryQuery__
 *
 * To run a query within a React component, call `useGetLotterySummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotterySummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotterySummaryQuery({
 *   variables: {
 *      lotteryId: // value for 'lotteryId'
 *   },
 * });
 */
export function useGetLotterySummaryQuery(baseOptions: Apollo.QueryHookOptions<GetLotterySummaryQuery, GetLotterySummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLotterySummaryQuery, GetLotterySummaryQueryVariables>(GetLotterySummaryDocument, options);
      }
export function useGetLotterySummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLotterySummaryQuery, GetLotterySummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLotterySummaryQuery, GetLotterySummaryQueryVariables>(GetLotterySummaryDocument, options);
        }
export type GetLotterySummaryQueryHookResult = ReturnType<typeof useGetLotterySummaryQuery>;
export type GetLotterySummaryLazyQueryHookResult = ReturnType<typeof useGetLotterySummaryLazyQuery>;
export type GetLotterySummaryQueryResult = Apollo.QueryResult<GetLotterySummaryQuery, GetLotterySummaryQueryVariables>;
export const GetLotteryTicketsPublicByLotteryIdDocument = gql`
    query getLotteryTicketsPublicByLotteryId($lotteryId: BigInt!) {
  LotteryTicketsPublic: allLotteryTicketPublics(
    condition: {lotteryId: $lotteryId}
    orderBy: [WIN_RANGE_ASC]
  ) {
    edges {
      node {
        lotteryId
        paymentReference
        blockId
        winRange {
          start {
            value
            inclusive
          }
          end {
            value
            inclusive
          }
        }
        benefactor
      }
    }
  }
}
    `;

/**
 * __useGetLotteryTicketsPublicByLotteryIdQuery__
 *
 * To run a query within a React component, call `useGetLotteryTicketsPublicByLotteryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotteryTicketsPublicByLotteryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotteryTicketsPublicByLotteryIdQuery({
 *   variables: {
 *      lotteryId: // value for 'lotteryId'
 *   },
 * });
 */
export function useGetLotteryTicketsPublicByLotteryIdQuery(baseOptions: Apollo.QueryHookOptions<GetLotteryTicketsPublicByLotteryIdQuery, GetLotteryTicketsPublicByLotteryIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLotteryTicketsPublicByLotteryIdQuery, GetLotteryTicketsPublicByLotteryIdQueryVariables>(GetLotteryTicketsPublicByLotteryIdDocument, options);
      }
export function useGetLotteryTicketsPublicByLotteryIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLotteryTicketsPublicByLotteryIdQuery, GetLotteryTicketsPublicByLotteryIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLotteryTicketsPublicByLotteryIdQuery, GetLotteryTicketsPublicByLotteryIdQueryVariables>(GetLotteryTicketsPublicByLotteryIdDocument, options);
        }
export type GetLotteryTicketsPublicByLotteryIdQueryHookResult = ReturnType<typeof useGetLotteryTicketsPublicByLotteryIdQuery>;
export type GetLotteryTicketsPublicByLotteryIdLazyQueryHookResult = ReturnType<typeof useGetLotteryTicketsPublicByLotteryIdLazyQuery>;
export type GetLotteryTicketsPublicByLotteryIdQueryResult = Apollo.QueryResult<GetLotteryTicketsPublicByLotteryIdQuery, GetLotteryTicketsPublicByLotteryIdQueryVariables>;
export const GetLotteryWinnersDocument = gql`
    query getLotteryWinners($lotteryId: BigInt!) {
  LotteryWinners: allLotteryWinners(condition: {lotteryId: $lotteryId}) {
    edges {
      node {
        lotteryId
        paymentReference
        blockId
        benefactor
      }
    }
  }
}
    `;

/**
 * __useGetLotteryWinnersQuery__
 *
 * To run a query within a React component, call `useGetLotteryWinnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotteryWinnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotteryWinnersQuery({
 *   variables: {
 *      lotteryId: // value for 'lotteryId'
 *   },
 * });
 */
export function useGetLotteryWinnersQuery(baseOptions: Apollo.QueryHookOptions<GetLotteryWinnersQuery, GetLotteryWinnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLotteryWinnersQuery, GetLotteryWinnersQueryVariables>(GetLotteryWinnersDocument, options);
      }
export function useGetLotteryWinnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLotteryWinnersQuery, GetLotteryWinnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLotteryWinnersQuery, GetLotteryWinnersQueryVariables>(GetLotteryWinnersDocument, options);
        }
export type GetLotteryWinnersQueryHookResult = ReturnType<typeof useGetLotteryWinnersQuery>;
export type GetLotteryWinnersLazyQueryHookResult = ReturnType<typeof useGetLotteryWinnersLazyQuery>;
export type GetLotteryWinnersQueryResult = Apollo.QueryResult<GetLotteryWinnersQuery, GetLotteryWinnersQueryVariables>;
export const GetPaymentDocument = gql`
    query getPayment($paymentId: BigInt!) {
  Payment: paymentById(id: $paymentId) {
    ...PaymentFields
  }
}
    ${PaymentFieldsFragmentDoc}`;

/**
 * __useGetPaymentQuery__
 *
 * To run a query within a React component, call `useGetPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useGetPaymentQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, options);
      }
export function useGetPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, options);
        }
export type GetPaymentQueryHookResult = ReturnType<typeof useGetPaymentQuery>;
export type GetPaymentLazyQueryHookResult = ReturnType<typeof useGetPaymentLazyQuery>;
export type GetPaymentQueryResult = Apollo.QueryResult<GetPaymentQuery, GetPaymentQueryVariables>;
export const GetPaymentByPersonOrTemporaryPersonDocument = gql`
    query getPaymentByPersonOrTemporaryPerson($paymentId: BigInt!, $sessionToken: UUID) {
  Payment: getPaymentByPersonOrTemporaryPerson(
    paymentId: $paymentId
    sessionToken: $sessionToken
  ) {
    ...PaymentFields
  }
}
    ${PaymentFieldsFragmentDoc}`;

/**
 * __useGetPaymentByPersonOrTemporaryPersonQuery__
 *
 * To run a query within a React component, call `useGetPaymentByPersonOrTemporaryPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentByPersonOrTemporaryPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentByPersonOrTemporaryPersonQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetPaymentByPersonOrTemporaryPersonQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentByPersonOrTemporaryPersonQuery, GetPaymentByPersonOrTemporaryPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentByPersonOrTemporaryPersonQuery, GetPaymentByPersonOrTemporaryPersonQueryVariables>(GetPaymentByPersonOrTemporaryPersonDocument, options);
      }
export function useGetPaymentByPersonOrTemporaryPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentByPersonOrTemporaryPersonQuery, GetPaymentByPersonOrTemporaryPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentByPersonOrTemporaryPersonQuery, GetPaymentByPersonOrTemporaryPersonQueryVariables>(GetPaymentByPersonOrTemporaryPersonDocument, options);
        }
export type GetPaymentByPersonOrTemporaryPersonQueryHookResult = ReturnType<typeof useGetPaymentByPersonOrTemporaryPersonQuery>;
export type GetPaymentByPersonOrTemporaryPersonLazyQueryHookResult = ReturnType<typeof useGetPaymentByPersonOrTemporaryPersonLazyQuery>;
export type GetPaymentByPersonOrTemporaryPersonQueryResult = Apollo.QueryResult<GetPaymentByPersonOrTemporaryPersonQuery, GetPaymentByPersonOrTemporaryPersonQueryVariables>;
export const GetPaymentsDocument = gql`
    query getPayments($personId: BigInt!) {
  Payments: paymentsByPersonId(personId: $personId) {
    edges {
      node {
        ...PaymentFields
        giftAidClaimsByPaymentIdAndPersonId {
          edges {
            node {
              nodeId
              paymentId
              personId
              addressId
              reportId
            }
          }
        }
      }
    }
  }
}
    ${PaymentFieldsFragmentDoc}`;

/**
 * __useGetPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
      }
export function useGetPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
        }
export type GetPaymentsQueryHookResult = ReturnType<typeof useGetPaymentsQuery>;
export type GetPaymentsLazyQueryHookResult = ReturnType<typeof useGetPaymentsLazyQuery>;
export type GetPaymentsQueryResult = Apollo.QueryResult<GetPaymentsQuery, GetPaymentsQueryVariables>;
export const GetRecurringPaymentDocument = gql`
    query getRecurringPayment($id: BigInt!) {
  recurringPayment: recurringPaymentById(id: $id) {
    ...RecurringPaymentFields
  }
}
    ${RecurringPaymentFieldsFragmentDoc}`;

/**
 * __useGetRecurringPaymentQuery__
 *
 * To run a query within a React component, call `useGetRecurringPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecurringPaymentQuery(baseOptions: Apollo.QueryHookOptions<GetRecurringPaymentQuery, GetRecurringPaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecurringPaymentQuery, GetRecurringPaymentQueryVariables>(GetRecurringPaymentDocument, options);
      }
export function useGetRecurringPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecurringPaymentQuery, GetRecurringPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecurringPaymentQuery, GetRecurringPaymentQueryVariables>(GetRecurringPaymentDocument, options);
        }
export type GetRecurringPaymentQueryHookResult = ReturnType<typeof useGetRecurringPaymentQuery>;
export type GetRecurringPaymentLazyQueryHookResult = ReturnType<typeof useGetRecurringPaymentLazyQuery>;
export type GetRecurringPaymentQueryResult = Apollo.QueryResult<GetRecurringPaymentQuery, GetRecurringPaymentQueryVariables>;
export const GetRecurringPaymentByPersonOrTemporaryPersonDocument = gql`
    query getRecurringPaymentByPersonOrTemporaryPerson($id: BigInt, $sessionToken: UUID) {
  recurringPayment: getRecurringPaymentByPersonOrTemporaryPerson(
    recurringPaymentId: $id
    sessionToken: $sessionToken
  ) {
    ...RecurringPaymentFields
  }
}
    ${RecurringPaymentFieldsFragmentDoc}`;

/**
 * __useGetRecurringPaymentByPersonOrTemporaryPersonQuery__
 *
 * To run a query within a React component, call `useGetRecurringPaymentByPersonOrTemporaryPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringPaymentByPersonOrTemporaryPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringPaymentByPersonOrTemporaryPersonQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetRecurringPaymentByPersonOrTemporaryPersonQuery(baseOptions?: Apollo.QueryHookOptions<GetRecurringPaymentByPersonOrTemporaryPersonQuery, GetRecurringPaymentByPersonOrTemporaryPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecurringPaymentByPersonOrTemporaryPersonQuery, GetRecurringPaymentByPersonOrTemporaryPersonQueryVariables>(GetRecurringPaymentByPersonOrTemporaryPersonDocument, options);
      }
export function useGetRecurringPaymentByPersonOrTemporaryPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecurringPaymentByPersonOrTemporaryPersonQuery, GetRecurringPaymentByPersonOrTemporaryPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecurringPaymentByPersonOrTemporaryPersonQuery, GetRecurringPaymentByPersonOrTemporaryPersonQueryVariables>(GetRecurringPaymentByPersonOrTemporaryPersonDocument, options);
        }
export type GetRecurringPaymentByPersonOrTemporaryPersonQueryHookResult = ReturnType<typeof useGetRecurringPaymentByPersonOrTemporaryPersonQuery>;
export type GetRecurringPaymentByPersonOrTemporaryPersonLazyQueryHookResult = ReturnType<typeof useGetRecurringPaymentByPersonOrTemporaryPersonLazyQuery>;
export type GetRecurringPaymentByPersonOrTemporaryPersonQueryResult = Apollo.QueryResult<GetRecurringPaymentByPersonOrTemporaryPersonQuery, GetRecurringPaymentByPersonOrTemporaryPersonQueryVariables>;
export const GetRecurringPaymentsDocument = gql`
    query getRecurringPayments($personId: BigInt!) {
  RecurringPayments: recurringPaymentsByPersonId(personId: $personId) {
    edges {
      node {
        ...RecurringPaymentFields
      }
    }
  }
}
    ${RecurringPaymentFieldsFragmentDoc}`;

/**
 * __useGetRecurringPaymentsQuery__
 *
 * To run a query within a React component, call `useGetRecurringPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringPaymentsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetRecurringPaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetRecurringPaymentsQuery, GetRecurringPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecurringPaymentsQuery, GetRecurringPaymentsQueryVariables>(GetRecurringPaymentsDocument, options);
      }
export function useGetRecurringPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecurringPaymentsQuery, GetRecurringPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecurringPaymentsQuery, GetRecurringPaymentsQueryVariables>(GetRecurringPaymentsDocument, options);
        }
export type GetRecurringPaymentsQueryHookResult = ReturnType<typeof useGetRecurringPaymentsQuery>;
export type GetRecurringPaymentsLazyQueryHookResult = ReturnType<typeof useGetRecurringPaymentsLazyQuery>;
export type GetRecurringPaymentsQueryResult = Apollo.QueryResult<GetRecurringPaymentsQuery, GetRecurringPaymentsQueryVariables>;
export const GetTotalPledgeSignupsDocument = gql`
    query getTotalPledgeSignups($startDate: Date!, $excludeTrialPledges: Boolean = false) {
  TotalPledgeSignups: getTotalPledgeSignups(
    startDate: $startDate
    excludeTrialPledges: $excludeTrialPledges
  )
}
    `;

/**
 * __useGetTotalPledgeSignupsQuery__
 *
 * To run a query within a React component, call `useGetTotalPledgeSignupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalPledgeSignupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalPledgeSignupsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      excludeTrialPledges: // value for 'excludeTrialPledges'
 *   },
 * });
 */
export function useGetTotalPledgeSignupsQuery(baseOptions: Apollo.QueryHookOptions<GetTotalPledgeSignupsQuery, GetTotalPledgeSignupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTotalPledgeSignupsQuery, GetTotalPledgeSignupsQueryVariables>(GetTotalPledgeSignupsDocument, options);
      }
export function useGetTotalPledgeSignupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTotalPledgeSignupsQuery, GetTotalPledgeSignupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTotalPledgeSignupsQuery, GetTotalPledgeSignupsQueryVariables>(GetTotalPledgeSignupsDocument, options);
        }
export type GetTotalPledgeSignupsQueryHookResult = ReturnType<typeof useGetTotalPledgeSignupsQuery>;
export type GetTotalPledgeSignupsLazyQueryHookResult = ReturnType<typeof useGetTotalPledgeSignupsLazyQuery>;
export type GetTotalPledgeSignupsQueryResult = Apollo.QueryResult<GetTotalPledgeSignupsQuery, GetTotalPledgeSignupsQueryVariables>;
export const SendReceiptDocument = gql`
    query sendReceipt($paymentId: BigInt!) {
  sendReceipt(paymentId: $paymentId)
}
    `;

/**
 * __useSendReceiptQuery__
 *
 * To run a query within a React component, call `useSendReceiptQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendReceiptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendReceiptQuery({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useSendReceiptQuery(baseOptions: Apollo.QueryHookOptions<SendReceiptQuery, SendReceiptQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendReceiptQuery, SendReceiptQueryVariables>(SendReceiptDocument, options);
      }
export function useSendReceiptLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendReceiptQuery, SendReceiptQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendReceiptQuery, SendReceiptQueryVariables>(SendReceiptDocument, options);
        }
export type SendReceiptQueryHookResult = ReturnType<typeof useSendReceiptQuery>;
export type SendReceiptLazyQueryHookResult = ReturnType<typeof useSendReceiptLazyQuery>;
export type SendReceiptQueryResult = Apollo.QueryResult<SendReceiptQuery, SendReceiptQueryVariables>;
export const AddReminderDocument = gql`
    mutation addReminder($reminderDate: Date!, $email: String!, $newsletterSignup: Boolean!, $reminderSourcePath: String!) {
  addReminder(
    input: {email: $email, reminderDate: $reminderDate, newsletterSignup: $newsletterSignup, reminderSourcePath: $reminderSourcePath}
  ) {
    reminder {
      email
      reminderDate
      newsletterSignup
      reminderSourcePath
    }
  }
}
    `;
export type AddReminderMutationFn = Apollo.MutationFunction<AddReminderMutation, AddReminderMutationVariables>;

/**
 * __useAddReminderMutation__
 *
 * To run a mutation, you first call `useAddReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReminderMutation, { data, loading, error }] = useAddReminderMutation({
 *   variables: {
 *      reminderDate: // value for 'reminderDate'
 *      email: // value for 'email'
 *      newsletterSignup: // value for 'newsletterSignup'
 *      reminderSourcePath: // value for 'reminderSourcePath'
 *   },
 * });
 */
export function useAddReminderMutation(baseOptions?: Apollo.MutationHookOptions<AddReminderMutation, AddReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReminderMutation, AddReminderMutationVariables>(AddReminderDocument, options);
      }
export type AddReminderMutationHookResult = ReturnType<typeof useAddReminderMutation>;
export type AddReminderMutationResult = Apollo.MutationResult<AddReminderMutation>;
export type AddReminderMutationOptions = Apollo.BaseMutationOptions<AddReminderMutation, AddReminderMutationVariables>;
export const CreateAddressDocument = gql`
    mutation createAddress($personId: BigInt, $sessionToken: UUID, $addressType: AddressType, $firstName: String, $lastName: String, $houseNumber: String, $addressLine1: String, $addressLine2: String, $city: String, $region: String, $countryCode: String, $postalCode: String, $latitude: Float, $longitude: Float, $googlePlaceId: String) {
  mutationResult: createAddressByPersonOrTemporaryPerson(
    input: {personId: $personId, sessionToken: $sessionToken, addressType: $addressType, firstName: $firstName, lastName: $lastName, houseNumber: $houseNumber, addressLine1: $addressLine1, addressLine2: $addressLine2, city: $city, region: $region, countryCode: $countryCode, postalCode: $postalCode, latitude: $latitude, longitude: $longitude, googlePlaceId: $googlePlaceId}
  ) {
    address {
      id
      personId
      addressType
      firstName
      lastName
      houseNumber
      addressLine1
      addressLine2
      city
      region
      postalCode
      countryCode
    }
  }
}
    `;
export type CreateAddressMutationFn = Apollo.MutationFunction<CreateAddressMutation, CreateAddressMutationVariables>;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      sessionToken: // value for 'sessionToken'
 *      addressType: // value for 'addressType'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      houseNumber: // value for 'houseNumber'
 *      addressLine1: // value for 'addressLine1'
 *      addressLine2: // value for 'addressLine2'
 *      city: // value for 'city'
 *      region: // value for 'region'
 *      countryCode: // value for 'countryCode'
 *      postalCode: // value for 'postalCode'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      googlePlaceId: // value for 'googlePlaceId'
 *   },
 * });
 */
export function useCreateAddressMutation(baseOptions?: Apollo.MutationHookOptions<CreateAddressMutation, CreateAddressMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAddressMutation, CreateAddressMutationVariables>(CreateAddressDocument, options);
      }
export type CreateAddressMutationHookResult = ReturnType<typeof useCreateAddressMutation>;
export type CreateAddressMutationResult = Apollo.MutationResult<CreateAddressMutation>;
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<CreateAddressMutation, CreateAddressMutationVariables>;
export const CreateTemporaryPersonDocument = gql`
    mutation createTemporaryPerson($email: String!, $recaptchaToken: String!, $isLongLasting: Boolean = false) {
  createTemporaryPersonByEmail(
    input: {email: $email, recaptchaToken: $recaptchaToken, isLongLasting: $isLongLasting}
  ) {
    TemporaryPerson: temporaryPerson {
      email
      sessionToken
      tokenIssuedAt
      tokenExpiresAt
    }
  }
}
    `;
export type CreateTemporaryPersonMutationFn = Apollo.MutationFunction<CreateTemporaryPersonMutation, CreateTemporaryPersonMutationVariables>;

/**
 * __useCreateTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useCreateTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemporaryPersonMutation, { data, loading, error }] = useCreateTemporaryPersonMutation({
 *   variables: {
 *      email: // value for 'email'
 *      recaptchaToken: // value for 'recaptchaToken'
 *      isLongLasting: // value for 'isLongLasting'
 *   },
 * });
 */
export function useCreateTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemporaryPersonMutation, CreateTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemporaryPersonMutation, CreateTemporaryPersonMutationVariables>(CreateTemporaryPersonDocument, options);
      }
export type CreateTemporaryPersonMutationHookResult = ReturnType<typeof useCreateTemporaryPersonMutation>;
export type CreateTemporaryPersonMutationResult = Apollo.MutationResult<CreateTemporaryPersonMutation>;
export type CreateTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<CreateTemporaryPersonMutation, CreateTemporaryPersonMutationVariables>;
export const SyncAuth0UserDocument = gql`
    mutation syncAuth0User($auth0User: JSON!) {
  syncAuth0User(input: {auth0User: $auth0User}) {
    Person: person {
      id
      firstName
      lastName
      email
      fullName
      birthDate
      createdAt
      updatedAt
      isAnonymized
      auth0UserId
    }
  }
}
    `;
export type SyncAuth0UserMutationFn = Apollo.MutationFunction<SyncAuth0UserMutation, SyncAuth0UserMutationVariables>;

/**
 * __useSyncAuth0UserMutation__
 *
 * To run a mutation, you first call `useSyncAuth0UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncAuth0UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncAuth0UserMutation, { data, loading, error }] = useSyncAuth0UserMutation({
 *   variables: {
 *      auth0User: // value for 'auth0User'
 *   },
 * });
 */
export function useSyncAuth0UserMutation(baseOptions?: Apollo.MutationHookOptions<SyncAuth0UserMutation, SyncAuth0UserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncAuth0UserMutation, SyncAuth0UserMutationVariables>(SyncAuth0UserDocument, options);
      }
export type SyncAuth0UserMutationHookResult = ReturnType<typeof useSyncAuth0UserMutation>;
export type SyncAuth0UserMutationResult = Apollo.MutationResult<SyncAuth0UserMutation>;
export type SyncAuth0UserMutationOptions = Apollo.BaseMutationOptions<SyncAuth0UserMutation, SyncAuth0UserMutationVariables>;
export const UpdatePersonByIdDocument = gql`
    mutation updatePersonById($personId: BigInt!, $firstName: String, $lastName: String, $email: String, $birthDate: Date) {
  updatePersonByPersonId(
    input: {personId: $personId, firstName: $firstName, lastName: $lastName, email: $email, birthDate: $birthDate}
  ) {
    person {
      id
      fullName
      firstName
      lastName
      email
      birthDate
    }
  }
}
    `;
export type UpdatePersonByIdMutationFn = Apollo.MutationFunction<UpdatePersonByIdMutation, UpdatePersonByIdMutationVariables>;

/**
 * __useUpdatePersonByIdMutation__
 *
 * To run a mutation, you first call `useUpdatePersonByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonByIdMutation, { data, loading, error }] = useUpdatePersonByIdMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      birthDate: // value for 'birthDate'
 *   },
 * });
 */
export function useUpdatePersonByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonByIdMutation, UpdatePersonByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonByIdMutation, UpdatePersonByIdMutationVariables>(UpdatePersonByIdDocument, options);
      }
export type UpdatePersonByIdMutationHookResult = ReturnType<typeof useUpdatePersonByIdMutation>;
export type UpdatePersonByIdMutationResult = Apollo.MutationResult<UpdatePersonByIdMutation>;
export type UpdatePersonByIdMutationOptions = Apollo.BaseMutationOptions<UpdatePersonByIdMutation, UpdatePersonByIdMutationVariables>;
export const UpdatePersonEmailDocument = gql`
    mutation updatePersonEmail($personId: BigInt!, $email: String!) {
  Person: updatePersonEmailAuth0(input: {personId: $personId, email: $email}) {
    message: string
  }
}
    `;
export type UpdatePersonEmailMutationFn = Apollo.MutationFunction<UpdatePersonEmailMutation, UpdatePersonEmailMutationVariables>;

/**
 * __useUpdatePersonEmailMutation__
 *
 * To run a mutation, you first call `useUpdatePersonEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonEmailMutation, { data, loading, error }] = useUpdatePersonEmailMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdatePersonEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonEmailMutation, UpdatePersonEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonEmailMutation, UpdatePersonEmailMutationVariables>(UpdatePersonEmailDocument, options);
      }
export type UpdatePersonEmailMutationHookResult = ReturnType<typeof useUpdatePersonEmailMutation>;
export type UpdatePersonEmailMutationResult = Apollo.MutationResult<UpdatePersonEmailMutation>;
export type UpdatePersonEmailMutationOptions = Apollo.BaseMutationOptions<UpdatePersonEmailMutation, UpdatePersonEmailMutationVariables>;
export const FindPersonByNameOrEmailDocument = gql`
    query findPersonByNameOrEmail($searchText: String!) {
  People: findPersonByNameOrEmail(searchText: $searchText) {
    edges {
      node {
        id
        email
        firstName
        lastName
        fullName
      }
    }
  }
}
    `;

/**
 * __useFindPersonByNameOrEmailQuery__
 *
 * To run a query within a React component, call `useFindPersonByNameOrEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPersonByNameOrEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPersonByNameOrEmailQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useFindPersonByNameOrEmailQuery(baseOptions: Apollo.QueryHookOptions<FindPersonByNameOrEmailQuery, FindPersonByNameOrEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindPersonByNameOrEmailQuery, FindPersonByNameOrEmailQueryVariables>(FindPersonByNameOrEmailDocument, options);
      }
export function useFindPersonByNameOrEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindPersonByNameOrEmailQuery, FindPersonByNameOrEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindPersonByNameOrEmailQuery, FindPersonByNameOrEmailQueryVariables>(FindPersonByNameOrEmailDocument, options);
        }
export type FindPersonByNameOrEmailQueryHookResult = ReturnType<typeof useFindPersonByNameOrEmailQuery>;
export type FindPersonByNameOrEmailLazyQueryHookResult = ReturnType<typeof useFindPersonByNameOrEmailLazyQuery>;
export type FindPersonByNameOrEmailQueryResult = Apollo.QueryResult<FindPersonByNameOrEmailQuery, FindPersonByNameOrEmailQueryVariables>;
export const GetAddressesDocument = gql`
    query getAddresses($personId: BigInt, $sessionToken: UUID) {
  Addresses: getAddressesByPersonOrTemporaryPerson(
    personId: $personId
    sessionToken: $sessionToken
  ) {
    edges {
      node {
        id
        personId
        addressType
        firstName
        lastName
        houseNumber
        addressLine1
        addressLine2
        city
        region
        postalCode
        countryCode
        coordinates
        createdAt
        updatedAt
      }
    }
  }
}
    `;

/**
 * __useGetAddressesQuery__
 *
 * To run a query within a React component, call `useGetAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressesQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetAddressesQuery(baseOptions?: Apollo.QueryHookOptions<GetAddressesQuery, GetAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddressesQuery, GetAddressesQueryVariables>(GetAddressesDocument, options);
      }
export function useGetAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddressesQuery, GetAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddressesQuery, GetAddressesQueryVariables>(GetAddressesDocument, options);
        }
export type GetAddressesQueryHookResult = ReturnType<typeof useGetAddressesQuery>;
export type GetAddressesLazyQueryHookResult = ReturnType<typeof useGetAddressesLazyQuery>;
export type GetAddressesQueryResult = Apollo.QueryResult<GetAddressesQuery, GetAddressesQueryVariables>;
export const GetPersonDocument = gql`
    query getPerson {
  Person: currentPerson {
    id
    email
    fullName
    firstName
    lastName
    birthDate
    auth0UserId
  }
}
    `;

/**
 * __useGetPersonQuery__
 *
 * To run a query within a React component, call `useGetPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPersonQuery(baseOptions?: Apollo.QueryHookOptions<GetPersonQuery, GetPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, options);
      }
export function useGetPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonQuery, GetPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonQuery, GetPersonQueryVariables>(GetPersonDocument, options);
        }
export type GetPersonQueryHookResult = ReturnType<typeof useGetPersonQuery>;
export type GetPersonLazyQueryHookResult = ReturnType<typeof useGetPersonLazyQuery>;
export type GetPersonQueryResult = Apollo.QueryResult<GetPersonQuery, GetPersonQueryVariables>;
export const GetPersonByIdDocument = gql`
    query getPersonById($personId: BigInt!) {
  Person: personById(id: $personId) {
    id
    firstName
    lastName
    fullName
    email
    birthDate
    createdAt
    updatedAt
    auth0UserId
  }
}
    `;

/**
 * __useGetPersonByIdQuery__
 *
 * To run a query within a React component, call `useGetPersonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonByIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPersonByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPersonByIdQuery, GetPersonByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPersonByIdQuery, GetPersonByIdQueryVariables>(GetPersonByIdDocument, options);
      }
export function useGetPersonByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPersonByIdQuery, GetPersonByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPersonByIdQuery, GetPersonByIdQueryVariables>(GetPersonByIdDocument, options);
        }
export type GetPersonByIdQueryHookResult = ReturnType<typeof useGetPersonByIdQuery>;
export type GetPersonByIdLazyQueryHookResult = ReturnType<typeof useGetPersonByIdLazyQuery>;
export type GetPersonByIdQueryResult = Apollo.QueryResult<GetPersonByIdQuery, GetPersonByIdQueryVariables>;
export const CancelRecurringReportedDonationDocument = gql`
    mutation cancelRecurringReportedDonation($recurringReportedDonationId: BigInt!) {
  cancelRecurringReportedDonation(
    input: {recurringReportedDonationId: $recurringReportedDonationId}
  ) {
    RecurringReportedDonation: recurringReportedDonation {
      id
    }
  }
}
    `;
export type CancelRecurringReportedDonationMutationFn = Apollo.MutationFunction<CancelRecurringReportedDonationMutation, CancelRecurringReportedDonationMutationVariables>;

/**
 * __useCancelRecurringReportedDonationMutation__
 *
 * To run a mutation, you first call `useCancelRecurringReportedDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRecurringReportedDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRecurringReportedDonationMutation, { data, loading, error }] = useCancelRecurringReportedDonationMutation({
 *   variables: {
 *      recurringReportedDonationId: // value for 'recurringReportedDonationId'
 *   },
 * });
 */
export function useCancelRecurringReportedDonationMutation(baseOptions?: Apollo.MutationHookOptions<CancelRecurringReportedDonationMutation, CancelRecurringReportedDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelRecurringReportedDonationMutation, CancelRecurringReportedDonationMutationVariables>(CancelRecurringReportedDonationDocument, options);
      }
export type CancelRecurringReportedDonationMutationHookResult = ReturnType<typeof useCancelRecurringReportedDonationMutation>;
export type CancelRecurringReportedDonationMutationResult = Apollo.MutationResult<CancelRecurringReportedDonationMutation>;
export type CancelRecurringReportedDonationMutationOptions = Apollo.BaseMutationOptions<CancelRecurringReportedDonationMutation, CancelRecurringReportedDonationMutationVariables>;
export const CreateFinancialPeriodDocument = gql`
    mutation createFinancialPeriod($personId: BigInt!, $startDate: Date!, $endDate: Date!, $wealthAmount: BigFloat, $currencyCode: String, $employmentStatus: IncomeEmploymentStatus, $donationsTaxDeductible: Boolean, $incomes: [IncomeInput]) {
  createFinancialPeriod(
    input: {personId: $personId, period: {start: {value: $startDate, inclusive: true}, end: {value: $endDate, inclusive: true}}, wealthAmount: $wealthAmount, currencyCode: $currencyCode, employmentStatus: $employmentStatus, donationsTaxDeductible: $donationsTaxDeductible, incomes: $incomes}
  ) {
    financialPeriod {
      id
      personId
      period {
        start {
          value
          inclusive
        }
        end {
          value
          inclusive
        }
      }
      wealthAmount
      currencyCode
    }
  }
}
    `;
export type CreateFinancialPeriodMutationFn = Apollo.MutationFunction<CreateFinancialPeriodMutation, CreateFinancialPeriodMutationVariables>;

/**
 * __useCreateFinancialPeriodMutation__
 *
 * To run a mutation, you first call `useCreateFinancialPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFinancialPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFinancialPeriodMutation, { data, loading, error }] = useCreateFinancialPeriodMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      wealthAmount: // value for 'wealthAmount'
 *      currencyCode: // value for 'currencyCode'
 *      employmentStatus: // value for 'employmentStatus'
 *      donationsTaxDeductible: // value for 'donationsTaxDeductible'
 *      incomes: // value for 'incomes'
 *   },
 * });
 */
export function useCreateFinancialPeriodMutation(baseOptions?: Apollo.MutationHookOptions<CreateFinancialPeriodMutation, CreateFinancialPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFinancialPeriodMutation, CreateFinancialPeriodMutationVariables>(CreateFinancialPeriodDocument, options);
      }
export type CreateFinancialPeriodMutationHookResult = ReturnType<typeof useCreateFinancialPeriodMutation>;
export type CreateFinancialPeriodMutationResult = Apollo.MutationResult<CreateFinancialPeriodMutation>;
export type CreateFinancialPeriodMutationOptions = Apollo.BaseMutationOptions<CreateFinancialPeriodMutation, CreateFinancialPeriodMutationVariables>;
export const CreateRecurringReportedDonationDocument = gql`
    mutation createRecurringReportedDonation($personId: BigInt!, $organizationId: BigInt!, $amount: BigFloat!, $currencyCode: String!, $startDate: Date!, $recurrenceInterval: IntervalInput!) {
  RecurringReportedDonation: createRecurringReportedDonation(
    input: {personId: $personId, organizationId: $organizationId, amount: $amount, currencyCode: $currencyCode, startDate: $startDate, recurrenceInterval: $recurrenceInterval}
  ) {
    recurringReportedDonation {
      id
      personId
      organizationId
      amount
      currencyCode
      startDate
      recurrenceInterval {
        years
        months
        days
        hours
        minutes
        seconds
      }
      nextDate
      status
      createdAt
      updatedAt
    }
  }
}
    `;
export type CreateRecurringReportedDonationMutationFn = Apollo.MutationFunction<CreateRecurringReportedDonationMutation, CreateRecurringReportedDonationMutationVariables>;

/**
 * __useCreateRecurringReportedDonationMutation__
 *
 * To run a mutation, you first call `useCreateRecurringReportedDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecurringReportedDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecurringReportedDonationMutation, { data, loading, error }] = useCreateRecurringReportedDonationMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      organizationId: // value for 'organizationId'
 *      amount: // value for 'amount'
 *      currencyCode: // value for 'currencyCode'
 *      startDate: // value for 'startDate'
 *      recurrenceInterval: // value for 'recurrenceInterval'
 *   },
 * });
 */
export function useCreateRecurringReportedDonationMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecurringReportedDonationMutation, CreateRecurringReportedDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecurringReportedDonationMutation, CreateRecurringReportedDonationMutationVariables>(CreateRecurringReportedDonationDocument, options);
      }
export type CreateRecurringReportedDonationMutationHookResult = ReturnType<typeof useCreateRecurringReportedDonationMutation>;
export type CreateRecurringReportedDonationMutationResult = Apollo.MutationResult<CreateRecurringReportedDonationMutation>;
export type CreateRecurringReportedDonationMutationOptions = Apollo.BaseMutationOptions<CreateRecurringReportedDonationMutation, CreateRecurringReportedDonationMutationVariables>;
export const CreateReportedDonationDocument = gql`
    mutation createReportedDonation($personId: BigInt!, $donationDate: Date!, $amount: BigFloat!, $currencyCode: String!, $organizationId: BigInt!, $pledgeId: BigInt, $financialPeriodId: BigInt) {
  createReportedDonationByPersonId(
    input: {personId: $personId, donationDate: $donationDate, amount: $amount, currencyCode: $currencyCode, organizationId: $organizationId, pledgeId: $pledgeId, financialPeriodId: $financialPeriodId}
  ) {
    reportedDonation {
      id
      personId
      donationDate
      amount
      currencyCode
      organizationId
    }
  }
}
    `;
export type CreateReportedDonationMutationFn = Apollo.MutationFunction<CreateReportedDonationMutation, CreateReportedDonationMutationVariables>;

/**
 * __useCreateReportedDonationMutation__
 *
 * To run a mutation, you first call `useCreateReportedDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportedDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportedDonationMutation, { data, loading, error }] = useCreateReportedDonationMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      donationDate: // value for 'donationDate'
 *      amount: // value for 'amount'
 *      currencyCode: // value for 'currencyCode'
 *      organizationId: // value for 'organizationId'
 *      pledgeId: // value for 'pledgeId'
 *      financialPeriodId: // value for 'financialPeriodId'
 *   },
 * });
 */
export function useCreateReportedDonationMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportedDonationMutation, CreateReportedDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportedDonationMutation, CreateReportedDonationMutationVariables>(CreateReportedDonationDocument, options);
      }
export type CreateReportedDonationMutationHookResult = ReturnType<typeof useCreateReportedDonationMutation>;
export type CreateReportedDonationMutationResult = Apollo.MutationResult<CreateReportedDonationMutation>;
export type CreateReportedDonationMutationOptions = Apollo.BaseMutationOptions<CreateReportedDonationMutation, CreateReportedDonationMutationVariables>;
export const DeleteFinancialPeriodDocument = gql`
    mutation deleteFinancialPeriod($id: BigInt!) {
  deleteFinancialPeriod(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export type DeleteFinancialPeriodMutationFn = Apollo.MutationFunction<DeleteFinancialPeriodMutation, DeleteFinancialPeriodMutationVariables>;

/**
 * __useDeleteFinancialPeriodMutation__
 *
 * To run a mutation, you first call `useDeleteFinancialPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFinancialPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFinancialPeriodMutation, { data, loading, error }] = useDeleteFinancialPeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFinancialPeriodMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFinancialPeriodMutation, DeleteFinancialPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFinancialPeriodMutation, DeleteFinancialPeriodMutationVariables>(DeleteFinancialPeriodDocument, options);
      }
export type DeleteFinancialPeriodMutationHookResult = ReturnType<typeof useDeleteFinancialPeriodMutation>;
export type DeleteFinancialPeriodMutationResult = Apollo.MutationResult<DeleteFinancialPeriodMutation>;
export type DeleteFinancialPeriodMutationOptions = Apollo.BaseMutationOptions<DeleteFinancialPeriodMutation, DeleteFinancialPeriodMutationVariables>;
export const DeleteReportedDonationDocument = gql`
    mutation deleteReportedDonation($reportedDonationId: BigInt!) {
  deleteReportedDonation(input: {id: $reportedDonationId}) {
    clientMutationId
  }
}
    `;
export type DeleteReportedDonationMutationFn = Apollo.MutationFunction<DeleteReportedDonationMutation, DeleteReportedDonationMutationVariables>;

/**
 * __useDeleteReportedDonationMutation__
 *
 * To run a mutation, you first call `useDeleteReportedDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportedDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportedDonationMutation, { data, loading, error }] = useDeleteReportedDonationMutation({
 *   variables: {
 *      reportedDonationId: // value for 'reportedDonationId'
 *   },
 * });
 */
export function useDeleteReportedDonationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportedDonationMutation, DeleteReportedDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportedDonationMutation, DeleteReportedDonationMutationVariables>(DeleteReportedDonationDocument, options);
      }
export type DeleteReportedDonationMutationHookResult = ReturnType<typeof useDeleteReportedDonationMutation>;
export type DeleteReportedDonationMutationResult = Apollo.MutationResult<DeleteReportedDonationMutation>;
export type DeleteReportedDonationMutationOptions = Apollo.BaseMutationOptions<DeleteReportedDonationMutation, DeleteReportedDonationMutationVariables>;
export const SignPledgeDocument = gql`
    mutation signPledge($personId: BigInt!, $startDate: Date!, $endDate: Date!, $incomePercentage: BigFloat!, $wealthPercentage: BigFloat, $pledgeType: PledgeType!, $motivation: String, $locale: String, $metadata: JSON!, $incomeAmount: BigFloat, $wealthAmount: BigFloat, $currencyCode: String!, $employmentStatus: IncomeEmploymentStatus!, $firstName: String, $lastName: String, $birthDate: Date) {
  signPledge(
    input: {personId: $personId, pledgePeriod: {start: {value: $startDate, inclusive: true}, end: {value: $endDate, inclusive: true}}, incomePercentage: $incomePercentage, wealthPercentage: $wealthPercentage, pledgeType: $pledgeType, motivation: $motivation, locale: $locale, metadata: $metadata, incomeAmount: $incomeAmount, wealthAmount: $wealthAmount, currencyCode: $currencyCode, employmentStatus: $employmentStatus, personFirstName: $firstName, personLastName: $lastName, personBirthDate: $birthDate}
  ) {
    pledge {
      id
      personId
      incomePercentage
      wealthPercentage
      motivation
      locale
      metadata
      pledgeType
      period {
        start {
          value
          inclusive
        }
        end {
          value
          inclusive
        }
      }
    }
  }
}
    `;
export type SignPledgeMutationFn = Apollo.MutationFunction<SignPledgeMutation, SignPledgeMutationVariables>;

/**
 * __useSignPledgeMutation__
 *
 * To run a mutation, you first call `useSignPledgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignPledgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signPledgeMutation, { data, loading, error }] = useSignPledgeMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      incomePercentage: // value for 'incomePercentage'
 *      wealthPercentage: // value for 'wealthPercentage'
 *      pledgeType: // value for 'pledgeType'
 *      motivation: // value for 'motivation'
 *      locale: // value for 'locale'
 *      metadata: // value for 'metadata'
 *      incomeAmount: // value for 'incomeAmount'
 *      wealthAmount: // value for 'wealthAmount'
 *      currencyCode: // value for 'currencyCode'
 *      employmentStatus: // value for 'employmentStatus'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      birthDate: // value for 'birthDate'
 *   },
 * });
 */
export function useSignPledgeMutation(baseOptions?: Apollo.MutationHookOptions<SignPledgeMutation, SignPledgeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignPledgeMutation, SignPledgeMutationVariables>(SignPledgeDocument, options);
      }
export type SignPledgeMutationHookResult = ReturnType<typeof useSignPledgeMutation>;
export type SignPledgeMutationResult = Apollo.MutationResult<SignPledgeMutation>;
export type SignPledgeMutationOptions = Apollo.BaseMutationOptions<SignPledgeMutation, SignPledgeMutationVariables>;
export const SignPledgeByTemporaryPersonDocument = gql`
    mutation signPledgeByTemporaryPerson($sessionToken: UUID!, $startDate: Date!, $endDate: Date!, $incomePercentage: BigFloat!, $wealthPercentage: BigFloat, $pledgeType: PledgeType!, $motivation: String, $locale: String, $metadata: JSON!, $incomeAmount: BigFloat, $wealthAmount: BigFloat, $currencyCode: String!, $employmentStatus: IncomeEmploymentStatus!, $firstName: String, $lastName: String, $birthDate: Date) {
  signPledgeByTemporaryPerson(
    input: {sessionToken: $sessionToken, pledgePeriod: {start: {value: $startDate, inclusive: true}, end: {value: $endDate, inclusive: true}}, incomePercentage: $incomePercentage, wealthPercentage: $wealthPercentage, pledgeType: $pledgeType, motivation: $motivation, locale: $locale, metadata: $metadata, incomeAmount: $incomeAmount, wealthAmount: $wealthAmount, currencyCode: $currencyCode, employmentStatus: $employmentStatus, personFirstName: $firstName, personLastName: $lastName, personBirthDate: $birthDate}
  ) {
    pledge {
      id
      personId
      incomePercentage
      wealthPercentage
      motivation
      locale
      metadata
      pledgeType
      period {
        start {
          value
          inclusive
        }
        end {
          value
          inclusive
        }
      }
    }
  }
}
    `;
export type SignPledgeByTemporaryPersonMutationFn = Apollo.MutationFunction<SignPledgeByTemporaryPersonMutation, SignPledgeByTemporaryPersonMutationVariables>;

/**
 * __useSignPledgeByTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useSignPledgeByTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignPledgeByTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signPledgeByTemporaryPersonMutation, { data, loading, error }] = useSignPledgeByTemporaryPersonMutation({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      incomePercentage: // value for 'incomePercentage'
 *      wealthPercentage: // value for 'wealthPercentage'
 *      pledgeType: // value for 'pledgeType'
 *      motivation: // value for 'motivation'
 *      locale: // value for 'locale'
 *      metadata: // value for 'metadata'
 *      incomeAmount: // value for 'incomeAmount'
 *      wealthAmount: // value for 'wealthAmount'
 *      currencyCode: // value for 'currencyCode'
 *      employmentStatus: // value for 'employmentStatus'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      birthDate: // value for 'birthDate'
 *   },
 * });
 */
export function useSignPledgeByTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<SignPledgeByTemporaryPersonMutation, SignPledgeByTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignPledgeByTemporaryPersonMutation, SignPledgeByTemporaryPersonMutationVariables>(SignPledgeByTemporaryPersonDocument, options);
      }
export type SignPledgeByTemporaryPersonMutationHookResult = ReturnType<typeof useSignPledgeByTemporaryPersonMutation>;
export type SignPledgeByTemporaryPersonMutationResult = Apollo.MutationResult<SignPledgeByTemporaryPersonMutation>;
export type SignPledgeByTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<SignPledgeByTemporaryPersonMutation, SignPledgeByTemporaryPersonMutationVariables>;
export const UpdateFinancialPeriodDocument = gql`
    mutation updateFinancialPeriod($id: BigInt!, $startDate: Date!, $endDate: Date!, $wealthAmount: BigFloat, $currencyCode: String, $employmentStatus: IncomeEmploymentStatus, $donationsTaxDeductible: Boolean, $incomes: [IncomeInput]) {
  updateFinancialPeriod(
    input: {id: $id, period: {start: {value: $startDate, inclusive: true}, end: {value: $endDate, inclusive: true}}, wealthAmount: $wealthAmount, currencyCode: $currencyCode, employmentStatus: $employmentStatus, donationsTaxDeductible: $donationsTaxDeductible, incomes: $incomes}
  ) {
    financialPeriod {
      id
      personId
      period {
        start {
          value
          inclusive
        }
        end {
          value
          inclusive
        }
      }
      wealthAmount
      currencyCode
    }
  }
}
    `;
export type UpdateFinancialPeriodMutationFn = Apollo.MutationFunction<UpdateFinancialPeriodMutation, UpdateFinancialPeriodMutationVariables>;

/**
 * __useUpdateFinancialPeriodMutation__
 *
 * To run a mutation, you first call `useUpdateFinancialPeriodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFinancialPeriodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFinancialPeriodMutation, { data, loading, error }] = useUpdateFinancialPeriodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      wealthAmount: // value for 'wealthAmount'
 *      currencyCode: // value for 'currencyCode'
 *      employmentStatus: // value for 'employmentStatus'
 *      donationsTaxDeductible: // value for 'donationsTaxDeductible'
 *      incomes: // value for 'incomes'
 *   },
 * });
 */
export function useUpdateFinancialPeriodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFinancialPeriodMutation, UpdateFinancialPeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFinancialPeriodMutation, UpdateFinancialPeriodMutationVariables>(UpdateFinancialPeriodDocument, options);
      }
export type UpdateFinancialPeriodMutationHookResult = ReturnType<typeof useUpdateFinancialPeriodMutation>;
export type UpdateFinancialPeriodMutationResult = Apollo.MutationResult<UpdateFinancialPeriodMutation>;
export type UpdateFinancialPeriodMutationOptions = Apollo.BaseMutationOptions<UpdateFinancialPeriodMutation, UpdateFinancialPeriodMutationVariables>;
export const UpdateReportedPaymentDocument = gql`
    mutation updateReportedPayment($id: BigInt!, $personId: BigInt!, $pledgeId: BigInt, $financialPeriodId: BigInt) {
  updateReportedPayment(
    input: {id: $id, personId: $personId, pledgeId: $pledgeId, financialPeriodId: $financialPeriodId}
  ) {
    payment {
      id
    }
  }
}
    `;
export type UpdateReportedPaymentMutationFn = Apollo.MutationFunction<UpdateReportedPaymentMutation, UpdateReportedPaymentMutationVariables>;

/**
 * __useUpdateReportedPaymentMutation__
 *
 * To run a mutation, you first call `useUpdateReportedPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportedPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportedPaymentMutation, { data, loading, error }] = useUpdateReportedPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      personId: // value for 'personId'
 *      pledgeId: // value for 'pledgeId'
 *      financialPeriodId: // value for 'financialPeriodId'
 *   },
 * });
 */
export function useUpdateReportedPaymentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportedPaymentMutation, UpdateReportedPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportedPaymentMutation, UpdateReportedPaymentMutationVariables>(UpdateReportedPaymentDocument, options);
      }
export type UpdateReportedPaymentMutationHookResult = ReturnType<typeof useUpdateReportedPaymentMutation>;
export type UpdateReportedPaymentMutationResult = Apollo.MutationResult<UpdateReportedPaymentMutation>;
export type UpdateReportedPaymentMutationOptions = Apollo.BaseMutationOptions<UpdateReportedPaymentMutation, UpdateReportedPaymentMutationVariables>;
export const UpdatePledgeByPersonOrTemporaryPersonDocument = gql`
    mutation updatePledgeByPersonOrTemporaryPerson($pledgeId: BigInt!, $metadata: JSON!, $sessionToken: UUID) {
  updatePledgeByPersonOrTemporaryPerson(
    input: {pledgeId: $pledgeId, metadata: $metadata, sessionToken: $sessionToken}
  ) {
    pledge {
      id
      metadata
    }
  }
}
    `;
export type UpdatePledgeByPersonOrTemporaryPersonMutationFn = Apollo.MutationFunction<UpdatePledgeByPersonOrTemporaryPersonMutation, UpdatePledgeByPersonOrTemporaryPersonMutationVariables>;

/**
 * __useUpdatePledgeByPersonOrTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useUpdatePledgeByPersonOrTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePledgeByPersonOrTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePledgeByPersonOrTemporaryPersonMutation, { data, loading, error }] = useUpdatePledgeByPersonOrTemporaryPersonMutation({
 *   variables: {
 *      pledgeId: // value for 'pledgeId'
 *      metadata: // value for 'metadata'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useUpdatePledgeByPersonOrTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePledgeByPersonOrTemporaryPersonMutation, UpdatePledgeByPersonOrTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePledgeByPersonOrTemporaryPersonMutation, UpdatePledgeByPersonOrTemporaryPersonMutationVariables>(UpdatePledgeByPersonOrTemporaryPersonDocument, options);
      }
export type UpdatePledgeByPersonOrTemporaryPersonMutationHookResult = ReturnType<typeof useUpdatePledgeByPersonOrTemporaryPersonMutation>;
export type UpdatePledgeByPersonOrTemporaryPersonMutationResult = Apollo.MutationResult<UpdatePledgeByPersonOrTemporaryPersonMutation>;
export type UpdatePledgeByPersonOrTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<UpdatePledgeByPersonOrTemporaryPersonMutation, UpdatePledgeByPersonOrTemporaryPersonMutationVariables>;
export const UpdateReportedDonationDocument = gql`
    mutation updateReportedDonation($id: BigInt!, $personId: BigInt!, $donationDate: Date!, $amount: BigFloat!, $currencyCode: String!, $organizationId: BigInt!, $pledgeId: BigInt, $financialPeriodId: BigInt) {
  updateReportedDonation(
    input: {id: $id, personId: $personId, donationDate: $donationDate, amount: $amount, currencyCode: $currencyCode, organizationId: $organizationId, pledgeId: $pledgeId, financialPeriodId: $financialPeriodId}
  ) {
    reportedDonation {
      id
      personId
      donationDate
      amount
      currencyCode
      organizationId
    }
  }
}
    `;
export type UpdateReportedDonationMutationFn = Apollo.MutationFunction<UpdateReportedDonationMutation, UpdateReportedDonationMutationVariables>;

/**
 * __useUpdateReportedDonationMutation__
 *
 * To run a mutation, you first call `useUpdateReportedDonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportedDonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportedDonationMutation, { data, loading, error }] = useUpdateReportedDonationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      personId: // value for 'personId'
 *      donationDate: // value for 'donationDate'
 *      amount: // value for 'amount'
 *      currencyCode: // value for 'currencyCode'
 *      organizationId: // value for 'organizationId'
 *      pledgeId: // value for 'pledgeId'
 *      financialPeriodId: // value for 'financialPeriodId'
 *   },
 * });
 */
export function useUpdateReportedDonationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportedDonationMutation, UpdateReportedDonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportedDonationMutation, UpdateReportedDonationMutationVariables>(UpdateReportedDonationDocument, options);
      }
export type UpdateReportedDonationMutationHookResult = ReturnType<typeof useUpdateReportedDonationMutation>;
export type UpdateReportedDonationMutationResult = Apollo.MutationResult<UpdateReportedDonationMutation>;
export type UpdateReportedDonationMutationOptions = Apollo.BaseMutationOptions<UpdateReportedDonationMutation, UpdateReportedDonationMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($pledgeId: BigInt!, $emailVerificationToken: UUID!) {
  verifyEmail(input: {pledgeId: $pledgeId, token: $emailVerificationToken}) {
    pledge {
      id
    }
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      pledgeId: // value for 'pledgeId'
 *      emailVerificationToken: // value for 'emailVerificationToken'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const GetCompletionPaymentsByPersonIdDocument = gql`
    query getCompletionPaymentsByPersonId($personId: BigInt!) {
  CompletionPayments: allCompletionPayments(
    condition: {personId: $personId}
    orderBy: [DONATION_DATE_DESC, AMOUNT_DESC]
  ) {
    edges {
      node {
        objectId
        objectType
        reportedDonationId
        organizationId
        paymentId
        personId
        pledgeId
        financialPeriodId
        amount
        currencyCode
        amountNormalized
        allocation
        donationDate
        externalOrganization: externalOrganizationByOrganizationId {
          id
          name
        }
        liability: liabilityByPledgeIdAndFinancialPeriodId {
          liabilityPeriod {
            start {
              value
              inclusive
            }
            end {
              value
              inclusive
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCompletionPaymentsByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetCompletionPaymentsByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletionPaymentsByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletionPaymentsByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetCompletionPaymentsByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetCompletionPaymentsByPersonIdQuery, GetCompletionPaymentsByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompletionPaymentsByPersonIdQuery, GetCompletionPaymentsByPersonIdQueryVariables>(GetCompletionPaymentsByPersonIdDocument, options);
      }
export function useGetCompletionPaymentsByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompletionPaymentsByPersonIdQuery, GetCompletionPaymentsByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompletionPaymentsByPersonIdQuery, GetCompletionPaymentsByPersonIdQueryVariables>(GetCompletionPaymentsByPersonIdDocument, options);
        }
export type GetCompletionPaymentsByPersonIdQueryHookResult = ReturnType<typeof useGetCompletionPaymentsByPersonIdQuery>;
export type GetCompletionPaymentsByPersonIdLazyQueryHookResult = ReturnType<typeof useGetCompletionPaymentsByPersonIdLazyQuery>;
export type GetCompletionPaymentsByPersonIdQueryResult = Apollo.QueryResult<GetCompletionPaymentsByPersonIdQuery, GetCompletionPaymentsByPersonIdQueryVariables>;
export const GetFinancialPeriodsByPersonIdDocument = gql`
    query getFinancialPeriodsByPersonId($personId: BigInt!) {
  FinancialPeriods: getFinancialPeriodsByPersonId(personId: $personId) {
    edges {
      node {
        id
        personId
        period {
          start {
            value
            inclusive
          }
          end {
            value
            inclusive
          }
        }
        employmentStatus
        donationsTaxDeductible
        wealthAmount
        currencyCode
        incomes: incomesByFinancialPeriodId {
          edges {
            node {
              nodeId
              id
              personId
              amount
              financialPeriodId
            }
          }
        }
        liabilities: liabilitiesByFinancialPeriodId {
          edges {
            node {
              nodeId
              pledgeId
              pledge: pledgeByPledgeId {
                id
                pledgeType
                incomePercentage
                wealthPercentage
              }
              financialPeriodId
              personId
              liabilityPeriod {
                start {
                  value
                  inclusive
                }
                end {
                  value
                  inclusive
                }
              }
              currencyCode
              liabilityAmount
              liabilityNormalized
              incomeLiabilityAmount
              wealthLiabilityAmount
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetFinancialPeriodsByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetFinancialPeriodsByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancialPeriodsByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancialPeriodsByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetFinancialPeriodsByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetFinancialPeriodsByPersonIdQuery, GetFinancialPeriodsByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinancialPeriodsByPersonIdQuery, GetFinancialPeriodsByPersonIdQueryVariables>(GetFinancialPeriodsByPersonIdDocument, options);
      }
export function useGetFinancialPeriodsByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinancialPeriodsByPersonIdQuery, GetFinancialPeriodsByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinancialPeriodsByPersonIdQuery, GetFinancialPeriodsByPersonIdQueryVariables>(GetFinancialPeriodsByPersonIdDocument, options);
        }
export type GetFinancialPeriodsByPersonIdQueryHookResult = ReturnType<typeof useGetFinancialPeriodsByPersonIdQuery>;
export type GetFinancialPeriodsByPersonIdLazyQueryHookResult = ReturnType<typeof useGetFinancialPeriodsByPersonIdLazyQuery>;
export type GetFinancialPeriodsByPersonIdQueryResult = Apollo.QueryResult<GetFinancialPeriodsByPersonIdQuery, GetFinancialPeriodsByPersonIdQueryVariables>;
export const GetIncomesByPersonIdDocument = gql`
    query getIncomesByPersonId($personId: BigInt!) {
  Incomes: getIncomesByPersonId(personId: $personId) {
    edges {
      node {
        id
        personId
        amount
        financialPeriod: financialPeriodByFinancialPeriodId {
          id
          currencyCode
          period {
            start {
              value
              inclusive
            }
            end {
              value
              inclusive
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetIncomesByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetIncomesByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomesByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomesByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetIncomesByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetIncomesByPersonIdQuery, GetIncomesByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncomesByPersonIdQuery, GetIncomesByPersonIdQueryVariables>(GetIncomesByPersonIdDocument, options);
      }
export function useGetIncomesByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncomesByPersonIdQuery, GetIncomesByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncomesByPersonIdQuery, GetIncomesByPersonIdQueryVariables>(GetIncomesByPersonIdDocument, options);
        }
export type GetIncomesByPersonIdQueryHookResult = ReturnType<typeof useGetIncomesByPersonIdQuery>;
export type GetIncomesByPersonIdLazyQueryHookResult = ReturnType<typeof useGetIncomesByPersonIdLazyQuery>;
export type GetIncomesByPersonIdQueryResult = Apollo.QueryResult<GetIncomesByPersonIdQuery, GetIncomesByPersonIdQueryVariables>;
export const GetLiabilitiesByPersonIdDocument = gql`
    query getLiabilitiesByPersonId($personId: BigInt!) {
  Liabilities: allLiabilities(condition: {personId: $personId}) {
    edges {
      node {
        personId
        pledgeId
        financialPeriodId
        liabilityPeriod {
          start {
            value
            inclusive
          }
          end {
            value
            inclusive
          }
        }
        currencyCode
        liabilityAmount
        liabilityNormalized
      }
    }
  }
}
    `;

/**
 * __useGetLiabilitiesByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetLiabilitiesByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiabilitiesByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiabilitiesByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetLiabilitiesByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetLiabilitiesByPersonIdQuery, GetLiabilitiesByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLiabilitiesByPersonIdQuery, GetLiabilitiesByPersonIdQueryVariables>(GetLiabilitiesByPersonIdDocument, options);
      }
export function useGetLiabilitiesByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLiabilitiesByPersonIdQuery, GetLiabilitiesByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLiabilitiesByPersonIdQuery, GetLiabilitiesByPersonIdQueryVariables>(GetLiabilitiesByPersonIdDocument, options);
        }
export type GetLiabilitiesByPersonIdQueryHookResult = ReturnType<typeof useGetLiabilitiesByPersonIdQuery>;
export type GetLiabilitiesByPersonIdLazyQueryHookResult = ReturnType<typeof useGetLiabilitiesByPersonIdLazyQuery>;
export type GetLiabilitiesByPersonIdQueryResult = Apollo.QueryResult<GetLiabilitiesByPersonIdQuery, GetLiabilitiesByPersonIdQueryVariables>;
export const GetLiabilityCompletionsDocument = gql`
    query getLiabilityCompletions($pledgeId: BigInt!) {
  LiabilityCompletions: getLiabilityCompletionByPledge(pledgeId: $pledgeId) {
    edges {
      node {
        personId
        pledgeId
        financialPeriodId
        currencyCode
        period {
          start {
            value
            inclusive
          }
          end {
            value
            inclusive
          }
        }
        liabilityAmount
        incomeLiabilityAmount
        wealthLiabilityAmount
        financialPeriodWealth
        completionAmount
        completionAmountNormalized
        completionPercentage
      }
    }
  }
}
    `;

/**
 * __useGetLiabilityCompletionsQuery__
 *
 * To run a query within a React component, call `useGetLiabilityCompletionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiabilityCompletionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiabilityCompletionsQuery({
 *   variables: {
 *      pledgeId: // value for 'pledgeId'
 *   },
 * });
 */
export function useGetLiabilityCompletionsQuery(baseOptions: Apollo.QueryHookOptions<GetLiabilityCompletionsQuery, GetLiabilityCompletionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLiabilityCompletionsQuery, GetLiabilityCompletionsQueryVariables>(GetLiabilityCompletionsDocument, options);
      }
export function useGetLiabilityCompletionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLiabilityCompletionsQuery, GetLiabilityCompletionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLiabilityCompletionsQuery, GetLiabilityCompletionsQueryVariables>(GetLiabilityCompletionsDocument, options);
        }
export type GetLiabilityCompletionsQueryHookResult = ReturnType<typeof useGetLiabilityCompletionsQuery>;
export type GetLiabilityCompletionsLazyQueryHookResult = ReturnType<typeof useGetLiabilityCompletionsLazyQuery>;
export type GetLiabilityCompletionsQueryResult = Apollo.QueryResult<GetLiabilityCompletionsQuery, GetLiabilityCompletionsQueryVariables>;
export const GetLiabilityTotalCompletionsDocument = gql`
    query getLiabilityTotalCompletions($pledgeId: BigInt!) {
  LiabilityTotalCompletions: getLiabilityTotalCompletion(pledgeId: $pledgeId) {
    edges {
      node {
        pledgeId
        currencyCode
        currencySortOrder
        liabilityAmount
        liabilityAmountNormalized
        completionAmount
        completionAmountNormalized
        completionPercentage
      }
    }
  }
}
    `;

/**
 * __useGetLiabilityTotalCompletionsQuery__
 *
 * To run a query within a React component, call `useGetLiabilityTotalCompletionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLiabilityTotalCompletionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLiabilityTotalCompletionsQuery({
 *   variables: {
 *      pledgeId: // value for 'pledgeId'
 *   },
 * });
 */
export function useGetLiabilityTotalCompletionsQuery(baseOptions: Apollo.QueryHookOptions<GetLiabilityTotalCompletionsQuery, GetLiabilityTotalCompletionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLiabilityTotalCompletionsQuery, GetLiabilityTotalCompletionsQueryVariables>(GetLiabilityTotalCompletionsDocument, options);
      }
export function useGetLiabilityTotalCompletionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLiabilityTotalCompletionsQuery, GetLiabilityTotalCompletionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLiabilityTotalCompletionsQuery, GetLiabilityTotalCompletionsQueryVariables>(GetLiabilityTotalCompletionsDocument, options);
        }
export type GetLiabilityTotalCompletionsQueryHookResult = ReturnType<typeof useGetLiabilityTotalCompletionsQuery>;
export type GetLiabilityTotalCompletionsLazyQueryHookResult = ReturnType<typeof useGetLiabilityTotalCompletionsLazyQuery>;
export type GetLiabilityTotalCompletionsQueryResult = Apollo.QueryResult<GetLiabilityTotalCompletionsQuery, GetLiabilityTotalCompletionsQueryVariables>;
export const GetPledgeByPersonOrTemporaryPersonDocument = gql`
    query getPledgeByPersonOrTemporaryPerson($pledgeId: BigInt!, $sessionToken: UUID) {
  Pledge: getPledgeByPersonOrTemporaryPerson(
    pledgeId: $pledgeId
    sessionToken: $sessionToken
  ) {
    id
    personId
    period {
      start {
        value
        inclusive
      }
      end {
        value
        inclusive
      }
    }
    incomePercentage
    wealthPercentage
    pledgeType
    motivation
    locale
    metadata
    emailVerifiedAt
    email
  }
}
    `;

/**
 * __useGetPledgeByPersonOrTemporaryPersonQuery__
 *
 * To run a query within a React component, call `useGetPledgeByPersonOrTemporaryPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPledgeByPersonOrTemporaryPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPledgeByPersonOrTemporaryPersonQuery({
 *   variables: {
 *      pledgeId: // value for 'pledgeId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetPledgeByPersonOrTemporaryPersonQuery(baseOptions: Apollo.QueryHookOptions<GetPledgeByPersonOrTemporaryPersonQuery, GetPledgeByPersonOrTemporaryPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPledgeByPersonOrTemporaryPersonQuery, GetPledgeByPersonOrTemporaryPersonQueryVariables>(GetPledgeByPersonOrTemporaryPersonDocument, options);
      }
export function useGetPledgeByPersonOrTemporaryPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPledgeByPersonOrTemporaryPersonQuery, GetPledgeByPersonOrTemporaryPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPledgeByPersonOrTemporaryPersonQuery, GetPledgeByPersonOrTemporaryPersonQueryVariables>(GetPledgeByPersonOrTemporaryPersonDocument, options);
        }
export type GetPledgeByPersonOrTemporaryPersonQueryHookResult = ReturnType<typeof useGetPledgeByPersonOrTemporaryPersonQuery>;
export type GetPledgeByPersonOrTemporaryPersonLazyQueryHookResult = ReturnType<typeof useGetPledgeByPersonOrTemporaryPersonLazyQuery>;
export type GetPledgeByPersonOrTemporaryPersonQueryResult = Apollo.QueryResult<GetPledgeByPersonOrTemporaryPersonQuery, GetPledgeByPersonOrTemporaryPersonQueryVariables>;
export const GetPledgeRanksByPersonIdDocument = gql`
    query getPledgeRanksByPersonId($personId: BigInt!) {
  PledgeRanks: allPledgeRanks(
    orderBy: RANK_BY_PLEDGE_TYPE_DESC
    condition: {personId: $personId}
  ) {
    edges {
      node {
        createdAt
        pledgeType
        rankByPledgeType
        commencementDate
        personByPersonId {
          id
          fullName
        }
      }
    }
  }
}
    `;

/**
 * __useGetPledgeRanksByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetPledgeRanksByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPledgeRanksByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPledgeRanksByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPledgeRanksByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetPledgeRanksByPersonIdQuery, GetPledgeRanksByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPledgeRanksByPersonIdQuery, GetPledgeRanksByPersonIdQueryVariables>(GetPledgeRanksByPersonIdDocument, options);
      }
export function useGetPledgeRanksByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPledgeRanksByPersonIdQuery, GetPledgeRanksByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPledgeRanksByPersonIdQuery, GetPledgeRanksByPersonIdQueryVariables>(GetPledgeRanksByPersonIdDocument, options);
        }
export type GetPledgeRanksByPersonIdQueryHookResult = ReturnType<typeof useGetPledgeRanksByPersonIdQuery>;
export type GetPledgeRanksByPersonIdLazyQueryHookResult = ReturnType<typeof useGetPledgeRanksByPersonIdLazyQuery>;
export type GetPledgeRanksByPersonIdQueryResult = Apollo.QueryResult<GetPledgeRanksByPersonIdQuery, GetPledgeRanksByPersonIdQueryVariables>;
export const GetPledgedMemberPublicsLatestRankDocument = gql`
    query getPledgedMemberPublicsLatestRank($pledgeType: PledgeType = GIVING_WHAT_WE_CAN) {
  PledgedMembers: allPledgedMembersPublicsList(
    orderBy: RANK_BY_PLEDGE_TYPE_DESC
    first: 1
    condition: {pledgeType: $pledgeType}
  ) {
    rankByPledgeType
  }
}
    `;

/**
 * __useGetPledgedMemberPublicsLatestRankQuery__
 *
 * To run a query within a React component, call `useGetPledgedMemberPublicsLatestRankQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPledgedMemberPublicsLatestRankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPledgedMemberPublicsLatestRankQuery({
 *   variables: {
 *      pledgeType: // value for 'pledgeType'
 *   },
 * });
 */
export function useGetPledgedMemberPublicsLatestRankQuery(baseOptions?: Apollo.QueryHookOptions<GetPledgedMemberPublicsLatestRankQuery, GetPledgedMemberPublicsLatestRankQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPledgedMemberPublicsLatestRankQuery, GetPledgedMemberPublicsLatestRankQueryVariables>(GetPledgedMemberPublicsLatestRankDocument, options);
      }
export function useGetPledgedMemberPublicsLatestRankLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPledgedMemberPublicsLatestRankQuery, GetPledgedMemberPublicsLatestRankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPledgedMemberPublicsLatestRankQuery, GetPledgedMemberPublicsLatestRankQueryVariables>(GetPledgedMemberPublicsLatestRankDocument, options);
        }
export type GetPledgedMemberPublicsLatestRankQueryHookResult = ReturnType<typeof useGetPledgedMemberPublicsLatestRankQuery>;
export type GetPledgedMemberPublicsLatestRankLazyQueryHookResult = ReturnType<typeof useGetPledgedMemberPublicsLatestRankLazyQuery>;
export type GetPledgedMemberPublicsLatestRankQueryResult = Apollo.QueryResult<GetPledgedMemberPublicsLatestRankQuery, GetPledgedMemberPublicsLatestRankQueryVariables>;
export const GetPledgesByPersonIdDocument = gql`
    query getPledgesByPersonId($personId: BigInt!) {
  Pledges: getPledgesByPersonId(personId: $personId) {
    edges {
      node {
        id
        personId
        period {
          start {
            value
            inclusive
          }
          end {
            value
            inclusive
          }
        }
        incomePercentage
        wealthPercentage
        pledgeType
        motivation
        liabilities: liabilitiesByPledgeId {
          edges {
            node {
              nodeId
              pledgeId
              financialPeriodId
              personId
              currencyCode
              liabilityAmount
              liabilityNormalized
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPledgesByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetPledgesByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPledgesByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPledgesByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetPledgesByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetPledgesByPersonIdQuery, GetPledgesByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPledgesByPersonIdQuery, GetPledgesByPersonIdQueryVariables>(GetPledgesByPersonIdDocument, options);
      }
export function useGetPledgesByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPledgesByPersonIdQuery, GetPledgesByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPledgesByPersonIdQuery, GetPledgesByPersonIdQueryVariables>(GetPledgesByPersonIdDocument, options);
        }
export type GetPledgesByPersonIdQueryHookResult = ReturnType<typeof useGetPledgesByPersonIdQuery>;
export type GetPledgesByPersonIdLazyQueryHookResult = ReturnType<typeof useGetPledgesByPersonIdLazyQuery>;
export type GetPledgesByPersonIdQueryResult = Apollo.QueryResult<GetPledgesByPersonIdQuery, GetPledgesByPersonIdQueryVariables>;
export const GetRecurringReportedDonationDocument = gql`
    query getRecurringReportedDonation($recurringReportedDonationId: BigInt!) {
  RecurringReportedDonation: recurringReportedDonationById(
    id: $recurringReportedDonationId
  ) {
    ...RecurringReportedDonation
  }
}
    ${RecurringReportedDonationFragmentDoc}`;

/**
 * __useGetRecurringReportedDonationQuery__
 *
 * To run a query within a React component, call `useGetRecurringReportedDonationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringReportedDonationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringReportedDonationQuery({
 *   variables: {
 *      recurringReportedDonationId: // value for 'recurringReportedDonationId'
 *   },
 * });
 */
export function useGetRecurringReportedDonationQuery(baseOptions: Apollo.QueryHookOptions<GetRecurringReportedDonationQuery, GetRecurringReportedDonationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecurringReportedDonationQuery, GetRecurringReportedDonationQueryVariables>(GetRecurringReportedDonationDocument, options);
      }
export function useGetRecurringReportedDonationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecurringReportedDonationQuery, GetRecurringReportedDonationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecurringReportedDonationQuery, GetRecurringReportedDonationQueryVariables>(GetRecurringReportedDonationDocument, options);
        }
export type GetRecurringReportedDonationQueryHookResult = ReturnType<typeof useGetRecurringReportedDonationQuery>;
export type GetRecurringReportedDonationLazyQueryHookResult = ReturnType<typeof useGetRecurringReportedDonationLazyQuery>;
export type GetRecurringReportedDonationQueryResult = Apollo.QueryResult<GetRecurringReportedDonationQuery, GetRecurringReportedDonationQueryVariables>;
export const GetRecurringReportedDonationsByPersonIdDocument = gql`
    query getRecurringReportedDonationsByPersonId($personId: BigInt!) {
  RecurringReportedDonations: getRecurringReportedDonationsByPersonId(
    personId: $personId
  ) {
    edges {
      node {
        id
        personId
        organizationId
        amount
        currencyCode
        startDate
        nextDate
        status
        createdAt
        updatedAt
        externalOrganization: externalOrganizationByOrganizationId {
          id
          name
          organizationSlug
          url
        }
        recurrenceInterval {
          years
          months
          days
          hours
          minutes
          seconds
        }
      }
    }
  }
}
    `;

/**
 * __useGetRecurringReportedDonationsByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetRecurringReportedDonationsByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecurringReportedDonationsByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecurringReportedDonationsByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetRecurringReportedDonationsByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetRecurringReportedDonationsByPersonIdQuery, GetRecurringReportedDonationsByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecurringReportedDonationsByPersonIdQuery, GetRecurringReportedDonationsByPersonIdQueryVariables>(GetRecurringReportedDonationsByPersonIdDocument, options);
      }
export function useGetRecurringReportedDonationsByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecurringReportedDonationsByPersonIdQuery, GetRecurringReportedDonationsByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecurringReportedDonationsByPersonIdQuery, GetRecurringReportedDonationsByPersonIdQueryVariables>(GetRecurringReportedDonationsByPersonIdDocument, options);
        }
export type GetRecurringReportedDonationsByPersonIdQueryHookResult = ReturnType<typeof useGetRecurringReportedDonationsByPersonIdQuery>;
export type GetRecurringReportedDonationsByPersonIdLazyQueryHookResult = ReturnType<typeof useGetRecurringReportedDonationsByPersonIdLazyQuery>;
export type GetRecurringReportedDonationsByPersonIdQueryResult = Apollo.QueryResult<GetRecurringReportedDonationsByPersonIdQuery, GetRecurringReportedDonationsByPersonIdQueryVariables>;
export const GetReportedDonationDocument = gql`
    query getReportedDonation($reportedDonationId: BigInt!) {
  ReportedDonation: reportedDonationById(id: $reportedDonationId) {
    ...ReportedDonation
  }
}
    ${ReportedDonationFragmentDoc}`;

/**
 * __useGetReportedDonationQuery__
 *
 * To run a query within a React component, call `useGetReportedDonationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportedDonationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportedDonationQuery({
 *   variables: {
 *      reportedDonationId: // value for 'reportedDonationId'
 *   },
 * });
 */
export function useGetReportedDonationQuery(baseOptions: Apollo.QueryHookOptions<GetReportedDonationQuery, GetReportedDonationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportedDonationQuery, GetReportedDonationQueryVariables>(GetReportedDonationDocument, options);
      }
export function useGetReportedDonationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportedDonationQuery, GetReportedDonationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportedDonationQuery, GetReportedDonationQueryVariables>(GetReportedDonationDocument, options);
        }
export type GetReportedDonationQueryHookResult = ReturnType<typeof useGetReportedDonationQuery>;
export type GetReportedDonationLazyQueryHookResult = ReturnType<typeof useGetReportedDonationLazyQuery>;
export type GetReportedDonationQueryResult = Apollo.QueryResult<GetReportedDonationQuery, GetReportedDonationQueryVariables>;
export const GetReportedDonationsByPersonIdDocument = gql`
    query getReportedDonationsByPersonId($personId: BigInt!) {
  ReportedDonations: reportedDonationsByPersonId(personId: $personId) {
    edges {
      node {
        id
        personId
        amount
        amountNormalized
        currencyCode
        donationDate
        createdAt
        updatedAt
        externalOrganization: externalOrganizationByOrganizationId {
          id
          name
          organizationSlug
        }
      }
    }
  }
}
    `;

/**
 * __useGetReportedDonationsByPersonIdQuery__
 *
 * To run a query within a React component, call `useGetReportedDonationsByPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportedDonationsByPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportedDonationsByPersonIdQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetReportedDonationsByPersonIdQuery(baseOptions: Apollo.QueryHookOptions<GetReportedDonationsByPersonIdQuery, GetReportedDonationsByPersonIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportedDonationsByPersonIdQuery, GetReportedDonationsByPersonIdQueryVariables>(GetReportedDonationsByPersonIdDocument, options);
      }
export function useGetReportedDonationsByPersonIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportedDonationsByPersonIdQuery, GetReportedDonationsByPersonIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportedDonationsByPersonIdQuery, GetReportedDonationsByPersonIdQueryVariables>(GetReportedDonationsByPersonIdDocument, options);
        }
export type GetReportedDonationsByPersonIdQueryHookResult = ReturnType<typeof useGetReportedDonationsByPersonIdQuery>;
export type GetReportedDonationsByPersonIdLazyQueryHookResult = ReturnType<typeof useGetReportedDonationsByPersonIdLazyQuery>;
export type GetReportedDonationsByPersonIdQueryResult = Apollo.QueryResult<GetReportedDonationsByPersonIdQuery, GetReportedDonationsByPersonIdQueryVariables>;
export const CreatePollOptionVoteByPersonOrTemporaryPersonDocument = gql`
    mutation createPollOptionVoteByPersonOrTemporaryPerson($pledgeId: BigInt!, $pollOptionId: BigInt!, $context: String!, $sessionToken: UUID) {
  createPollOptionVoteByPersonOrTemporaryPerson(
    input: {sessionToken: $sessionToken, pledgeId: $pledgeId, pollOptionId: $pollOptionId, context: $context}
  ) {
    pollOptionVote {
      pledgeId
      pollOptionId
      context
    }
  }
}
    `;
export type CreatePollOptionVoteByPersonOrTemporaryPersonMutationFn = Apollo.MutationFunction<CreatePollOptionVoteByPersonOrTemporaryPersonMutation, CreatePollOptionVoteByPersonOrTemporaryPersonMutationVariables>;

/**
 * __useCreatePollOptionVoteByPersonOrTemporaryPersonMutation__
 *
 * To run a mutation, you first call `useCreatePollOptionVoteByPersonOrTemporaryPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePollOptionVoteByPersonOrTemporaryPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPollOptionVoteByPersonOrTemporaryPersonMutation, { data, loading, error }] = useCreatePollOptionVoteByPersonOrTemporaryPersonMutation({
 *   variables: {
 *      pledgeId: // value for 'pledgeId'
 *      pollOptionId: // value for 'pollOptionId'
 *      context: // value for 'context'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useCreatePollOptionVoteByPersonOrTemporaryPersonMutation(baseOptions?: Apollo.MutationHookOptions<CreatePollOptionVoteByPersonOrTemporaryPersonMutation, CreatePollOptionVoteByPersonOrTemporaryPersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePollOptionVoteByPersonOrTemporaryPersonMutation, CreatePollOptionVoteByPersonOrTemporaryPersonMutationVariables>(CreatePollOptionVoteByPersonOrTemporaryPersonDocument, options);
      }
export type CreatePollOptionVoteByPersonOrTemporaryPersonMutationHookResult = ReturnType<typeof useCreatePollOptionVoteByPersonOrTemporaryPersonMutation>;
export type CreatePollOptionVoteByPersonOrTemporaryPersonMutationResult = Apollo.MutationResult<CreatePollOptionVoteByPersonOrTemporaryPersonMutation>;
export type CreatePollOptionVoteByPersonOrTemporaryPersonMutationOptions = Apollo.BaseMutationOptions<CreatePollOptionVoteByPersonOrTemporaryPersonMutation, CreatePollOptionVoteByPersonOrTemporaryPersonMutationVariables>;
export const GetPollByPersonOrTemporaryPersonDocument = gql`
    query getPollByPersonOrTemporaryPerson($pollId: BigInt!, $sessionToken: UUID) {
  Poll: pollPublicById(id: $pollId) {
    id
    voteMechanism
    questionOrdering
    question
    Options: pollOptionPublicsByPollIdList {
      id
      pollId
      label
      voteCount
      Votes: votesByPersonOrTemporaryPersonList(sessionToken: $sessionToken) {
        pledgeId
        pollOptionId
        context
      }
    }
  }
}
    `;

/**
 * __useGetPollByPersonOrTemporaryPersonQuery__
 *
 * To run a query within a React component, call `useGetPollByPersonOrTemporaryPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPollByPersonOrTemporaryPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPollByPersonOrTemporaryPersonQuery({
 *   variables: {
 *      pollId: // value for 'pollId'
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetPollByPersonOrTemporaryPersonQuery(baseOptions: Apollo.QueryHookOptions<GetPollByPersonOrTemporaryPersonQuery, GetPollByPersonOrTemporaryPersonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPollByPersonOrTemporaryPersonQuery, GetPollByPersonOrTemporaryPersonQueryVariables>(GetPollByPersonOrTemporaryPersonDocument, options);
      }
export function useGetPollByPersonOrTemporaryPersonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPollByPersonOrTemporaryPersonQuery, GetPollByPersonOrTemporaryPersonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPollByPersonOrTemporaryPersonQuery, GetPollByPersonOrTemporaryPersonQueryVariables>(GetPollByPersonOrTemporaryPersonDocument, options);
        }
export type GetPollByPersonOrTemporaryPersonQueryHookResult = ReturnType<typeof useGetPollByPersonOrTemporaryPersonQuery>;
export type GetPollByPersonOrTemporaryPersonLazyQueryHookResult = ReturnType<typeof useGetPollByPersonOrTemporaryPersonLazyQuery>;
export type GetPollByPersonOrTemporaryPersonQueryResult = Apollo.QueryResult<GetPollByPersonOrTemporaryPersonQuery, GetPollByPersonOrTemporaryPersonQueryVariables>;
export const AllCountriesDocument = gql`
    query allCountries {
  Countries: allCountries(orderBy: NAME_ASC) {
    edges {
      node {
        name
        isoAlpha2
        isoAlpha3
      }
    }
  }
}
    `;

/**
 * __useAllCountriesQuery__
 *
 * To run a query within a React component, call `useAllCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCountriesQuery(baseOptions?: Apollo.QueryHookOptions<AllCountriesQuery, AllCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCountriesQuery, AllCountriesQueryVariables>(AllCountriesDocument, options);
      }
export function useAllCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCountriesQuery, AllCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCountriesQuery, AllCountriesQueryVariables>(AllCountriesDocument, options);
        }
export type AllCountriesQueryHookResult = ReturnType<typeof useAllCountriesQuery>;
export type AllCountriesLazyQueryHookResult = ReturnType<typeof useAllCountriesLazyQuery>;
export type AllCountriesQueryResult = Apollo.QueryResult<AllCountriesQuery, AllCountriesQueryVariables>;
export const AllCountriesWithCurrenciesDocument = gql`
    query allCountriesWithCurrencies {
  Countries: allCountries(orderBy: NAME_ASC) {
    edges {
      node {
        name
        isoAlpha2
        isoAlpha3
        currencies: currencyByCountriesByCountryCodeList(orderBy: PRECEDENCE_ASC) {
          currencyCode
        }
      }
    }
  }
}
    `;

/**
 * __useAllCountriesWithCurrenciesQuery__
 *
 * To run a query within a React component, call `useAllCountriesWithCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCountriesWithCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCountriesWithCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllCountriesWithCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<AllCountriesWithCurrenciesQuery, AllCountriesWithCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCountriesWithCurrenciesQuery, AllCountriesWithCurrenciesQueryVariables>(AllCountriesWithCurrenciesDocument, options);
      }
export function useAllCountriesWithCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCountriesWithCurrenciesQuery, AllCountriesWithCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCountriesWithCurrenciesQuery, AllCountriesWithCurrenciesQueryVariables>(AllCountriesWithCurrenciesDocument, options);
        }
export type AllCountriesWithCurrenciesQueryHookResult = ReturnType<typeof useAllCountriesWithCurrenciesQuery>;
export type AllCountriesWithCurrenciesLazyQueryHookResult = ReturnType<typeof useAllCountriesWithCurrenciesLazyQuery>;
export type AllCountriesWithCurrenciesQueryResult = Apollo.QueryResult<AllCountriesWithCurrenciesQuery, AllCountriesWithCurrenciesQueryVariables>;
export const AllCurrenciesDocument = gql`
    query allCurrencies($currencyType: CurrencyType) {
  Currencies: allCurrencies(condition: {currencyType: $currencyType}) {
    edges {
      node {
        name
        code
        decimals
        symbol
        currencyType
      }
    }
  }
}
    `;

/**
 * __useAllCurrenciesQuery__
 *
 * To run a query within a React component, call `useAllCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCurrenciesQuery({
 *   variables: {
 *      currencyType: // value for 'currencyType'
 *   },
 * });
 */
export function useAllCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<AllCurrenciesQuery, AllCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCurrenciesQuery, AllCurrenciesQueryVariables>(AllCurrenciesDocument, options);
      }
export function useAllCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCurrenciesQuery, AllCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCurrenciesQuery, AllCurrenciesQueryVariables>(AllCurrenciesDocument, options);
        }
export type AllCurrenciesQueryHookResult = ReturnType<typeof useAllCurrenciesQuery>;
export type AllCurrenciesLazyQueryHookResult = ReturnType<typeof useAllCurrenciesLazyQuery>;
export type AllCurrenciesQueryResult = Apollo.QueryResult<AllCurrenciesQuery, AllCurrenciesQueryVariables>;
export const AllFiatCurrenciesDocument = gql`
    query allFiatCurrencies {
  FiatCurrencies: allCurrencies(
    condition: {currencyType: FIAT}
    orderBy: CODE_ASC
  ) {
    edges {
      node {
        name
        code
        symbol
      }
    }
  }
}
    `;

/**
 * __useAllFiatCurrenciesQuery__
 *
 * To run a query within a React component, call `useAllFiatCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFiatCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFiatCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFiatCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<AllFiatCurrenciesQuery, AllFiatCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllFiatCurrenciesQuery, AllFiatCurrenciesQueryVariables>(AllFiatCurrenciesDocument, options);
      }
export function useAllFiatCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFiatCurrenciesQuery, AllFiatCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllFiatCurrenciesQuery, AllFiatCurrenciesQueryVariables>(AllFiatCurrenciesDocument, options);
        }
export type AllFiatCurrenciesQueryHookResult = ReturnType<typeof useAllFiatCurrenciesQuery>;
export type AllFiatCurrenciesLazyQueryHookResult = ReturnType<typeof useAllFiatCurrenciesLazyQuery>;
export type AllFiatCurrenciesQueryResult = Apollo.QueryResult<AllFiatCurrenciesQuery, AllFiatCurrenciesQueryVariables>;
export const AllMembersDocument = gql`
    query allMembers {
  Pledges: allPledgedMembersPublicsList(orderBy: RANK_BY_PLEDGE_TYPE_DESC) {
    pledgeType
    active
    rankByPledgeType
    fullName
    commencementDate
  }
}
    `;

/**
 * __useAllMembersQuery__
 *
 * To run a query within a React component, call `useAllMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllMembersQuery(baseOptions?: Apollo.QueryHookOptions<AllMembersQuery, AllMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllMembersQuery, AllMembersQueryVariables>(AllMembersDocument, options);
      }
export function useAllMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMembersQuery, AllMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllMembersQuery, AllMembersQueryVariables>(AllMembersDocument, options);
        }
export type AllMembersQueryHookResult = ReturnType<typeof useAllMembersQuery>;
export type AllMembersLazyQueryHookResult = ReturnType<typeof useAllMembersLazyQuery>;
export type AllMembersQueryResult = Apollo.QueryResult<AllMembersQuery, AllMembersQueryVariables>;
export const ClosestExchangeRateDocument = gql`
    query closestExchangeRate($currencyCode: String!, $date: Date!) {
  ExchangeRate: closestExchangeRate(currencyCode: $currencyCode, date: $date) {
    currencyCode
    date
    rate
  }
}
    `;

/**
 * __useClosestExchangeRateQuery__
 *
 * To run a query within a React component, call `useClosestExchangeRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosestExchangeRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosestExchangeRateQuery({
 *   variables: {
 *      currencyCode: // value for 'currencyCode'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useClosestExchangeRateQuery(baseOptions: Apollo.QueryHookOptions<ClosestExchangeRateQuery, ClosestExchangeRateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClosestExchangeRateQuery, ClosestExchangeRateQueryVariables>(ClosestExchangeRateDocument, options);
      }
export function useClosestExchangeRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClosestExchangeRateQuery, ClosestExchangeRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClosestExchangeRateQuery, ClosestExchangeRateQueryVariables>(ClosestExchangeRateDocument, options);
        }
export type ClosestExchangeRateQueryHookResult = ReturnType<typeof useClosestExchangeRateQuery>;
export type ClosestExchangeRateLazyQueryHookResult = ReturnType<typeof useClosestExchangeRateLazyQuery>;
export type ClosestExchangeRateQueryResult = Apollo.QueryResult<ClosestExchangeRateQuery, ClosestExchangeRateQueryVariables>;
export const GetCurrencyDocument = gql`
    query getCurrency($code: String!) {
  Currency: currencyByCode(code: $code) {
    name
    code
    decimals
    symbol
    currencyType
  }
}
    `;

/**
 * __useGetCurrencyQuery__
 *
 * To run a query within a React component, call `useGetCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrencyQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetCurrencyQuery(baseOptions: Apollo.QueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, options);
      }
export function useGetCurrencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrencyQuery, GetCurrencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrencyQuery, GetCurrencyQueryVariables>(GetCurrencyDocument, options);
        }
export type GetCurrencyQueryHookResult = ReturnType<typeof useGetCurrencyQuery>;
export type GetCurrencyLazyQueryHookResult = ReturnType<typeof useGetCurrencyLazyQuery>;
export type GetCurrencyQueryResult = Apollo.QueryResult<GetCurrencyQuery, GetCurrencyQueryVariables>;
export const PaymentCurrenciesDocument = gql`
    query paymentCurrencies {
  USD: currencyByCode(code: "USD") {
    ...currencyFields
  }
  GBP: currencyByCode(code: "GBP") {
    ...currencyFields
  }
}
    ${CurrencyFieldsFragmentDoc}`;

/**
 * __usePaymentCurrenciesQuery__
 *
 * To run a query within a React component, call `usePaymentCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePaymentCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<PaymentCurrenciesQuery, PaymentCurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentCurrenciesQuery, PaymentCurrenciesQueryVariables>(PaymentCurrenciesDocument, options);
      }
export function usePaymentCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentCurrenciesQuery, PaymentCurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentCurrenciesQuery, PaymentCurrenciesQueryVariables>(PaymentCurrenciesDocument, options);
        }
export type PaymentCurrenciesQueryHookResult = ReturnType<typeof usePaymentCurrenciesQuery>;
export type PaymentCurrenciesLazyQueryHookResult = ReturnType<typeof usePaymentCurrenciesLazyQuery>;
export type PaymentCurrenciesQueryResult = Apollo.QueryResult<PaymentCurrenciesQuery, PaymentCurrenciesQueryVariables>;
export const PaymentExchangeRatesDocument = gql`
    query paymentExchangeRates($date: Date!) {
  GBP: closestExchangeRate(currencyCode: "GBP", date: $date) {
    ...exchangeRateFields
  }
  BTC: closestExchangeRate(currencyCode: "BTC", date: $date) {
    ...exchangeRateFields
  }
  ETH: closestExchangeRate(currencyCode: "ETH", date: $date) {
    ...exchangeRateFields
  }
  LTC: closestExchangeRate(currencyCode: "LTC", date: $date) {
    ...exchangeRateFields
  }
}
    ${ExchangeRateFieldsFragmentDoc}`;

/**
 * __usePaymentExchangeRatesQuery__
 *
 * To run a query within a React component, call `usePaymentExchangeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentExchangeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentExchangeRatesQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function usePaymentExchangeRatesQuery(baseOptions: Apollo.QueryHookOptions<PaymentExchangeRatesQuery, PaymentExchangeRatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentExchangeRatesQuery, PaymentExchangeRatesQueryVariables>(PaymentExchangeRatesDocument, options);
      }
export function usePaymentExchangeRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentExchangeRatesQuery, PaymentExchangeRatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentExchangeRatesQuery, PaymentExchangeRatesQueryVariables>(PaymentExchangeRatesDocument, options);
        }
export type PaymentExchangeRatesQueryHookResult = ReturnType<typeof usePaymentExchangeRatesQuery>;
export type PaymentExchangeRatesLazyQueryHookResult = ReturnType<typeof usePaymentExchangeRatesLazyQuery>;
export type PaymentExchangeRatesQueryResult = Apollo.QueryResult<PaymentExchangeRatesQuery, PaymentExchangeRatesQueryVariables>;
export const GetStatsDocument = gql`
    query getStats {
  allGlobalStatsList {
    gwwcPledgesTotal
    activeTryGivingPledgesTotal
    tryGivingCompletedTotal
    pledgersCountriesTotal
    pledgesDonationsTotal
    gwwcDonationsTotal
  }
}
    `;

/**
 * __useGetStatsQuery__
 *
 * To run a query within a React component, call `useGetStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetStatsQuery, GetStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
      }
export function useGetStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatsQuery, GetStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatsQuery, GetStatsQueryVariables>(GetStatsDocument, options);
        }
export type GetStatsQueryHookResult = ReturnType<typeof useGetStatsQuery>;
export type GetStatsLazyQueryHookResult = ReturnType<typeof useGetStatsLazyQuery>;
export type GetStatsQueryResult = Apollo.QueryResult<GetStatsQuery, GetStatsQueryVariables>;
export const GetPaymentStatsAggregateDocument = gql`
    query getPaymentStatsAggregate {
  PaymentStats: getPaymentStatsAggregate {
    totalDonations
    numDonations
    numDonors
    totalDonationsLastYear
    numDonationsLastYear
    numDonorsLastYear
  }
}
    `;

/**
 * __useGetPaymentStatsAggregateQuery__
 *
 * To run a query within a React component, call `useGetPaymentStatsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentStatsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentStatsAggregateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentStatsAggregateQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentStatsAggregateQuery, GetPaymentStatsAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentStatsAggregateQuery, GetPaymentStatsAggregateQueryVariables>(GetPaymentStatsAggregateDocument, options);
      }
export function useGetPaymentStatsAggregateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentStatsAggregateQuery, GetPaymentStatsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentStatsAggregateQuery, GetPaymentStatsAggregateQueryVariables>(GetPaymentStatsAggregateDocument, options);
        }
export type GetPaymentStatsAggregateQueryHookResult = ReturnType<typeof useGetPaymentStatsAggregateQuery>;
export type GetPaymentStatsAggregateLazyQueryHookResult = ReturnType<typeof useGetPaymentStatsAggregateLazyQuery>;
export type GetPaymentStatsAggregateQueryResult = Apollo.QueryResult<GetPaymentStatsAggregateQuery, GetPaymentStatsAggregateQueryVariables>;
export const UpdateSettingsContactPreferenceDocument = gql`
    mutation updateSettingsContactPreference($personId: BigInt!, $currentAddressId: BigInt) {
  updateContactPreferenceByPersonId(
    input: {personId: $personId, currentAddressId: $currentAddressId}
  ) {
    contactPreference {
      personId
      currentAddressId
    }
  }
}
    `;
export type UpdateSettingsContactPreferenceMutationFn = Apollo.MutationFunction<UpdateSettingsContactPreferenceMutation, UpdateSettingsContactPreferenceMutationVariables>;

/**
 * __useUpdateSettingsContactPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsContactPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsContactPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsContactPreferenceMutation, { data, loading, error }] = useUpdateSettingsContactPreferenceMutation({
 *   variables: {
 *      personId: // value for 'personId'
 *      currentAddressId: // value for 'currentAddressId'
 *   },
 * });
 */
export function useUpdateSettingsContactPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSettingsContactPreferenceMutation, UpdateSettingsContactPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSettingsContactPreferenceMutation, UpdateSettingsContactPreferenceMutationVariables>(UpdateSettingsContactPreferenceDocument, options);
      }
export type UpdateSettingsContactPreferenceMutationHookResult = ReturnType<typeof useUpdateSettingsContactPreferenceMutation>;
export type UpdateSettingsContactPreferenceMutationResult = Apollo.MutationResult<UpdateSettingsContactPreferenceMutation>;
export type UpdateSettingsContactPreferenceMutationOptions = Apollo.BaseMutationOptions<UpdateSettingsContactPreferenceMutation, UpdateSettingsContactPreferenceMutationVariables>;
export const GetSettingsDocument = gql`
    query getSettings($personId: BigInt!) {
  ContactPreference: contactPreferenceByPersonId(personId: $personId) {
    currentAddressId
  }
}
    `;

/**
 * __useGetSettingsQuery__
 *
 * To run a query within a React component, call `useGetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsQuery({
 *   variables: {
 *      personId: // value for 'personId'
 *   },
 * });
 */
export function useGetSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
      }
export function useGetSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSettingsQuery, GetSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSettingsQuery, GetSettingsQueryVariables>(GetSettingsDocument, options);
        }
export type GetSettingsQueryHookResult = ReturnType<typeof useGetSettingsQuery>;
export type GetSettingsLazyQueryHookResult = ReturnType<typeof useGetSettingsLazyQuery>;
export type GetSettingsQueryResult = Apollo.QueryResult<GetSettingsQuery, GetSettingsQueryVariables>;