import React, { useEffect, createContext, useContext, useCallback } from 'react'
import { useCookies } from 'react-cookie'

export const REFERRAL_COOKIE_NAME = '@aletheia/referral'
// See https://ga-dev-tools.appspot.com/campaign-url-builder/
export const REFERRAL_KEYS = [
  'referral_url',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
] as const
export type ReferralKey = typeof REFERRAL_KEYS[number]

const ReferralsContext = createContext<ReferralsProviderValue>({
  referral: undefined,
})
ReferralsContext.displayName = 'ReferralsContext'
const { Provider } = ReferralsContext

export type Referral = Partial<Record<ReferralKey, string>>

export type ReferralsProviderMethods = {
  referral: Referral | undefined
}

export type ReferralsProviderValue = ReferralsProviderMethods

/**
 * A provider that takes referral information from the URL and stores it
 * in a cookie so that other components can use referral information
 * via the `useReferral` hook
 */
export const ReferralsProvider: React.FC = ({ children }) => {
  const [cookies, setCookie] = useCookies([REFERRAL_COOKIE_NAME])

  /**
   * Save any referral information from the URL (in the form of Google UTM tags)
   * to a cookie, so we can use it later
   */
  const setReferral = useCallback(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    // URLSearchParams.entries() returns an iterator, so we need to cast
    // to an Array. We then filter out any query params that aren't in our
    // whitelist, and cast to an object of key/value pairs
    const entries = Array.from(urlSearchParams.entries()).filter(
      (entry): entry is [ReferralKey, string] => {
        const [key, value] = entry
        return (
          REFERRAL_KEYS.includes(key as ReferralKey) &&
          typeof value === 'string'
        )
      },
    )
    const params = Object.fromEntries(entries)
    const referral: Referral = {
      ...params,
      referral_url: document.referrer,
    }
    // Two weeks in seconds
    const twoWeeks = 60 * 60 * 24 * 7 * 2
    setCookie(REFERRAL_COOKIE_NAME, referral, { maxAge: twoWeeks })
  }, [setCookie])

  /** Set referrals cookie on load */
  useEffect(() => {
    if (typeof window === 'undefined') return
    setReferral()
  }, [setReferral])

  const referral: Referral | undefined = cookies[REFERRAL_COOKIE_NAME]

  const value = { referral }

  return <Provider value={value}>{children}</Provider>
}

export const useReferral = (): ReferralsProviderValue =>
  useContext(ReferralsContext)
