import { Button, makeStyles, Link as MUILink } from '@material-ui/core'
import classnames from 'classnames'
import React from 'react'

import { ConsentCTA } from '@aletheia/common/components/CookieConsent'

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    fontSize: theme.typography.body1.fontSize,
    position: 'fixed',
    bottom: '0',
    width: '100%',
    background: '#424242',
    color: 'white',
    padding: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10, // Ensure the banner is always on top
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column', // Stack buttons vertically for small screens
      alignItems: 'flex-start', // Align buttons to the left
      fontSize: 16,
    },
  },
  text: {
    maxWidth: '1200px',
  },
  buttonContainer: {
    display: 'flex',
    gap: '20px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '15px',
    },
  },
  button: {
    fontWeight: 900,
    padding: theme.spacing(1.5, 3),
    margin: 0,
  },
  rejectButton: {
    backgroundColor: '#757575',
    '&:hover': {
      backgroundColor: '#616161',
    },
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    },
  },
  acceptButton: {
    // don't break text
    whiteSpace: 'nowrap',
  },
  link: {
    color: theme.palette.primary.light,
    textDecoration: 'underline',
  },
}))

const CookieBanner: React.FC<{
  saveConsent: (newPreferences: boolean) => void
  setCTA: (cta: string) => void
}> = ({ saveConsent, setCTA }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.bannerContainer}>
        <div className={classes.text}>
          We and our partners use cookies, including to review how our site is
          used, to improve our site&apos;s performance, and to serve
          personalized ads and content. For more information see our{' '}
          <MUILink
            href="https://www.effectivealtruism.org/cookie-notice"
            target="_blank"
            className={classes.link}
          >
            cookie notice
          </MUILink>
          . By clicking on &quot;Accept All&quot; you consent to our use of
          cookies and to the sharing of this data with our partners.
        </div>
        <div className={classes.buttonContainer}>
          <Button
            onClick={() => {
              setCTA(ConsentCTA.RequiredOnly)
              saveConsent(false)
            }}
            className={classnames(classes.button, classes.rejectButton)}
          >
            <strong>Reject</strong>
          </Button>
          <Button
            onClick={() => {
              setCTA(ConsentCTA.All)
              saveConsent(true)
            }}
            color="primary"
            variant="contained"
            className={classnames(classes.button, classes.acceptButton)}
          >
            <strong>Accept All</strong>
          </Button>
        </div>
      </div>
    </>
  )
}

export default CookieBanner
