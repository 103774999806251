import { makeStyles, Grid, Divider, Collapse } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import SwipeableDrawer, {
  SwipeableDrawerProps,
} from '@material-ui/core/SwipeableDrawer'
import CloseIcon from '@material-ui/icons/Close'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'

import ButtonLink from '../ButtonLink'
import Link from '../Link'
import { NavProps } from './'
import NavUserMobile from './NavUserMobile'
import { TRouteMember } from './types'
import { isRoute, isRouteDivider, isRouteParent } from './utils'

function hideOnMobileFilter(route: TRouteMember) {
  return isRoute(route) ? !route.hideOnMobile : true
}

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    padding: theme.spacing(2),
  },
  closeButton: {
    marginTop: 8,
  },
  list: {
    width: 265,
  },
  listItemChildRoute: {
    // marginLeft: '1em',
    '& .MuiTypography-root': {
      fontSize: '0.875em',
    },
  },
  childRoutesWrapper: {
    background: theme.palette.grey[100],
    boxShadow: `inset 0 0 5px ${theme.palette.grey[500]}`,
  },
}))

const NavMobile: React.FC<NavProps & SwipeableDrawerProps> = ({
  Logo,
  routes,
  navUserLoggedInRoutes,
  navUserLoggedOutRoutes,
  open,
  onClose,
  onOpen,
}) => {
  const classes = useStyles()
  const [openMenu, setOpenMenu] = useState<string>()

  function handleOpenMenu(menuId: string) {
    if (openMenu === menuId) setOpenMenu(undefined)
    else setOpenMenu(menuId)
  }

  function handleClose(
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) {
    setOpenMenu(undefined)
    onClose(event)
  }

  return (
    <SwipeableDrawer {...{ open, onClose: handleClose, onOpen }} anchor="right">
      <Grid container>
        <Grid item xs={9}>
          <div className={classes.logoWrapper}>
            <Link href="/" onClick={handleClose}>
              <Logo />
            </Link>
          </div>
        </Grid>
        <Grid item xs={3}>
          <IconButton onClick={handleClose} className={classes.closeButton}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <List className={classes.list}>
        {routes.filter(hideOnMobileFilter).map((route) => {
          if (isRoute(route)) {
            return (
              <ListItem
                button
                component={ButtonLink}
                key={route.as || route.href}
                href={route.href}
                as={route.as}
                onClick={handleClose}
                color={route.color}
              >
                <ListItemText>{route.label}</ListItemText>
              </ListItem>
            )
          } else if (isRouteParent(route)) {
            return (
              <React.Fragment key={route.basePath}>
                <ListItem button onClick={() => handleOpenMenu(route.basePath)}>
                  <ListItemText>
                    {route.label}{' '}
                    {openMenu === route.basePath ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </ListItemText>
                </ListItem>
                <Collapse
                  in={openMenu === route.basePath}
                  className={classes.childRoutesWrapper}
                >
                  {route.children
                    .filter(hideOnMobileFilter)
                    .map((childRoute, index) => {
                      if (isRoute(childRoute)) {
                        return (
                          <ListItem
                            key={childRoute.as || childRoute.href}
                            button
                            component={ButtonLink}
                            href={childRoute.href}
                            as={childRoute.as}
                            onClick={handleClose}
                            className={classes.listItemChildRoute}
                          >
                            <ListItemText>{childRoute.label}</ListItemText>
                          </ListItem>
                        )
                      } else if (isRouteDivider(childRoute)) {
                        return <Divider key={`divider-${index}`} />
                      }
                    })}
                </Collapse>
              </React.Fragment>
            )
          }
          return null
        })}
        <Divider />
        <NavUserMobile
          loggedInRoutes={navUserLoggedInRoutes}
          loggedOutRoutes={navUserLoggedOutRoutes}
          onLinkClick={handleClose}
        />
      </List>
    </SwipeableDrawer>
  )
}

export default NavMobile
