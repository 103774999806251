import { AnalyticsBrowser } from '@segment/analytics-next'
import React from 'react'

const AnalyticsContext = React.createContext<AnalyticsBrowser | undefined>(
  undefined,
)

type Props = {
  writeKey?: string
  children: React.ReactNode
}
export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const analytics = React.useMemo(
    () => (writeKey ? AnalyticsBrowser.load({ writeKey }) : undefined),
    [writeKey],
  )

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const browser = React.useContext(AnalyticsContext)
  const snippet = typeof window !== 'undefined' && window.analytics

  // Return globally set analytics from snippet if set
  const result = snippet || browser

  // Prevent showing this warning when building the page on the server
  if (!result && typeof window !== 'undefined') {
    console.warn('Segment not loaded!')
  }
  return result
}

export default AnalyticsProvider
