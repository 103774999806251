import { Box, LinearProgress, makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

const useStyles = makeStyles((_theme) => ({
  root: {
    opacity: 1,
    transition: 'opacity 0.4s',
    '&.route-loading': {
      opacity: 0.3,
    },
  },
  linearProgressWrapper: {
    position: 'relative',
    '& .MuiLinearProgress-root': {
      position: 'absolute',
      width: '100%',
    },
  },
}))

const RouteLoadingIndicator: React.FC = ({ children }) => {
  const classes = useStyles()
  const router = useRouter()
  const [loading, setLoading] = useState(false)

  function routeChangeStart() {
    setLoading(true)
  }
  function routeChangeComplete() {
    setLoading(false)
  }
  useEffect(() => {
    router.events.on('routeChangeStart', routeChangeStart)
    router.events.on('routeChangeComplete', routeChangeComplete)
    router.events.on('routeChangeError', routeChangeComplete)
    return () => {
      router.events.off('routeChangeStart', routeChangeStart)
      router.events.off('routeChangeComplete', routeChangeComplete)
      router.events.off('routeChangeError', routeChangeComplete)
    }
  }, [router.events])
  return (
    <>
      {loading && (
        <div className={classes.linearProgressWrapper}>
          <LinearProgress />
        </div>
      )}
      <div
        className={classnames(
          classes.root,
          loading ? 'route-loading' : 'route-loaded',
        )}
      >
        {children}
      </div>
    </>
  )
}

export default RouteLoadingIndicator
