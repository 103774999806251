import { makeStyles } from '@material-ui/core'
import React from 'react'

import Nav, { TNavRoutes, TNavRoutesFlat } from '../Nav'
import RouteLoadingIndicator from '../RouteLoadingIndicator'

export interface LayoutProps {
  Component: React.ElementType
  Logo: React.ElementType
  NavContent?: React.ElementType
  Footer?: React.ElementType
  navRoutes: TNavRoutes
  navUserLoggedInRoutes?: TNavRoutesFlat
  navUserLoggedOutRoutes?: TNavRoutesFlat
}

const useStyles = makeStyles((_theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  content: {
    display: 'block',
    flexGrow: 1,
  },
  componentWrapper: {
    // marginTop: theme.spacing(3),
  },
  footer: {},
}))

const Layout: React.FC<LayoutProps> = ({
  Component,
  Logo,
  Footer,
  NavContent,
  navRoutes,
  navUserLoggedInRoutes,
  navUserLoggedOutRoutes,
  // apolloErrors
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Nav
        Logo={Logo}
        Content={NavContent}
        routes={navRoutes}
        navUserLoggedInRoutes={navUserLoggedInRoutes}
        navUserLoggedOutRoutes={navUserLoggedOutRoutes}
      />
      <div className={classes.content}>
        <RouteLoadingIndicator>
          <div className={classes.componentWrapper}>
            <Component />
          </div>
        </RouteLoadingIndicator>
      </div>
      {Footer && (
        <div className={classes.footer}>
          <Footer />
        </div>
      )}
    </div>
  )
}

export default Layout
