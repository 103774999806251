import { z } from 'zod'

const numeric = z.string().transform((value) => +value)
export const PaymentStatsParser = z
  .object({
    totalDonations: numeric,
    numDonations: numeric,
    numDonors: numeric,
    totalDonationsLastYear: numeric,
    numDonationsLastYear: numeric,
    numDonorsLastYear: numeric,
  })
  .nullish()
