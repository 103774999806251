import React from 'react'

type LogoProps = {
  className?: string
}

const Logo: React.FC<LogoProps> = ({ className }) => {
  return (
    <img
      className={className}
      src="https://res.cloudinary.com/cea/image/upload/v1681753801/eafunds/ea-funds-logo.svg"
    />
  )
}

export default Logo
