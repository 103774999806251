import { makeStyles, Button, Menu, MenuItem, Divider } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import ButtonLink from '../ButtonLink'
import { NavProps } from './'
import NavUserDesktop from './NavUserDesktop'
import { TRouteMember } from './types'
import { isRoute, isRouteDivider, isRouteParent } from './utils'

function hideOnDesktopFilter(route: TRouteMember) {
  return isRoute(route) ? !route.hideOnDesktop : true
}

// Render nav buttons on desktops
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  navWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  navContentWrapper: {},
  navUserWrapper: {},

  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '&.active': {
      paddingBottom: 3,
      borderBottom: `3px solid ${theme.palette.primary.main}`,
      borderRadius: 0,
    },
    '&.open': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  menuItem: {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: '0.875rem',
    paddingTop: 0,
    paddingBottom: 0,
  },
  expandMoreIcon: {
    color: theme.palette.grey[500],
  },
  divider: {
    display: 'inline-block',
    height: '1.5em',
    margin: `auto ${theme.spacing(2)}px`,
    borderLeft: '1px solid #CCC',
  },
}))

const NavDesktop: React.FC<NavProps> = ({
  routes,
  navUserLoggedInRoutes,
  navUserLoggedOutRoutes,
  Content,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [openMenu, setOpenMenu] = useState<string>()
  const { asPath } = useRouter()

  function handleRouteParentClick(basePath: string) {
    return function (event: React.MouseEvent<HTMLButtonElement>) {
      if (anchorEl || openMenu) {
        handleCloseMenu()
      } else {
        setAnchorEl(event.currentTarget)
        setOpenMenu(basePath)
      }
    }
  }

  function handleCloseMenu() {
    setAnchorEl(undefined)
    setOpenMenu(undefined)
  }

  return (
    <div className={classes.root}>
      <div className={classes.navWrapper}>
        {routes.filter(hideOnDesktopFilter).map((route, index) => {
          if (isRoute(route)) {
            const active = asPath
              .split('/')
              .includes((route.as || route.href).substring(1))
            return (
              <ButtonLink
                className={classnames(classes.button, { active })}
                key={route.as || route.href}
                color={route.color || 'inherit'}
                variant={route.variant || 'text'}
                as={route.as}
                href={route.href}
                onClick={handleCloseMenu}
              >
                {route.label}
              </ButtonLink>
            )
          } else if (isRouteParent(route)) {
            const active = asPath.includes(route.basePath)
            const open = openMenu === route.basePath
            return (
              <React.Fragment key={route.basePath}>
                <Button
                  className={classnames(classes.button, { active, open })}
                  onClick={handleRouteParentClick(route.basePath)}
                >
                  {route.label}{' '}
                  <ExpandMoreIcon className={classes.expandMoreIcon} />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={!!anchorEl && open}
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  getContentAnchorEl={null}
                >
                  {route.children
                    .filter(hideOnDesktopFilter)
                    .map((childRoute, index) => {
                      if (isRoute(childRoute)) {
                        return (
                          <MenuItem
                            key={childRoute.as || childRoute.href}
                            component={ButtonLink}
                            as={childRoute.as}
                            href={childRoute.href}
                            className={classes.menuItem}
                            onClick={handleCloseMenu}
                          >
                            {childRoute.label}
                          </MenuItem>
                        )
                      } else if (isRouteDivider(childRoute)) {
                        return <Divider key={`divider-${index}`} />
                      }
                    })}
                </Menu>
              </React.Fragment>
            )
          } else if (isRouteDivider(route)) {
            return <div className={classes.divider} key={`divider-${index}`} />
          }
          return null
        })}
      </div>
      {Content && (
        <div className={classes.navContentWrapper}>
          <Content />
        </div>
      )}
      <div className={classes.navUserWrapper}>
        <NavUserDesktop
          loggedInRoutes={navUserLoggedInRoutes}
          loggedOutRoutes={navUserLoggedOutRoutes}
        />
      </div>
    </div>
  )
}

export default NavDesktop
