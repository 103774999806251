import {
  useGetAddressesQuery,
  useFindPersonByNameOrEmailQuery,
} from '../graphql'
import { flattenGQLArrayResult, makeQueryWithReducer } from '../utils'
import { useSkipWithoutPerson } from '../utils/useWithPerson'

export * from './types'

export const useGetAddressesQueryWithReducer = makeQueryWithReducer(
  useGetAddressesQuery,
  (result) => flattenGQLArrayResult(result.data?.Addresses),
)

export const useFindPersonByNameOrEmailQueryWithReducer = makeQueryWithReducer(
  useFindPersonByNameOrEmailQuery,
  (result) => flattenGQLArrayResult(result.data?.People),
  useSkipWithoutPerson,
)
