import Script from 'next/script'
import React from 'react'

type IntercomProps = {
  appId?: string
  name?: string
  email?: string
  user_id?: string
}

const Intercom: React.FC<IntercomProps> = ({ appId }) => {
  if (!appId) return null
  if (!/^[a-z0-9]+$/.test(appId)) throw new Error(`Invalid Intercom app ID`)

  /** Render the Intercom snippet with an app_id key */
  function renderSnippet(appId: string): string {
    return `
    window.intercomSettings = {
      app_id: "${appId}",
    };
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');
    ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];
    i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');
    s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${appId}';
    var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();
    }else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    `
  }

  return (
    <Script
      id="intercom-snippet"
      dangerouslySetInnerHTML={{ __html: renderSnippet(appId) }}
      strategy="lazyOnload"
    />
  )
}
export default Intercom
