import { Draft, produce } from 'immer'
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from 'react'

import {
  TPerson,
  useGetPersonLazyQuery,
  useSyncAuth0UserMutation,
  useGetPersonByIdLazyQuery,
} from '@aletheia/graphql'

import { usePrevious } from '../../utils/hooks'
import { TAuth0User, useAuth } from '../Auth'
import { ErrorDialog, ErrorDialogProps } from '../Auth/ErrorDialog'
import { useIntercom } from '../Intercom'
import { useAnalytics } from '../Segment'

const PersonContext = createContext<PersonProviderValue>({
  Person: undefined,
  refreshPerson: () => undefined,
  loading: false,
  picture: undefined,
})
PersonContext.displayName = 'PersonContext'
const { Provider } = PersonContext

export type PersonProviderProps = {
  readonly Person: TPerson | undefined
  readonly loading: boolean
  readonly picture: string | null | undefined
}

export type PersonProviderMethods = {
  refreshPerson: () => void
}

export type PersonProviderValue = PersonProviderProps & PersonProviderMethods

export type PersonProviderState = PersonProviderProps & {
  readonly error?: Error
}

export const initialState: PersonProviderState = {
  Person: undefined,
  loading: true,
  picture: undefined,
}

/** Update the `Person` property of an `PersonProviderState` object */
export function setPerson(
  state: PersonProviderState,
  Person: TPerson,
): PersonProviderState {
  return produce(state, (draft: Draft<PersonProviderState>) => {
    draft.Person = Person
  })
}

/** Update the `Loading` property of an `PersonProviderState` object */
export function setLoading(
  state: PersonProviderState,
  loading: boolean,
): PersonProviderState {
  return produce(state, (draft: Draft<PersonProviderState>) => {
    draft.loading = loading
  })
}

/** Update the `Person` property of an `PersonProviderState` object */
export function setError(
  state: PersonProviderState,
  error: Error | undefined,
): PersonProviderState {
  return produce(state, (draft: Draft<PersonProviderState>) => {
    draft.error = error
  })
}

/**
 * Provides the current logged-in user's Person object (or the Person object
 * matching the provided `personId`), for use in descendant components.
 * Contains methods for getting the Person from the database, and for
 * refreshing the data if it changes.
 */
export const PersonProvider: React.FC<{
  personId?: string
  errorDialog?: React.FC<ErrorDialogProps>
}> = ({ children, personId, errorDialog = ErrorDialog }) => {
  const [state, setState] = useState<PersonProviderState>(initialState)
  const analytics = useAnalytics()
  const intercom = useIntercom()
  const { user } = useAuth()
  const prevUser = usePrevious(user)
  /**
   * This query is for performing the initial sync of the Person object.
   * Typically it'll be the only query that gets the Person
   */
  const [syncAuth0UserMutation] = useSyncAuth0UserMutation()
  /**
   * This query is for refetching the person after initial sync (e.g. if the
   * Person's details are updated). The fetchPolicy is network-only, meaning it
   * will bypass the client Apollo cache and always get a fresh version of the
   * person from the server
   */
  const [getPersonQuery, { data: PersonQueryData }] = useGetPersonLazyQuery({
    fetchPolicy: 'network-only',
  })

  /**
   * This query is for getting a Person if a personId is provided on props.
   * Instead of running the syncAuth0User mutation, we just get the person
   * specified by the id.
   */
  const [
    getPersonByIdQuery,
    { data: PersonByIdQueryData, loading: PersonByIdLoading },
  ] = useGetPersonByIdLazyQuery()

  /** Handler to set loading state */
  const handleSetLoading = (loading: boolean) => {
    setState((state) => setLoading(state, loading))
  }

  /**
   * Sync the Auth0 user with the Parfit API. This will create a new Person
   * object if the email doesn't exist yet, or will attach the Auth0 user ID to
   * an existing Person with a matching email.
   */
  const syncUser = useCallback(
    async (auth0User: TAuth0User) => {
      await handleSetLoading(true)
      try {
        const res = await syncAuth0UserMutation({ variables: { auth0User } })
        const Person: TPerson | undefined | null =
          res.data?.syncAuth0User?.Person
        if (Person) {
          setState((state) => setPerson(state, Person))
        }
      } catch (err: any) {
        setState((state) => setError(state, err))
      } finally {
        handleSetLoading(false)
      }
    },
    [syncAuth0UserMutation],
  )

  /** If the component has a personId on props, use it to get the person */
  const getPersonById = useCallback(
    async (personId: string) => {
      if (!personId) throw new Error(`Cannot get Person, personId is undefined`)
      await getPersonByIdQuery({ variables: { personId } })
    },
    [getPersonByIdQuery],
  )

  /** Refresh the Person by getting current data from the server */
  const refreshPerson = useCallback(async () => {
    if (!personId) {
      await getPersonQuery()
    } else {
      // TODO: this doesn't work yet, because it'll just get from the cache.
      // The alternative is to set the query fetchPolicy as `network-only`, but
      // then any time you navigate to a new route you reload the person
      await getPersonById(personId)
    }
  }, [getPersonById, getPersonQuery, personId])

  /** Identify the person after login, assuming there's no personId on props */
  useEffect(() => {
    if (!state.Person?.id || !analytics || personId) return
    analytics.identify(state.Person.id, {
      name: state.Person.fullName,
      email: state.Person.email,
    })
  }, [
    analytics,
    personId,
    state.Person?.email,
    state.Person?.fullName,
    state.Person?.id,
  ])

  /** Identify the person after login for the intercom messenger */
  useEffect(() => {
    if (!state.Person?.id || !intercom || personId) return
    // Note: Intercom is already booted, this adds additional user context.
    // See https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
    intercom('boot', {
      name: state.Person.fullName || undefined,
      email: state.Person.email || undefined,
      user_id: state.Person.id,
    })
  }, [
    intercom,
    personId,
    state.Person?.email,
    state.Person?.fullName,
    state.Person?.id,
  ])

  /**
   * If we get a new Auth0 user object, sync with the server (except in cases
   * where we've got a personId on props)
   */
  useEffect(() => {
    if (user && !prevUser && !personId) {
      syncUser(user)
    }
  }, [prevUser, user, syncUser, personId])

  /**
   * If we get a new Auth0 user object, sync with the server (except in cases
   * where we've got a personId on props)
   */
  useEffect(() => {
    if (personId) {
      getPersonById(personId)
    }
  }, [personId, getPersonById])

  /** If we've run getPersonLazyQuery, update Person */
  useEffect(() => {
    const NewPerson = PersonQueryData?.Person
    if (NewPerson && NewPerson !== state.Person) {
      setState((state) => setPerson(state, NewPerson))
    }
  }, [PersonQueryData?.Person, state.Person])

  /** If we've run getPersonByIdLazyQuery, update Person */
  useEffect(() => {
    const NewPerson = PersonByIdQueryData?.Person
    if (NewPerson && NewPerson !== state.Person) {
      setState((state) => setPerson(state, NewPerson))
    }
  }, [PersonByIdQueryData?.Person, state.Person])

  /**
   * Keep component loading state in sync with getPersonByIdLazyQuery loading
   * state
   */
  useEffect(() => {
    if (!personId) return
    handleSetLoading(PersonByIdLoading)
  }, [PersonByIdLoading, personId])

  /** Log errors out when they get set */
  useEffect(() => {
    if (state.error) {
      console.error(state.error.message)
    }
  }, [state.error])

  const { error, ...rest } = state
  const value = { ...rest, refreshPerson, picture: user?.picture }
  const Dialog = errorDialog
  const onClose = () => setState((state) => setError(state, undefined))
  return (
    <Provider value={value}>
      <Dialog error={error} onClose={onClose} />

      {children}
    </Provider>
  )
}

export const usePerson = (): PersonProviderValue => useContext(PersonContext)
