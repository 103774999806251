/// <reference types="@types/intercom-web" />
import { Button, ButtonProps } from '@material-ui/core'
import React from 'react'

type ShowIntercomButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onError?: () => void
}

export const ShowIntercomButton: React.FC<
  ShowIntercomButtonProps & ButtonProps
> = ({ onError, onClick, ...buttonProps }) => {
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    const intercom = window.Intercom

    if (!intercom) {
      handleError()
      return
    }
    intercom('show')
    if (onClick) onClick(event)
  }

  function handleError() {
    if (onError) onError()
  }

  return <Button {...buttonProps} onClick={handleClick} />
}
