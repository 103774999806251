import { ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { GraphQLError } from 'graphql'
import React, { useState } from 'react'

import { useApollo } from '../../lib/apollo'
import Link from '../Link'

type ApolloProviderProps = {
  state: NormalizedCacheObject
}

const EMAIL_MISMATCH_ERROR_RE = /Email does not match JWT claims/

const ErrorDisplay: React.FC<{ error: GraphQLError }> = ({ error }) => {
  const { message } = error
  if (EMAIL_MISMATCH_ERROR_RE.test(message))
    return (
      <>
        Your email has changed. You need to{' '}
        <Link href="/api/auth/login">log in</Link> again
      </>
    )
  return <>{message}</>
}

const ApolloProviderWrapper: React.FC<ApolloProviderProps> = ({
  state,
  children,
}) => {
  const client = useApollo(state)
  return (
    <>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </>
  )
}

export default ApolloProviderWrapper
