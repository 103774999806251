import { pathParamToPath } from '../../utils/dataFetching'
import { TAuth0User } from '../Auth'
import {
  TRoute,
  TRouteParent,
  TRouteDivider,
  TRouteMember,
  TNavRoutes,
} from './types'

/** Guard to tell if a given route-like object is a TRoute */
export function isRoute(routeMember: TRouteMember): routeMember is TRoute {
  return !!(routeMember as TRoute).href
}

/** Guard to tell if a given route-like object is a TRouteParent */
export function isRouteParent(
  routeMember: TRouteMember,
): routeMember is TRouteParent {
  return !!(routeMember as TRouteParent).children
}

/** Guard to tell if a given route-like object is a TRouteDivider */
export function isRouteDivider(
  routeMember: TRouteMember,
): routeMember is TRouteDivider {
  return routeMember === 'DIVIDER'
}

/** Flatten route declaration into a single array of routes */
export function flattenRoutes(routes: TNavRoutes): TRoute[] {
  const flatRoutes: TRoute[] = []
  for (const route of routes) {
    if (isRoute(route)) {
      flatRoutes.push(route)
    } else if (isRouteParent(route)) {
      for (const childRoute of route.children) {
        if (isRoute(childRoute)) flatRoutes.push(childRoute)
      }
    }
  }
  return flatRoutes
}

/** Get all of the paths in a routes declaration */
export function getPathsFromRoutes(routes: TNavRoutes): string[] {
  const flatRoutes = flattenRoutes(routes)
  return flatRoutes.map((route) => route.as || route.href)
}

/** Verify if a path is found within a routes declaration */
export function pathInRoutes(
  path: string | string[],
  routes: TNavRoutes,
): boolean {
  const _path = Array.isArray(path) ? pathParamToPath(path) : path
  const paths = getPathsFromRoutes(routes)
  return paths.some((testPath) => testPath === _path)
}

/**
 * Compute a deterministic color value for a user's avatar based on their name
 *
 * Code from
 * https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starti
 * g-from-his-username-with-javascript-cd0675943b66
 */
export const stringToHslColor = (
  str: string,
  saturation = 66,
  luminance = 60,
): string => {
  let hash = 0
  for (const s of str.split('')) {
    hash = s.charCodeAt(0) + ((hash << 5) - hash)
  }

  const h = hash % 360
  return `hsl(${h}, ${saturation}%, ${luminance}%)`
}

/** Get the full name of an Auth0 user */
export const getUserName = (user: TAuth0User): string =>
  `${user.given_name} ${user.family_name}`.trim() || user.email
